import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import * as R from 'ramda'
import { connect } from 'react-redux'
import { Button, Checkbox, FormControlLabel, Modal } from '@material-ui/core'

import RoleTextField from './role-text-field'

import {
  createRoleRequest,
  deleteRoleRequest,
  getAccessList,
  getRole,
  getRoleRequest,
  getSelectedRole,
  updateRoleRequest,
  getAccessListForUserRequest,
} from '../../../modules/users'

import { useStyles } from './styles'
import assets from '../../../assets'
import clsx from 'clsx'

const Wrapper = styled.div`
  width: 452px;
  display: flex;
  flex-direction: column;
  ::-webkit-scrollbar {
    display: none;
  }
`

const TitleWrapper = styled.div`
  display: flex;
  position: relative;
  margin-bottom: 10px;
`

const CreateTitle = styled.div`
  padding: 11px 36px 9px 20px;
  background: #ffffff;
  width: 452px;
  height: 36px;
  box-shadow: 0 6px 14px rgba(2, 9, 75, 0.06);
  border-radius: 8px;
  font-family: FuturaMediumC, sans-serif;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.4);
`

const Title = styled.div`
  padding: 11px 36px 9px 20px;
  background: #ffffff;
  width: 414px;
  height: 36px;
  box-shadow: 0 6px 14px rgba(2, 9, 75, 0.06);
  border-radius: 8px 0 0 8px;
  font-family: FuturaMediumC, sans-serif;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.4);
`

const DeleteIcon = styled.img.attrs({ src: assets.img.deleteColorIcon })`
  position: absolute;
  right: 50px;
  top: 8px;
  cursor: pointer;
`

const EditIcon = styled.img.attrs({ src: assets.img.pencilRed })``
const EditActiveIcon = styled.img.attrs({ src: assets.img.pencil })``
const EditIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  margin-left: 2px;
  background: linear-gradient(0deg, #ffffff, #ffffff),
    linear-gradient(92.15deg, #f6a26d -38.69%, #f87c2e 107.08%);
  box-shadow: 0 6px 14px rgba(2, 9, 75, 0.06);
  border-radius: 0 8px 8px 0;
  cursor: pointer;
  ${({ isActive }) =>
    isActive &&
    css`
      background: linear-gradient(92.15deg, #f6a26d -38.69%, #f87c2e 107.08%);
    `}
`

const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  padding: 25px 20px 5px 20px;
  width: 452px;
  background: #ffffff;
  box-shadow: 0 6px 14px rgba(2, 9, 75, 0.06);
  border-radius: 8px;
  box-sizing: border-box;
  max-height: 700px;
  ::-webkit-scrollbar {
    display: none;
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  margin-bottom: 20px;
  margin-top: 10px;
`

const Container = styled.div`
  width: 516px;
  height: 245px;
  background: #ffffff;
  box-shadow: 0 6px 14px rgba(2, 9, 75, 0.06);
  border-radius: 8px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
`
const TextContainer = styled.div`
  margin-top: 70px;
  font-family: FuturaBookC, sans-serif;
  font-size: 25px;
  line-height: 30px;
  text-align: center;
  color: #3d4751;
  font-weight: normal;
`

const ButtonsContainer = styled.div`
  margin-top: auto;
  margin-bottom: 20px;
  display: flex;
  background: #fff;
  justify-content: center;
`

const ConfirmButton = styled.button`
  width: 95px;
  height: 36px;
  background: linear-gradient(92.32deg, #f49355 0%, #f0640c 100%);
  border-radius: 8px;
  outline: 0;
  border: 0;
  font-family: FuturaMediumC, sans-serif;
  font-size: 17px;
  color: rgba(255, 255, 255, 1);
  cursor: pointer;
`

const CancelButton = styled(ConfirmButton)`
  background: linear-gradient(0deg, #9ba1a6, #9ba1a6),
    linear-gradient(92.32deg, #f49355 0%, #f0640c 100%);
  margin-left: 8px;
  cursor: pointer;
`

const AccessWrapper = styled.div`
  padding-left: 20px;
  display: flex;
  margin-bottom: 20px;
  justify-content: space-between;
  align-items: center;
`
const AccessTitle = styled.div`
  font-family: FuturaMediumC, sans-serif;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.9);
`
const AccessCheckWrapper = styled.div`
  width: 250px;
  display: flex;
`
const AccessViewTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 125px;
  font-family: FuturaBookC, sans-serif;
  font-size: 12px;
  color: rgba(18, 22, 26, 0.4);
`
const AccessCreateTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 125px;
  font-family: FuturaBookC, sans-serif;
  font-size: 12px;
  color: rgba(18, 22, 26, 0.4);
`

const setRoleName = role => {
  switch (role) {
    case 'users':
      return 'Пользователи'
    case 'organizations':
      return 'Организации'
    case 'transports':
      return 'Транспорт'
    case 'orders':
      return 'Заявки'
    case 'schedule':
      return 'Расписание'
    case 'promotions':
      return 'Акции'
    case 'categories':
      return 'Категории'
    case 'roles':
      return 'Роли'
    case 'analytics':
      return 'Аналитика'
    default:
      return role.charAt(0).toUpperCase() + role.slice(1)
  }
}

const RoleCard = ({
  selectedRole,
  setRoleCardMode,
  roleCardMode,
  updateRole,
  createRole,
  deleteRole,
  accessList,
  getRole,
  role,
  accessListForUser,
  setModalShow,
  editAccess,
}) => {
  const classes = useStyles()
  const isEditMode = roleCardMode.open && roleCardMode.mode === 'EDIT'
  const isCreatingMode = roleCardMode.open && roleCardMode.mode === 'CREATE'

  const [isDeletingRole, setIsDeletingRole] = useState(false)
  const [accessListData, setAccessListData] = useState({})
  const [deleteAccess, setDeleteAccess] = useState([])
  const [addAccess, setAddAccess] = useState([])

  const [roleEditData, setRoleEditData] = useState({
    id: '',
    name: '',
  })
  const [roleCreateData, setRoleCreateData] = useState({
    name: '',
  })

  const groupBy = (xs, key) =>
    xs.reduce((rv, x) => {
      ;(rv[x[key]] = rv[x[key]] || []).push({
        ...x,
        checked: !isCreatingMode
          ? role.length > 0 &&
            !!role.find(
              item =>
                item.module === x.module &&
                item['accessType'] === x['accessType'],
            )
          : false,
      })
      return rv
    }, {})

  useEffect(() => {
    if (selectedRole && selectedRole.id) {
      getRole({ roleId: selectedRole.id })
    }
  }, [selectedRole])

  useEffect(() => {
    setAccessListData(groupBy(accessList, 'module'))
  }, [accessList, role])

  const handleSubmit = e => {
    e.preventDefault()
    setRoleCardMode({
      open: false,
      mode: '',
    })
    if (!isCreatingMode) {
      updateRole({
        body: { name: roleEditData.name },
        roleId: roleEditData.id,
        deleteAccess,
        addAccess,
      })
      setRoleEditData({
        id: '',
        name: '',
      })
    } else {
      createRole({ body: { ...roleCreateData, accesses: [...addAccess] } })
      setRoleCreateData({
        name: '',
      })
    }
    accessListForUser()
  }

  useEffect(() => {
    if (!isCreatingMode) {
      setRoleEditData({
        name: selectedRole.name || '',
        id: selectedRole.id || '',
      })
    }
    accessListForUser()
  }, [selectedRole])

  const handleEditIconClick = () => {
    if (isEditMode) {
      setRoleCardMode({
        open: true,
        mode: 'VIEW',
      })
      setRoleEditData({
        name: selectedRole.name,
        id: selectedRole.id,
      })
      setAccessListData(groupBy(accessList, 'module'))
    } else if (isCreatingMode) {
      setRoleCardMode({
        open: false,
        mode: '',
      })
      setRoleCreateData({
        name: '',
      })
    } else {
      setRoleCardMode({
        open: true,
        mode: 'EDIT',
      })
    }
    accessListForUser()
  }

  const handleDelete = () => {
    deleteRole({ roleId: selectedRole.id })
    setIsDeletingRole(false)
    setRoleCardMode({
      open: false,
      mode: '',
    })
    setRoleEditData({
      name: '',
      id: '',
    })
    accessListForUser()
  }
  const handleViewCheckboxChange = (item, viewObj, createObj) => {
    const newView = {
      ...viewObj,
      checked: !viewObj.checked,
    }
    if (viewObj.checked) {
      if (
        role.length > 0 &&
        role.find(
          item =>
            item.module === viewObj.module &&
            item['accessType'] === viewObj['accessType'],
        )
      ) {
        setDeleteAccess([...deleteAccess, viewObj.id])
      } else {
        setAddAccess(addAccess.filter(item => item !== viewObj.id))
      }
    } else {
      if (
        isCreatingMode ||
        (role.length > 0 &&
          !role.find(
            item =>
              item.module === viewObj.module &&
              item['accessType'] === viewObj['accessType'],
          )) ||
        role.length === 0
      ) {
        setAddAccess([...addAccess, viewObj.id])
      } else {
        setDeleteAccess(deleteAccess.filter(item => item !== viewObj.id))
      }
    }
    setAccessListData({
      ...accessListData,
      [item]: [newView, createObj],
    })
    accessListForUser()
  }
  const handleCreateCheckboxChange = (item, viewObj, createObj) => {
    const newCreate = {
      ...createObj,
      checked: !createObj.checked,
    }
    if (createObj.checked) {
      if (
        role.length > 0 &&
        role.find(
          item =>
            item.module === createObj.module &&
            item['accessType'] === createObj['accessType'],
        )
      ) {
        setDeleteAccess([...deleteAccess, createObj.id])
      } else {
        setAddAccess(addAccess.filter(item => item !== createObj.id))
      }
    } else {
      if (
        isCreatingMode ||
        (role.length > 0 &&
          !role.find(
            item =>
              item.module === createObj.module &&
              item['accessType'] === createObj['accessType'],
          )) ||
        role.length === 0
      ) {
        setAddAccess([...addAccess, createObj.id])
      } else {
        setDeleteAccess(deleteAccess.filter(item => item !== createObj.id))
      }
    }
    setAccessListData({
      ...accessListData,
      [item]: [newCreate, viewObj],
    })
    accessListForUser()
  }

  return (
    <Wrapper>
      <Modal open={isDeletingRole} onClose={() => setIsDeletingRole(false)}>
        <Container>
          <TextContainer>
            Удалить роль <br />
            {selectedRole.name} ?
          </TextContainer>
          <ButtonsContainer>
            <ConfirmButton
              onClick={() =>
                editAccess ? handleDelete() : setModalShow(true)
              }
            >
              Да
            </ConfirmButton>
            <CancelButton onClick={() => setIsDeletingRole(false)}>
              Отмена
            </CancelButton>
          </ButtonsContainer>
        </Container>
      </Modal>
      <TitleWrapper>
        {!isCreatingMode ? (
          <>
            <DeleteIcon onClick={() => setIsDeletingRole(true)} />
            <Title>Настройки Роли</Title>
            <EditIconWrapper
              isActive={isEditMode}
              onClick={() =>
                editAccess ? handleEditIconClick() : setModalShow(true)
              }
            >
              {isEditMode ? <EditActiveIcon /> : <EditIcon />}
            </EditIconWrapper>
          </>
        ) : (
          <CreateTitle>Настройки Роли</CreateTitle>
        )}
      </TitleWrapper>
      <FormWrapper onSubmit={handleSubmit}>
        <RoleTextField
          onChange={e =>
            isCreatingMode
              ? setRoleCreateData({ ...roleCreateData, name: e.target.value })
              : setRoleEditData({ ...roleEditData, name: e.target.value })
          }
          disabled={!isEditMode && !isCreatingMode}
          label="Название"
          value={isCreatingMode ? roleCreateData.name : roleEditData.name}
        />
        <AccessWrapper>
          <AccessTitle />
          <AccessCheckWrapper>
            <AccessViewTitle>Просмотр</AccessViewTitle>
            <AccessCreateTitle>Создание и редактирование</AccessCreateTitle>
          </AccessCheckWrapper>
        </AccessWrapper>
        {accessListData &&
          Object.keys(accessListData).map(item => {
            const viewObj = accessListData[item].find(
              item => item['accessType'] === 'view',
            )
            const createObj = accessListData[item].find(
              item => item['accessType'] === 'edit',
            )

            return (
              <AccessWrapper key={item}>
                <AccessTitle>{setRoleName(item)}</AccessTitle>
                <AccessCheckWrapper>
                  <AccessViewTitle>
                    <FormControlLabel
                      disabled={!isCreatingMode && !isEditMode}
                      label={null}
                      classes={{
                        root: classes.labelRoot,
                      }}
                      control={
                        <Checkbox
                          checkedIcon={
                            <span
                              className={clsx(
                                classes.icon,
                                classes.checkedIcon,
                              )}
                            />
                          }
                          icon={<span className={classes.icon} />}
                          className={classes.root}
                          onChange={() =>
                            handleViewCheckboxChange(item, viewObj, createObj)
                          }
                          checked={viewObj.checked}
                          name={viewObj.id.toString()}
                        />
                      }
                    />
                  </AccessViewTitle>
                  <AccessCreateTitle>
                    <FormControlLabel
                      disabled={!isCreatingMode && !isEditMode}
                      label={null}
                      classes={{
                        root: classes.labelRoot,
                      }}
                      key={item.id}
                      control={
                        <Checkbox
                          checkedIcon={
                            <span
                              className={clsx(
                                classes.icon,
                                classes.checkedIcon,
                              )}
                            />
                          }
                          onChange={() =>
                            handleCreateCheckboxChange(item, viewObj, createObj)
                          }
                          icon={<span className={classes.icon} />}
                          className={classes.root}
                          checked={createObj.checked}
                          name={createObj.id.toString()}
                        />
                      }
                    />
                  </AccessCreateTitle>
                </AccessCheckWrapper>
              </AccessWrapper>
            )
          })}
        {(isEditMode || isCreatingMode) && (
          <ButtonWrapper>
            <Button
              type="submit"
              classes={{
                root: classes.addButton,
              }}
            >
              {isCreatingMode ? 'Создать' : 'Сохранить'}
            </Button>
            <Button
              onClick={handleEditIconClick}
              classes={{
                root: classes.cancelButton,
              }}
            >
              Отмена
            </Button>
          </ButtonWrapper>
        )}
      </FormWrapper>
    </Wrapper>
  )
}

export default connect(
  R.applySpec({
    selectedRole: getSelectedRole,
    accessList: getAccessList,
    role: getRole,
  }),
  {
    updateRole: updateRoleRequest,
    createRole: createRoleRequest,
    deleteRole: deleteRoleRequest,
    getRole: getRoleRequest,
    accessListForUser: getAccessListForUserRequest,
  },
)(RoleCard)
