import React from 'react'
import styled from 'styled-components'
import moment from 'moment'

const Container = styled.a`
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0 6px 14px rgba(2, 9, 75, 0.06);
  box-sizing: border-box;
  padding: 6px 7px 3px 7px;
  margin-bottom: 5px;
  user-select: none;
  border-radius: 4px;
  color: green;
  display: flex;
`

const Content = styled.div`
  flex-grow: 1;
  flex-basis: 100%;
  display: flex;
  flex-direction: column;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`

const AmoId = styled.div`
  font-family: FuturaMediumC sans-serif;
  font-size: 12px;
  color: rgba(18, 22, 26, 0.9);
`

const Time = styled.div`
  font-family: FuturaBookC sans-serif;
  font-size: 12px;
  color: rgba(0, 0, 0, 1);
`

const Organization = styled.div`
  font-family: FuturaMediumC sans-serif;
  font-size: 12px;
  color: rgba(18, 22, 26, 0.7);
  margin-top: 2px;
`

const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: FuturaMediumC sans-serif;
  font-size: 12px;
  color: rgba(18, 22, 26, 0.7);
  margin-top: 2px;
`
const Info = styled.div`
  display: flex;
`

const Price = styled.div`
  font-family: FuturaMediumC sans-serif;
  font-size: 12px;
  color: rgba(18, 22, 26, 0.4);
  margin-right: 5px;
`

const Material = styled.div`
  font-family: FuturaMediumC sans-serif;
  font-size: 12px;
  color: rgba(18, 22, 26, 0.4);
  border: 1px solid rgba(18, 22, 26, 0.2);
  box-sizing: border-box;
  border-radius: 3px;
  padding: 0 5px;
`
const Address = styled.div`
  font-family: FuturaMediumC;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 9px;
  color: rgba(18, 22, 26, 0.7);
  margin-bottom: 2px;
`

const Dot = styled.div`
  background: ${({ moreOneDay, oneDay, overdue }) => {
    if (moreOneDay) {
      return '#0BD781'
    } else if (oneDay) {
      return '#FAB400'
    } else if (overdue) {
      return '#F55555'
    }
  }};
  border-radius: 50%;
  width: 5px;
  height: 5px;
`

const getStyle = (provided, style) => {
  if (!style) {
    return provided.draggableProps.style
  }

  return {
    ...provided.draggableProps.style,
    ...style,
  }
}

const QuoteItem = props => {
  const {
    quote,
    isDragging,
    isGroupedOver,
    provided,
    style,
    index,
    onClick,
    setModalShow,
    editAccess,
  } = props
  const today = moment()
  const orderDate = moment(JSON.parse(quote.date).date)
  const daysDiffFromOrderDate = Math.ceil(
    moment.duration(orderDate.diff(today)).asDays(),
  )
  const draggableProps = editAccess ? { ...provided.draggableProps } : {}
  const dragHandleProps = editAccess ? { ...provided.dragHandleProps } : {}
  const moreOneDay = daysDiffFromOrderDate > 1
  const oneDay = daysDiffFromOrderDate === -0
  const overdue = daysDiffFromOrderDate < 0
  const address =
    JSON.parse(quote.address).address.length > 35
      ? `${JSON.parse(quote.address).address.slice(0, 35)}...`
      : JSON.parse(quote.address).address
  const material =
    quote.product && quote.product.length > 25
      ? `${quote.product.slice(0, 25)}...`
      : quote.product

  return (
    <Container
      onClick={() => (editAccess ? onClick() : setModalShow(true))}
      isDragging={() => (editAccess ? isDragging : setModalShow(true))}
      isGroupedOver={isGroupedOver}
      ref={provided.innerRef}
      {...draggableProps}
      {...dragHandleProps}
      style={getStyle(provided, style)}
      data-is-dragging={isDragging}
      data-testid={quote.orderNumber}
      data-index={index}
    >
      <Content>
        <Header>
          <AmoId>№{quote?.orderNumber}</AmoId>
          <Time>{JSON.parse(quote.date)?.text}</Time>
        </Header>
        <Organization>{quote?.organization}</Organization>
        <Footer>
          <Info>
            <Price>{Number(quote.price)} ₽</Price>
            <Material>{material}</Material>
          </Info>
        </Footer>
        <Footer>
          <Info>
            <Address>{address}</Address>
          </Info>
          {quote.transportId ? null : (
            <Dot moreOneDay={moreOneDay} oneDay={oneDay} overdue={overdue} />
          )}
        </Footer>
        {quote.transportId ? (
          <Footer>
            <Price>{quote.driverName}</Price>
            <Price>{quote.carNumber}</Price>
          </Footer>
        ) : null}
      </Content>
    </Container>
  )
}

export default React.memo(QuoteItem)
