import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import {
  DeletionPopup as OrganisationsDeletionPopup,
  OrganizationDocumentsPopup,
} from '../components/organizations'
import UserDeletionPopup from '../components/organizations/UserDeletionPopUp'
import {
  getTaskModalVisible,
  getUserDeletionModalVisible,
  getOrganisationDeletionModalVisible,
  getOrganisationDocumentsModalVisible,
} from '../modules/ui'

const Container = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  background: rgba(18, 22, 26, 0.665);

  width: 100%;
  height: 100%;
`

const PopupsContainer = () => {
  const isUserDeletionModalVisible = useSelector(getUserDeletionModalVisible)
  const isOrganisationDeletionModalVisible = useSelector(
    getOrganisationDeletionModalVisible,
  )
  const isOrganizationDocumentsModalVisible = useSelector(
    getOrganisationDocumentsModalVisible,
  )
  const isVisible =
    isUserDeletionModalVisible ||
    isOrganisationDeletionModalVisible ||
    isOrganizationDocumentsModalVisible

  return (
    isVisible && (
      <Container>
        {isUserDeletionModalVisible && <UserDeletionPopup></UserDeletionPopup>}
        {isOrganisationDeletionModalVisible && (
          <OrganisationsDeletionPopup></OrganisationsDeletionPopup>
        )}
        {isOrganizationDocumentsModalVisible && (
          <OrganizationDocumentsPopup></OrganizationDocumentsPopup>
        )}
      </Container>
    )
  )
}

export default PopupsContainer
