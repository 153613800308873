import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  root: {
    padding: '10px 10px 10px 20px',
    backgroundColor: '#ffffff',
    borderRadius: 11,
  },
  firstRow: {
    marginBottom: 15,
  },
  secondRow: {
    marginBottom: 7,
  },
}))

export default useStyles
