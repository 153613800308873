import React, { useEffect, useRef } from 'react'
import { Checkbox, FormControlLabel } from '@material-ui/core'
import clsx from 'clsx'
import styled from 'styled-components'

import useStyles from './styles'

const RoleDropdown = styled.div`
  position: absolute;
  width: 248px;
  max-width: 100%;
  top: 36px;
  left: 6px;
  background: #ffffff;
  box-shadow: 0 6px 14px rgba(2, 9, 75, 0.06);
  border-radius: 8px;
  padding: 10px 20px;
  box-sizing: border-box;
  z-index: 200;

  @media screen and (min-width: 320px) and (max-width: 767px) {
    left: 0;
  }
`

const Label = styled.div`
  font-family: FuturaBookC, sans-serif;
  font-size: 14px;
  display: flex;
  align-items: center;
  color: #000000;
  opacity: 0.6;
  text-transform: none;
`

const useOnClickOutside = (ref, handler) => {
  useEffect(
    () => {
      const listener = event => {
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current.contains(event.target)) {
          return
        }

        handler(event)
      }

      document.addEventListener('mousedown', listener)
      document.addEventListener('touchstart', listener)

      return () => {
        document.removeEventListener('mousedown', listener)
        document.removeEventListener('touchstart', listener)
      }
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [ref, handler],
  )
}

const UserRolesDropdown = ({ roles, onChange, setIsRoleDropdownOpened }) => {
  const classes = useStyles()
  const ref = useRef()
  useOnClickOutside(ref, () => setIsRoleDropdownOpened(false))

  return (
    <RoleDropdown ref={ref}>
      {roles.map(item => (
        <FormControlLabel
          key={item.id}
          control={
            <Checkbox
              checkedIcon={
                <span className={clsx(classes.icon, classes.checkedIcon)} />
              }
              icon={<span className={classes.icon} />}
              className={classes.root}
              checked={item.checked}
              onChange={() => onChange(item)}
              name={item.id.toString()}
            />
          }
          label={<Label>{item.name}</Label>}
        />
      ))}
    </RoleDropdown>
  )
}

export default UserRolesDropdown
