import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Field } from 'redux-form'
import { RelatedProductsCheckBox } from '../common'
import assets from '../../assets'

const Container = styled.div`
  display: flex;
  flex-direction: column;

  background: #ffffff;
  box-shadow: 0px 6px 14px rgba(2, 9, 75, 0.06);
  border-radius: 8px;

  margin-top: 4px;
  padding: 18px 20px 24px 20px;
`

const SectionTitle = styled.h2`
  font-family: FuturaMediumC;
  font-size: 12px;
  text-transform: uppercase;
  color: #000000;
  opacity: 0.2;
  margin: 0;
  padding: 0 0 10px;
`

const inputProps = {
  style: {
    padding: '10px 15px !important',
    fontFamily: 'FuturaBookC',
    fontSize: '14px',
    color: '#000000',
    opacity: '0.8',
  },
}

const RelatedProducts = ({ relatedProducts, selectedProduct }) => {
  return (
    <Container>
      <SectionTitle>
        Сопутствующие товары
      </SectionTitle>
      <Field
        props={{ relatedProducts, selectedProduct }}
        name="relatedProduct"
        inputProps={inputProps}
        component={RelatedProductsCheckBox}
      />
    </Container>
  )
}

export default RelatedProducts
