import React from 'react'
import Button from '@material-ui/core/Button'

import useStyles from './styles'

const AddOrder = ({ onClick }) => {
  const classes = useStyles()

  return (
    <Button
      onClick={onClick}
      classes={{
        root: classes.addOrderButton,
      }}
    >
      Создать заявку
    </Button>
  )
}

export default AddOrder
