import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { makeStyles } from '@material-ui/core/styles'
import {
  Modal,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core'

import { StyledTableCell } from './styled-table'
import { connect } from 'react-redux'
import * as R from 'ramda'
import { deleteOrderRequest, editOrderRequest } from '../../../modules/orders'

import assets from '../../../assets'
import ListStatusFilter from './list-status-filter'
import { getAmoUsersList } from '../../../modules/users'
import ListTableBody from './list-table-body'

const Container = styled.div`
  width: 100%;
  display: flex;
  margin: 15px 0;
  overflow-x: auto;
`
const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`
const SettingWrapper = styled.div`
  position: absolute;
  right: 10px;
  top: 9px;
  cursor: pointer;
`
const SortWrapper = styled.div`
  position: absolute;
  right: 10px;
  top: 22%;
  cursor: pointer;
  ${({ isActive }) =>
          isActive &&
          css`
            top: 14px;
            transform: rotate(180deg);
          `}
`

const useStyles = makeStyles({
  table: {
    minWidth: 1408,
    borderCollapse: 'separate',
    borderSpacing: '4px',
  },
  container: {
    maxHeight: '600px',
  },
})

const ModalContainer = styled.div`
  width: 516px;
  height: 245px;
  background: #ffffff;
  box-shadow: 0 6px 14px rgba(2, 9, 75, 0.06);
  border-radius: 8px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
`
const TextContainer = styled.div`
  margin-top: 70px;
  font-family: FuturaBookC, sans-serif;
  font-size: 25px;
  line-height: 30px;
  text-align: center;
  color: #3d4751;
  font-weight: normal;
`

const ButtonsContainer = styled.div`
  margin-top: auto;
  margin-bottom: 20px;
  display: flex;
  background: #fff;
  justify-content: center;
`

const ConfirmButton = styled.button`
  width: 95px;
  height: 36px;
  background: linear-gradient(92.32deg, #f49355 0%, #f0640c 100%);
  border-radius: 8px;
  outline: 0;
  border: 0;
  font-family: FuturaMediumC, sans-serif;
  font-size: 17px;
  color: rgba(255, 255, 255, 1);
  cursor: pointer;
`

const CancelButton = styled(ConfirmButton)`
  background: linear-gradient(0deg, #9ba1a6, #9ba1a6),
  linear-gradient(92.32deg, #f49355 0%, #f0640c 100%);
  margin-left: 8px;
  cursor: pointer;
`

const TrashIconActive = styled.img.attrs({ src: assets.img.trash })``
const TTNExist = styled.img.attrs({ src: assets.img.ttnExist })``
const SettingIcon = styled.img.attrs({ src: assets.img.setting })``
const SortIcon = styled.img.attrs({ src: assets.img.sortIcon })``

const List = ({
                orders,
                initialOrders,
                deleteOrder,
                setModalState,
                setStatusId,
                statusId,
                isFilterOpen,
                setIsFilterOpen,
                setModalShow,
                editAccess,
                activeTab,
                updateOrder,
              }) => {
  const classes = useStyles()
  const [state, setState] = useState({
    orders: {},
    ordersMap: [],
  })
  const [isDeleting, setIsDeleting] = useState(false)
  const [deletingOrder, setDeletingOrder] = useState({})

  const [isTTNExistModalOpen, setIsTTNExistModalOpen] = useState(false)
  const [ttnExistOrder, setTTNExistOrder] = useState({})

  useEffect(() => {
    setState({
      orders: orders,
      ordersMap: Object.values(orders).flat(),
    })
  }, [orders])

  const [sort, setSort] = useState(false)

  const isCompleted = activeTab === 'completed'

  const statuses = () =>
    initialOrders.reduce(
      (previous, column) => ({
        ...previous,
        [column.id]: {
          title: column.title,
        },
      }),
      {},
    )

  const handleTTNExist = () => {
    const { id, ttnIsExist } = ttnExistOrder
    updateOrder({ data: { id, ttnIsExist: !ttnIsExist }, boardId: '' })
    setState({
      ...state,
      ordersMap: state.ordersMap.map(order => {
        if (order.id === id) {
          order.ttnIsExist = !ttnIsExist
        }
        return order
      }),
    })
    setIsTTNExistModalOpen(false)
  }

  const handleDelete = () => {
    deleteOrder({ orderId: deletingOrder.id })
    setState({
      ...state,
      ordersMap: state.ordersMap.filter(order => order.id !== deletingOrder.id),
    })
    setIsDeleting(false)
  }

  const openEditOrderModal = (item) => {
    setModalState({
      open: true,
      id: item.id,
      type: 'VIEW',
    })
  }

  return (
    <>
      <Modal open={isDeleting} onClose={() => setIsDeleting(false)}>
        <ModalContainer>
          <TextContainer>
            Удалить заявку <br />
            {deletingOrder.id} ?
          </TextContainer>
          <ButtonsContainer>
            <ConfirmButton
              onClick={() =>
                editAccess ? handleDelete() : setModalShow(true)
              }
            >
              Да
            </ConfirmButton>
            <CancelButton onClick={() => setIsDeleting(false)}>
              Отмена
            </CancelButton>
          </ButtonsContainer>
        </ModalContainer>
      </Modal>
      <Modal open={isTTNExistModalOpen} onClose={() => setIsTTNExistModalOpen(false)}>
        <ModalContainer>
          <TextContainer>
            {`ТТН ${ttnExistOrder.ttnIsExist ? 'не сдана' : 'сдана'} в заявке с номером`}
            <br />
            {ttnExistOrder.orderNumber} ?
          </TextContainer>
          <ButtonsContainer>
            <ConfirmButton
              onClick={() =>
                editAccess ? handleTTNExist() : setModalShow(true)
              }
            >
              Да
            </ConfirmButton>
            <CancelButton onClick={() => setIsTTNExistModalOpen(false)}>
              Отмена
            </CancelButton>
          </ButtonsContainer>
        </ModalContainer>
      </Modal>
      <TableContainer component={Container} className={classes.container}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <StyledTableCell isFirst>Номер заявки</StyledTableCell>
              <StyledTableCell>Организация</StyledTableCell>
              <StyledTableCell minWidth={60}>Сумма</StyledTableCell>
              <StyledTableCell minWidth={150}>
                Товар
                <SortWrapper isActive={sort} onClick={() => setSort(!sort)}>
                  <SortIcon />
                </SortWrapper>
              </StyledTableCell>
              {!isCompleted && (
                <StyledTableCell minWidth={250}>
                  Статус
                  <SettingWrapper
                    onClick={() => setIsFilterOpen(!isFilterOpen)}
                  >
                    <SettingIcon />
                  </SettingWrapper>
                  <ListStatusFilter
                    setOpen={setIsFilterOpen}
                    statuses={statuses()}
                    statusId={statusId}
                    setStatusId={setStatusId}
                    open={isFilterOpen}
                  />
                </StyledTableCell>
              )}
              {isCompleted && (
                <>
                  <StyledTableCell minWidth={100}>Факт</StyledTableCell>
                  <StyledTableCell minWidth={200}>Водитель</StyledTableCell>
                </>
              )}
              <StyledTableCell>ТТН</StyledTableCell>
              <StyledTableCell>Адрес</StyledTableCell>
              <StyledTableCell>ФИО ЗАКАЗЧИКА</StyledTableCell>
              <StyledTableCell>ТЕЛЕФОН ЗАКАЗЧИКА</StyledTableCell>
              {isCompleted && (
                <StyledTableCell>
                  <IconWrapper>
                    <TTNExist />
                  </IconWrapper>
                </StyledTableCell>
              )}
              <StyledTableCell isLast>
                <IconWrapper>
                  <TrashIconActive />
                </IconWrapper>
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <ListTableBody
              orders={state.ordersMap}
              sort={sort}
              openEditOrderModal={openEditOrderModal}
              setModalShow={setModalShow}
              isCompleted={isCompleted}
              editAccess={editAccess}
              statuses={statuses()}
              setIsDeleting={setIsDeleting}
              setDeletingOrder={setDeletingOrder}
              setTTNExistOrder={setTTNExistOrder}
              setIsTTNExistModalOpen={setIsTTNExistModalOpen}
            />
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default connect(
  R.applySpec({
    managers: getAmoUsersList,
  }),
  {
    updateOrder: editOrderRequest,
    deleteOrder: deleteOrderRequest,
  },
)(List)
