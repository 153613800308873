import React, { useState } from 'react'
import styled from 'styled-components'

import { triangle } from '../../../../assets/img'

import ModalPortal from '../ModalPortal'

const Wrapper = styled.div`
  height: 36px;
  border-radius: 8px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  background-color: #ffffff;
  padding: 10px;
  box-sizing: border-box;
`

const ListItem = styled.div`
  margin-bottom: 10px;
  font-family: 'FuturaBookC', sans-serif;
  font-size: 18px;
  line-height: 30px;
  color: rgba(18, 22, 26, 0.7);
`

const Triangle = styled.img.attrs({ src: triangle })`
  transform: rotate(90deg);
  margin-left: 10px;
`

const SelectedProductContainer = styled.div`
  font-family: 'FuturaBookC', sans-serif;
  font-size: 14px;
  line-height: 15px;
  color: rgba(18, 22, 26, 0.9);
`

const ProductSelect = ({ product, products, setProduct }) => {
  const [modalOpen, setIsOpen] = useState(false)

  const renderModal = () => {
    if (!modalOpen) return null
    const close = () => {
      setIsOpen(false)
    }

    const onClick = (product) => {
      setProduct(product)
      close()
    }

    return (
      <ModalPortal close={close} header='Организации'>
        {products.map(el => <ListItem onClick={() => onClick(el)} key={el.id}>{el.title}</ListItem>)}
      </ModalPortal>
    )
  }
  return (
    <>
      <Wrapper onClick={() => setIsOpen(true)}>
        <SelectedProductContainer>
          {product.title}
        </SelectedProductContainer>
        <Triangle />
      </Wrapper>
      {renderModal()}
    </>
  )
}

export default ProductSelect