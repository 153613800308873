import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import ModalPortal from './ModalPortal'
import CardModal from './board/CardModal'
import { useStyles } from './search-select/styles'
import { Button, TextField } from '@material-ui/core'
import SearchSelect from './search-select/index'
import { Option } from '../boards-select'

const ButtonRound = styled.div`
  position: fixed;
  bottom: 26px;
  right: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  box-shadow: 0 6px 14px rgba(2, 9, 75, 0.12);
  border-radius: 50%;
  font-size: 32px;
  ${({ isOpen }) =>
    !isOpen
      ? css`
          background: #f49355;
          color: white;
          z-index: unset;
        `
      : css`
          background: #ffffff;
          color: #f49355;
          z-index: 2;
        `}
`

const MenuContainer = styled.div`
  z-index: 2;
  position: fixed;
  bottom: 80px;
  right: 14px;
  background: white;
  border-radius: 5px;
`

const MenuItem = styled.div`
  box-sizing: border-box;
  width: 100%;
  font-family: 'FuturaBookC', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  color: rgba(18, 22, 26, 0.7);
  padding: 15px 20px;

  &:first-child {
    padding-bottom: 10px;
  }
`

const BorderModalTitle = styled.div`
  font-family: 'FuturaBookC', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  line-height: 30px;
  text-align: center;
  color: #3d4751;
  margin-top: 22px;
  margin-bottom: 27px;
  max-width: 300px;
`

const BorderModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Shadow = styled.div`
  z-index: 1;
  position: absolute;
  background: #3d4751;
  opacity: 0.1;
  top: 0;
  height: 100vh;
  left: 0;
  right: 0;
`

const PlusButton = ({
  handleGetOrders,
  organizations,
  createBoard,
  getBoards,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [borderModalOpen, setBorderModalOpen] = useState(false)
  const [newBorderName, setNewBorderName] = useState('')
  const [organizationId, setOrganizationId] = useState('')

  const classes = useStyles()

  const renderModal = () => {
    if (!modalOpen) return null
    const close = () => {
      setModalOpen(false)
    }

    return (
      <ModalPortal close={close}>
        <CardModal order={{}} close={close} handleGetOrders={handleGetOrders} />
      </ModalPortal>
    )
  }

  const renderBorderModal = () => {
    if (!borderModalOpen) return null
    const close = () => {
      setBorderModalOpen(false)
    }
    const handleSubmit = () => {
      createBoard({
        organizationId: organizationId,
        title: newBorderName,
      })
      close(true)
      setTimeout(() => {
        getBoards()
      }, 700)
    }

    return (
      <ModalPortal close={close}>
        <BorderModalContainer>
          <BorderModalTitle>Введите название новой доски</BorderModalTitle>
          <TextField
            value={newBorderName}
            onChange={e => setNewBorderName(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            classes={{
              root: classes.newBorderInput,
            }}
            variant="outlined"
            label=""
          />
          <SearchSelect
            label="Организация"
            value={organizationId}
            onChange={e => setOrganizationId(e.target.value)}
            transformX={-5}
          >
            {organizations.map(({ id, title }) => (
              <Option key={id} value={id} onClick={()=>setOrganizationId(id)}>
                {title}
              </Option>
            ))}
          </SearchSelect>
          <div className={classes.buttonWrapper}>
            <Button
              disabled={!newBorderName.trim() || !organizationId}
              onClick={handleSubmit}
              classes={{
                root: classes.addButton,
              }}
            >
              Создать
            </Button>
            <Button
              onClick={close}
              classes={{
                root: classes.cancelButton,
              }}
            >
              Отмена
            </Button>
          </div>
        </BorderModalContainer>
      </ModalPortal>
    )
  }

  const renderMenu = () => {
    if (!isOpen) return null
    return (
      <>
        <Shadow />
        <MenuContainer>
          <MenuItem
            onClick={() => {
              setIsOpen(false)
              setBorderModalOpen(true)
            }}
          >
                Создать доску
          </MenuItem>
          <MenuItem
            onClick={() => {
              setIsOpen(false)
              setModalOpen(true)
            }}
          >
            Создать заявку
          </MenuItem>
        </MenuContainer>
      </>
    )
  }
  return (
    <>
      <ButtonRound isOpen={isOpen} onClick={() => setIsOpen(!isOpen)}>
        {' '}
        +
      </ButtonRound>
      {renderMenu()}
      {renderModal()}
      {renderBorderModal()}
    </>
  )
}

export default PlusButton
