import * as R from 'ramda'

const getUsers = R.prop('users')

const getById = R.pipe(getUsers, R.prop('byId'))

const getAllIds = R.pipe(getUsers, R.prop('allIds'))

export const getSelectedUser = R.pipe(getUsers, R.prop('selectedUser'))

export const getIsCreatingUser = R.pipe(getUsers, R.prop('isCreatingUser'))

export const getUsersList = R.converge(
  (byId, allIds) => R.map(x => byId[x])(allIds),
  [getById, getAllIds],
)

export const getAmoUsersList = R.pipe(getUsers, R.prop('users'))

export const getUsersError = R.pipe(getUsers, R.prop('error'))

export const getAmoRoles = R.pipe(getUsers, R.prop('roles'))

export const getMeSelector = R.pipe(getUsers, R.prop('me'))

export const getRole = R.pipe(getUsers, R.prop('role'))
export const getAccessList = R.pipe(getUsers, R.prop('accessList'))
export const getAccessListForUser = R.pipe(
  getUsers,
  R.prop('accessListForUser'),
)

export const getAllAvaiableOrganisations = R.pipe(
  getUsers,
  R.prop('avaiableOrganisations'),
)

export const getSelectedRole = R.pipe(getUsers, R.prop('selectedRole'))

export const getActiveSort = R.pipe(getUsers, R.prop('activeSort'))

export const getIsLoading = R.pipe(getUsers, R.prop('isLoading'))

export const getFormInitialValues = state => {
  const selectedUser = getSelectedUser(state)
  const isCreating = getIsCreatingUser(state)

  if (selectedUser) {
    if (selectedUser.roles.map) {
      const user = { ...selectedUser, roles: [...selectedUser.roles] }
      const roles = getAmoRoles(state)
      const userRoles = user.roles.map(role => role.id)
      const map = new Map()
      if (roles.forEach)
        roles.forEach(role => {
          map.set(role.id, { ...role, checked: userRoles.includes(role.id) })
        })
      user.roles = [...map.values()]
      user.phone = user.phone.slice(2)
      return user
    }
    return selectedUser
  }

  if (isCreating) {
    return {}
  }
}
