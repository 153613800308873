import React, { useEffect } from 'react'
import * as R from 'ramda'
import { connect } from 'react-redux'
import styled from 'styled-components'
import assets from '../../assets'
import { getToken, logout } from '../../modules/auth'
import { getMeRequest, getMeSelector } from '../../modules/users'

const hasToken = (onTrue, onFalse) => ({ token }) => (token ? onTrue : onFalse)

const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 0 30px;
  height: 58px;
  background: #ecf1f4;
  justify-content: space-between;

  background: ${hasToken(
    'linear-gradient(177.04deg, #3d4751 0%, #162836 108.75%)',
    '#ecf1f4',
  )};
`

const Icon = styled.img`
  :last-child {
    margin-left: 15px;
  }
`

const UserLogin = styled.div`
  font-family: 'FuturaBookC', sans-serif;
  font-size: 19px;
  color: #ffffff;
  opacity: 0.6;
`

const UserPanel = styled.div`
  display: flex;
  align-items: center;
  height: 100px;
  cursor: pointer;
`
const MenuButton = styled.div`
  width: 24px;
  height: 24px;
  cursor: pointer;
`

const MenuIcon = styled.img`
  width: 100%;
`

const HeaderDump = ({
  token,
  logout,
  isMobile,
  isMenuOpen,
  toggleMenu,
  currentUser,
  getCurrentUser,
}) => {
  const privilege = ['Admin', 'Manager', 'Back-Office']
  const privilegeRus = ['Администратор', 'Менеджер', 'Бэк офис']
  const logoSource = React.useMemo(
    () => (token ? assets.img.logoLogin : assets.img.logo),
    [token],
  )
  const [adminPanelRole, setAdminPanelRole] = React.useState('')
  useEffect(() => {
    getCurrentUser()
    let privilegeIndex = 0
    if (currentUser?.roles?.length) {
      const userRoles = currentUser.roles.map(role => role.name)
      for (const roleName of privilegeRus) {
        if (userRoles.includes(roleName)) {
          privilegeIndex = privilegeRus.indexOf(roleName)
          break;
        }
      }
      setAdminPanelRole(privilege[privilegeIndex])
    }
  }, [currentUser.id])
  const logoutUser = React.useCallback(() => {
    logout()
  }, [logout])

  return (
    <Container token={token}>
      {isMobile && token ? (
        <MenuButton onClick={toggleMenu}>
          <MenuIcon
            src={isMenuOpen ? assets.img.closeMenuIcon : assets.img.openMenu}
          />
        </MenuButton>
      ) : null}
      <Icon src={logoSource} />
      {token && (
        <UserPanel onClick={logoutUser}>
          <UserLogin>{adminPanelRole}</UserLogin>
          <Icon src={assets.img.logout} />
        </UserPanel>
      )}
    </Container>
  )
}

const Header = connect(
  R.applySpec({
    token: getToken,
    currentUser: getMeSelector,
  }),
  {
    getCurrentUser: getMeRequest,
    logout,
  },
)(HeaderDump)

export default Header
