export const ROUTES = {
  MAIN: '/',
  ORDERS: '/',
  LOGIN: '/login',
  RESET_PASSWORD: '/reset_password',
  CATEGORIES: '/categories',
  PROMOTIONS: '/promotions',
  MODERATION: '/moderation',
  USERS: '/users',
  DRIVERS: '/drivers',
  SCHEDULE: '/schedule',
  ORGANIZATIONS: '/organization',
  TRANSPORT: '/transport',
  ROLES: '/roles',
  ANALYTICS: '/analytics',
  STORAGES: '/storages',
}

export const FORMS = {
  LOGIN: 'LOGIN',
  PRODUCT_EDITOR: 'PRODUCT_EDITOR',
  DRIVER: 'DRIVER',
  USER: 'USER',
  USER_EDITOR: 'USER_EDITOR',
  PROMOTION_EDITOR: 'promotion_editor',
  PREPAYMENT_EDITOR: 'PREPAYMENT_EDITOR',
  ORGANIZATION: 'ORGANIZATION',
  ORGANIZATION_USER: 'ORGANIZATION_USER',
  ORGANIZATION_CREATE: 'ORGANIZATION_CREATE',
  TRANSPORT: 'TRANSPORT',
  ORDERS_FILTER: 'ORDERS_FILTER',
  ORDER_MODAL: 'ORDER_MODAL',
  RESET_PASSWORD: 'RESET_PASSWORD',
  CREATE_CATEGORY: 'CREATE_CATEGORY',
}

export const MODULES = {
  AUTH: 'auth',
  CATEGORIES: 'categories',
  PRODUCTS: 'products',
  USERS: 'users',
  UI: 'ui',
  PROMOTIONS: 'promotions',
  DRIVERS: 'drivers',
  SCHEDULE: 'schedule',
  ORGANIZATIONS: 'organisantions',
  ADMINUSERS: 'adminUsers',
  BOARDS: 'boards',
  ORDERS: 'orders',
  TRANSPORT: 'transport',
  ANALYTICS: 'analytics',
  STORAGE: 'storage',
  STORAGES: 'storages',
}

export const EMAILJS_USER_ID = 'user_fbX9LsTfThsuyv56uohje'
export const EMAILJS_TEMPLATE_ID = 'driver_password'
