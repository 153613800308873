import React from 'react'
import styled from 'styled-components'
import AnalyticsPeriodFilter from '../../../analytics-header/analytics-period-filter'
import AnalyticsDownload from '../../../analytics-download'


const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const FilterWrapper = styled.div`
  display: flex;
  align-items: center;
`

const Input = styled.input`
  width: 204px;
  height: 36px;
  background: white;
  font-family: 'FuturaBookC', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #b9c2c8;
  padding-left: 11px;
  display: flex;
  align-items: center;
  outline: none;
  border-radius: 8px 8px 8px 8px;
  border: 1px solid #f49355;
  margin-left: 10px;
`

const Row = styled.div`
  display: flex;
`

const Text = styled.div`
  display: flex;
  align-items: center;
  font-family: FuturaMediumC, sans-serif;
  font-size: 14px;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.4);
  margin-left: 40px;
`

const Header = ({period, setPeriod, downloadOnClick,searchedDriver,setSearchedDriver}) => (
  <Wrapper>
    <FilterWrapper>
      <AnalyticsPeriodFilter period={period} setPeriod={setPeriod} />
      <Row>
        <Text>Поиск</Text>
        <Input
          value={searchedDriver}
          onChange={event => setSearchedDriver(event.target.value)}
        />
      </Row>
    </FilterWrapper>
    <AnalyticsDownload downloadOnClick={()=>downloadOnClick('driver')} />
  </Wrapper>
)

export default Header