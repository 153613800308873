import { ENDPOINTS } from '../../API'
import { handleStatuses } from '../../utils'
import querystring from 'query-string'

export const getStorages = ({ accessToken, search }) => [
  {
    "id": 2,
    "address": "address",
    "phone": "+70000000000",
    "price": null,
    "name": "test name",
    "isActive": true
  },
  {
    "id": 3,
    "address": "address",
    "phone": "+772321333333",
    "price": null,
    "name": "name",
    "isActive": true
  },
  {
    "id": 15,
    "address": "",
    "phone": "+721321313333",
    "price": null,
    "name": "33333",
    "isActive": true
  },
  {
    "id": 18,
    "address": "444444444",
    "phone": "+744444444444",
    "price": null,
    "name": "21313333444",
    "isActive": true
  },
  {
    "id": 19,
    "address": "фывфыв",
    "phone": "+711111111111",
    "price": null,
    "name": "фывыфв",
    "isActive": true
  },
]
  /*fetch(
    ENDPOINTS.STORAGES(
      querystring.stringify(search, { arrayFormat: 'bracket' }),
    ),
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    },
  )
    .then(handleStatuses())
    .then(res => res.json())*/

export const deleteStorage = ({ accessToken, id }) =>
  fetch(ENDPOINTS.STORAGES(id), {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify({ id }),
  }).then(handleStatuses())

export const updateStorage = ({ accessToken, id, ...rest }) =>
  fetch(ENDPOINTS.STORAGES(id), {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(rest),
  }).then(handleStatuses())

export const createStorage = ({ accessToken, ...rest }) => {
  return fetch(ENDPOINTS.STORAGES(), {
    method: 'POST',
    headers: {
      enctype: 'multipart/form-data',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(rest),
  })
    .then(handleStatuses())
    .then(res => res.json())
}

export const getStorageProviders = ({ accessToken, search, storageId }) =>
 [
   {
     id: 1,
     name: 'пример',
   }
 ]

export const createStorageProvider = ({ accessToken, ...rest }) => {
  return {
    id: 2,
    ...rest,
  }
}

export const updateStorageProvider = ({ accessToken, id, ...rest }) => {
  return {
    id,
    ...rest,
  }
}
export const deleteStorageProvider = ({ accessToken, id }) => {
  return {}
}

export const getStorageProducts = () => [
  {
    id: 20692831,
    category: 'Инертные материалы',
    semiCategory: 'Подкатегория',
    item: 'Щебень мелкозернистый',
    deliveryCoast: 100,
    status: 'В наличии',
  },
  {
    id: 20692832,
    category: 'Инертные материалы',
    semiCategory: 'Подкатегория',
    item: 'Щебень мелкозернистый',
    deliveryCoast: 101,
    status: 'Отсутствует',
  },
  {
    id: 20692833,
    category: 'Инертные материалы',
    semiCategory: 'Подкатегория',
    item: 'Щебень мелкозернистый',
    deliveryCoast: 101,
    status: 'Отсутствует',
  },
  {
    id: 20692834,
    category: 'Инертные материалы',
    semiCategory: 'Подкатегория',
    item: 'Щебень мелкозернистый',
    deliveryCoast: 100,
    status: 'В НАЛИЧИИ',
  },
]

export const createStorageProduct = ({ accessToken, storageId, providerId, ...rest }) => {
  return {
    id: 20692835,
    ...rest,
  };
}

export const updateStorageProduct = ({ accessToken, storageId, providerId, ...rest }) => {
  return {
    id: 20692835,
    ...rest,
  }
}

export const deleteStorageProduct = ({ accessToken, storageId, providerId, id }) => {
  return {}
}