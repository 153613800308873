import * as R from 'ramda'
import { createAction, handleActions, handleAction } from 'redux-actions'
import { combineReducers } from 'redux'
import { MODULES } from '../../constants'

export const createOrganizationRequest = createAction(
  `${MODULES.ORGANIZATIONS}/CREATE_ORGANIZATION_REQUEST`,
)

export const createOrganizationSuccess = createAction(
  `${MODULES.ORGANIZATIONS}/CREATE_ORGANIZATION_SUCCESS`,
)

export const createOrganizationFailure = createAction(
  `${MODULES.ORGANIZATIONS}/CREATE_ORGANIZATION_FAILURE`,
)

export const stopCreatingOrganization = createAction(
  `${MODULES.ORGANIZATIONS}/STOP_CREATING_ORGANIZATION`,
)

export const createOrganizationInfoRequest = createAction(
  `${MODULES.ORGANIZATIONS}/CREATE_ORGANIZATION_INFO_REQUEST`,
)

export const createOrganizationInfoSuccess = createAction(
  `${MODULES.ORGANIZATIONS}/CREATE_ORGANIZATION_INFO_SUCCESS`,
)

export const createOrganizationInfoFailure = createAction(
  `${MODULES.ORGANIZATIONS}/CREATE_ORGANIZATION_INFO_FAILURE`,
)

export const createOrganizationUserRequest = createAction(
  `${MODULES.ORGANIZATIONS}/CREATE_ORGANIZATION_USER_REQUEST`,
)

export const createOrganizationUserSuccess = createAction(
  `${MODULES.ORGANIZATIONS}/CREATE_ORGANIZATION_USER_SUCCESS`,
)

export const createOrganizationUserFailure = createAction(
  `${MODULES.ORGANIZATIONS}/CREATE_ORGANIZATION_USER_FAILURE`,
)

export const getOrganizationsRequest = createAction(
  `${MODULES.ORGANIZATIONS}/GET_ORGANIZATIONS_REQUEST`,
)

export const getOrganizationsSuccess = createAction(
  `${MODULES.ORGANIZATIONS}/GET_ORGANIZATIONS_SUCCESS`,
)

export const getOrganizationsFailure = createAction(
  `${MODULES.ORGANIZATIONS}/GET_ORGANIZATIONS_FAILURE`,
)

export const updateOrganizationRequest = createAction(
  `${MODULES.ORGANIZATIONS}/UPDATE_ORGANIZATION_REQUEST`,
)

export const updateOrganizationSuccess = createAction(
  `${MODULES.ORGANIZATIONS}/UPDATE_ORGANIZATION_SUCCESS`,
)

export const updateOrganizationFailure = createAction(
  `${MODULES.ORGANIZATIONS}/UPDATE_ORGANIZATION_FAILURE`,
)

export const selectOrganization = createAction(
  `${MODULES.ORGANIZATIONS}/SELECT_ORGANIZATION`,
)

export const selectUser = createAction(`${MODULES.ORGANIZATIONS}/SELECT_USER`)

export const selectDocument = createAction(
  `${MODULES.ORGANIZATIONS}/SELECT_DOCUMENT`,
)

export const setSelectedOrganizationUsers = createAction(
  `${MODULES.ORGANIZATIONS}/SET_SELECTED_ORGANIZATION_USERS`,
)

export const startCreatingOrganization = createAction(
  `${MODULES.ORGANIZATIONS}/START_CREATING_ORGANIZATION`,
)

export const creatingOrganizationFailure = createAction(
  `${MODULES.ORGANIZATIONS}/CREATING_ORGANIZATION_FAILURE`,
)

export const startCreatingOrganizationUser = createAction(
  `${MODULES.ORGANIZATIONS}/START_CREATING_ORGANIZATION_USER`,
)

export const stopCreatingOrganizationUser = createAction(
  `${MODULES.ORGANIZATIONS}/STOP_CREATING_ORGANIZATION_USER`,
)

export const getUserInfoRequest = createAction(
  `${MODULES.ORGANIZATIONS}/GET_USER_INFO_REQUEST`,
)

export const getUserInfoRequestFailure = createAction(
  `${MODULES.ORGANIZATIONS}/GET_USER_INFO_REQUEST_FAILURE`,
)

export const sendCredsSuccess = createAction(
  `${MODULES.ORGANIZATIONS}/SEND_CREDS_SUCCESS`,
)

export const sendCredsFailure = createAction(
  `${MODULES.ORGANIZATIONS}/SEND_CREDS_FAILURE`,
)

export const deleteOrganizationSuccess = createAction(
  `${MODULES.ORGANIZATIONS}/DELETE_ORGANIZATION_SUCCESS`,
)

export const deleteOrganizationRequest = createAction(
  `${MODULES.ORGANIZATIONS}/DELETE_ORGANIZATION_REQUEST`,
)

export const deleteOrganizationFailure = createAction(
  `${MODULES.ORGANIZATIONS}/DELETE_ORGANIZATION_SUCCESS`,
)

export const getOrganizationUsersSuccess = createAction(
  `${MODULES.ADMINUSERS}/GET_ORGUSERS_SUCCESS`,
)

export const deleteOrganizationUsersSuccess = createAction(
  `${MODULES.ADMINUSERS}/DELETE_ORGUSERS_SUCCESS`,
)

export const organizationSearchRequest = createAction(
  `${MODULES.ORGANIZATIONS}/ORGANIZATION_SEARCH_REQUEST`,
)

export const organizationSearchSuccess = createAction(
  `${MODULES.ORGANIZATIONS}/ORGANIZATION_SEARCH_SUCCESS`,
)

export const organizationSearchFailure = createAction(
  `${MODULES.ORGANIZATIONS}/ORGANIZATION_SEARCH_FAILURE`,
)

export const getActiveSortSuccess = createAction(
  `${MODULES.ORGANIZATIONS}/GET_ACTIVE_SORT_KEY_SUCCESS`,
)

const byId = handleActions(
  {
    [getOrganizationsSuccess]: (state, { payload }) => ({
      ...state,
      ...R.indexBy(R.prop('id'))(payload),
    }),
    [deleteOrganizationSuccess]: (state, { payload: id }) =>
      R.dissoc(id, state),
  },
  {},
)

const activeSort = handleActions(
  {
    [getActiveSortSuccess]: (_, { payload }) => payload,
  },
  {},
)

const allIds = handleActions(
  {
    [getOrganizationsSuccess]: (state, { payload }) =>
      R.uniq([...state, ...R.map(R.prop('id'))(payload)]),
    [deleteOrganizationSuccess]: (state, { payload: id }) =>
      state.filter(x => x !== id),
  },
  [],
)

const currentOrgUsers = handleActions(
  {
    [getOrganizationUsersSuccess]: (state, { payload }) => ({
      ...state,
      payload,
    }),
    [deleteOrganizationUsersSuccess]: (state, { payload: id }) =>
      R.dissoc(id, state),
  },
  {},
)

const getOrganizations = handleActions(
  {
    [getOrganizationsSuccess]: (_, { payload }) => payload,
  },
  [],
)

const isCreatingOrganization = handleActions(
  {
    [startCreatingOrganization]: R.T,
    [createOrganizationSuccess]: R.F,
    [createOrganizationFailure]: R.F,
    [selectOrganization]: R.F,
    [stopCreatingOrganization]: R.F,
  },
  null,
)

const isCreatingOrganizationUser = handleActions(
  {
    [startCreatingOrganizationUser]: R.T,
    [selectOrganization]: R.F,
    [selectUser]: R.F,
    [stopCreatingOrganizationUser]: R.F,
  },
  false,
)

const isPostingOrganization = handleActions(
  {
    [createOrganizationRequest]: R.T,
    [createOrganizationSuccess]: R.F,
    [createOrganizationFailure]: R.F,
  },
  null,
)

const organizationError = handleActions(
  {
    [createOrganizationFailure]: R.T,
    [createOrganizationSuccess]: R.F,
    [createOrganizationRequest]: R.F,
  },
  false,
)

const selectedOrganization = handleActions(
  {
    [selectOrganization]: (_, { payload }) => payload,
    [startCreatingOrganization]: R.always({}),
    [updateOrganizationSuccess]: R.always({}),
  },
  {},
)

const selectedDocument = handleActions(
  {
    [selectDocument]: (_, { payload }) => payload,
  },
  null,
)

const selectedUser = handleActions(
  {
    [selectUser]: (_, { payload }) => payload,
    [selectOrganization]: R.always(null),
    [startCreatingOrganization]: R.always(null),
    [startCreatingOrganizationUser]: R.always(null),
    //[updateOrganizationSuccess]: R.always(null),
  },
  {},
)

const searchedOrganization = handleActions(
  {
    [organizationSearchRequest]: (_, { payload }) => payload,
  },
  '',
)

const selectedOrganizationUsers = handleActions(
  {
    [setSelectedOrganizationUsers]: (_, { payload }) => payload,
    // [startCreatingOrganization]: R.always([]),
    // [updateOrganizationSuccess]: R.always([]),
  },
  [],
)

const isLoading = handleActions(
  {
    [getOrganizationsRequest]: R.T,
    [createOrganizationUserRequest]: R.T,
    //[createOrganizationRequest]: R.T,
    [getOrganizationsSuccess]: R.F,
    [getOrganizationsFailure]: R.F,
    [createOrganizationSuccess]: R.F,
    [createOrganizationUserSuccess]: R.F,
    [createOrganizationUserFailure]: R.F,
  },
  false,
)

const isUserLoading = handleActions(
  {
    [getUserInfoRequest]: R.T,
    [getUserInfoRequestFailure]: R.F,
    [selectUser]: R.F,
  },
  false,
)

const organizationsReducer = combineReducers({
  selectedOrganization,
  selectedOrganizationUsers,
  isLoading,
  isCreatingOrganization,
  isPostingOrganization,
  isCreatingOrganizationUser,
  byId,
  allIds,
  currentOrgUsers,
  organizationError,
  selectedUser,
  selectedDocument,
  isUserLoading,
  searchedOrganization,
  getOrganizations,
  activeSort
})

export default organizationsReducer
