import React, { useState } from 'react'
import { Modal } from '@material-ui/core'
import styled, { css } from 'styled-components'
import * as R from 'ramda'
import { connect } from 'react-redux'

import { TIMES } from './constants'
import { getSchedule } from '../../../../modules/schedule'

const Wrapper = styled.div`
  width: 280px;
  height: 120px;
  background: #ffffff;
  box-shadow: 0 6px 14px rgba(10, 21, 128, 0.08);
  border-radius: 10px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  display: flex;
  flex-direction: column;
  outline: 0;
  padding: 20px 30px;
`

const Title = styled.div`
  font-family: FuturaBookC, sans-serif;
  font-size: 16px;
  color: #35414c;
  text-align: center;
  margin-bottom: 15px;
`

const TimesWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 100px);
  grid-template-rows: repeat(6, 36px);
  grid-column-gap: 80px;
  grid-row-gap: 5px;
`

const Time = styled.div`
  font-family: FuturaBookC, sans-serif;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #35414c;
  cursor: pointer;
  opacity: ${({ isDisabled }) => (isDisabled ? 0.5 : 1)};
  ${({ isActive }) =>
    isActive
      ? css`
          background: #f0640c;
          box-shadow: 0 6px 14px rgba(2, 9, 75, 0.06);
          border-radius: 6px;
          color: #ffffff;
        `
      : css`
          :hover {
            background: #ffffff;
            box-shadow: 0 2px 5px rgba(10, 21, 128, 0.1);
            border-radius: 6px;
          }
        `}
`

const TimeModal = ({
  open,
  handleClose,
  initialValue,
  handleChange,
  schedule,
  date,
}) => {
  const [value, setValue] = useState(initialValue)
  const handleTimeClick = time => {
    setValue(time)
    handleChange(time)
    handleClose()
  }
  const scheduleData = schedule
    .filter(item => item.time !== 'выходной')
    .map(item => ({
      date: new Date(item.date).setHours(0, 0, 0, 0),
      time: item.time.split(' ')[1],
    }))
    .find(item => item.date === new Date(date).setHours(0, 0, 0, 0))

  return (
    <Modal open={open} onClose={handleClose}>
      <Wrapper>
        <Title>Время доставки</Title>
        <TimesWrapper>
          {TIMES.filter(item => {
            if (!scheduleData) {
              return item
            } else {
              return (
                Number(item.to.split(':')[0]) <=
                Number(scheduleData.time.split(':')[0])
              )
            }
          }).map(item => {
            return (
              <Time
                onClick={() => handleTimeClick(item)}
                isActive={R.equals(value, item)}
                key={`${item.from}-${item.to}`}
              >
                {item.from} {item.to}
              </Time>
            )
          })}
        </TimesWrapper>
      </Wrapper>
    </Modal>
  )
}

export default connect(
  R.applySpec({
    schedule: getSchedule,
  }),
)(TimeModal)
