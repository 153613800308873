import { withStyles } from '@material-ui/core/styles'
import { TableCell } from '@material-ui/core'

export const StyledTableCell = withStyles(() => ({
  head: {
    position: 'relative',
    height: 36,
    background: '#FFFFFF',
    boxShadow: '0px 6px 14px rgba(2, 9, 75, 0.06)',
    borderRadius: ({ isFirst, isLast }) =>
      isFirst ? '8px 0px 0px 8px' : isLast ? '0px 8px 8px 0px' : 0,
    color: 'rgba(0, 0, 0, 0.4)',
    textTransform: 'uppercase',
    padding: '0 20px',
    textAlign: 'center',
    minWidth: ({ minWidth }) => (minWidth ? minWidth : 40),
  },
  body: {
    cursor: ({ clickable }) => clickable && 'pointer',
    height: 46,
    background: '#FFFFFF',
    padding: '0 20px',
    borderRadius: ({ isFirst, isLast }) =>
      isFirst ? '8px 0px 0px 8px' : isLast ? '0px 8px 8px 0px' : 0,
    textAlign: 'center',
    fontFamily: 'FuturaBookC',
    fontSize: 14,
    color: 'rgba(0,0,0,0.8)',
  },
}))(TableCell)
