import * as R from 'ramda'

const getBoards = R.prop('boards')

export const getIsLoadingBoards = R.pipe(getBoards, R.prop('isLoading'))

export const getById = R.pipe(getBoards, R.prop('byId'))

export const getAllIds = R.pipe(getBoards, R.prop('allIds'))

export const getBoardsList = R.converge(
  (byId, allIds) => R.map(x => byId[x])(allIds),
  [getById, getAllIds],
)

export const getInitialBoardValues = R.converge(
  (boardId, allBoards) => {
    const selectedBoard = allBoards && boardId && R.evolve({ board: R.prop('id') })(allBoards[boardId])

    return selectedBoard || { board: '1' }
  },
  [getById],
)
