import React from 'react'
import * as R from 'ramda'
import { connect } from 'react-redux'
import styled from 'styled-components'
import EditTransportModal from '../transport/EditTransportModal'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { makeStyles } from '@material-ui/core/styles'
import assets from '../../assets'
import { getTransport, sortTranportRequest } from '../../modules/transport'
const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
    background: 'transparent',
  },
  table: {
    borderSpacing: '2px',
    borderCollapse: 'separate',
  },

  leftCell: {
    borderRadius: '8px 0px 0px 8px',
  },
  rightCell: {
    borderRadius: '0px 8px 8px 0px',
    padding: '0',
  },
  headerCell: {
    background: '#fff',
    fontFamily: 'FuturaMediumC',
    fontSize: '13px',
    lineHeight: '15px',
    textTransform: 'uppercase',
    color: 'rgba(0,0,0,0.4)',
    textAlign: 'center',
    maxWidth: '194px',
  },
  regularCell: {
    background: '#fff',
    fontFamily: 'FuturaBookC',
    fontSize: '13px',
    lineHeight: '15px',
    color: 'rgba(0,0,0,0.8)',
    textAlign: 'center',
    padding: '0',
  },

  prepaymentCell: {
    background: '#fff',
    padding: '5px',
    textAlign: 'center',
    width: 111,
  },
  phoneCell: {
    width: '116px',
    background: '#fff',
  },
  numberCell: {
    background: '#fff',

    width: '90px',
  },
  orderCell: {
    background: '#fff',

    width: '90px',
  },
  smallCell: {
    background: '#fff',
    width: '50px',
  },
  fioCell: {
    background: '#fff',
    textAlign: 'center',
    width: '214px',
  },
  confirmCell: {
    padding: '0 !important',
  },
  transportCell: {
    cursor: 'pointer',
  },
}))

const SortingArrow = styled.img.attrs({ src: assets.img.sortingArrow })`
  width: 11px;
  height: 6.3px;
  margin-left: 6px;
  cursor: pointer;
  transform: rotate(
    ${({ sortingDirection }) => (sortingDirection === 'asc' ? 180 : 0)}deg
  );
`

const DeleteColorIcon = styled.img.attrs({ src: assets.img.deleteColorIcon })``

const DeleteIcon = styled.img.attrs({ src: assets.img.deleteIcon })`
  cursor: pointer;
`

const StatusContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const StatusText = styled.div`
  font-family: 'FuturaBookC', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: ${props => (props.green ? '#0bd781' : props.red ? 'red' : '#B9C2C8')};
  padding-left: 5px;
`

const Circle = styled.div`
  height: 7px;
  width: 7px;
  border-radius: 50%;
  background: ${props =>
    props.green ? '#0bd781' : props.red ? 'red' : '#B9C2C8'};
  margin-bottom: 2px;
`

const TableBlockDump = ({
  setNumberForDelete,
  setIsDeleteModalOpen,
  setId,
  transportList,
  isEditModalOpen,
  setIsEditModalOpen,
  selectedSort,
  sortTranportRequest,
  editAccess,
  setModalShow,
  chosenId,
  setChosenId,
  editNumber,
  setEditNumber,
  editCapacity,
  setEditCapacity,
  editBrand,
  setEditBrand,
  categoryId,
  setCategoryId,
  status,
  setStatus,
  chosenDriver,
  setChosenDriver,
  openPopUp,
  width,
}) => {
  const classes = useStyles()
  const [sortingDirection, setSortingDirection] = React.useState('asc')

  const sortOrders = React.useCallback(() => {
    if (sortingDirection === 'desc') {
      setSortingDirection('asc')
      sortTranportRequest(sortingDirection)
    } else {
      setSortingDirection('desc')
      sortTranportRequest(sortingDirection)
    }
  }, [sortTranportRequest, sortingDirection])

  return (
    <Table className={classes.table}>
      <TableHead>
        <TableRow>
          <TableCell className={`${classes.headerCell} ${classes.leftCell} `}>
            ФИО ВОДИТЕЛЯ
          </TableCell>
          <TableCell className={`${classes.headerCell} ${classes.orderCell} `}>
            ЗАЯВОК
            <SortingArrow
              sortingDirection={sortingDirection}
              onClick={sortOrders}
            />
          </TableCell>
          <TableCell className={`${classes.headerCell} ${classes.numberCell} `}>
            ГОС НОМЕР
          </TableCell>
          <TableCell className={`${classes.headerCell} ${classes.phoneCell} `}>
            Номер телефона
          </TableCell>
          <TableCell className={`${classes.headerCell} ${classes.numberCell} `}>
            СТАТУС
          </TableCell>
          <TableCell className={`${classes.regularCell} ${classes.smallCell}`}>
            <DeleteColorIcon />
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {transportList.map((item, index) => (
          <>
            <>
              {isEditModalOpen && (
                <EditTransportModal
                  setIsOpen={setIsEditModalOpen}
                  id={chosenId}
                  editNumber={editNumber}
                  editBrand={editBrand}
                  editCapacity={editCapacity}
                  categoryId={categoryId}
                  status={status}
                  chosenDriver={chosenDriver}
                  setEditNumber={setEditNumber}
                  setEditCapacity={setEditCapacity}
                  setEditBrand={setEditBrand}
                  setCategoryId={setCategoryId}
                  setChosenDriver={setChosenDriver}
                  width={width}
                />
              )}
            </>
            <TableRow key={index}>
              <TableCell
                className={`${classes.leftCell} ${classes.fioCell}`}
                component="th"
                scope="row"
                onClick={() =>
                  editAccess.length
                    ? openPopUp({
                        id: item.transportId,
                        number: item.carNumber,
                        capacity: item.capacity,
                        brand: item.brand,
                        categoryId: item.transportCategoryId,
                        status: item.status,
                        driver: item.id,
                      })
                    : setModalShow(true)
                }
              >
                {item.fio}
              </TableCell>
              <TableCell
                className={`${classes.regularCell} ${classes.orderCell}`}
                onClick={() =>
                  editAccess.length
                    ? openPopUp({
                        id: item.transportId,
                        number: item.carNumber,
                        capacity: item.capacity,
                        brand: item.brand,
                        categoryId: item.transportCategoryId,
                        status: item.status,
                        driver: item.id,
                      })
                    : setModalShow(true)
                }
              >
                <span>{item.ordersCount}</span>
              </TableCell>
              <TableCell
                className={`${classes.regularCell} ${classes.numberCell}`}
                onClick={() =>
                  editAccess.length
                    ? openPopUp({
                        id: item.transportId,
                        number: item.carNumber,
                        capacity: item.capacity,
                        brand: item.brand,
                        categoryId: item.transportCategoryId,
                        status: item.status,
                        driver: item.id,
                      })
                    : setModalShow(true)
                }
              >
                {item.carNumber}
              </TableCell>
              <TableCell
                className={`${classes.regularCell} ${classes.phoneCell} ${classes.transportCell}`}
                onClick={() =>
                  editAccess.length
                    ? openPopUp({
                        id: item.transportId,
                        number: item.carNumber,
                        capacity: item.capacity,
                        brand: item.brand,
                        categoryId: item.transportCategoryId,
                        status: item.status,
                        driver: item.id,
                      })
                    : setModalShow(true)
                }
              >
                {item.phone}
              </TableCell>
              <TableCell
                className={`${classes.regularCell} ${classes.transportCell}`}
                onClick={() =>
                  editAccess.length
                    ? openPopUp({
                        id: item.transportId,
                        number: item.carNumber,
                        capacity: item.capacity,
                        brand: item.brand,
                        categoryId: item.transportCategoryId,
                        status: item.status,
                        driver: item.id,
                      })
                    : setModalShow(true)
                }
              >
                {item.status === 'dayOff' ? (
                  <StatusContainer>
                    <Circle />
                    <StatusText>Выходной</StatusText>
                  </StatusContainer>
                ) : item.status === 'active' ? (
                  <StatusContainer>
                    <Circle red />
                    <StatusText red>Работает</StatusText>
                  </StatusContainer>
                ) : (
                  <StatusContainer>
                    <Circle green />
                    <StatusText green>Свободен</StatusText>
                  </StatusContainer>
                )}
              </TableCell>

              <TableCell
                className={`${classes.regularCell} ${classes.rightCell}`}
              >
                <DeleteIcon
                  onClick={() => {
                    if (editAccess.length) {
                      setIsDeleteModalOpen(true)
                      setNumberForDelete(item.carNumber)
                      setId(item.transportId)
                    } else {
                      setModalShow(true)
                    }
                  }}
                />
              </TableCell>
            </TableRow>
          </>
        ))}
      </TableBody>
    </Table>
  )
}

const TableBlock = connect(R.applySpec({ transportList: getTransport }), {
  sortTranportRequest,
})(TableBlockDump)

export default TableBlock
