import React from 'react'
import { usePromotions } from '../../effects/promotions'
import { PageBlock, Searchbar } from '../common'
import PromotionElement from './PromotionElement'

const PromotionsBlock = ({ setModalShow, editAccess }) => {
  const {
    promotions,
    deletePromotionId,
    requestDelete,
    createPromotion,
  } = usePromotions()

  return (
    <PageBlock>
      <Searchbar
        onClick={() =>
          editAccess ? createPromotion() : setModalShow(true)
        }
        caption="Акции"
        disableSearchIcon
        disabled={false}
      />
      {promotions.map(promotion => (
        <PromotionElement
          key={promotion.id}
          deletePromotionId={deletePromotionId}
          requestDelete={requestDelete}
          editAccess={editAccess}
          setModalShow={setModalShow}
          {...promotion}
        />
      ))}
    </PageBlock>
  )
}

export default PromotionsBlock
