import styled from 'styled-components'
import React from 'react'
import { Droppable } from 'react-beautiful-dnd'
import assets from '../../../../assets'
import ReactDOM from 'react-dom'


const BottomContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: rgb(50%, 54%, 57%, .2);
  visibility: ${props => props.hidden ? 'hidden' : 'visible'};
  height: 57px;
`

const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 10px 15px 10px 20px;
  border-radius: 4px;
  height: 40px;
  color: ${props => props.hover ? '#FFFFFF' : '#F0640C'};
  background: ${props => props.hover ? '#F0640C' : '#FFFFFF'};;
  margin: 10px;
  text-transform: uppercase;
  font-size: 12px;

  &:last-child {
    margin-right: 21px;
  }

  img {
    filter: ${props => props.hover ? 'brightness(5000%)' : 'unset'};
  }
`

const Icon = styled.img`
  height: 20px;
  margin-right: 24px;
`

const TrashIcon = styled(Icon).attrs({ src: assets.img.trash })``

const SlashIcon = styled(Icon).attrs({ src: assets.img.slash })``

const CheckCircleIcon = styled(Icon).attrs({ src: assets.img.checkCircle })``


const BottomButtonsGroup = ({ hidden }) => {
    const child = (
      <BottomContainer hidden={hidden}>
          <Droppable
            droppableId='delete'
            type='QUOTE'
          >
              {(provided, snapshot) => (
                <Button ref={provided.innerRef} {...provided.droppableProps} hover={snapshot.isDraggingOver}>
                    <TrashIcon />
                    удалить
                </Button>

              )}
          </Droppable>
          <Droppable
            droppableId='notRealized'
            type='QUOTE'>
              {(provided, snapshot) => (
                <Button ref={provided.innerRef} {...provided.droppableProps} hover={snapshot.isDraggingOver}>
                    <SlashIcon />
                    не реализована
                </Button>
              )}
          </Droppable>
          <Droppable
            droppableId='completed'
            type='QUOTE'>
              {(provided, snapshot) => (
                <Button ref={provided.innerRef} {...provided.droppableProps} hover={snapshot.isDraggingOver}>
                    <CheckCircleIcon />
                    успешно завершена
                </Button>
              )}
          </Droppable>
      </BottomContainer>)

    const modalRoot = document.getElementById('bottomButtonsPortal');

    return modalRoot ? ReactDOM.createPortal(child, modalRoot) : null
}

export default BottomButtonsGroup