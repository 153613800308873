import { call, put, all, takeLatest, select } from 'redux-saga/effects'
import { format } from 'date-fns'

import {
  getAnalyticsRequest,
  getAnalyticsFailure,
  getAnalyticsSuccess,
  setTotalAnalytics,
  getAnalyticsInfoSuccess,
  getAnalyticsInfoFailure,
  getAnalyticsInfoRequest,
  getDownloadAnalyticsSuccess,
  getDownloadAnalyticsFailure,
  getDownloadAnalyticsRequest,
  getAnalyticsGraphs,
  getAnalyticsGraphsFailure,
  getAnalyticsGraphsSuccess,
} from './duck'
import * as AnalyticsManager from './AnalyticsManager'
import { getToken } from '../auth'

function* getAnalyticsSaga({ payload: { query, body, type } }) {
  try {
    const token = yield select(getToken)

    const initialBody = {
      ...body,
      startDate: body.startDate
        ? body.startDate
        : new Date(
            new Date(
              new Date().getFullYear(),
              new Date().getMonth(),
              2,
            ).setUTCHours(0, 0, 0),
          ),
      endDate: body.endDate ? body.endDate : new Date(),
    }

    const [analytics] = yield all([
      call(AnalyticsManager.getAnalytics, {
        token,
        query,
        body: initialBody,
        type,
      }),
    ])

    yield put(getAnalyticsSuccess(analytics.results))
    yield put(setTotalAnalytics(analytics.total))
  } catch (ex) {
    yield put(getAnalyticsFailure())
  }
}

function* getAnalyticsDownloadSaga({ payload: { query, type, id } }) {
  try {
    const token = yield select(getToken)
    const initialQuery = {
      ...query,
      startDate: query.startDate ? query.startDate : '2000-01-01',
      endDate: query.endDate ? query.endDate : format(new Date(), 'yyyy-MM-dd'),
    }

    const analytics = yield call(AnalyticsManager.downloadAnalytics, {
      token,
      query: initialQuery,
      type,
      id,
    })

    yield put(getDownloadAnalyticsSuccess(analytics))
  } catch (ex) {
    yield put(getDownloadAnalyticsFailure())
  }
}

function* getAnalyticsInfoSaga({ payload: { query, body, type, id } }) {
  try {
    const token = yield select(getToken)

    const initialBody = {
      ...body,
      startDate: body.startDate ? body.startDate : '',
      endDate: body.endDate ? body.endDate : '',
    }
    const [analyticsInfo] = yield all([
      call(AnalyticsManager.getAnalyticsInfo, {
        token,
        query,
        body: initialBody,
        type,
        id,
      }),
    ])

    yield put(getAnalyticsInfoSuccess(analyticsInfo))
  } catch (ex) {
    yield put(getAnalyticsInfoFailure())
  }
}

function* getAnalyticsGraphsSaga({
  payload: { type, endDate, startDate, organization },
}) {
  try {
    const token = yield select(getToken)
    const graph = yield call(AnalyticsManager.getGraphs, {
      type,
      endDate,
      startDate,
      organization,
      token,
    })
    yield put(getAnalyticsGraphsSuccess(graph))
  } catch (ex) {
    yield put(getAnalyticsGraphsFailure(ex))
  }
}

function* analyticsSaga() {
  yield all([
    takeLatest(getAnalyticsRequest, getAnalyticsSaga),
    takeLatest(getAnalyticsInfoRequest, getAnalyticsInfoSaga),
    takeLatest(getDownloadAnalyticsRequest, getAnalyticsDownloadSaga),
    takeLatest(getAnalyticsGraphs, getAnalyticsGraphsSaga),
  ])
}

export default analyticsSaga
