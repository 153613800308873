import React from 'react'
import styled from 'styled-components'
import { CategoriesBlock } from './index'
import { ProductsBlock } from '../products'
import { ProductEditor } from '../productEditor'

const CategoriesAndProductsWrapper = styled.div`
  display: flex;
  flex: 1;
`

const TabContentHead = styled.div`
  width: calc(100% - 30px);
  min-height: 6px;
  display: flex;
  background-color: #ffffff;
`

const CategoriesAndProducts = ({ editAccess, setModalShow }) => (
  <>
    <TabContentHead />
    <CategoriesAndProductsWrapper>
      <CategoriesBlock editAccess={editAccess} setModalShow={setModalShow} />
      <ProductsBlock editAccess={editAccess} setModalShow={setModalShow} />
      <ProductEditor editAccess={editAccess} setModalShow={setModalShow} />
    </CategoriesAndProductsWrapper>
  </>
)

export default CategoriesAndProducts
