import { handleStatuses } from '../../utils'
import { ENDPOINTS } from '../../API'

export const getBoards = ({ token }) =>
  fetch(ENDPOINTS.BOARDS(), {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
    .then(handleStatuses())
    .then(res => res.json())

export const createBoardColumn = ({ body, accessToken }) =>
  fetch(ENDPOINTS.CREATE_BOARD_COLUMN(), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(body),
  })
    .then(handleStatuses())
    .then(res => res.json())

export const moveOrder = ({ body, accessToken }) =>
  fetch(ENDPOINTS.MOVE_ORDER(), {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(body),
  })
    .then(handleStatuses())
    .then(res => res.json())

export const deleteColumn = ({ body, accessToken }) =>
  fetch(ENDPOINTS.DELETE_COLUMN(), {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(body),
  })
    .then(handleStatuses())
    .then(res => res.json())

export const createBoard = ({ body, accessToken }) =>
  fetch(ENDPOINTS.BOARDS(), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(body),
  })
    .then(handleStatuses())
    .then(res => res.json())