import { call, all, put, takeLatest, select } from 'redux-saga/effects'
import {
  getOrganizationsRequest,
  getOrganizationsSuccess,
  getOrganizationsFailure,
  createOrganizationRequest,
  createOrganizationFailure,
  createOrganizationSuccess,
  updateOrganizationRequest,
  updateOrganizationFailure,
  updateOrganizationSuccess,
  getUserInfoRequest,
  selectUser,
  getUserInfoRequestFailure,
  setSelectedOrganizationUsers,
  selectOrganization,
  deleteOrganizationSuccess,
  deleteOrganizationRequest,
  createOrganizationUserRequest,
  createOrganizationUserFailure,
  createOrganizationUserSuccess,
  getActiveSortSuccess,
} from './duck'

import { setTaskModalVisible } from '../ui'
import * as OrganizationsManager from './OrganizationsManager'
import * as UsersManager from '../users/UsersManager'
import { getToken } from '../auth'
import { getSelectedOrganization, getSearchedOrganization } from './selectors'

function* getOrganizationsSaga({ payload }) {
  try {
    const accessToken = yield select(getToken)
    const searchedOrganization = yield select(getSearchedOrganization)
    const sortKey = payload?.sortKey ? payload.sortKey : 'title'
    const sortDirection = payload?.sortDirection ? payload.sortDirection : 'asc'
    const organizations = yield call(OrganizationsManager.getOrganizations, {
      accessToken,
      search: {
        search: searchedOrganization !== '' ? searchedOrganization : '',
        sortKey,
        sortDirection,
      },
    })
    yield put(getActiveSortSuccess({ sortKey, sortDirection }))
    yield put(getOrganizationsSuccess(organizations))
  } catch (ex) {
    console.log(ex)
    yield put(getOrganizationsFailure(ex.localeMessage))
  }
}

function* createOrganizationSaga({
  payload: { title, form, documents, percentageOfPayment, type,nativeTown },
}) {
  try {
    const accessToken = yield select(getToken)

    const titles = documents.map(doc => doc.title)
    const files = documents.map(doc => doc.file)

    let readyToAppendTitles = '['
    titles.forEach(item => {
      readyToAppendTitles += `\"${item}\",`
    })
    readyToAppendTitles = readyToAppendTitles.slice(0, -1)
    readyToAppendTitles += ']'

    const formdata = new FormData()
    formdata.append('title', title)
    formdata.append('nativeTown', nativeTown)
    formdata.append('form', form)
    formdata.append('percentageOfPayment', percentageOfPayment)
    formdata.append('type', type)
    readyToAppendTitles !== ']'
      ? formdata.append('fileTitles', readyToAppendTitles)
      : console.log('test')

    files.forEach(item => {
      formdata.append('files', item, item.name)
    })

    yield call(OrganizationsManager.createOrganization, {
      formdata,
      accessToken,
    })

    yield put(createOrganizationSuccess({ title, form }))
    yield put(getOrganizationsRequest())
  } catch (ex) {
    console.log(ex)
    yield put(createOrganizationFailure(ex))
  }

  yield put(setTaskModalVisible(false))
}

function* updateOrganizationSaga({
  payload: { id, title, form, documents, VAT, percentageOfPayment, type, nativeTown  },
}) {
  console.log(nativeTown)
  try {
    const { documents: initialDocuments } = yield select(
      getSelectedOrganization,
    )

    const docsToDelete = initialDocuments.filter(
      item => !documents.map(item => item.id).includes(item.id),
    )

    const docsToAdd = documents.filter(
      item => !initialDocuments.map(item => item.id).includes(item.id),
    )

    const docsToUpdate = documents
      .filter(item => initialDocuments.map(item => item.id).includes(item.id))
      .map(item => {
        if (
          item.title !== initialDocuments.find(doc => doc.id === item.id).title
        ) {
          return item
        }
      })
      .filter(item => item)

    const accessToken = yield select(getToken)
    const body = { title, form, VAT, percentageOfPayment, type, nativeTown }
    console.log(nativeTown)

    if (docsToAdd.length > 0) {
      for (let i = 0; i < docsToAdd.length; i++) {
        const formdata = new FormData()
        formdata.append('file', docsToAdd[i].file)
        formdata.append('title', docsToAdd[i].title)


        yield call(
          OrganizationsManager.appendDocumentRequest,
          {
            formdata,
            accessToken,
          },
          id,
        )
      }
    }

    if (docsToDelete.length > 0) {
      for (let i = 0; i < docsToDelete.length; i++) {
        yield call(
          OrganizationsManager.deleteDocumentRequest,
          {
            accessToken,
          },
          docsToDelete[i].id,
        )
      }
    }

    if (docsToUpdate.length > 0) {
      for (let i = 0; i < docsToUpdate.length; i++) {
        const body = {
          documentId: docsToUpdate[i].id,
          title: docsToUpdate[i].title,
        }
        yield call(OrganizationsManager.updateDocumentRequest, {
          accessToken,
          body,
        })
      }
    }

    yield call(
      OrganizationsManager.updateOrganization,
      {
        body,
        accessToken,
      },
      id,
    )

    yield put(updateOrganizationSuccess())
  } catch (ex) {
    console.log(ex)
    yield put(updateOrganizationFailure(ex))
  }

  yield put(setTaskModalVisible(false))
}

function* removeOrganizationSaga({ payload: { organizationId } }) {
  try {
    const accessToken = yield select(getToken)
    yield call(
      OrganizationsManager.deleteOrganizationRequest,
      {
        accessToken,
      },
      organizationId,
    )
    yield put(deleteOrganizationSuccess(organizationId))
  } catch (ex) {
    yield put(updateOrganizationFailure())
  }
}

function* getOrganizationInfoSaga({ payload: organization }) {
  try {
    const accessToken = yield select(getToken)
    const organisationInfo = yield call(
      OrganizationsManager.getOrganizationInfo,
      {
        id: Object.keys(organization).length > 0 ? organization.id : '',
        accessToken,
      },
    )
    if (organisationInfo[0].users)
      yield put(setSelectedOrganizationUsers(organisationInfo[0].users))
    //yield put(deleteOrganizationSuccess(id))
    //yield put(setOrganisationDeletionModalVisible())
  } catch (ex) {
    //yield put(updateOrganizationFailure())
  }
}

function* getUserInfoSaga({ payload: user }) {
  try {
    const accessToken = yield select(getToken)
    if (user.id) {
      const UserInfo = yield call(OrganizationsManager.getUserInfo, {
        id: user.id,
        accessToken,
      })
      yield put(selectUser(UserInfo))
    }
    yield put(selectUser({}))
  } catch (ex) {
    yield put(getUserInfoRequestFailure())
  }
}

function* createOrganisationUserSaga({
  payload: { fio, password, phone, email, roles, position },
}) {
  const phoneWithCode = '+7' + phone.replace(/[^+\d]/g, '')
  try {
    const accessToken = yield select(getToken)
    const organisationId = yield select(getSelectedOrganization)
    const body = {
      email: email ? email : '-',
      fio: fio ? fio : '-',
      organizationId: organisationId ? organisationId : null,

      password,
      phone: phoneWithCode,
      position: position ? position : '-',

      roles: [...roles.values()]
        .filter(item => item.checked)
        .map(item => item.id),
    }

    yield call(UsersManager.createUser, {
      body,
      accessToken,
    })

    //yield put(getAmoUsersRequest())

    const organisationInfo = yield call(
      OrganizationsManager.getOrganizationInfo,
      { id: organisationId, accessToken },
    )
    if (organisationInfo[0].users)
      yield put(setSelectedOrganizationUsers(organisationInfo[0].users))

    yield put(createOrganizationUserSuccess())
  } catch (ex) {
    console.log(ex)
    yield put(createOrganizationUserFailure())
  }

  //yield put(setTaskModalVisible(false))
}

function* organizationsSaga() {
  yield all([
    takeLatest(createOrganizationRequest, createOrganizationSaga),
    takeLatest(updateOrganizationRequest, updateOrganizationSaga),
    takeLatest(getUserInfoRequest, getUserInfoSaga),
    takeLatest(
      [
        getOrganizationsRequest,
        updateOrganizationSuccess,
        createOrganizationSuccess,
      ],
      getOrganizationsSaga,
    ),
    takeLatest(deleteOrganizationRequest, removeOrganizationSaga),
    takeLatest(createOrganizationUserRequest, createOrganisationUserSaga),
    takeLatest(selectOrganization, getOrganizationInfoSaga),
  ])
}

export default organizationsSaga
