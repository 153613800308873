import { ENDPOINTS } from '../../API'
import { handleStatuses } from '../../utils'
import querystring from 'query-string'

export const getOrganizations = ({ accessToken, search }) =>
  fetch(
    ENDPOINTS.ORGANIZATIONS(
      querystring.stringify(search, { arrayFormat: 'bracket' }),
    ),
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    },
  )
    .then(handleStatuses())
    .then(res => res.json())

export const getOrganizationInfo = ({ id, accessToken }) =>
  fetch(ENDPOINTS.GET_ORGANISATION_INFO(id), {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  })
    .then(handleStatuses())
    .then(res => res.json())

export const getUserInfo = ({ id, accessToken }) =>
  fetch(ENDPOINTS.GET_USER_INFO(id), {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  })
    .then(handleStatuses())
    .then(res => res.json())

export const createOrganization = ({ formdata, accessToken }) => {
  return fetch(ENDPOINTS.ORGANIZATIONS(), {
    method: 'POST',
    headers: {
      enctype: 'multipart/form-data',
      Authorization: `Bearer ${accessToken}`,
    },
    body: formdata,
  })
    .then(handleStatuses())
    .then(res => res.json())
}

export const updateOrganization = ({ body, accessToken }, id) =>
  fetch(ENDPOINTS.ORGANIZATIONS() + `/${id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(body),
  }).then(handleStatuses())

export const updateDocumentRequest = ({ body, accessToken }) =>
  fetch(ENDPOINTS.DOCUMENTS(''), {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(body),
  }).then(handleStatuses())

export const deleteOrganizationRequest = ({ accessToken }, id) =>
  fetch(ENDPOINTS.ORGANIZATIONS() + `/${id}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  }).then(handleStatuses())

export const appendDocumentRequest = ({ formdata, accessToken }, id) =>
  fetch(ENDPOINTS.DOCUMENTS(id), {
    method: 'PUT',
    headers: {
      enctype: 'multipart/form-data',
      Authorization: `Bearer ${accessToken}`,
    },
    body: formdata,
  }).then(handleStatuses())

export const deleteDocumentRequest = ({ accessToken }, id) =>
  fetch(ENDPOINTS.DOCUMENTS(id), {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  }).then(handleStatuses())


export const getCities = () =>
  fetch(ENDPOINTS.CITY_LIST()).then((res) => res.json())
