import { combine, createStore as createStoreReatom } from '@reatom/core'

import { basket, deliveries } from './reatom/basket'

const rootReduce = combine({
  basket,
  deliveries,
})

const NameLocalStorageReatom = 'prostore'

export const createReatomStore = initState => {
  const store = createStoreReatom(rootReduce, {
    ...JSON.parse(localStorage.getItem(NameLocalStorageReatom) ?? '{}'),
    ...initState,
  })

  store.subscribe((_, store) => {
    localStorage.setItem(
      NameLocalStorageReatom,
      JSON.stringify({
        ...JSON.parse(localStorage.getItem(NameLocalStorageReatom) ?? '{}'),
        ...store,
      }),
    )
  })

  return store
}
