import React from 'react'
import * as R from 'ramda'
import { connect } from 'react-redux'
import {
  createCategoryRequest,
  getTransportCategorySelect,
  deleteCategoryRequest,
  editCategoryRequest,
} from '../../modules/transport'
import styled from 'styled-components'
import assets from '../../assets'

const Wrapper = styled.div`
  z-index: 1000;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0,0,0,.7);
`

const Container = styled.div`
  width: 300px;
  height: 389px;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 0px 6px 14px rgba(2, 9, 75, 0.06);
  border-radius: 8px;
`

const Content = styled.div`
  max-height: 300px;
  overflow-y: scroll;
  overflow-x: hidden;
`

const Heading = styled.div`
  font-family: FuturaMediumC;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;

  color: #000000;

  opacity: 0.4;
`

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
`

const Button = styled.div`
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  :first-child {
    margin-left: 5px;
  }
`

const NewCategoryContainer = styled.div`
  width: 181px;
  height: 36px;
  border-radius: 8px;
  border: 1px solid #f49355;
  display: flex;
  cursor: pointer;
  font-family: 'FuturaBookC', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  color: #f0640c;
  opacity: 0.8;
  margin-top: 14px;
`

const NewCategoryInput = styled.input`
  width: 181px;
  height: 36px;
  margin-top: 14px;
  background: white;
  font-family: 'FuturaBookC', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  padding-left: 11px;
  display: flex;
  align-items: center;
  outline: none;
  border: 1px solid #f49355;
  border-radius: 8px;
`

const DeleteColorIcon = styled.img.attrs({ src: assets.img.deleteColorIcon })`
  width: 15px;
  height: 15px;
`

const EditIcon = styled.img.attrs({ src: assets.img.editButton })`
  width: 15px;
  height: 15px;
  display: ${({ isActive }) => (isActive ? 'flex' : 'none')}};
`
const PlusIcon = styled.img.attrs({ src: assets.img.plusCategoryIcon })`
  width: 15px;
  height: 15px;
`

const AcceptIcon = styled.img.attrs({ src: assets.img.acceptIcon })`
  width: 15px;
  height: 15px;
  margin-top: 8px;
  margin-left: 4px;
  display: ${({ isActive }) => (isActive ? 'flex' : 'none')}};
`

const BottomButtonContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 12px;
  width: 100%;
  margin-left: 27px;
`

const BottomButton = styled.div`
  width: 95px;
  height: 36px;
  background: ${props =>
    props.add
      ? 'linear-gradient(92.32deg, #f49355 0%, #f0640c 100%)'
      : 'linear-gradient(0deg, #9BA1A6, #9BA1A6), linear-gradient(92.32deg, #F49355 0%, #F0640C 100%);'};
  border-radius: 8px;
  font-family: FuturaMediumC;
  font-style: normal;
  font-size: 17px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: white;
  margin-left: ${props => (props.add ? '0' : '8px')};
  cursor: pointer;
  padding: 0 5px;
`

const CategoryModalDump = ({
  setIsOpenAddCategoryModal,
  createCategory,
  transportCategoryList,
  isCreatingNewCategory,
  setIsCreatingNewCategory,
  deleteCategoryRequest,
  setUpdate,
  update,
  editCategoryRequest,
}) => {
  const [newCategoryTitle, setNewCategoryTitle] = React.useState('')
  const [categories, setCategories] = React.useState([])
  const [chosenTitle, setChosenTitle] = React.useState('')
  const [chosenId, setChosenId] = React.useState(0)

  React.useEffect(() => {
    if (transportCategoryList) {
      setCategories(transportCategoryList)
    }
  }, [transportCategoryList, update])

  const updateText = ({ event, index, id }) => {
    const transportCategories = [...transportCategoryList]
    transportCategories[index].title = event.target.value
    setCategories(transportCategories)
    setChosenTitle(event.target.value)
    setChosenId(id)
  }

  return (
   <Wrapper>
     <Container>
       <Content>
         <Heading>Категории</Heading>
         {categories.map((category, index) => (
           <FlexContainer>
             <NewCategoryInput
               placeholder={category.title}
               value={category.title}
               onChange={event => {
                 updateText({
                   event: event,
                   index: index,
                   id: category.id,
                 })
               }}
               name={'' + index}
             />
             <ButtonContainer>
               <Button>
                 <EditIcon
                   isActive={category.id !== chosenId}
                   onClick={() => setChosenId(category.id)}
                 />
                 <AcceptIcon
                   isActive={category.id === chosenId}
                   onClick={() => {
                     editCategoryRequest({ title: chosenTitle, id: chosenId })
                     setChosenId(0)
                   }}
                 />
               </Button>
               <Button>
                 <DeleteColorIcon
                   onClick={() => {
                     deleteCategoryRequest(category.id)
                     setUpdate(!update)
                   }}
                 />
               </Button>
             </ButtonContainer>
           </FlexContainer>
         ))}
         {isCreatingNewCategory && (
           <FlexContainer>
             <NewCategoryInput
               value={newCategoryTitle}
               onChange={event => setNewCategoryTitle(event.target.value)}
             />
             <Button
               onClick={() => {
                 createCategory(newCategoryTitle)
                 setIsCreatingNewCategory(false)
               }}
             >
               <AcceptIcon isActive={true} />
             </Button>
           </FlexContainer>
         )}
         <NewCategoryContainer onClick={() => setIsCreatingNewCategory(true)}>
           <>Новая категория</> <PlusIcon />
         </NewCategoryContainer>
       </Content>
       <BottomButtonContainer>
         <BottomButton
           add
           onClick={() => {
             setIsOpenAddCategoryModal(false)
             editCategoryRequest({ title: chosenTitle, id: chosenId })
             setChosenId(0)
           }}
         >
           Подтвердить
         </BottomButton>
         <BottomButton onClick={() => setIsOpenAddCategoryModal(false)}>
           Отмена
         </BottomButton>
       </BottomButtonContainer>
     </Container>
   </Wrapper>
  )
}

const CategoryModal = R.compose(
  connect(R.applySpec({ transportCategoryList: getTransportCategorySelect }), {
    createCategory: createCategoryRequest,
    deleteCategoryRequest,
    editCategoryRequest,
  }),
)(CategoryModalDump)

export default CategoryModal
