import React from 'react'
import * as R from 'ramda'
import { connect } from 'react-redux'
import styled from 'styled-components'
import assets from '../../assets'
import { getTransportCategorySelect } from '../../modules/transport'

const Container = styled.div`
  display: flex;
  align-items: ${({ isActive }) => (isActive ? 'normal' : 'center')};
  justify-content: space-between;
  height: 35px;
  max-width: 260px;
  position: relative;
  background-color: white;
  margin-top: 8px;
  border-radius: 6px;
  color: #35414c;
  font-size: 14px;
  line-height: 19px;
  z-index: 20;
  border: 1px solid #f49355;
  border-bottom-left-radius: ${({ isActive }) => (isActive ? '0' : '6px')};
  border-bottom-right-radius: ${({ isActive }) => (isActive ? '0' : '6px')};
  cursor: pointer;
`

const DropDownContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: ${({ isActive }) => (isActive ? 'absolute' : 'positio')};
  height: ${({ isActive }) => (isActive ? 'auto' : '39px')};
  background-color: white;
  max-height: ${({ isActive }) => (isActive ? '200px' : '39px')};
  width: 100%;
  min-width: 260px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  padding-bottom: 10px;
  top: 100%;
  left: -1px;
  z-index: 30;
  border: 1px solid #f49355;
  overflow: scroll;
  overflow-x: hidden;
  ::-webkit-scrollbar {
    display: none;
  }
`

const DropDownStart = styled.div`
  font-family: 'FuturaBookC', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 15px;
  display: flex;
  align-items: center;
  color: rgba(18, 22, 26, 0.9);
  padding-left: 15px;
`

const DropDownDefault = styled.div`
  font-family: 'FuturaBookC', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 15px;
  display: flex;
  align-items: center;
  color: rgba(18, 22, 26, 0.9);
  opacity: 0.8;
  padding-left: 15px;
`

const DropDownItem = styled.div`
  padding-top: 15px;
  padding-left: 15px;
  z-index: 1;
  font-family: 'FuturaBookC', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 15px;
  color: rgba(18, 22, 26, 0.9);
`

const DropDownItemText = styled.div`
  z-index: 1;
  font-family: 'FuturaBookC', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 15px;
  color: rgba(18, 22, 26, 0.9);
`

const Arrow = styled.img.attrs({ src: assets.img.selectArrow })`
  transform: ${({ isActive }) => (isActive ? 'rotate(180deg)' : '')};
  margin-right: 10px;
  width: 10px;
`

const CategoriesDropDownDump = ({
  transportCategoryList,
  isCategoryOpen,
  setIsCategoryOpen,
  setTransportCategoryId,
}) => {
  const [chosenCategory, setChosenCategory] = React.useState(
    'Выберите категорию',
  )

  return (
    <Container
      onClick={() => setIsCategoryOpen(!isCategoryOpen)}
      isActive={isCategoryOpen}
    >
      {isCategoryOpen ? (
        <DropDownStart>
          {chosenCategory}
          <DropDownContainer isActive={isCategoryOpen}>
            {transportCategoryList.map((category, index) => (
              <DropDownItem key={index}>
                <DropDownItemText
                  onClick={() => {
                    setChosenCategory(category.title)
                    setTransportCategoryId(category.id)
                  }}
                >
                  {category.title}
                </DropDownItemText>
              </DropDownItem>
            ))}
          </DropDownContainer>
        </DropDownStart>
      ) : (
        <DropDownDefault>{chosenCategory}</DropDownDefault>
      )}
      <Arrow isActive={isCategoryOpen} />
    </Container>
  )
}

const CategoriesDropDown = connect(
  R.applySpec({ transportCategoryList: getTransportCategorySelect }),
  {},
)(CategoriesDropDownDump)

export default CategoriesDropDown
