import React from 'react'
import * as R from 'ramda'
import { connect } from 'react-redux'
import styled from 'styled-components'
import NewInput from '../common/NewInput'
import { editTransportRequest } from '../../modules/transport'
import DriversDropDown from '../transport/DriversDropDown'
import CategoriesDropDown from '../transport/CategoriesDropDown'

const Container = styled.div`
  width: 300px;
  height: 380px;
  background: white;
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  left: ${props => (props.width <= 767 ? '0' : '10%')};
  right: 0;
  margin-left: auto;
  margin-right: auto;
  top: -20%;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  box-shadow: 0px 6px 14px rgba(2, 9, 75, 0.06);
  border-radius: 8px;
  padding-left: 20px;
`

const Heading = styled.div`
  font-family: FuturaMediumC;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  opacity: 0.4;
  padding-top: 18px;
`

const inputProps = {
  style: {
    padding: '10px 15px !important',
    fontFamily: 'FuturaBookC',
    fontSize: '14px',
    color: '#000000',
    opacity: '0.8',
    maxWidth: '260px',
  },
}

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 12px;
`

const Button = styled.div`
  width: 95px;
  height: 36px;
  background: ${props =>
    props.add
      ? 'linear-gradient(92.32deg, #f49355 0%, #f0640c 100%)'
      : 'linear-gradient(0deg, #9BA1A6, #9BA1A6), linear-gradient(92.32deg, #F49355 0%, #F0640C 100%);'};
  border-radius: 8px;
  font-family: FuturaMediumC;
  font-style: normal;
  font-size: 17px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: white;
  margin-left: ${props => (props.add ? '0' : '8px')};
  cursor: pointer;
`

const EditTransportModalDump = ({
  setIsOpen,
  updateDriver,
  id,
  editNumber,
  editBrand,
  editCapacity,
  categoryId,
  status,
  chosenDriver,
  setEditNumber,
  setEditCapacity,
  setEditBrand,
  setCategoryId,
  setChosenDriver,
  width,
}) => {
  const [isCategoryOpen, setIsCategoryOpen] = React.useState(false)
  const [isDriverOpen, setIsDriverOpen] = React.useState(false)

  React.useEffect(() => {
    if (isCategoryOpen) {
      setIsDriverOpen(false)
    }
    if (isDriverOpen) {
      setIsCategoryOpen(false)
    }
  }, [isDriverOpen, isCategoryOpen])

  return (
    <Container width={width}>
      <Heading>ИЗМЕНИТЬ ТС</Heading>
      <NewInput
        name="editBrand"
        inputProps={inputProps}
        label="Марка"
        setBrandName={setEditBrand}
        value={editBrand}
      />
      <CategoriesDropDown
        isCategoryOpen={isCategoryOpen}
        setIsCategoryOpen={setIsCategoryOpen}
        setTransportCategoryId={setCategoryId}
      />
      <DriversDropDown
        isDriverOpen={isDriverOpen}
        setIsDriverOpen={setIsDriverOpen}
        setUserId={setChosenDriver}
      />
      <NewInput
        name="editCapacity"
        inputProps={inputProps}
        label="Тоннаж"
        setCarSize={setEditCapacity}
        value={Number(editCapacity)}
      />
      <NewInput
        name="editCarNumber"
        inputProps={inputProps}
        label="ГосНомер"
        setCarNumber={setEditNumber}
        value={editNumber}
      />

      <ButtonContainer>
        <Button
          add
          onClick={() => {
            setIsOpen(false)

            updateDriver({
              userId: chosenDriver,
              id: id,
              carNumber: editNumber,
              status: status,
              transportCategoryId: categoryId,
              brand: editBrand,
              capacity: editCapacity,
            })
          }}
        >
          Подтвердить
        </Button>
        <Button onClick={() => setIsOpen(false)}>Отменить</Button>
      </ButtonContainer>
    </Container>
  )
}

const EditTransportModal = R.compose(
  connect(R.applySpec({}), {
    updateDriver: editTransportRequest,
  }),
)(EditTransportModalDump)

export default EditTransportModal
