import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Button } from '@material-ui/core'

import OrganizationTextField from '../../organization-text-field'

import useFile from './useFile'

import assets from '../../../../assets'
import { useStyles } from './styles'

const AddDocument = styled.div`
  height: 36px;
  background: #ffffff;
  border: 1px solid rgba(18, 22, 26, 0.1);
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  padding: 0 15px;
  margin-bottom: 15px;
  margin-top: -5px;
  cursor: pointer;
`
const AddDocumentTitle = styled.div`
  font-family: FuturaBookC, sans-serif;
  font-size: 14px;
  display: flex;
  align-items: center;
  color: rgba(18, 22, 26, 0.4);
`

const AddIcon = styled.img.attrs({ src: assets.img.addGray })`
  opacity: 0.2;
`

const AddWrapper = styled.div`
  margin-bottom: 15px;
  font-family: FuturaMediumC, sans-serif;
  font-size: 12px;
  text-transform: uppercase;
  color: rgba(18, 22, 26, 0.7);
`

const AddTitle = styled.div`
  margin-top: 10px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
`

const DocumentsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
`

const ButtonsWrapper = styled.div`
  display: flex;
  margin-top: -2px;
`

const AddFileIcon = styled.img.attrs({ src: assets.img.addFile })`
  cursor: pointer;
`

const DeleteHoverIcon = styled.img.attrs({ src: assets.img.deleteWhite })`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 0;
  height: 0;
`

const FileWrapper = styled.div`
  position: relative;
  width: 40px;
  height: 40px;
  border: 1px solid rgba(18, 22, 26, 0.1);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
  :hover {
    ${DeleteHoverIcon} {
      width: 20px;
      height: 20px;
    }
    background: ${({ noHover }) => !noHover && 'rgba(18, 22, 26, 0.4)'};
  }
`

const FilePreview = styled.img`
  width: 24px;
  height: 34px;
`

const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const DeleteTitle = styled.div`
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  font-family: FuturaMediumC, sans-serif;
  font-size: 12px;
  text-transform: uppercase;
  color: #f49355;
`

const DeleteIcon = styled.img.attrs({ src: assets.img.delete })`
  margin-left: 10px;
  cursor: pointer;
`

const Download = styled.img.attrs({ src: assets.img.document })`
  width: 20px;
`

const ErrorText = styled.div`
  display: flex;
  align-items: center;
  font-family: FuturaMediumC, sans-serif;
  font-size: 12px;
  color: red;
  padding-top: 7px;
  margin-right: 10px;
`

const Document = ({
  isAdd,
  isAddingDocument,
  setIsAddingDocument,
  document,
  handleAddDocuments,
  handleDeleteDocuments,
}) => {
  const classes = useStyles()
  const [isEditing, setIsEditing] = useState(false)
  const [onSelect, onCancel, files, onFileDelete] = useFile({})
  const [docTitle, setDocTitle] = useState('')
  const [initDoc, setInitDoc] = useState({})

  useEffect(() => {
    if (document && Object.keys(document).length > 0) {
      if (document.uri) {
        setInitDoc(document)
      } else if (document.file.name) {
        const fileReader = new FileReader()
        fileReader.readAsDataURL(document.file)
        fileReader.onload = () => {
          setInitDoc({ ...document, data: fileReader.result })
        }
      } else {
        setInitDoc(document)
      }

      setDocTitle('')
      onCancel()
    } else {
      onCancel()
      setDocTitle('')
      setInitDoc({})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [document, isEditing])

  const handleSubmitClick = e => {
    e.preventDefault()
    const docs = files.map((item, index) => ({
      id: `id-${index}-${new Date().getTime()}`,
      file: item.file,
      title: docTitle,
    }))
    handleAddDocuments(docs)
    onCancel()
    setDocTitle('')
  }
  const handleEditSubmitClick = e => {
    e.preventDefault()
    const docs = files.map((item, index) => ({
      id: `id-${index}-${new Date().getTime()}`,
      file: item.file,
      title: initDoc.title,
    }))
    handleAddDocuments([initDoc, ...docs])
    setInitDoc(document)
    onCancel()
    setIsEditing(false)
  }

  const getIsImage = image => {
    const acceptedExtensions = ['jpeg', 'jpg', 'png', 'JPEG', 'JPG', 'PNG']

    const docFilenameArr = image.split('.')
    const fileExt = docFilenameArr[docFilenameArr.length - 1]
    return acceptedExtensions.includes(fileExt)
  }

  const addingDocument = !isAddingDocument ? (
    <AddDocument onClick={() => setIsAddingDocument(true)}>
      <AddDocumentTitle>Добавить документ</AddDocumentTitle>
      <AddIcon />
    </AddDocument>
  ) : (
    <AddWrapper>
      <AddTitle>Добавить документ</AddTitle>
      <OrganizationTextField
        onChange={e => setDocTitle(e.target.value)}
        value={docTitle}
        label="Название документа"
      />
      <DocumentsWrapper>
        {files.map(file => (
          <FileWrapper
            onClick={() => onFileDelete(file)}
            key={file.file.lastModified}
          >
            <DeleteHoverIcon />
            {getIsImage(file.file ? file.file.name : file.result) ? (
              <FilePreview src={file.result} alt="result" />
            ) : (
              <>
                <Download />
              </>
            )}
          </FileWrapper>
        ))}
        <AddFileIcon onClick={onSelect} />
      </DocumentsWrapper>
      <ButtonsWrapper>
        {!docTitle ? (
          <ErrorText>Введите название</ErrorText>
        ) : (
          <Button
            onClick={handleSubmitClick}
            classes={{
              root: classes.addButton,
            }}
          >
            Готово
          </Button>
        )}

        <Button
          onClick={() => {
            setIsAddingDocument(false)
            onCancel()
            setDocTitle('')
          }}
          classes={{
            root: classes.cancelButton,
          }}
        >
          Отмена
        </Button>
      </ButtonsWrapper>
    </AddWrapper>
  )

  const editingDocument = !isEditing ? (
    <OrganizationTextField
      onClick={() => setIsEditing(true)}
      readOnly
      value={initDoc.title}
    />
  ) : (
    <AddWrapper>
      <Title>
        <AddTitle>Изменить</AddTitle>
        <DeleteTitle>
          Удалить{' '}
          <DeleteIcon
            onClick={() => {
              setIsEditing(false)
              handleDeleteDocuments(initDoc.id)
            }}
          />
        </DeleteTitle>
      </Title>
      <OrganizationTextField
        onChange={e => setInitDoc({ ...initDoc, title: e.target.value })}
        value={initDoc.title}
        label="Название документа"
      />
      <DocumentsWrapper>
        <FileWrapper noHover>
          {getIsImage(initDoc.file ? initDoc.file.name : initDoc.uri) ? (
            <FilePreview
              src={
                initDoc.data ? initDoc.data : `${initDoc.uri}`
              }
              alt="result"
            />
          ) : (
            <>
              <Download />
            </>
          )}
        </FileWrapper>
        {files.map(file => (
          <FileWrapper
            onClick={() => onFileDelete(file)}
            key={file.file.lastModified}
          >
            <DeleteHoverIcon />
            {getIsImage(file.file ? file.file.name : file.result) ? (
              <FilePreview src={file.result} alt="result" />
            ) : (
              <>
                <Download />
              </>
            )}
          </FileWrapper>
        ))}
        <AddFileIcon onClick={onSelect} />
      </DocumentsWrapper>
      <ButtonsWrapper>
        <Button
          onClick={handleEditSubmitClick}
          classes={{
            root: classes.addButton,
          }}
        >
          Готово
        </Button>
        <Button
          onClick={() => {
            setIsEditing(false)
            onCancel()
            if (document && Object.keys(document).length > 0) {
              setInitDoc(document)
            }
          }}
          classes={{
            root: classes.cancelButton,
          }}
        >
          Отмена
        </Button>
      </ButtonsWrapper>
    </AddWrapper>
  )

  return isAdd ? addingDocument : editingDocument
}

export default Document
