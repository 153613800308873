import React from 'react'
import styled from 'styled-components'
import moment from 'moment'
import * as R from 'ramda'
import EditTransportModal from '../transport/EditTransportModal'

import { connect } from 'react-redux'

import { getTransport, sortTranportRequest } from '../../modules/transport'

const Container = styled.a`
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0 6px 14px rgba(2, 9, 75, 0.06);
  box-sizing: border-box;
  padding: 6px 7px 3px 7px;
  margin-bottom: 5px;
  user-select: none;
  border-radius: 4px;
  color: green;
  display: flex;
`

const Content = styled.div`
  flex-grow: 1;
  flex-basis: 100%;
  display: flex;
  flex-direction: column;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`

const AmoId = styled.div`
  font-family: FuturaMediumC sans-serif;
  font-size: 12px;
  color: rgba(18, 22, 26, 0.9);
`

const Time = styled.div`
  font-family: FuturaBookC sans-serif;
  font-size: 12px;
  color: rgba(0, 0, 0, 1);
`

const Organization = styled.div`
  font-family: FuturaMediumC sans-serif;
  font-size: 12px;
  color: rgba(18, 22, 26, 0.7);
  margin-top: 2px;
`

const Price = styled.div`
  font-family: FuturaMediumC sans-serif;
  font-size: 12px;
  color: rgba(18, 22, 26, 0.4);
  margin-right: 5px;
`

const Material = styled.div`
  font-family: FuturaMediumC sans-serif;
  font-size: 12px;
  color: rgba(18, 22, 26, 0.4);
  border: 1px solid rgba(18, 22, 26, 0.2);
  box-sizing: border-box;
  border-radius: 3px;
  padding: 0 5px;
`

const StatusContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const StatusText = styled.div`
  font-family: FuturaMediumC sans-serif;
  font-size: 12px;
  padding: 0 5px;
  color: ${props => (props.green ? '#0bd781' : props.red ? 'red' : '#B9C2C8')};
`

const MobileTransportDump = ({
  transportList,
  chosenId,
  setChosenId,
  editNumber,
  setEditNumber,
  editCapacity,
  setEditCapacity,
  editBrand,
  setEditBrand,
  categoryId,
  setCategoryId,
  status,
  setStatus,
  chosenDriver,
  setChosenDriver,
  openPopUp,
  isEditModalOpen,
  setIsEditModalOpen,
  editAccess,
  setModalShow,
  width,
}) => {
  return (
    <div style={{ marginTop: 15 }}>
      {isEditModalOpen && (
        <EditTransportModal
          setIsOpen={setIsEditModalOpen}
          id={chosenId}
          editNumber={editNumber}
          editBrand={editBrand}
          editCapacity={editCapacity}
          categoryId={categoryId}
          status={status}
          chosenDriver={chosenDriver}
          setEditNumber={setEditNumber}
          setEditCapacity={setEditCapacity}
          setEditBrand={setEditBrand}
          setCategoryId={setCategoryId}
          setChosenDriver={setChosenDriver}
          width={width}
        />
      )}
      {transportList.map((item, index) => (
        <Container
          onClick={() =>
            editAccess.length
              ? openPopUp({
                  id: item.transportId,
                  number: item.carNumber,
                  capacity: item.capacity,
                  brand: item.brand,
                  categoryId: item.transportCategoryId,
                  status: item.status,
                  driver: item.id,
                })
              : setModalShow(true)
          }
        >
          <Content>
            <Header>
              <AmoId>{item.fio}</AmoId>
              <Time> {item.phone}</Time>
            </Header>
            <Header>
              <Organization>{item.title}</Organization>
              <Organization>
                {item.ordersCount !== '0' ? (
                  <StatusText green>{item.ordersCount}</StatusText>
                ) : (
                  <StatusText>{item.ordersCount}</StatusText>
                )}
              </Organization>
            </Header>
            <Header>
              <Price> {item.carNumber}</Price>
              <Material>
                {item.status === 'dayOff' ? (
                  <StatusContainer>
                    <StatusText>Выходной</StatusText>
                  </StatusContainer>
                ) : item.status === 'active' ? (
                  <StatusContainer>
                    <StatusText red>Работает</StatusText>
                  </StatusContainer>
                ) : (
                  <StatusContainer>
                    <StatusText green>Свободен</StatusText>
                  </StatusContainer>
                )}
              </Material>
            </Header>
          </Content>
        </Container>
      ))}
    </div>
  )
}

const MobileTransport = connect(R.applySpec({ transportList: getTransport }), {
  sortTranportRequest,
})(MobileTransportDump)

export default MobileTransport
