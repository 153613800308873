import React from 'react'
import * as R from 'ramda'
import { reduxForm, Field } from 'redux-form'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { TextInput, Button } from '../components'
import { FORMS } from '../constants'
import { sendLogin } from '../modules/auth'
import { getLoginErrors } from '../modules/redux-form'
import { withRediretToMain } from '../utils'
import { Link } from 'react-router-dom'

const Container = styled.div`
  display: flex;
  flex: 1;
  background: #ecf1f4;
`

const LoginFormTitle = styled.div`
  font-size: 25px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.01em;
  color: linear-gradient(170.86deg, #3d4751 0%, #162836 108.75%);
`

const LoginFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  background: #ffffff;
  box-shadow: 0 6px 14px rgba(2, 9, 75, 0.06);
  border-radius: 8px;
  height: 373px;
  width: 360px;
  box-sizing: border-box;
  padding: 44px 39px 0 38px;
  font-family: 'FuturaBookC', sans-serif;
`

const LoginTextInput = styled(TextInput)`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 16px;

  &:first-child {
    margin-top: 34px;
  }
`

const LoginButton = styled(Button)`
  margin-top: 27px;
  width: 100%;
`

const ErrorText = styled.div`
  text-align: center;
  margin-top: 16px;
  color: #f55555;
`

const FieldsContainer = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const StyledLink = styled(Link)`
  text-decoration: none;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 19px;
  margin-top: 20px;
  color: rgba(18, 22, 26, 0.4);
`

const inputLabelProps = {
  style: {
    color: '#000000',
    opacity: 0.4,
    fontFamily: 'FuturaBookC',
    fontSize: 17,
  },
}

const LoginDump = ({ sendLogin, handleSubmit, submitting, errors }) => (
  <Container>
    <LoginFormContainer>
      <LoginFormTitle>Авторизация</LoginFormTitle>
      <FieldsContainer>
        <Field
          name='login'
          component={LoginTextInput}
          inputLabelProps={inputLabelProps}
          label='Логин'
        />
        <Field
          name='password'
          component={LoginTextInput}
          inputLabelProps={inputLabelProps}
          type='password'
          label='Пароль'
        />
      </FieldsContainer>
      <LoginButton
        title='Войти'
        onClick={handleSubmit(sendLogin)}
        isLoading={submitting}
      />
      {errors.description && <ErrorText>{errors.description}</ErrorText>}
      <StyledLink to='reset_password'>Восстановить пароль</StyledLink>
    </LoginFormContainer>
  </Container>
)

const Login = R.compose(
  reduxForm({
    form: FORMS.LOGIN,
    enableReinitialize: true,
    initialValues: {
      login: '',
      password: ''
    }
  }),
  connect(R.applySpec({ errors: getLoginErrors }), { sendLogin }),
  withRediretToMain(),
)(LoginDump)

export default Login
