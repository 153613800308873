import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(() => ({
  deleteButton: {
    width: 30,
    height: 30,
    position: 'absolute',
    right: 0,
    top: 0,
    padding: 0
  },
}))
