import Button from '@material-ui/core/Button'
import React from 'react'
import useStyles from './styles'
import {downloadExampleProducts} from '../../../modules/products/ProductsManager'

const DownloadExampleButton = () => {


  const classes = useStyles()
  return (
    <Button
      onClick={downloadExampleProducts}
      classes={{ root: classes.loadButton }}
    >
      Скачать шаблон
    </Button>
  )
}

export default DownloadExampleButton