import { call, put, all, takeLatest, delay, select } from 'redux-saga/effects'

import {
  getOrdersRequest,
  getOrdersSuccess,
  getOrdersFailure,
  getIsOrganizationSuccess,
  createOrderSuccess,
  createOrderFailure,
  createOrderRequest,
  getOrderSuccess,
  getOrderFailure,
  getOrderRequest,
  deleteOrderSuccess,
  deleteOrderFailure,
  deleteOrderRequest,
  editOrderSuccess,
  editOrderFailure,
  editOrderRequest,
  completeOrderRequest,
  notRealizeOrderRequest,
  completeOrderSuccess,
  completeOrderFailure,
  notRealizeOrderSuccess,
  getTotalOrdersRequest,
  downloadCompletedOrders,
  getTotalOrdersRequestSuccess,
} from './duck'
import { getById } from './selectors'
import * as OrdersManager from './OrdersManager'
import { getToken } from '../auth'
import * as R from 'ramda'

function* getDownloadCompletedOrdersSaga({ payload: { boardId, query } }) {
  try {
    const token = yield select(getToken)
    yield call(OrdersManager.downloadCompletedOrders, {
      token,
      query,
      boardId,
    })
  } catch (ex) {
    console.log('Ошибка в получении заказов', ex)
    yield put(getOrdersFailure())
  }
}

function* getTotalOrdersSaga({ payload: { query } }) {
  try {
    const token = yield select(getToken)
    const { count } = yield call(OrdersManager.getTotalOrders, {
      token,
      query,
    })
    yield put(getTotalOrdersRequestSuccess(count))
  } catch (ex) {
    console.log('Ошибка в получении заказов', ex)
    yield put(getOrdersFailure())
  }
}

function* getOrdersSaga({ payload: { boardId, query } }) {
  try {
    const token = yield select(getToken)
    yield put(getOrdersSuccess([]))
    const orders = yield call(OrdersManager.getOrders, {
      token,
      boardId,
      query,
    })
    yield put(getOrdersSuccess(orders.boardState))
    yield put(getIsOrganizationSuccess(orders.isOrganization))
  } catch (ex) {
    console.log('Ошибка в получении заказов', ex)
    yield put(getOrdersFailure())
  }
}

function* getOrderSaga({ payload: { orderId } }) {
  try {
    const token = yield select(getToken)
    const [order] = yield all([
      call(OrdersManager.getOrder, { token, orderId }),
      delay(1000),
    ])

    yield put(getOrderSuccess(order))
  } catch (ex) {
    yield put(getOrderFailure())
  }
}

function* createOrderSaga({ payload: { boardId, data } }) {
  try {
    const token = yield select(getToken)
    yield call(OrdersManager.createOrder, { token, boardId, data })

    yield put(createOrderSuccess())
  } catch (ex) {
    yield put(createOrderFailure())
  }
}

function* editOrderSaga({ payload: { boardId, data } }) {
  try {
    const token = yield select(getToken)
    yield call(OrdersManager.editOrder, { token, boardId, data })

    yield put(editOrderSuccess())
  } catch (ex) {
    yield put(editOrderFailure())
  }
}

function* completeOrderSaga({ payload: { boardId, orderId, columnId } }) {
  const token = yield select(getToken)
  yield call(OrdersManager.updateOrderState, {
    token,
    orderId,
    state: 'realize',
  })
  yield put(completeOrderSuccess())
}

function* notRealizeOrderSaga({ payload: { orderId, columnId } }) {
  const token = yield select(getToken)
  yield call(OrdersManager.updateOrderState, {
    token,
    orderId,
    state: 'notRealize',
  })
  yield put(notRealizeOrderSuccess({ orderId, columnId }))
}

function* deleteOrderSaga({ payload: { orderId, columnId } }) {
  const byId = yield select(getById)
  let data = null
  if (columnId) {
    data = R.find(R.propEq('id', Number(orderId)), byId[columnId].orders)
  }

  try {
    const token = yield select(getToken)
    yield call(OrdersManager.deleteOrder, { token, orderId })
    yield put(deleteOrderSuccess())
  } catch (ex) {
    yield put(deleteOrderFailure({ data, columnId }))
  }
}

function* ordersSaga() {
  yield all([
    takeLatest(downloadCompletedOrders, getDownloadCompletedOrdersSaga),
    takeLatest(getOrdersRequest, getOrdersSaga),
    takeLatest(getTotalOrdersRequest, getTotalOrdersSaga),
    takeLatest(createOrderRequest, createOrderSaga),
    takeLatest(getOrderRequest, getOrderSaga),
    takeLatest(deleteOrderRequest, deleteOrderSaga),
    takeLatest(completeOrderRequest, completeOrderSaga),
    takeLatest(notRealizeOrderRequest, notRealizeOrderSaga),
    takeLatest(editOrderRequest, editOrderSaga),
  ])
}

export default ordersSaga
