import * as R from 'ramda'

const getOrders = R.prop('orders')

export const getIsOrganization = R.pipe(getOrders, R.prop('isOrganization'))

export const getById = R.pipe(getOrders, R.prop('byId'))

export const getCurrentOrder = R.pipe(getOrders, R.prop('currentOrder'))

export const getAllIds = R.pipe(getOrders, R.prop('allIds'))

export const getTotalOrders = R.pipe(getOrders, R.prop('totalOrders'))

export const getOrdersList = R.converge(
  (byId, allIds) => R.map(x => byId[x])(allIds),
  [getById, getAllIds],
)

export const getInitialOrderValues = R.converge(
  (orderId, allOrders) => {
    const selectedOrder =
      allOrders &&
      orderId &&
      R.evolve({ board: R.prop('id') })(allOrders[orderId])

    return selectedOrder || { order: '1' }
  },
  [getById],
)
