const URL = ''
export const ENDPOINTS = {
  ME: () => `${URL}/api/v1/users/me`,
  STORAGES: (storageId) => `${URL}/api/v1/admin/storage`,
  ORDERS: boardId => `${URL}/api/v1/admin/order/${boardId}`,
  TOTAL_ORDERS: query => `${URL}/api/v1/admin/orders/total${query}`,
  DELETE_ORDER: orderId => `${URL}/api/v1/admin/order/${orderId}`,
  UPDATE_ORDER_STATE: () => `${URL}/api/v1/admin/order/state`,
  GET_ORDER: orderId => `${URL}/api/v1/admin/order/preview/${orderId}`,
  DRIVERS: () => `${URL}/api/v1/drivers`,
  LOGIN: () => `${URL}/api/v1/admin/login`,
  RESET_LOGIN: () => `${URL}/api/v1/admin/reset`,
  CATEGORIES: () => `${URL}/api/v1/admin/categories`,
  CATEGORY: () => `${URL}/api/v1/category`,
  SUBCATEGORY: () => `${URL}/api/v1/subcategory`,
  PRODUCTS: filter => `${URL}/api/v1/admin/products${filter ? '?' + filter : ''}`,
  PRODUCTS_CREATE: filters => `${URL}/api/v1/products${filters ? '?' + filters : ''}`,
  PRODUCT: id => `${URL}/api/v1/products/${id}`,
  PRODUCTS_BY_CITY: query => `${URL}/api/v1/products/city/price?${query}`,
  UPLOAD_PRODUCTS_PRICE: () => '/api/v1/products/cityprice',
  USERS: filters => `${URL}/api/v1/users${filters ? '?' + filters : ''}`,
  TOGGLE_DISABLED_PRODUCT: id => `${URL}/api/v1/products/${id}/toggleDisabled`,
  PROMOTIONS: id => `${URL}/api/v1/promotions${id ? `/${id}` : ''}`,
  SET_USER_STATUS: (userId, status) =>
    `${URL}/api/v1/users/${userId}/${status}`,
  GET_ANALYTICS_GRAPHS: query =>
    `${URL}/api/v1/admin/analytics/diagram?${query}`,
  SET_USER: () => `${URL}/api/v1/admin/user/update`,
  SCHEDULE: () => `${URL}/api/v1/admin/schedule`,
  ORGANIZATIONS: search =>
    `${URL}/api/v1/admin/organization${search ? '?' + search : ''}`,
  ADMINUSERS: () => `${URL}/api/v1/admin/users`,

  GET_USER_INFO: id => `${URL}/api/v1/admin/users/${id}`,
  GET_USERS: filters =>
    `${URL}/api/v1/admin/users${filters ? '?' + filters : ''}`,
  UPDATE_USER: () => `${URL}/api/v1/admin/users`,
  CREATE_USER: () => `${URL}/api/v1/admin/users`,
  DELETE_USER: id => `${URL}/api/v1/admin/users/${id}`,
  GET_ROLES: () => `${URL}/api/v1/admin/roles`,
  ACCESS: () => `${URL}/api/v1/admin/access`,
  GET_ROLE: roleId => `${URL}/api/v1/admin/roles/${roleId}`,
  UPDATE_ROLES: () => `${URL}/api/v1/admin/roles`,
  GET_ALL_ORGANISATIONS: () => `${URL}/api/v1/admin/organization`,
  GET_ORGANISATION_INFO: id => `${URL}/api/v1/admin/organization/${id}`,
  CREATE_DOCUMENT: id => `${URL}/api/v1/admin/documents/${id}`,
  DOCUMENTS: id => `${URL}/api/v1/admin/documents/${id}`,
  TRANSPORT: id => `${URL}/api/v1/admin/transport${id ? `/${id}` : ''}`,

  GET_TRANSPORT: filters =>
    `${URL}/api/v1/admin/transport${filters ? '?' + filters : ''}`,

  TRANSPORT_CATEGORY: id =>
    `${URL}/api/v1/admin/transport/category${id ? `/${id}` : ''}`,
  TRANSPORT_STATUSES: () => `${URL}/api/v1/admin/transport/statuses`,
  BOARDS: () => `${URL}/api/v1/admin/board`,
  CREATE_BOARD_COLUMN: () => `${URL}/api/v1/admin/leadstatus`,
  MOVE_ORDER: () => `${URL}/api/v1/admin/order/status`,
  DELETE_COLUMN: () => `${URL}/api/v1/admin/leadstatus`,
  ACCESS_LIST: () => `${URL}/api/v1/admin/accesslist`,
  ACCESS_LIST_FOR_USER: () => `${URL}/api/v1/admin/access`,
  ANALYTICS: type => `${URL}/api/v1/admin/analytics/${type}`,
  ANALYTICS_INFO: (type, id) => `${URL}/api/v1/admin/analytics/${type}/${id}`,
  ANALYTICS_DOWNLOAD: (type, id, query) =>
    `/api/v1/admin/analytics/${type}/${
      id ? `${id}/download${query}` : `download${query}`
    }`,
  PRODUCTS_EXAMPLE_DOWNLOAD: () => `${URL}/api/v1/products/city/example`,
  DOWNLOAD_COMPLETED_ORDERS: query =>
    `${URL}/api/v1/admin/order/download/completed/${query}`,
  CITY_LIST: ()=> `${URL}/api/v1/products/city/list`
}
