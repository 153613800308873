import React from 'react'
import styled from 'styled-components'
import assets from '../../../../assets'
import { completeOrderRequest, deleteOrderRequest, notRealizeOrderRequest } from '../../../../modules/orders'
import { connect } from 'react-redux'

const Wrapper = styled.div`
  background: rgba(255, 255, 255, 0.4);
  width: 100%;
  height: 85px;
  position: fixed;
  left: 0;
  bottom: 0;
  box-sizing: border-box;
  padding: 10px 22px;
  display: flex;
  justify-content: center;
`

const Button = styled.div`
  background: #FFFFFF;
  border-radius: 4px;
  height: 65px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: FuturaMediumC;
  font-weight: 500;
  font-size: 10px;
  text-transform: uppercase;
  margin-right: 10px;
  color: #F0640C;
  padding: 10px 15px;
  box-sizing: border-box;
  text-align: center;

  &:last-child {
    margin-right: 0;
  }
`
const Icon = styled.img`
  height: 20px;
  margin-bottom: 7px;
`

const TrashIcon = styled(Icon).attrs({ src: assets.img.trash })``

const SlashIcon = styled(Icon).attrs({ src: assets.img.slash })``

const CheckCircleIcon = styled(Icon).attrs({ src: assets.img.checkCircle })``

const BottomButtons = ({ order, columnId, deleteOrder, completeOrder, notRealizeOrder, close }) => {

  const onComplete = () => {
    completeOrder({ orderId: order.id, columnId, boardId: '' })
    close()
  }
  const onDelete = () => {
    deleteOrder({ orderId: order.id, columnId, boardId: '' })
    close()
  }
  const onNotRealize = () => {
    notRealizeOrder({ orderId: order.id, columnId, boardId: '' })
    close()
  }

  return (
    <Wrapper>
      <Button onClick={onDelete}> <TrashIcon /> Удалить</Button>
      <Button onClick={onNotRealize}> <SlashIcon /> Не <br /> реализована </Button>
      <Button onClick={onComplete}> <CheckCircleIcon /> Успешно <br /> завершена</Button>
    </Wrapper>
  )
}

export default connect(null, {
  deleteOrder: deleteOrderRequest,
  completeOrder: completeOrderRequest,
  notRealizeOrder: notRealizeOrderRequest,
})(BottomButtons)