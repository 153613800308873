import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import assets from '../../assets'

const Container = styled.div`
  width: ${props => (props.width >= 1440 ? '163px' : '37px')};
  min-width: 37px;
  height: 36px;
  background: white;
  box-shadow: 0px 6px 14px rgba(2, 9, 75, 0.06);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
  cursor: pointer;
`

const Text = styled.div`
  font-family: 'FuturaBookC', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  text-align: right;
  letter-spacing: 0.02em;

  color: #000000;

  opacity: 0.4;
`

const CategoryIcon = styled.img.attrs({ src: assets.img.categoriesIcon })`
  margin-left: ${props => (props.width >= 1440 ? '12px' : '0')};
  width: ${props => (props.width >= 1440 ? 'auto' : '20px')};
`

const CategoryContainer = ({
  setIsOpenAddCategoryModal,
  setModalShow,
  editAccess,
  width,
}) => {
  return (
    <Container
      width={width}
      onClick={() =>
        editAccess ? setIsOpenAddCategoryModal(true) : setModalShow(true)
      }
    >
      {width >= 1440 && <Text>Категории</Text>}
      <CategoryIcon width={width} />
    </Container>
  )
}

export default CategoryContainer
