import React, { useState, useEffect, useCallback, useRef } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { TextField } from '@material-ui/core'
import * as R from 'ramda'
import styled from 'styled-components'

const styles = () => ({
  notchedOutline: {
    borderRadius: '8px',
    borderWidth: '1px',
    borderColor: '#F49355  !important',
  },
  focused: {
    fontSize: 15,
    borderColor: '#F0640C !important',
  },
})

const InputWrapper = React.memo(props => (
  <TextField {...R.dissoc('isFocusedOrHasValue', props)} />
))

const Container = styled.div`
  position: relative;
`
const FormContainer = styled.div`
  width: 100%;
`
const Error = styled.span`
  font-family: 'FuturaBookC', sans-serif;
  font-size: 15px;
  line-height: 17px;

  color: #f55555;
`

const StyledTextField = styled(InputWrapper)`
  width: 100%;

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  label {
    margin-top: ${({ isFocusedOrHasValue }) =>
      isFocusedOrHasValue ? 0 : -10}px;
  }

  input {
    padding: 9.5px;
  }

  .MuiInputBase-multiline {
    padding: 9.5px 15px;
  }

  .MuiFormHelperText-root {
    margin: 10px 43px 15px 0;
    font-family: 'FuturaBookC', sans-serif;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
    opacity: 0.4;
  }
`

const ListBox = styled.div`
  width: 100%;
  z-index: 100;
  position: absolute;
  background-color: white;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  padding-top: 8px;
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
`

const ListItem = styled.div`
  color: black;
  cursor: pointer;
  background-color: white;
  font-family: 'FuturaBookC', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 15px;
  padding: 5px 15px;
  text-align: left;
  :hover {
    color: #f0640c;
  }
`

const SelectInput = ({
  data,
  listItemRenderer,
  input: { onChange, value },
  meta: { touched, error },
  className,
  inputProps,
  classes,
  label,
  ...props
}) => {
  const handleListItemClick = useCallback(
    (value, onChange) => () => onChange(value),
    [],
  )

  const [isFocused, setFocus] = useState(false)

  const formRef = useRef(null)

  const handleFocus = useCallback(() => {
    setFocus(true)
  }, [setFocus])

  const handleBlur = useCallback(() => {
    setTimeout(() => {
      setFocus(false)
      if (formRef.current) {
        formRef.current.dispatchEvent(new Event('submit'))
      }
    }, 150)
  }, [setFocus])

  return (
    <Container className={className}>
      <FormContainer onFocus={handleFocus} onBlur={handleBlur}>
        <StyledTextField
          margin="normal"
          variant="outlined"
          color="#F0640C"
          value={value.title ? value.title : value}
          type={'text'}
          InputProps={{
            ...inputProps,
            classes: {
              notchedOutline: classes.notchedOutline,
              focused: classes.cssFocused,
            },
          }}
          {...value}
          {...props}
          label={value ? '' : label}
        />
        {touched && !!error && <Error>{error}</Error>}

        <ListBox visible={isFocused}>
          {data.map(item =>
            listItemRenderer(item, handleListItemClick(item, onChange)),
          )}
        </ListBox>
      </FormContainer>
    </Container>
  )
}

export default withStyles(styles)(SelectInput)
