import React from 'react'
import * as R from 'ramda'
import { connect } from 'react-redux'
import styled from 'styled-components'
import {
  getTransportCategorySelect,
  selectCategoryRequest,
} from '../../modules/transport'

const HeadingType = styled.div`
  height: 36px;
  display: flex;
  align-items: center;
  margin: ${props => (props.width <= 1023 ? '0 15px 0 5px' : '0 25px 0 25px')};
  font-family: FuturaMediumC sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  min-width: 75px;
  text-transform: uppercase;
  color: #000000;
  opacity: 0.4;
  @media screen and (min-width: 1024px) and (max-width: 1439px) {
    margin-right: 0;
  }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    max-width: 83px;
  }
`

const Categories = styled.div`
  display: flex;
  flex: 1 1 auto;
  overflow-y: auto;
  overflow-x: auto;
  width: 900px;
  margin-right: 20px;
  @media screen and (min-width: 1024px) and (max-width: 1439px) {
    max-width: 500px;
    min-width: 500px;
  }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    max-width: 450px;
    min-width: 450px;
  }
`

const TypeBlock = styled.div`
  background: ${({ isActive }) => (isActive ? 'none' : 'white')};
  box-shadow: 0 6px 10px rgba(2, 9, 75, 0.06);
  border-radius: 8px;
  border: ${({ isActive }) => (isActive ? '1px solid #F0640C' : 'none')};
  /* width: auto; */
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  margin-right: 5px;
  cursor: pointer;
`

const TypeBlockText = styled.div`
  font-family: FuturaBookC sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #000000;
  opacity: 0.6;
  white-space: nowrap;
`

const CategoriesBlockDump = ({
  typeBlock,
  setTypeBlock,
  transportCategoryList,
  selectCategory,
  width,
}) => {
  return (
    <div style={{ display: 'flex' }}>
      <HeadingType width={width}> Категории ТС</HeadingType>
      <Categories>
        <TypeBlock
          onClick={() => {
            setTypeBlock(0)
            selectCategory('')
          }}
          isActive={typeBlock === 0}
        >
          <TypeBlockText>Все</TypeBlockText>
        </TypeBlock>
        {transportCategoryList.map(item => (
          <TypeBlock
            key={item.id}
            isActive={typeBlock === item.id}
            onClick={() => {
              setTypeBlock(item.id)
              selectCategory(item.id)
            }}
          >
            <TypeBlockText>{item.title}</TypeBlockText>
          </TypeBlock>
        ))}
      </Categories>
    </div>
  )
}

const CategoriesBlock = connect(
  R.applySpec({ transportCategoryList: getTransportCategorySelect }),
  { selectCategory: selectCategoryRequest },
)(CategoriesBlockDump)

export default CategoriesBlock
