import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(() => ({
  paper: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: 516,
    height: 245,
    background: '#FFFFFF',
    boxShadow: '0px 6px 14px rgba(2, 9, 75, 0.06)',
    borderRadius: 8,
  },
  title: {
    fontFamily: 'FuturaBookC',
    fontSize: 25,
    textAlign: 'center',
    color: '#3D4751',
  },
  input: {
    marginTop: 28,
    height: 37,
    width: 260,
    '& > .MuiOutlinedInput-root': {
      borderRadius: '8px',
    },
    '& > .MuiOutlinedInput-root > input': {
      fontFamily: 'FuturaBookC',
      fontSize: 14,
      padding: '11px 0 9px 11px',
    },
    '& > .MuiOutlinedInput-root > .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #F49355',
    },
  },
  addButton: {
    marginTop: 44,
    marginRight: 8,
    width: 95,
    height: 36,
    boxShadow: '0px 6px 14px 0px rgba(2, 9, 75, 0.06)',
    padding: '9px 11px',
    fontFamily: 'FuturaBookC',
    fontSize: 16,
    color: '#ffffff',
    textTransform: 'initial',
    lineHeight: 'initial',
    borderRadius: 8,
    background: 'linear-gradient(92.15deg, #F6A26D -38.69%, #F87C2E 107.08%)',
  },
  cancelButton: {
    marginTop: 44,
    width: 95,
    height: 36,
    background:
      'linear-gradient(0deg, #9BA1A6, #9BA1A6), linear-gradient(92.32deg, #F49355 0%, #F0640C 100%)',
    borderRadius: 8,
    boxShadow: '0px 6px 14px 0px rgba(2, 9, 75, 0.06)',
    padding: '9px 11px',
    fontFamily: 'FuturaBookC',
    fontSize: 16,
    color: '#ffffff',
    textTransform: 'initial',
    lineHeight: 'initial',
  },
  buttonWrapper: {
    display: 'flex',
  },
}))
