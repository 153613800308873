import React, { useEffect } from 'react'
import * as R from 'ramda'
import styled from 'styled-components'
import { TileContent } from '../components/common/TileContent'
import Calendar from 'react-calendar'
import moment from 'moment'
import '../components/common/Calendar.css'
import { connect } from 'react-redux'
import { getSchedule, getScheduleIsLoading } from '../modules/schedule'
import {
  getScheduleRequest,
  createScheduleRequest,
} from '../modules/schedule/duck'

import { getAccessListForUser } from '../modules/users'
import AccessForbiddenPopup from '../components/common/AccessForbidden'
import { useWebSockets } from '../context'

const Container = styled.div`
  margin-left: 20px;
  margin-top: 20px;
`
const ScheduleDumb = ({
  schedule,
  getScheduleRequest,
  createScheduleRequest,
  accessList,
}) => {
  const [modalShow, setModalShow] = React.useState(false)

  const [editAccess, setEditAccesses] = React.useState([])

  const [viewAccess, setViewAccess] = React.useState([])

  const [modalViewShow, setModalViewShow] = React.useState(false)

  React.useEffect(() => {
    if (!accessList.length) return
    let view = accessList.filter(
      access => access.module === 'schedule' && access.accessType === 'view',
    )
    let edit = accessList.filter(
      access => access.module === 'schedule' && access.accessType === 'edit',
    )
    setEditAccesses(edit)
    setViewAccess(view)
    setModalViewShow(!view.length)
  }, [accessList, accessList.length])

  useEffect(() => {
    if (!schedule.length) {
      getScheduleRequest()
    }
  }, [getScheduleRequest, schedule.length])

  const [date, setDate] = React.useState(new Date())

  const { updatedData } = useWebSockets()

  const scheduleEvents = ['createSchedule']

  React.useEffect(() => {
    if (updatedData) {
      if (scheduleEvents.includes(updatedData.action)) {
        getScheduleRequest()
      }
    }
  }, [updatedData])

  const onChange = nextdate => {
    setDate(nextdate)
  }
  // moment(nextdate)
  // .add(3, 'hours')
  // .format('YYYY-MM-DD hh:mm:ss'))

  return modalViewShow ? (
    <AccessForbiddenPopup
      moduleName={'Расписание'}
      setModalShow={setModalViewShow}
      canClose={false}
    />
  ) : (
    <Container>
      {modalShow && (
        <AccessForbiddenPopup
          moduleName={'Расписание'}
          setModalShow={setModalShow}
        />
      )}

      <Calendar
        tileContent={props => (
          <TileContent
            createScheduleRequest={createScheduleRequest}
            schedule={schedule}
            date={props.date}
            editAccess={editAccess}
            setModalShow={setModalShow}
          />
        )}
        defaultValue={new Date()}
        onChange={onChange}
        value={date}
        minDate={new Date()}
        maxDate={
          new Date(
            moment()
              .add(10, 'years')
              .add(moment().daysInMonth() - new Date().getDate(), 'day'),
          )
        }
      />
    </Container>
  )
}

const Schedule = connect(
  R.applySpec({
    schedule: getSchedule,
    isLoading: getScheduleIsLoading,
    accessList: getAccessListForUser,
  }),
  { getScheduleRequest, createScheduleRequest },
)(ScheduleDumb)

export default Schedule
