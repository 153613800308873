import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import * as R from 'ramda'
import { Route, Switch, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { ROUTES } from '../constants'
import { getIsInitializing, startInitialize } from '../modules/auth'
import { NavigationService } from '../services'
import { Login, ResetPassword } from '../screens'
import { Header, Loader, TaskLoader } from '../components'
import PopupsContainer from './PopupsContainer'

import Main from './Main'
import { useMediaQuery } from '../components/address/commons'
import { widthMobileScreen } from '../components/address/utils'

const LoaderContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
`

const StyledLoader = styled(Loader)`
  margin: auto;
`

const RootDump = ({ history, startInitialize, isInitializing }) => {

  const [isMenuOpen, setMenuOpen] = useState(false)

  const toggleMenu = ()=>setMenuOpen(!isMenuOpen)

  useEffect(() => {
    startInitialize()
    NavigationService.setHistory(history)
  }, [history, startInitialize])

  const isMobile = useMediaQuery(`(max-width: ${widthMobileScreen - 1}px)`)

  const renderContent = () => isInitializing ? (
    <LoaderContainer>
      <StyledLoader />
    </LoaderContainer>
  ) : (
    <>
      <TaskLoader />
      <PopupsContainer />
      <Switch>
        <Route path={ROUTES.LOGIN} component={Login} />
        <Route path={ROUTES.RESET_PASSWORD} component={ResetPassword} />
        <Main  isMobile={isMobile} isMenuOpen={isMenuOpen} setMenuOpen={setMenuOpen}/>
      </Switch>
    </>
  )

  return (
    <>
      <Header isMobile={isMobile} isMenuOpen={isMenuOpen} toggleMenu={toggleMenu} />
      {renderContent()}
    </>
  )
}

const Root = R.compose(
  withRouter,
  connect(R.applySpec({ isInitializing: getIsInitializing }), {
    startInitialize,
  }),
)(RootDump)

export default Root
