import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { makeStyles } from '@material-ui/core/styles'
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
} from '@material-ui/core'
import { connect } from 'react-redux'
import * as R from 'ramda'

import { StyledTableCell } from './table-cell'
import { StyledTableRow } from './table-row'

import { getAnalyticsList } from '../../../../modules/analytics'

import assets from '../../../../assets'
import { FooterTableCell } from './footer-table-cell'
import Header from './header'

const Container = styled.div`
  width: 1020px;
  display: flex;
  margin: 15px 0;
`

const SortWrapper = styled.div`
  position: absolute;
  right: 12px;
  top: 11px;
  cursor: pointer;
  ${({ isActive }) =>
          isActive &&
          css`
            top: 13px;
            transform: rotate(180deg);
          `}
`

const SortIcon = styled.img.attrs({ src: assets.img.sortIcon })``

const useStyles = makeStyles({
  table: {
    borderCollapse: 'separate',
    borderSpacing: '4px',
  },
})

const Organizations = ({
  organizationAnalytics,
  handleGetAnalyticsInfo,
  requestData,
  getAnalytics,
  setInfoTotal,
  searchedOrg,
  setSearchedOrg,
  setPeriod,
  period,
  downloadOnClick
}) => {
  const classes = useStyles()
  const [sort, setSort] = useState(false)

  useEffect(()=>{
    getAnalytics({...requestData,type:'organization'})
  },[requestData,getAnalytics])

  return (
   <>
     <Header searchedOrg={searchedOrg}
             setSearchedOrg={setSearchedOrg}
             period={period}
             setPeriod={setPeriod}
             downloadOnClick={downloadOnClick}
     />
     <TableContainer component={Container}>
       <Table className={classes.table}>
         <TableHead>
           <StyledTableRow>
             <StyledTableCell isFirst minWidth={220}>
               Название организации
             </StyledTableCell>
             <StyledTableCell minWidth={100}>
               Заявки
               <SortWrapper isActive={sort} onClick={() => setSort(!sort)}>
                 <SortIcon />
               </SortWrapper>
             </StyledTableCell>
             <StyledTableCell minWidth={140}>Всего Покупок</StyledTableCell>
             <StyledTableCell minWidth={140}>Доля Платформы</StyledTableCell>
             <StyledTableCell minWidth={160}>
               Заявки в текущем месяце
             </StyledTableCell>
             <StyledTableCell minWidth={170}>
               Покупок в текущем месяце
             </StyledTableCell>
             <StyledTableCell minWidth={100}>Открытые заявки</StyledTableCell>
             <StyledTableCell isLast minWidth={100}>
               Отсутствует ТТН
             </StyledTableCell>
           </StyledTableRow>
         </TableHead>
         <TableBody>
           {organizationAnalytics &&
           organizationAnalytics
             .sort((a, b) => {
               if (!sort) {
                 return a.fullStats
                   ? b.fullStats
                     ? a.fullStats.ordersCount - b.fullStats.ordersCount
                     : -1
                   : 1
               } else {
                 return b.fullStats
                   ? a.fullStats
                     ? b.fullStats.ordersCount - a.fullStats.ordersCount
                     : -1
                   : 1
               }
             })
             .map(item => (
               <StyledTableRow
                 onClick={() => {
                   handleGetAnalyticsInfo(item.id)
                   setInfoTotal(
                     item.fullStats ? item.fullStats.ordersCount : 0,
                   )
                 }}
                 key={item.id}
               >
                 <StyledTableCell isFirst>{item.title}</StyledTableCell>
                 <StyledTableCell>
                   {item.fullStats ? item.fullStats.ordersCount : '-'}
                 </StyledTableCell>
                 <StyledTableCell>
                   {item.fullStats
                     ? `${Math.ceil(Number(item.fullStats.totalEarnings))} ₽`
                     : '-'}
                 </StyledTableCell>
                 <StyledTableCell>
                   {item.fullStats && item.percentageOfPayment
                     ? `${Math.ceil(Number(item.fullStats.totalEarnings) * (Number(item.percentageOfPayment)/100))} ₽`
                     : '-'}
                 </StyledTableCell>
                 <StyledTableCell>
                   {item.statsPerPeriod
                     ? item.statsPerPeriod.ordersCount
                     : '-'}
                 </StyledTableCell>
                 <StyledTableCell>
                   {item.statsPerPeriod
                     ? `${Math.ceil(
                       Number(item.statsPerPeriod.totalEarnings),
                     )} ₽`
                     : '-'}
                 </StyledTableCell>
                 <StyledTableCell>
                   {item.additional ? item.additional.opencount : '-'}
                 </StyledTableCell>
                 <StyledTableCell>
                   {item.additional ? item.additional.withoutttn : '-'}
                 </StyledTableCell>
               </StyledTableRow>
             ))}
           <StyledTableRow>
             <FooterTableCell textAlign='left'>Всего</FooterTableCell>
             <FooterTableCell textAlign='center'>
               {organizationAnalytics &&
               organizationAnalytics.reduce(
                 (acc, init) =>
                   init.fullStats
                     ? acc + Number(init.fullStats.ordersCount)
                     : acc + 0,
                 0,
               )}
             </FooterTableCell>
             <FooterTableCell textAlign='center'>
               {organizationAnalytics &&
               organizationAnalytics.reduce(
                 (acc, init) =>
                   init.fullStats
                     ? acc + Number(init.fullStats.totalEarnings)
                     : acc + 0,
                 0,
               )} ₽
             </FooterTableCell>
             <FooterTableCell textAlign='center'>{
               organizationAnalytics &&
               organizationAnalytics.reduce(
                 (acc, item) =>
                   item.fullStats && item.percentageOfPayment
                     ? acc + Math.ceil(Number(item.fullStats.totalEarnings) * (Number(item.percentageOfPayment) / 100))
                     : acc + 0,
                 0,
               )
             } ₽
             </FooterTableCell>
             <FooterTableCell textAlign='center'>
               {organizationAnalytics &&
               organizationAnalytics.reduce(
                 (acc, init) =>
                   init.statsPerPeriod
                     ? acc + Number(init.statsPerPeriod.ordersCount)
                     : acc + 0,
                 0,
               )}
             </FooterTableCell>
             <FooterTableCell textAlign='center'>
               {organizationAnalytics &&
               organizationAnalytics.reduce(
                 (acc, init) =>
                   init.statsPerPeriod
                     ? acc + Number(init.statsPerPeriod.totalEarnings)
                     : acc + 0,
                 0,
               )} ₽
             </FooterTableCell>
             <FooterTableCell textAlign='center'>
               {organizationAnalytics &&
               organizationAnalytics.reduce(
                 (acc, init) =>
                   init.additional
                     ? acc + Number(init.additional.opencount)
                     : acc + 0,
                 0,
               )}
             </FooterTableCell>
             <FooterTableCell textAlign='center'>
               {organizationAnalytics &&
               organizationAnalytics.reduce(
                 (acc, init) =>
                   init.additional
                     ? acc + Number(init.additional.withoutttn)
                     : acc + 0,
                 0,
               )}
             </FooterTableCell>
           </StyledTableRow>
         </TableBody>
       </Table>
     </TableContainer>
   </>
  )
}

export default connect(
  R.applySpec({
    organizationAnalytics: getAnalyticsList,
  }),
)(Organizations)
