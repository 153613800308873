import React, { useEffect, useState } from 'react'
import * as R from 'ramda'
import { connect } from 'react-redux'

import { Option } from '../../boards-select'
import SearchSelect from '../../search-select'

import { getIsOrganization } from '../../../../modules/orders'
import { moveOrderRequest } from '../../../../modules/boards'

const ListStatus = ({
  statuses,
  value: initialValue,
  moveOrder,
  currentOrderId,
  isOrganization,
  setModalShow,
  editAccess,
}) => {
  const [value, setValue] = useState('')

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  const handleStatusChange = e => {
    setValue(e.target.value)
    moveOrder({
      isOrganization,
      leadStatusId: e.target.value,
      id: currentOrderId,
    })
  }
  return (
    <SearchSelect
      value={value}
      onChange={e => (editAccess ? handleStatusChange(e) : setModalShow(true))}
      width={190}
    >
      {Object.keys(statuses).map(item => (
        <Option key={item} value={item}>
          {statuses[item].title}
        </Option>
      ))}
    </SearchSelect>
  )
}

export default connect(
  R.applySpec({
    isOrganization: getIsOrganization,
  }),
  { moveOrder: moveOrderRequest },
)(ListStatus)
