import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { RadioGroup, FormGroup, Checkbox, TextField } from '@material-ui/core'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import * as R from 'ramda'
import styled from 'styled-components'

const styles = () => ({
  notchedOutline: {
    borderRadius: '8px',
    borderWidth: '1px',
    borderColor: '#F49355  !important',
  },
  focused: {
    fontSize: 15,
    borderColor: '#F0640C !important',
  },
})

const InputWrapper = React.memo(props => (
  <TextField {...R.dissoc('isFocusedOrHasValue', props)} />
))

const Container = styled.div`
  position: relative;
`
const FormContainer = styled.div`
  width: 100%;
`
const Error = styled.span`
  font-family: 'FuturaBookC', sans-serif;
  font-size: 15px;
  line-height: 17px;

  color: #f55555;
`

const StyledTextField = styled(InputWrapper)`
  width: 100%;

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  label {
    margin-top: ${({ isFocusedOrHasValue }) =>
      isFocusedOrHasValue ? 0 : -10}px;
  }

  input {
    padding: 9.5px;
  }

  .MuiInputBase-multiline {
    padding: 9.5px 15px;
  }

  .MuiFormHelperText-root {
    margin: 10px 43px 15px 0;
    font-family: 'FuturaBookC', sans-serif;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
    opacity: 0.4;
  }
`

const CheckboxInput = ({
  roles,
  disabled,
  input: { onChange, value },
  meta: { touched, error },
  className,
  inputProps,
  classes,
  label,
  selectedUser,
  ...props
}) => {

  const createInitialState = useCallback(() => {
    const map = new Map()
    if (roles.forEach)
      roles.forEach(role => {
        map.set(role.id, { ...role, checked: false })
      })
    return map
  }, [roles])

  const [isFocused, setFocus] = useState(false)
  const [state, setState] = useState(new Map())

  useEffect(() => {
    if (value) {
      const map = new Map()
      if (value.forEach)
      value.forEach(item => {
        map.set(item.id, { ...item })
      })
      setState(map)
    }
    if(state.size == 0) setState(createInitialState())
  }, [createInitialState, roles, selectedUser, state.size, value])

  const formRef = useRef(null)

  const handleFocus = useCallback(() => {
    setFocus(true)
  }, [setFocus])

  const handleBlur = useCallback(() => {
    setTimeout(() => {
      setFocus(false)
      if (formRef.current) {
        formRef.current.dispatchEvent(new Event('submit'))
      }
    }, 150)
  }, [setFocus])

  const handleChange = ({ id, checked }) => {
    const role = state.get(id)
    state.set(role.id, { ...role, checked })
    setState(new Map(state))
    onChange([...state.values()])
  }

  const getTextValue = () => {
    if (state) {
      let text = ''
      for (var [key, _value] of state.entries()) {
        if (_value.checked) text += `${_value.name}; `
      }
      return text
    }
    return ''
  }

  return (
    <Container className={className}>
      <FormContainer onMouseEnter={handleFocus} onMouseLeave={handleBlur}>
        <StyledTextField
          margin="normal"
          variant="outlined"
          color="#F0640C"
          value={getTextValue()}
          type={'text'}
          InputProps={{
            ...inputProps,
            classes: {
              notchedOutline: classes.notchedOutline,
              focused: classes.cssFocused,
            },
          }}
          {...value}
          {...props}
          label={value ? '' : label}
        />
        {touched && !!error && <Error>{error}</Error>}
        {!disabled && <FormGroup
          style={{ display: isFocused ? 'block' : 'none' }}
          onClick={e => {
            e.stopPropagation()
          }}
        >
          {[...state.values()].map(role => {
            return (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={role.checked}
                    onChange={event => {
                      event.stopPropagation()
                      handleChange({
                        id: role.id,
                        checked: event.target.checked,
                      })
                    }}
                    name={role.id}
                  />
                }
                label={role.name}
              />
            )
          })}
        </FormGroup>}
      </FormContainer>
    </Container>
  )
}

export default withStyles(styles)(CheckboxInput)
