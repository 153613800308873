import React from 'react'
import * as R from 'ramda'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { selectStatusRequest } from '../../modules/transport'

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: ${props => (props.width <= 767 ? 'center' : 'flex')};
  margin-top: ${props => (props.width <= 767 ? '10px' : '0')};
  width: ${props => (props.width <= 767 ? '300px' : 'auto')};
`

const HeadingActive = styled.div`
  display: ${props => (props.width <= 767 ? 'none' : 'flex')};
  align-items: center;
  margin: ${props => (props.width <= 1023 ? '0 0 0 5px' : '0 0 0 25px')};
  font-family: FuturaMediumC;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;

  color: #000000;

  opacity: 0.4;
`

const ActiveBlockContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 93px;
  height: 54px;
  background: ${props =>
    props.blue
      ? '#0BA6D7'
      : props.red
      ? '#F55555'
      : props.purple
      ? '#C299B7'
      : '#F0640C'};
  box-shadow: 0px 6px 10px rgba(2, 9, 75, 0.06);
  border-radius: 8px;
  cursor: pointer;
`

const BlockBorder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 15px;
  width: 101px;
  height: 62px;
  border-radius: 8px;
  border: ${({ props, isActive }) =>
    isActive
      ? props =>
          props.blue
            ? '1px solid #0BA6D7'
            : props.red
            ? '1px solid #F55555'
            : props.purple
            ? '1px solid #C299B7'
            : '1px solid #F0640C'
      : 'none'};
`

const BlockText = styled.div`
  color: white;
  font-family: 'FuturaBookC', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
`

const ActiveBlockDump = ({
  activeBlock,
  setActiveBlock,
  statuses,
  selectStatus,
  width,
}) => {
  console.log(width)
  return (
    <Container width={width}>
      <HeadingActive width={width}> АКТИВНОСТЬ </HeadingActive>
      <BlockBorder isActive={activeBlock === 1}>
        <ActiveBlockContainer
          onClick={() => {
            setActiveBlock(1)
            selectStatus('')
          }}
        >
          <BlockText>
            Всего <br /> {statuses.Всего}
          </BlockText>
        </ActiveBlockContainer>
      </BlockBorder>
      <BlockBorder blue isActive={activeBlock === 2}>
        <ActiveBlockContainer
          blue
          onClick={() => {
            setActiveBlock(2)
            selectStatus('free')
          }}
        >
          <BlockText>
            Свободно <br /> {statuses.Свободно}
          </BlockText>
        </ActiveBlockContainer>
      </BlockBorder>
      <BlockBorder red isActive={activeBlock === 3}>
        <ActiveBlockContainer
          red
          onClick={() => {
            setActiveBlock(3)
            selectStatus('active')
          }}
        >
          <BlockText>
            Занято <br /> {statuses.Занято}
          </BlockText>
        </ActiveBlockContainer>
      </BlockBorder>
      <BlockBorder purple isActive={activeBlock === 4}>
        <ActiveBlockContainer
          purple
          onClick={() => {
            setActiveBlock(4)
            selectStatus('dayOff')
          }}
        >
          <BlockText>
            Не в рейсе <br /> {statuses['Не в рейсе']}
          </BlockText>
        </ActiveBlockContainer>
      </BlockBorder>
    </Container>
  )
}

const ActiveBlock = connect(R.applySpec({}), {
  selectStatus: selectStatusRequest,
})(ActiveBlockDump)

export default ActiveBlock
