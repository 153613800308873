import React, { useState } from 'react'
import styled from 'styled-components'
import { Modal } from '@material-ui/core'

import assets from '../../../assets'

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`

const Header = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  height: 60px;
`

const Main = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
`

const CancelButtonContainer = styled.div`
  position: absolute;
  top: 15px;
  right: 25px;
  height: 25px;
`

const BtnArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50%;
  font-size: 30px;
  margin: 0 60px;
  cursor: pointer;
  width: 40px;
  color: white;
  padding: 40px;
  @media screen and (min-width: 320px) and (max-width: 767px) {
    margin: 0;
    padding: 15px;
  }
`

const ImgArea = styled.img`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`

const ImgLink = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 45%;
  text-decoration: none;
  border: 0;
  @media screen and (min-width: 320px) and (max-width: 767px) {
    max-width: 90%;
  }
`

const ImgContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
`

const TextContainer = styled.div`
  color: white;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-family: FuturaBookC, sans-serif;
  font-size: 20px;
  letter-spacing: 0;
`

const FileNumber = styled(TextContainer)`
  font-size: 15px;
  margin-top: 15px;
`

const HelperText = styled.div`
  font-family: FuturaBookC, sans-serif;
  font-size: 20px;
  letter-spacing: 0;
  text-align: center;
  color: #e6e6e6;
  margin-top: 10px;
`

const DeleteIcon = styled.img.attrs({ src: assets.img.deleteWhite })`
  height: 25px;
  cursor: pointer;
`

const ArrowRight = styled.img.attrs({ src: assets.img.vectorRight })``

const ArrowLeft = styled.img.attrs({ src: assets.img.vectorLeft })``

const Download = styled.img.attrs({ src: assets.img.document })`
  width: 100px;
`

const DocumentsViewModal = ({ open, handleModalClose, documents }) => {
  const [selectedImage, setImage] = useState(0)

  const next = () => {
    if (selectedImage < documents.length - 1) {
      setImage(selectedImage + 1)
    }
  }

  const prev = () => {
    if (selectedImage > 0) {
      setImage(selectedImage - 1)
    }
  }

  const acceptedExtensions = ['jpeg', 'jpg', 'png', 'JPEG', 'JPG', 'PNG']

  const re = /(?:\.([^.]+))?$/
  const docFilename = documents[selectedImage].uri
  const docFilenameArr = docFilename.split('/')
  const fileExt = re.exec(docFilenameArr[docFilenameArr.length - 1])[1]
  const isImage = acceptedExtensions.includes(fileExt)

  return (
    <Modal open={open} onClose={handleModalClose}>
      <Wrapper>
        <Header>
          <TextContainer>{documents[selectedImage].title}</TextContainer>
          <CancelButtonContainer onClick={handleModalClose}>
            <DeleteIcon />
          </CancelButtonContainer>
        </Header>
        <FileNumber>{selectedImage + 1 + '/' + documents.length}</FileNumber>
        <Main>
          <BtnArea onClick={prev}>
            <ArrowLeft />
          </BtnArea>
          <ImgLink
            href={`${documents[selectedImage].uri}`}
            download={`${documents[selectedImage].uri}`}
          >
            <ImgContainer>
              {isImage ? (
                <ImgArea src={documents[selectedImage].uri} />
              ) : (
                <>
                  <Download />
                  <HelperText>Для скачивания нажмите на значок</HelperText>
                </>
              )}
            </ImgContainer>
          </ImgLink>
          <BtnArea onClick={next}>
            <ArrowRight />
          </BtnArea>
        </Main>
      </Wrapper>
    </Modal>
  )
}

export default DocumentsViewModal
