import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import useStyles from './styles'
import LoadResultModal from './UploadResultModal'
import { connect } from 'react-redux'
import { uploadProductsRequest } from '../../../modules/products'

const UploadButton = ({ uploadProducts, setModalShow, editAccess }) => {
  const [isShowModal, setShowModal] = useState(false)

  const classes = useStyles()

  const fileInput = React.useRef(null)

  const handleFileChange = e => {
    e.stopPropagation()
    e.preventDefault()
    const file = e.target.files[0]
    if (file) {
      setShowModal(true)
      uploadProducts(file)
    }
    e.target.value = null
  }

  return (
    <>
      <input
        type="file"
        style={{ display: 'none' }}
        onChange={handleFileChange}
        ref={fileInput}
        multiple={false}
        accept=".xlsx"
      />
      <Button
        onClick={() =>
          editAccess ? fileInput.current.click() : setModalShow(true)
        }
        classes={{ root: classes.loadButton }}
      >
        Загрузить цены
      </Button>
      <LoadResultModal isShow={isShowModal} close={() => setShowModal(false)} />
    </>
  )
}

export default connect(null, { uploadProducts: uploadProductsRequest })(
  UploadButton,
)
