import React from 'react'

export const useOnClickOutside = f => {
  const isClickedContainer = React.useRef(false)
  const state = React.useRef(f)
  React.useEffect(() => {
    state.current = f
  })
  React.useEffect(() => {
    const handleOnClick = () => {
      if (!isClickedContainer.current) {
        state.current()
      }
      isClickedContainer.current = false
    }
    window.addEventListener('click', handleOnClick)
    return () => {
      window.removeEventListener('click', handleOnClick)
      isClickedContainer.current = false
    }
  }, [])
  return () => {
    isClickedContainer.current = true
  }
}

export const withFullHeight = () => BaseComponent =>
  React.forwardRef((props, topRef) => {
    const ref = React.useRef()
    React.useLayoutEffect(() => {
      const handlerResize = () => {
        if (ref.current) {
          ref.current.style.setProperty('height', `${window.innerHeight}px`)
        }
      }
      handlerResize()
      window.addEventListener('resize', handlerResize)
      return () => window.removeEventListener('resize', handlerResize)
    }, [])

    return (
      <BaseComponent
        ref={refX => {
          ref.current = refX
          if (topRef) {
            topRef.current = refX
          }
        }}
        {...props}
      />
    )
  })

export const widthMobileScreen = 768

export const ltTablet = `@media only screen and (max-width: ${widthMobileScreen -
  1}px)`
export const btTablet = `@media only screen and (min-width: ${widthMobileScreen}px)`
