import React, { useEffect, useState } from 'react'
import { Button, Modal } from '@material-ui/core'
import styled, { css } from 'styled-components'
import 'react-datepicker/dist/react-datepicker.css'
import { format } from 'date-fns'
import moment from 'moment'
import { MONTHS, DAYS, DAYS_LEAP } from './constants'
import { getUTCDate } from '../../../../../screens/analytics'

import assets from '../../../../../assets'
import { useStyles } from './styles'

const Wrapper = styled.div`
  width: 337px;
  height: 440px;
  background: #ffffff;
  box-shadow: 0 6px 14px rgba(2, 9, 75, 0.06);
  border-radius: 8px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  display: flex;
  flex-direction: column;
  outline: 0;
  padding: 20px 30px;
`

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`

const Title = styled.div`
  font-family: FuturaMediumC, sans-serif;
  font-size: 16px;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.4);
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`
const Arrow = styled.img.attrs({ src: assets.img.arrow })``

const LeftArrow = styled(Arrow)`
  transform: rotate(90deg);
  width: 14px;
  height: 14px;
`
const RightArrow = styled(Arrow)`
  transform: rotate(-90deg);
  width: 14px;
  height: 14px;
`
const ArrowButton = styled.button`
  width: 48px;
  height: 48px;
  background: #ffffff;
  box-shadow: 0 2px 5px rgba(10, 21, 128, 0.1);
  border-radius: 6px;
  border: none;
  outline: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`

const DateTitle = styled.div`
  display: flex;
  align-items: center;
  font-family: FuturaDemiC, sans-serif;
  font-size: 17px;
  text-align: center;
  color: #3d4751;
`

const DaysWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 48px);
  grid-template-rows: repeat(5, 48px);
`

const Day = styled.div`
  font-family: FuturaBookC, sans-serif;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ isDisabled, isActive }) =>
    isDisabled && !isActive ? 'rgba(61,71,81,0.35)' : '#35414c'};
  cursor: pointer;
  ${({ isBetween }) =>
    isBetween &&
    css`
      background: rgba(240, 100, 12, 0.15);
    `};
  ${({ isActive }) =>
    isActive
      ? css`
          background: #f0640c;
          box-shadow: 0 6px 14px rgba(2, 9, 75, 0.06);
          border-radius: 6px;
          color: #ffffff;
        `
      : css`
          :hover {
            background: #ffffff;
            box-shadow: 0 2px 5px rgba(10, 21, 128, 0.1);
            border-radius: 6px;
          }
        `};
`

const ButtonWrapper = styled.div`
  display: flex;
  margin-top: auto;
`

const CloseIcon = styled.img.attrs({ src: assets.img.closeMenu })`
  opacity: 0.4;
  cursor: pointer;
`
const ValueDivider = styled.img.attrs({ src: assets.img.divider })``

const ValueWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`

const ValueInput = styled.div`
  height: 38px;
  width: 130px;
  border-radius: 6px;
  background: #ffffff;
  border: 1px solid #dbdfe5;
  box-sizing: border-box;
  box-shadow: inset 0 2px 4px rgba(2, 9, 75, 0.12);
  padding: 11px 26px 8px 15px;
  font-family: FuturaMediumC, sans-serif;
  font-size: 17px;
  letter-spacing: 0.02em;
  color: #3d4751;
`

const isLeapYear = year =>
  (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0

const PeriodModal = ({ open, handleClose, initialValue, handleChange }) => {
  const classes = useStyles()
  const [start, setStart] = useState(false)

  const [year, setYear] = useState(new Date().getFullYear())
  const [month, setMonth] = useState(new Date().getMonth())

  const [yearStart, setYearStart] = useState(new Date().getFullYear())
  const [monthStart, setMonthStart] = useState(new Date().getMonth())
  const [dayStart, setDayStart] = useState(new Date().getDate())

  const [yearEnd, setYearEnd] = useState(new Date().getFullYear())
  const [monthEnd, setMonthEnd] = useState(new Date().getMonth())
  const [dayEnd, setDayEnd] = useState(new Date().getDate())

  const [daysForMap, setDaysForMap] = React.useState([])

  const days = isLeapYear(year) ? DAYS_LEAP : DAYS

  useEffect(() => {
    if (
      initialValue &&
      Object.keys(initialValue).length > 0 &&
      initialValue.startDate
    ) {
      setYearStart(new Date(initialValue.startDate).getFullYear())
      setMonthStart(new Date(initialValue.startDate).getMonth())
      setDayStart(new Date(initialValue.startDate).getDate())

      setYearEnd(new Date(initialValue.endDate).getFullYear())
      setMonthEnd(new Date(initialValue.endDate).getMonth())
      setDayEnd(new Date(initialValue.endDate).getDate())
    }
  }, [initialValue])

  useEffect(() => {
    setDaysForMap(
      Array(days[month])
        .fill({})
        .map((_, index) => ({
          isDisabled:
            new Date(year, month, index + 1).setHours(0, 0, 0, 0) <
            new Date().setHours(0, 0, 0, 0),
          dayValue: index + 1,
          yearValue: year,
          monthValue: month,
        })),
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [month])

  const handleButtonClick = type => {
    if (type === 'left') {
      if (month === 0) {
        setYear(year - 1)
        setMonth(11)
      } else {
        setMonth(month - 1)
      }
    } else {
      if (month === 11) {
        setYear(year + 1)
        setMonth(0)
      } else {
        setMonth(month + 1)
      }
    }
  }

  const handleCloseClick = () => {
    handleClose()
    setYear(new Date().getFullYear())
    setMonth(new Date().getMonth())

    if (initialValue.startDate) {
      setYearStart(new Date(initialValue.startDate).getFullYear())
      setMonthStart(new Date(initialValue.startDate).getMonth())
      setDayStart(new Date(initialValue.startDate).getDate())
      setYearEnd(new Date(initialValue.endDate).getFullYear())
      setMonthEnd(new Date(initialValue.endDate).getMonth())
      setDayEnd(new Date(initialValue.endDate).getDate())
    }
  }

  const handleDayClick = (dayValue, monthValue, yearValue) => {
    if (!start) {
      setDayStart(dayValue)
      setMonthStart(monthValue)
      setYearStart(yearValue)
      setStart(!start)
    } else {
      if (
        new Date(yearValue, monthValue, dayValue).setHours(0, 0, 0, 0) >
        new Date(yearStart, monthStart, dayStart).setHours(0, 0, 0, 0)
      ) {
        setDayEnd(dayValue)
        setMonthEnd(monthValue)
        setYearEnd(yearValue)
        setStart(!start)
      }
    }
  }

  const handleSubmit = () => {
    const startTime = moment(new Date(yearStart, monthStart, dayStart)).format(
      'YYYY-MM-DD',
    )
    const endTime = moment(new Date(yearEnd, monthEnd, dayEnd)).format(
      'YYYY-MM-DD',
    )
    handleChange({
      startDate: startTime,
      endDate: endTime,
    })
    setYear(new Date().getFullYear())
    setMonth(new Date().getMonth())
    handleClose()
    setStart(false)
  }

  return (
    <Modal open={open} onClose={handleCloseClick}>
      <Wrapper>
        <TitleWrapper>
          <Title>Выбрать период</Title>
          <CloseIcon onClick={handleCloseClick} />
        </TitleWrapper>
        <ValueWrapper>
          <ValueInput>
            {format(new Date(yearStart, monthStart, dayStart), 'yyyy.MM.dd')}
          </ValueInput>
          <ValueDivider />
          <ValueInput>
            {format(new Date(yearEnd, monthEnd, dayEnd), 'yyyy.MM.dd')}
          </ValueInput>
        </ValueWrapper>
        <Header>
          <ArrowButton onClick={() => handleButtonClick('left')}>
            <LeftArrow />
          </ArrowButton>
          <DateTitle>
            {MONTHS[month]} {year}
          </DateTitle>
          <ArrowButton onClick={() => handleButtonClick('right')}>
            <RightArrow />
          </ArrowButton>
        </Header>
        <DaysWrapper>
          {daysForMap.map(({ dayValue, monthValue, yearValue, isDisabled }) => (
            <Day
              isBetween={
                new Date(yearValue, monthValue, dayValue).setHours(0, 0, 0, 0) >
                  new Date(yearStart, monthStart, dayStart).setHours(
                    0,
                    0,
                    0,
                    0,
                  ) &&
                new Date(yearValue, monthValue, dayValue).setHours(0, 0, 0, 0) <
                  new Date(yearEnd, monthEnd, dayEnd).setHours(0, 0, 0, 0)
              }
              isActive={
                new Date(yearValue, monthValue, dayValue).setHours(
                  0,
                  0,
                  0,
                  0,
                ) ===
                  new Date(yearStart, monthStart, dayStart).setHours(
                    0,
                    0,
                    0,
                    0,
                  ) ||
                new Date(yearValue, monthValue, dayValue).setHours(
                  0,
                  0,
                  0,
                  0,
                ) === new Date(yearEnd, monthEnd, dayEnd).setHours(0, 0, 0, 0)
              }
              onClick={() => handleDayClick(dayValue, monthValue, yearValue)}
              key={dayValue}
            >
              {dayValue}
            </Day>
          ))}
        </DaysWrapper>
        <ButtonWrapper>
          <Button
            onClick={handleSubmit}
            classes={{
              root: classes.addButton,
            }}
          >
            Готово
          </Button>
          <Button
            classes={{
              root: classes.cancelButton,
            }}
            onClick={handleCloseClick}
          >
            Отмена
          </Button>
        </ButtonWrapper>
      </Wrapper>
    </Modal>
  )
}

export default PeriodModal
