import React from 'react'
import styled, { css } from 'styled-components'

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 15px;
`

const OrdersCountTitle = styled.div`
  font-family: FuturaBookC, sans-serif;
  font-size: 16px;
  color: #8b949b;
  margin-right: 10px;
`

const Count = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 38px;
  height: 38px;
  font-family: FuturaBookC, sans-serif;
  font-size: 16px;
  ${({ isActive }) =>
    isActive
      ? css`
          background: #ffffff;
          box-shadow: 0 6px 14px rgba(10, 21, 128, 0.1);
          color: #f0640c;
        `
      : css`
          color: #495560;
        `}
  border-radius: 6px;
`

const OrdersCount = ({ countOnPage, setCountOnPage }) => {
  return (
    <Wrapper>
      <OrdersCountTitle>Показывать строк</OrdersCountTitle>
      <Count isActive={countOnPage === 5} onClick={() => setCountOnPage(5)}>
        5
      </Count>
      <Count isActive={countOnPage === 10} onClick={() => setCountOnPage(10)}>
        10
      </Count>
      <Count isActive={countOnPage === 20} onClick={() => setCountOnPage(20)}>
        20
      </Count>
    </Wrapper>
  )
}

export default OrdersCount
