import React, { useState, useCallback } from 'react'
import * as R from 'ramda'
import styled from 'styled-components'
import TextField from '@material-ui/core/TextField'
import { withStyles } from '@material-ui/core/styles'
import MaskedInput from 'react-text-mask'

function TextMaskCustom(props) {
  const { inputRef, ...other } = props

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null)
      }}
      mask={[
        //'\+',
        '(',
        /[1-9]/,
        /\d/,
        /\d/,
        ')',
        ' ',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
      ]}
      //placeholderChar={'\u2000'}
    />
  )
}

const styles = () => ({
  notchedOutline: {
    borderRadius: '8px',
    borderWidth: '1px',
    borderColor: '#F49355  !important',
  },
  focused: {
    fontSize: 15,
    borderColor: '#F0640C !important',
  },
})

const labelStyle = {
  color: '#000000',
  opacity: 0.4,
  fontFamily: 'FuturaBookC',
  fontSize: 17,
}

const Container = styled.div`
  position: relative;
`

const InputWrapper = React.memo(props => (
  <TextField {...R.dissoc('isFocusedOrHasValue', props)} />
))

const StyledTextField = styled(InputWrapper)`
  width: 100%;

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  label {
    margin-top: ${({ isFocusedOrHasValue }) =>
      isFocusedOrHasValue ? 0 : -10}px;
  }

  input {
    padding: 9.5px;
  }

  .MuiInputBase-multiline {
    padding: 9.5px 15px;
  }

  .MuiFormHelperText-root {
    margin: 10px 43px 15px 0;
    font-family: 'FuturaBookC', sans-serif;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
    opacity: 0.4;
  }
`

const Error = styled.span`
  font-family: 'FuturaBookC', sans-serif;
  font-size: 15px;
  line-height: 17px;

  color: #f55555;
`

const identity = val => {
  return val
}

const PhoneInputDump = ({
  classes,
  type,
  className,
  input: { onChange, ...input },
  meta: { touched, error },
  inputProps,
  labelPropsStyle,
  onChangeNormilizer = identity,
  errorLabelProps,
  ...props
}) => {
  const [isFocused, setFocus] = useState(false)

  const onChangeHandler = useCallback(
    ({ target: { value } }) => {
      onChange(onChangeNormilizer(value))
    },
    [onChange, onChangeNormilizer],
  )

  return (
    <Container className={className}>
      <StyledTextField
        margin="normal"
        variant="outlined"
        color="#F0640C"
        onFocusCapture={() => {
          setFocus(true)
        }}
        onBlurCapture={() => {
          setFocus(false)
        }}
        InputLabelProps={{ style: { ...labelStyle, ...labelPropsStyle } }}
        isFocusedOrHasValue={isFocused || !!input.value.toString()}
        id="formatted-numberformat-input"
        InputProps={{
          inputComponent: TextMaskCustom,
          classes: {
            notchedOutline: classes.notchedOutline,
            focused: classes.cssFocused,
          },
        }}
        onChange={onChangeHandler}
        showMask={isFocused}
        {...input}
        {...props}
      />
      {touched && !!error && <Error>{error}</Error>}
    </Container>
  )
}

const PhoneInput = withStyles(styles)(PhoneInputDump)

export default PhoneInput
