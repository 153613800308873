import { ENDPOINTS } from '../../API'
import { handleStatuses } from '../../utils'
import querystring from 'query-string'

export const getTransport = ({ accessToken, filters }) =>
  fetch(
    ENDPOINTS.GET_TRANSPORT(
      querystring.stringify(filters, { arrayFormat: 'bracket' }),
    ),
    {
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${accessToken}`,
      },
    },
  )
    .then(handleStatuses())
    .then(res => res.json())

export const getTransportCategory = ({ accessToken }) =>
  fetch(ENDPOINTS.TRANSPORT_CATEGORY(), {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  })
    .then(handleStatuses())
    .then(res => res.json())

export const getTransportStatuses = ({ accessToken }) =>
  fetch(ENDPOINTS.TRANSPORT_STATUSES(), {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  })
    .then(handleStatuses())
    .then(res => res.json())

export const createTransportCategory = ({ body, accessToken }) =>
  fetch(ENDPOINTS.TRANSPORT_CATEGORY(), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(body),
  })
    .then(handleStatuses())
    .then(res => res.json())

export const createTransport = ({ body, accessToken }) =>
  fetch(ENDPOINTS.TRANSPORT(), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(body),
  })
    .then(handleStatuses())
    .then(res => res.json())

export const deleteTransport = ({ id, accessToken }) =>
  fetch(ENDPOINTS.TRANSPORT(id), {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  }).then(handleStatuses())

export const deleteCategory = ({ id, accessToken }) =>
  fetch(ENDPOINTS.TRANSPORT_CATEGORY(id), {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  }).then(handleStatuses())

export const editTransport = ({ body, accessToken }) =>
  fetch(ENDPOINTS.TRANSPORT(), {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(body),
  }).then(handleStatuses())

export const editCategory = ({ body, accessToken }) =>
  fetch(ENDPOINTS.TRANSPORT_CATEGORY(), {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(body),
  }).then(handleStatuses())
