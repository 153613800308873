import React, { useCallback, useState } from 'react'
import styled from 'styled-components'
import * as R from 'ramda'
import { connect } from 'react-redux'
import {
  deleteSubcategoryRequest,
  getCategoriesList,
  setSelectedCategory,
  getSelectedCategory,
} from '../../modules/categories'
import { PageBlock, ListTitleBlock } from '../common'
import CategoryElement from './CategoryElement'
import assets from '../../assets'
import CreateCategory from './CreateCategory'

const StyledListHeaderBlock = styled.div`
  overflow-y: unset;
  display: flex;
  align-items: center;

`

const StyledListTitleBlock = styled(ListTitleBlock)`
  width: 100%;
  padding: 11px 0 9px 20px;
  background: #ffffff;
  box-shadow: 0 6px 14px rgb(2 9 75 / 6%);
  border-radius: 8px 0 0 8px;
  opacity: 1;
  color: rgba(0, 0, 0, 0.4);
`

const PlusIcon = styled.img``
const AddButton = styled.button`
  cursor: pointer;
  appearance: none;
  outline: none;
  border: none;
  border-radius: 0 8px 8px 0;
  height: 100%;
  padding: 0 9px;
  margin-left: 4px;
  background: ${({ isCreating }) =>
          isCreating
                  ? 'linear-gradient(135deg, #F49355 0%, #F0640C 100%)'
                  : '#fff'};
`


const CategoriesBlockDump = ({
                               categories,
                               selectedCategory,
                               deleteSubcategoryRequest,
                               selectCategory,
                               isEditable,
                               editAccess,
                               setModalShow,
                             }) => {

  const [isCreating, setIsCreating] = useState(false)
  const onAddButtonClick = () => {
    if (isCreating) {
      return
    }
    setIsCreating(true)
    selectCategory(null)
  }

  const onCategoryElementClick = useCallback(
    id => {
      setIsCreating(false)
      if (id !== selectedCategory) {
        selectCategory(id)
      }
    },
    [selectedCategory, selectCategory],
  )

  const renderCreateCategory = () => {
    if (isCreating) return (
      <CreateCategory
        onCancel={() => setIsCreating(false)}
      />
    )
    return null
  }

  const renderItems = () => {
    if (isCreating) return null
    return (
      categories.map(props => (
        <CategoryElement
          key={props.id}
          selectedCategory={selectedCategory}
          deleteSubcategoryRequest={deleteSubcategoryRequest}
          onClick={onCategoryElementClick}
          isEditable={isEditable}
          {...props}
          editAccess={editAccess}
          setModalShow={setModalShow}
        />
      ))
    )
  }


  return (
    <PageBlock>
      <StyledListHeaderBlock>
        <StyledListTitleBlock>Категории</StyledListTitleBlock>
        <AddButton onClick={onAddButtonClick} isCreating={isCreating}>
          <PlusIcon
            src={isCreating ? assets.img.whitePlus : assets.img.plus}
          />
        </AddButton>
      </StyledListHeaderBlock>
      {renderItems()}
      {renderCreateCategory()}
    </PageBlock>
  )
}

CategoriesBlockDump.defaultProps = {
  isEditable: true,
}

const CategoriesBlock = connect(
  R.applySpec({
    categories: getCategoriesList,
    selectedCategory: getSelectedCategory,
  }),
  {
    deleteSubcategoryRequest,
    selectCategory: setSelectedCategory,
  },
)(CategoriesBlockDump)

export default CategoriesBlock
