import querystring from 'query-string'
import { handleStatuses } from '../../utils'
import { ENDPOINTS } from '../../API'

window.querystring = querystring

export const getOrders = ({ token, boardId, query }) =>
  fetch(
    ENDPOINTS.ORDERS(
      `${boardId}?${querystring.stringify(query, { arrayFormat: 'bracket' })}`,
    ),
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    },
  )
    .then(handleStatuses())
    .then(res => res.json())

export const createOrder = ({ token, boardId, data }) =>
  fetch(ENDPOINTS.ORDERS(boardId), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  })
    .then(handleStatuses())
    .then(res => res.json())

export const editOrder = ({ token, boardId, data }) =>
  fetch(ENDPOINTS.ORDERS(boardId), {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  })
    .then(handleStatuses())
    .then(res => res.json())

export const getOrder = ({ token, orderId }) =>
  fetch(ENDPOINTS.GET_ORDER(orderId), {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
    .then(handleStatuses())
    .then(res => res.json())

export const deleteOrder = ({ token, orderId }) =>
  fetch(ENDPOINTS.DELETE_ORDER(orderId), {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
    .then(handleStatuses())
    .then(res => res.json())

export const updateOrderState = ({ token, orderId, state }) =>
  fetch(ENDPOINTS.UPDATE_ORDER_STATE(), {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ orderId, state }),
  })
    .then(handleStatuses())
    .then(res => res.json())

export const getTotalOrders = ({ token, query }) =>
  fetch(
    ENDPOINTS.TOTAL_ORDERS(
      `?${querystring.stringify(query, { arrayFormat: 'bracket' })}`,
    ),
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    },
  )
    .then(handleStatuses())
    .then(res => res.json())

export const downloadCompletedOrders = ({ token, boardId, query }) =>
  fetch(
    ENDPOINTS.DOWNLOAD_COMPLETED_ORDERS(
      `${boardId}?${querystring.stringify(query, { arrayFormat: 'bracket' })}`,
    ),
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    },
  )
    .then(handleStatuses())
    .then(res => res.blob())
    .then(blob => {
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = 'Выгрузка.xlsx'
      document.body.appendChild(a)
      a.click()
      a.remove()
    })
