import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { FilePicker } from 'react-file-picker'
import { TextField } from '@material-ui/core'
import * as R from 'ramda'
import styled from 'styled-components'
import assets from '../../assets'

const styles = () => ({
  notchedOutline: {
    borderRadius: '8px',
    borderWidth: '1px',
    borderColor: '#F49355  !important',
  },
  shadowOutline: {
    boxShadow: '0px 6px 10px rgba(2, 9, 75, 0.06)',
    borderRadius: '8px',
    border: '0px',
  },
  focused: {
    fontSize: 15,
    borderColor: '#F0640C !important',
  },
})

const InputWrapper = React.memo(props => (
  <TextField {...R.dissoc('isFocusedOrHasValue', props)} />
))

const Container = styled.div`
  position: relative;
`
const FormContainer = styled.div`
  width: 100%;
`
const Error = styled.span`
  font-family: 'FuturaBookC', sans-serif;
  font-size: 15px;
  line-height: 17px;

  color: #f55555;
`

const ButtonsContainer = styled.div`
  display: flex;
  background: #fff;
  padding: 20px;

  border-radius: 8px;
`

const ConfirmButton = styled.button`
  height: 36px;
  border: none;
  background: linear-gradient(135deg, #f49355 0%, #f0640c 100%);
  box-shadow: 0px 4px 10px rgba(247, 137, 68, 0.55);
  cursor: pointer;
  outline: none;
  border-radius: 3px;
  color: white;
  margin-right: 15px;
`

const CancelButton = styled(ConfirmButton)`
  background: linear-gradient(135deg, #b6b6b6 0%, #636363 100%);
`

const EditButton = styled.button`
  height: 36px;
  border: none;
  background: linear-gradient(135deg, #f49355 0%, #f0640c 100%);
  box-shadow: 0px 4px 10px rgba(247, 137, 68, 0.55);
  cursor: pointer;
  outline: none;
  border-radius: 3px;
  color: white;
  margin-right: 15px;
`

const StyledTextField = styled(InputWrapper)`
  cursor: ${({ editMode }) => (!editMode ? 'pointer' : '')};
  width: 100%;

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  label {
    margin-top: ${({ isFocusedOrHasValue }) =>
      isFocusedOrHasValue ? 0 : -10}px;
  }

  input {
    padding: 9.5px;
  }

  .MuiInputBase-multiline {
    padding: 9.5px 15px;
  }

  .MuiFormHelperText-root {
    margin: 10px 43px 15px 0;
    font-family: 'FuturaBookC', sans-serif;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
    opacity: 0.4;
  }
`
const Title = styled.div`
  display: flex;
  margin-bottom: 4px;
  margin-top: 14px;
  margin-bottom: 6px;
  font-family: FuturaMediumC;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  opacity: 0.2;
  text-transform: uppercase;
`

const DeletionTitle = styled.div`
  cursor: pointer;
  display: flex;
  color: #f49355;
  font-family: FuturaMediumC;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  opacity: 0.8;
  text-transform: uppercase;
`

const DocImagesContainer = styled.div`
  display: flex;
  margin-top: 8px;
`
const DocImgWrapper = styled.div`
  overflow: hidden;
  position:relative;
  color: black;
  cursor: pointer;
  background-color: white;
  height: 40px;
  width: 40px;
  border: 1px solid rgba(18, 22, 26, 0.1);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px
  text-align: left;
  &:hover {
    
  }
`

const DocImg = styled.img`
  cursor: pointer;
  background-color: white;
  height: 100%;
  width: 100%;
  :hover {
  }
`

const AddFileIcon = styled.img.attrs({ src: assets.img.addFile })`
  cursor: pointer;
`

const FadeContainer = styled.div`
  opacity: 0;
  :hover {
    opacity: 1;
  }
  height: 100%;
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
`
const DeletionBg = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  background: rgba(18, 22, 26, 0.4);
`
const DeleteIcon = styled.img.attrs({ src: assets.img.delete })`
  margin-left: 5px;
`
const DeleteImgIcon = styled.img.attrs({ src: assets.img.deleteWhite })`
  height: 60%;
  position: absolute;
`

const DocumentItem = ({
  id,
  files,
  selectDocument,
  title,
  edit,
  onClickDeleteButton,
  classes,
  onChange,
  ...props
}) => {
  const handleCancel = () => {
    setFocus(false)
    setTitle(initialTitle)
    setFiles(initialFiles)
  }

  const handleSubmit = () => {
    if (!error) {
      const fls = newFiles.map(file => {
        return { ...file, title: newTitle }
      })

      onChange({ initialTitle, title: newTitle, files: fls })
      setFocus(false)
      setInitialTitle(newTitle)
      setInitialFiles(newFiles)
    }
  }

  useEffect(() => {
    setTitle(title)
    setInitialTitle(title)
  }, [title])

  useEffect(() => {
    setFiles(files)
    setInitialFiles(files)
    setFocus(false)
    setError(null)
  }, [files])

  const [error, setError] = useState(null)

  const [newTitle, setTitle] = useState(title)
  const [initialTitle, setInitialTitle] = useState(title)

  const [newFiles, setFiles] = useState(files)
  const [initialFiles, setInitialFiles] = useState(files)

  const handleAddingFile = file => {
    const newList = newFiles.concat(file)
    setFiles(newList)
  }

  useEffect(() => {
    if (!newTitle.length) setError('Название документа не может быть пустым')
    else if (!newFiles.length) setError('Прикрепите минимум 1 документ')
    else setError(null)
  }, [newFiles, newTitle, title])

  const handleRemovingFile = index => {
    const newList = newFiles.filter((item, i) => {
      if (index == i) return false
      else return true
    })
    setFiles(newList)
  }

  const handleChangeTitle = event => {
    const value = event.target.value
    setTitle(value)
  }

  const [isFocused, setFocus] = useState(false)

  const handleFocus = useCallback(() => {
    if (edit) setFocus(true)
  }, [edit])

  return (
    <div onFocus={handleFocus}>
      {edit && isFocused && (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Title>ИЗМЕНИТЬ</Title>
          <div
            style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
          >
            <DeletionTitle onClick={onClickDeleteButton}>Удалить</DeletionTitle>
            <DeleteIcon style={{ marginLeft: '5px' }} />
          </div>
        </div>
      )}
      <StyledTextField
        onClick={() => {
          if (!edit) selectDocument(newFiles)
        }}
        margin="normal"
        variant="outlined"
        color="#F0640C"
        onChange={handleChangeTitle}
        value={newTitle}
        type={'text'}
        InputProps={{
          classes: {
            notchedOutline: edit
              ? classes.notchedOutline
              : classes.shadowOutline,
            focused: classes.cssFocused,
          },
        }}
        label={edit ? 'Название документа' : ''}
      />
      {error && <Error>{error}</Error>}
      {edit && isFocused && (
        <>
          <DocImagesContainer>
            {newFiles.map((image, index) => (
              <DocImgWrapper
                onClick={() => {
                  handleRemovingFile(index)
                }}
              >
                <DocImg src={`${image.uri}`} />
                <FadeContainer>
                  <DeletionBg />
                  <DeleteImgIcon />
                </FadeContainer>
              </DocImgWrapper>
            ))}
            <FilePicker
              onChange={handleAddingFile}
              onError={error => {
                console.log(error)
              }}
            >
              <AddFileIcon onClick={handleAddingFile} />
            </FilePicker>
          </DocImagesContainer>

          <ButtonsContainer>
            {(newFiles.length != initialFiles.length ||
              newTitle != initialTitle) && (
              <EditButton onClick={handleSubmit}>Сохранить</EditButton>
            )}
            <CancelButton onClick={handleCancel}>Отменить</CancelButton>
          </ButtonsContainer>
        </>
      )}
    </div>
  )
}

const AddDocumentButton = ({ item, onClick, ...props }) => {
  const Button = styled.button`
    height: 34px;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 16px;
    margin-bottom: 8px;
    padding: 9.5px;
    box-sizing: border-box;
    background: #fff;
    font-family: 'FuturaBookC', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(18, 22, 26, 0.4);
  `

  return (
    <Button onClick={onClick}>
      <span>Добавить документ</span>
      <span>+</span>
    </Button>
  )
}

const SelectDocuments = ({
  data,
  listItemRenderer,
  input: { onChange, value },
  meta: { touched, error },
  className,
  inputProps,
  classes,
  label,
  editMode,
  creatingMode,
  ...props
}) => {
  const [isCreatingDocument, setCreatingDocumentStatus] = useState(false)

  const [textValue, setTextValue] = useState(null)
  const [newDocumentsList, setNewDocumentsList] = useState([])
  const [previewUrls, setPreviewUrls] = useState([])


  const deleteDocument = title => {
    const newFormValues = value.filter(item => {
      if (item.title == title) return false
      return true
    })

    onChange(newFormValues)
  }

  const [localError, setLocalError] = useState(null)

  const handleDocItemChange = ({ initialTitle, title, files }) => {
    if (true) {
      const newFormValues = value
        .filter(item => {
          if (item.title == initialTitle) return false
          return true
        })
        .concat(files)

      onChange(newFormValues)
    }
  }

  const handleAddingFile = file => {
    const newList = newDocumentsList.concat(file)

    setNewDocumentsList(newList)
  }

  const handleCancel = () => {
    setCreatingDocumentStatus(false)
    setPreviewUrls([])
    setNewDocumentsList([])
  }

  const confirmAddingFile = () => {
    if (!localError) {
      const newFiles = newDocumentsList.map((item, i) => {
        return {
          title: textValue,
          id: null,
          uri: null,
          file: item,
        }
      })
      const newFormValues = value.concat(newFiles)

      onChange(newFormValues)
      setNewDocumentsList([])
      setPreviewUrls([])
      setCreatingDocumentStatus(false)
    }
  }

  const startCreatingDocument = () => {
    setCreatingDocumentStatus(true)
  }

  const handleRemovingFile = index => {
    const newList = newDocumentsList.filter((item, i) => {
      if (index == i) return false
      else return true
    })
    if(newList.length==0){
      setPreviewUrls([])
    }
    setNewDocumentsList(newList)

  }

  const groupByTitle = R.groupBy(document => document.title)
  const docList = useMemo(() => {
    const data = JSON.parse(JSON.stringify(value))
    const groupedData = groupByTitle(data)

    return R.values(groupedData)
  }, [groupByTitle, value])

  useEffect(() => {
    if (!textValue) setLocalError('Название документа не может быть пустым')
    else if (!newDocumentsList.length)
      setLocalError('Прикрепите минимум 1 документ')
    else setLocalError(null)
  }, [textValue, newDocumentsList])

  useEffect(() => {
    setCreatingDocumentStatus(false)
    setLocalError(false)
    setNewDocumentsList([])
    setPreviewUrls([])
  }, [editMode])

  const updatePreviewUrls = function() {
    const urls = []
    newDocumentsList.forEach((file, i) => {
      let reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onloadend = () => {
        urls.push(reader.result)
        if (urls.length == newDocumentsList.length) setPreviewUrls(urls)
      }
    })
  }

  useEffect(() => {
    updatePreviewUrls()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, newDocumentsList])
  return (
    <>
      <Container className={className}>
        <FormContainer>
          {docList.map((item, index) => (
            <DocumentItem
              onClickDeleteButton={() => {
                deleteDocument(item[0].title)
              }}
              key={index}
              id={index}
              onChange={handleDocItemChange}
              title={item[0].title}
              classes={classes}
              edit={editMode}
              isCreating={creatingMode}
              files={item}
              {...props}
            />
          ))}
          {isCreatingDocument ? (
            <>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Title
                  onClick={() => {
                    setCreatingDocumentStatus(false)
                  }}
                >
                  ДОБАВИТЬ ДОКУМЕНТ
                </Title>
              </div>

              <StyledTextField
                onChange={event => {
                  setTextValue(event.target.value)
                }}
                editMode={editMode}
                margin="normal"
                label="Название документа"
                variant="outlined"
                color="#F0640C"
                type={'text'}
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline,
                    focused: classes.cssFocused,
                  },
                }}
              />
              {localError && <Error>{localError}</Error>}
              <DocImagesContainer>
                {previewUrls.map((image, index) => (
                  <DocImgWrapper
                    key={index}
                    onClick={() => {
                      handleRemovingFile(index)
                    }}
                  >
                    <DocImg src={image} />
                    <FadeContainer>
                      <DeletionBg />
                      <DeleteImgIcon />
                    </FadeContainer>
                  </DocImgWrapper>
                ))}
                <FilePicker
                  onChange={handleAddingFile}
                  onError={error => {
                    console.log(error)
                  }}
                >
                  <AddFileIcon />
                </FilePicker>
              </DocImagesContainer>
              <ButtonsContainer>
                <ConfirmButton onClick={confirmAddingFile}>
                  Добавить
                </ConfirmButton>
                <CancelButton
                  onClick={handleCancel}
                >
                  Отменить
                </CancelButton>
              </ButtonsContainer>
            </>
          ) : (
            (editMode || creatingMode) && (
              <AddDocumentButton onClick={startCreatingDocument} />
            )
          )}
        </FormContainer>
      </Container>
    </>
  )
}

export default withStyles(styles)(SelectDocuments)
