import { useEffect, useState } from 'react'
import io from 'socket.io-client'

const getWSUrl = ()=> {
  const currentOrigin = window.location.origin
  if(currentOrigin.includes('localhost')) {
    return 'https://devadmin.prostore.su'
  }
  return currentOrigin
}

export const useWebSockets = () => {
  const [updatedData, setUpdatedData] = useState()
  useEffect(() => {
    const socket = io(getWSUrl(),{

      transports: ['websocket'],
      port: 3000,
    })

    socket.onAny((action, data) => {
      setUpdatedData({ toUpdate: { ...data }, action })
    })

    return () => socket.disconnect()
  }, [updatedData])

  return {
    updatedData,
  }
}
