import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { makeStyles } from '@material-ui/core/styles'
import { Table, TableBody, TableContainer, TableHead } from '@material-ui/core'
import { connect } from 'react-redux'
import * as R from 'ramda'

import { StyledTableCell } from './table-cell'
import { StyledTableRow } from './table-row'

import { getAnalyticsList } from '../../../../modules/analytics'

import assets from '../../../../assets'
import { FooterTableCell } from '../organizations/footer-table-cell'
import Header from './header'

const Container = styled.div`
  width: 1020px;
  display: flex;
  margin: 15px 0;
`

const SortWrapper = styled.div`
  position: absolute;
  right: 12px;
  top: 6px;
  cursor: pointer;
  ${({ isActive }) =>
          isActive &&
          css`
            top: 8px;
            transform: rotate(180deg);
          `}
`

const SortIcon = styled.img.attrs({ src: assets.img.sortIcon })``

const useStyles = makeStyles({
  table: {
    borderCollapse: 'separate',
    borderSpacing: '4px',
  },
})

const Drivers = ({
                   driverAnalytics,
                   handleGetAnalyticsInfo,
                   setInfoTotal,
                   getAnalytics,
                   requestData,
                   period,
                   setPeriod,
                   downloadOnClick,
                   searchedDriver,
                   setSearchedDriver,
                 }) => {
  const classes = useStyles()
  const [sort, setSort] = useState(false)

  useEffect(() => {
    getAnalytics({ ...requestData, type: 'driver' })
  }, [requestData, getAnalytics])

  return (
    <>
      <Header period={period} setPeriod={setPeriod}
              downloadOnClick={downloadOnClick}
              searchedDriver={searchedDriver}
              setSearchedDriver={setSearchedDriver}
      />
      <TableContainer component={Container}>
        <Table className={classes.table}>
          <TableHead>
            <StyledTableRow>
              <StyledTableCell isFirst minWidth={270}>
                Имя водителя
              </StyledTableCell>
              <StyledTableCell minWidth={170}>Гос Номер</StyledTableCell>
              <StyledTableCell minWidth={175}>
                Заявки
                <SortWrapper isActive={sort} onClick={() => setSort(!sort)}>
                  <SortIcon />
                </SortWrapper>
              </StyledTableCell>
              <StyledTableCell minWidth={230}>
                Заявки в текущем месяце
              </StyledTableCell>
              <StyledTableCell minWidth={170}>Отсутствует ТТН</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {driverAnalytics &&
            driverAnalytics
              .sort((a, b) => {
                if (!sort) {
                  return a.order
                    ? b.order
                      ? a.order.all - b.order.all
                      : -1
                    : 1
                } else {
                  return b.order
                    ? a.order
                      ? b.order.all - a.order.all
                      : -1
                    : 1
                }
              })
              .map(item => (
                <StyledTableRow
                  onClick={() => {
                    handleGetAnalyticsInfo(item.id, 'driver')
                    setInfoTotal(item.order ? item.order.all : 0)
                  }}
                  key={item.id}
                >
                  <StyledTableCell isFirst>{item.fio}</StyledTableCell>
                  <StyledTableCell>{item.carNumber}</StyledTableCell>
                  <StyledTableCell>
                    {item.order ? item.order.all : '-'}
                  </StyledTableCell>
                  <StyledTableCell>
                    {item.order ? item.order.inThePeriod : '-'}
                  </StyledTableCell>
                  <StyledTableCell>
                    {item.order ? item.order.withoutTTN : '-'}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            <StyledTableRow>
              <FooterTableCell textAlign='left'>Всего</FooterTableCell>
              <FooterTableCell />
              <FooterTableCell textAlign='center'>
                {driverAnalytics &&
                driverAnalytics.reduce(
                  (acc, init) =>
                    init.order ? acc + Number(init.order.all) : acc + 0,
                  0,
                )}
              </FooterTableCell>
              <FooterTableCell textAlign='center'>
                {driverAnalytics &&
                driverAnalytics.reduce(
                  (acc, init) =>
                    init.order ? acc + Number(init.order.inThePeriod) : acc + 0,
                  0,
                )}
              </FooterTableCell>
              <FooterTableCell textAlign='center'>
                {driverAnalytics &&
                driverAnalytics.reduce(
                  (acc, init) =>
                    init.order ? acc + Number(init.order.withoutTTN) : acc + 0,
                  0,
                )}
              </FooterTableCell>
            </StyledTableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default connect(
  R.applySpec({
    driverAnalytics: getAnalyticsList,
  }),
)(Drivers)
