import * as R from 'ramda'
import React, { useMemo, useState } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import styled, { css } from 'styled-components'

import AnalyticsPeriodFilter from '../../analytics-header/analytics-period-filter'
import { getOrganizationList } from '../../../../modules/organizations'
import CustomSelect from '../../../common/CustomSelect'

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const FilterWrapper = styled.div`
  display: flex;
  align-items: center;
`

const Row = styled.div`
  display: flex;
`

const ErrorText = styled.div`
  color: #f55555;
  font-family: 'FuturaBookC', sans-serif;
  font-size: 13px;
  line-height: 15px;
  margin-left: 75px;
  margin-top: 5px;
`

const GraphRangeContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 9px 10px;
  border-radius: 8px;
  margin-left: 5px;
  font-family: 'FuturaBookC', sans-serif;
  font-size: 16px;
  line-height: 18px;
  color: #666666;
  cursor: pointer;

  ${({ isActive }) => isActive
          ? css`
            border: 1px solid #f49355;
          `
          : css`
            border: 1px solid #ffffff;
            background: #ffffff;
            box-shadow: 0 6px 10px rgba(2, 9, 75, 0.06);
          `}
  &:first-child {
    margin-left: 20px;
  }
`

const CustomSelectWrapper = styled.div`
  width: 180px;
  padding-right: 20px;
`

const SelectItem = styled.div`
  width: 100%;
  height: 30px;
  border-bottom: 1px solid #f49355;
  padding: 12px 28px 9px 15px;
  box-sizing: border-box;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  &:first-child {
    border-top: 1px solid #f49355;
  }

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    background-color: #f2f2f2
  }
`

const graphsRanges = [
  {
    type: 'month',
    label: 'Месяц',
  },
  {
    type: 'quarter',
    label: 'Квартал',
  },
  {
    type: 'year',
    label: 'Год',
  },
]


const Header = ({ period, setPeriod, organizations, selectedOrganization, setSelectedOrganization, rangeType, setRangeType}) => {

  const organizationsList = useMemo(() => (
    [{ id: 0, title: 'Все организации' }, ...organizations.map(({ title, id }) => ({ id, title }))]
  ), [organizations])

  const  onChangeRange = (type) => {
    setPeriod({
      endDate: "",
      startDate: ""
    })
    setRangeType(type)
  }

  const [periodError,setPeriodError] = useState('')

  const onChangePeriod = (period) => {
    const {endDate, startDate} = period

    setPeriod(period)
    setRangeType('')
    setPeriodError('')

    if(endDate && startDate) {
      const diff =  moment.duration(moment(endDate).diff(moment(startDate))).asDays()
      if(diff>365) setPeriodError('Период не может быть больше года')
    }
  }
  const renderGraphsRanges = () => (
    <Row>
      {graphsRanges.map(({label,type}) => (
        <GraphRangeContainer key={type} onClick={()=>onChangeRange(type)} isActive={type === rangeType}>
          {label}
        </GraphRangeContainer>
      ))}
    </Row>
  )

  const renderOrganizationsItems = () => (
    organizationsList
      .filter(el => el.title !== selectedOrganization.title)
      .map((item) => (
        <SelectItem onClick={() => setSelectedOrganization(item)} key={item.id}>{item.title}</SelectItem>
      ))
  )
  return (
    <Wrapper>
      <FilterWrapper>
        <CustomSelectWrapper>
          <CustomSelect title={selectedOrganization.title}>
            {renderOrganizationsItems()}
          </CustomSelect>
        </CustomSelectWrapper>
        <div>
          <AnalyticsPeriodFilter period={period} setPeriod={onChangePeriod} />
          {periodError && <ErrorText>{periodError}</ErrorText> }
        </div>
        {renderGraphsRanges()}
      </FilterWrapper>
    </Wrapper>
  )

}

export default connect(
  R.applySpec({
    organizations: getOrganizationList,
  }), null)(Header)