import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import * as R from 'ramda'

import { getBoardsRequest, getIsLoadingBoards } from '../../modules/boards'
import { getProductsRequest } from '../../modules/products'
import { getAmoUsersRequest } from '../../modules/users'
import { getIsLoading, getOrganizationsRequest} from '../../modules/organizations'
import { getOrdersList } from '../../modules/orders'
import { getTransportRequest } from '../../modules/transport'
import { useMediaQuery } from '../../components/address/commons'
import { widthMobileScreen } from '../../components/address/utils'
import { getAccessListForUser } from '../../modules/users'

import { Loader } from '../../components'
import { OrdersFilter, Board, List, Mobile } from '../../components/orders'
import AccessForbiddenPopup from '../../components/common/AccessForbidden'
import { generateOrdersColumns } from '../../utils/generateOrdersColumns'

const Wrap = styled.div`
  overflow-y: auto;
  width: 100%;
  padding: 20px;
  background-color: #ecf1f4;
`

const BoardWrapper = styled.div`
  max-width: ${({ width }) => width}px;
  background-color: #ecf1f4;
`

const StyledLoader = styled(Loader)`
  margin: auto;
  align-self: center;
`

const MODAL_TYPES = {
  create: 'CREATE',
  edit: 'EDIT',
  view: 'VIEW',
}

const BOARD_TYPES = {
  list: 'LIST',
  board: 'BOARD',
}

const Orders = ({
  getOrganizations,
  getBoards,
  getProducts,
  getUsers,
  getDrivers,
  isLoading,
  isLoadingBoards,
  orders,
  accessList,
}) => {
  const [editForbiddenModalShow, setEditForbiddenModalShow] = React.useState(false)

  const [isEditForbidden, setIsEditForbidden] = React.useState(false)

  const [isViewForbidden, setIsViewForbidden] = React.useState(false)

  const [isFilterOpen, setIsFilterOpen] = useState(false)

  const [boardType, setBoardType] = useState(BOARD_TYPES.board)

  const [statusId, setStatusId] = useState('')

  const [activeTab, setActiveTab] = React.useState('incoming')

  const [filterByType, setFilterByType] = useState(0)

  const [windowSize, setWindowSize] = useState({
    width: null,
    height: null,
  })
  const [modalState, setModalState] = useState({
    open: false,
    type: MODAL_TYPES.view,
    id: '',
  })

  useEffect(() => {
    if (!accessList.length) return
    let view =  false
    let edit = false

    accessList.forEach(i => {
      if(i.module === 'orders' && i.accessType === 'view'){
        view = true
      }
      if(i.module === 'orders' && i.accessType === 'edit'){
        edit = true
      }
    })
    setIsEditForbidden(edit)
    setIsViewForbidden(!view)
  }, [accessList, accessList.length])


  useEffect(() => {
    getBoards()
    getOrganizations()
    getDrivers()
    getUsers({})
    getProducts({ getAll: true })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth - 58,
        height: window.innerHeight,
      })
    }

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const isMobile = useMediaQuery(`(max-width: ${widthMobileScreen - 1}px)`)

  const ordersColumns = generateOrdersColumns(orders, filterByType)


  const openCreateOrderModal = () => {
    setModalState({
      open: true,
      type: MODAL_TYPES.create,
      id: '',
    })
  }

  const renderDesktop = () => (
    <>
      <OrdersFilter
        setIsFilterOpen={setIsFilterOpen}
        statusId={statusId}
        boardType={boardType}
        setBoardType={setBoardType}
        boardTypes={BOARD_TYPES}
        modalState={modalState}
        setModalState={setModalState}
        setFilterByType={setFilterByType}
        filterByType={filterByType}
        setActiveTab={setActiveTab}
        ActiveTab={activeTab}
        setModalOpen={openCreateOrderModal}
        editAccess={isEditForbidden}
        setModalShow={setEditForbiddenModalShow}
      />
      <BoardWrapper width={windowSize.width}>
        {boardType === BOARD_TYPES.board ? (
          <Board
            setModalState={setModalState}
            initial={ordersColumns}
            editAccess={isEditForbidden}
            setModalShow={setEditForbiddenModalShow}
            activeTab={activeTab}
          />
        ) : (
          <List
            isFilterOpen={isFilterOpen}
            setIsFilterOpen={setIsFilterOpen}
            statusId={statusId}
            setStatusId={setStatusId}
            setModalState={setModalState}
            orders={ordersColumns}
            initialOrders={orders}
            editAccess={isEditForbidden}
            setModalShow={setEditForbiddenModalShow}
            activeTab={activeTab}
          />
        )}
      </BoardWrapper>
    </>
  )

  const renderContent = () => {
    if (isMobile) return <Mobile />
    return renderDesktop()
  }

  return isViewForbidden ? (
    <AccessForbiddenPopup
      moduleName='Заявки'
      canClose={false}
    />
  ) : (
    <>
      {editForbiddenModalShow && (
        <AccessForbiddenPopup
          moduleName='Заявки'
          setModalShow={setEditForbiddenModalShow}
          canClose={true}
        />
      )}
      <Wrap>
        {isLoading || isLoadingBoards ? <StyledLoader /> : renderContent()}
      </Wrap>
      <div id="bottomButtonsPortal" />
    </>
  )
}

export default connect(
  R.applySpec({
    isLoading: getIsLoading,
    isLoadingBoards: getIsLoadingBoards,
    orders: getOrdersList,
    accessList: getAccessListForUser,
  }),
  {
    getBoards: getBoardsRequest,
    getDrivers: getTransportRequest,
    getProducts: getProductsRequest,
    getOrganizations: getOrganizationsRequest,
    getUsers: getAmoUsersRequest,
  },
)(Orders)
