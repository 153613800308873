import React, { useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import * as R from 'ramda'
import { connect } from 'react-redux'
import { Button, Modal } from '@material-ui/core'
import { format } from 'date-fns'
import SearchSelectDropDown from '../../common/SearchSelectDropDown'
import UserTextField from './user-text-field'
import UserRolesDropdown from './user-roles-dropdown'

import {
  createUserRequest,
  deleteUserRequest,
  getAllAvaiableOrganisations,
  getAmoRoles,
  getAmoUsersList,
  getIsLoading,
  getSelectedUser,
  getUsersError,
  selectUser,
  updateUserRequest,
} from '../../../modules/users'

import { useStyles } from './styles'
import assets from '../../../assets'

const Wrapper = styled.div`
  width: 300px;
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  @media screen and (min-width: 320px) and (max-width: 767px) {
    overflow: auto;
    width: 100%;
  }
`

const ErrorText = styled.div`
  display: flex;
  align-items: center;
  font-family: FuturaMediumC, sans-serif;
  font-size: 12px;
  color: red;
  margin-top: -10px;
  margin-bottom: 10px;
  margin-left: 5px;
`

const TitleWrapper = styled.div`
  display: flex;
  position: relative;
  margin-bottom: 10px;
`

const CreateTitle = styled.div`
  padding: 11px 36px 9px 20px;
  background: #ffffff;
  width: 300px;
  height: 36px;
  box-shadow: 0 6px 14px rgba(2, 9, 75, 0.06);
  border-radius: 8px;
  font-family: FuturaMediumC, sans-serif;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.4);
  @media screen and (min-width: 320px) and (max-width: 767px) {
    width: 100%;
  }
`

const Title = styled.div`
  padding: 11px 36px 9px 20px;
  background: #ffffff;
  width: 262px;
  height: 36px;
  box-shadow: 0 6px 14px rgba(2, 9, 75, 0.06);
  border-radius: 8px 0 0 8px;
  font-family: FuturaMediumC, sans-serif;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.4);
  @media screen and (min-width: 320px) and (max-width: 767px) {
    width: 100%;
  }
`

const DeleteIcon = styled.img.attrs({ src: assets.img.deleteColorIcon })`
  position: absolute;
  right: 50px;
  top: 8px;
  cursor: pointer;
`

const EditIcon = styled.img.attrs({ src: assets.img.pencilRed })``
const EditActiveIcon = styled.img.attrs({ src: assets.img.pencil })``
const EditIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  margin-left: 2px;
  background: linear-gradient(0deg, #ffffff, #ffffff),
    linear-gradient(92.15deg, #f6a26d -38.69%, #f87c2e 107.08%);
  box-shadow: 0 6px 14px rgba(2, 9, 75, 0.06);
  border-radius: 0 8px 8px 0;
  cursor: pointer;
  ${({ isActive }) =>
    isActive &&
    css`
      background: linear-gradient(92.15deg, #f6a26d -38.69%, #f87c2e 107.08%);
    `}
`

const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  padding: 25px 20px 5px 20px;
  width: 300px;
  background: #ffffff;
  box-shadow: 0 6px 14px rgba(2, 9, 75, 0.06);
  border-radius: 8px;
  box-sizing: border-box;
  @media screen and (min-width: 320px) and (max-width: 767px) {
    width: 100%;
  }
`
const RoleFieldWrapper = styled.div`
  position: relative;
`

const ButtonWrapper = styled.div`
  display: flex;
  margin-bottom: 20px;
  margin-top: -2px;
`

const Container = styled.div`
  width: 516px;
  height: 245px;
  background: #ffffff;
  box-shadow: 0 6px 14px rgba(2, 9, 75, 0.06);
  border-radius: 8px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
`
const TextContainer = styled.div`
  margin-top: 70px;
  font-family: FuturaBookC, sans-serif;
  font-size: 25px;
  line-height: 30px;
  text-align: center;
  color: #3d4751;
  font-weight: normal;
`

const ButtonsContainer = styled.div`
  margin-top: auto;
  margin-bottom: 20px;
  display: flex;
  background: #fff;
  justify-content: center;
`

const ConfirmButton = styled.button`
  width: 95px;
  height: 36px;
  background: linear-gradient(92.32deg, #f49355 0%, #f0640c 100%);
  border-radius: 8px;
  outline: 0;
  border: 0;
  font-family: FuturaMediumC, sans-serif;
  font-size: 17px;
  color: rgba(255, 255, 255, 1);
  cursor: pointer;
`

const CancelButton = styled(ConfirmButton)`
  background: linear-gradient(0deg, #9ba1a6, #9ba1a6),
    linear-gradient(92.32deg, #f49355 0%, #f0640c 100%);
  margin-left: 8px;
  cursor: pointer;
`

const UserCard = ({
  selectedUser,
  setUserCardMode,
  userCardMode,
  roles,
  updateUser,
  organizations,
  createUser,
  deleteUser,
  organization,
  setModalShow,
  editAccess,
  error,
  isLoading,
}) => {
  const classes = useStyles()
  const isEditMode = userCardMode.open && userCardMode.mode === 'EDIT'
  const isCreatingMode = userCardMode.open && userCardMode.mode === 'CREATE'
  const [errorText, setErrorText] = useState('')
  const [rolesCheckboxes, setRolesCheckboxes] = useState([])
  const [isRoleDropdownOpened, setIsRoleDropdownOpened] = useState(false)
  const [isDeletingUser, setIsDeletingUser] = useState(false)
  const [isChangeOrganizationUser, setIsChangeOrganizationUser] = useState(
    false,
  )
  const [readyForChangeOrganization, setReadyForChangeOrganization] = useState(
    false,
  )
  const [
    isSelectOrganizationModalOpen,
    setIsSelectOrganizationModalOpen,
  ] = React.useState(false)
  const [userEditData, setUserEditData] = useState({
    email: '',
    fio: '',
    id: '',
    password: '',
    phone: '',
    roles: [],
    currentOrganization: '',
  })
  const [userCreateData, setUserCreateData] = useState({
    email: '',
    fio: '',
    id: '',
    password: '',
    phone: '',
    roles: [],
    organizationId: organization ? organization.id : '',
    position: '',
  })



  const resetForm = () => {

    setUserCardMode({
      open: false,
      mode: 'VIEW',
    })
    setUserEditData({
      email: '',
      fio: '',
      id: '',
      password: '',
      phone: '',
      roles: [],
      currentOrganization: '',
    })
    setUserCreateData({
      email: '',
      fio: '',
      id: '',
      password: '',
      phone: '',
      roles: [],
      organizationId: '',
      position: '',
    })


  }

  const handleSubmit = e => {
    e.preventDefault()
    e.stopPropagation()
    let dataRelatedMode = isCreatingMode ? userCreateData : userEditData
    let errorTextMessage = `${dataRelatedMode.fio ? '' : 'ФИО; '}${
      dataRelatedMode.email ? '' : 'Email; '
    }${dataRelatedMode.phone ? '' : 'Телефон; '}${
      dataRelatedMode.roles.length ? '' : 'Роль; '
    }${dataRelatedMode.position ? '' : 'Должность; '}${
      dataRelatedMode.currentOrganization || dataRelatedMode.organizationId
        ? ''
        : 'Организация; '
    }`
    if (!errorTextMessage) {
      setErrorText('')
      setIsRoleDropdownOpened(false)
      setRolesCheckboxes([])
      if (!isCreatingMode) {
        if (selectedUser.phone === userEditData.phone) {
          updateUser({ ...userEditData, phone: undefined })
        } else {
          updateUser(userEditData)
        }
        setReadyForChangeOrganization(false)
      } else {
        createUser({ ...userCreateData, resetForm } )
      }
    } else {
      setErrorText(`Заполните следующие поля: ${errorTextMessage}`)
    }
  }
  const isInitialMount = useRef(true)

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false
    } else if (!error && !isLoading) {
      setUserCardMode({
        open: false,
        mode: '',
      })
    }
  }, [error, isLoading])

  useEffect(() => {
    if (!isCreatingMode) {
      setIsRoleDropdownOpened(false)
      setUserEditData({
        email: selectedUser.email || '',
        fio: selectedUser.fio || '',
        id: selectedUser.id || '',
        password: selectedUser.password || undefined,
        phone: selectedUser.phone || '',
        roles: selectedUser.roles || [],
        currentOrganization: selectedUser.currentOrganization || '',
        position: selectedUser.position || '',
      })
      setRolesCheckboxes(
        roles.map(role => ({
          ...role,
          checked: selectedUser.roles.map(item => item.id).includes(role.id),
        })),
      )
    }
  }, [selectedUser])

  useEffect(() => {
    setRolesCheckboxes(
      roles.map(role => ({
        ...role,
        checked: isCreatingMode
          ? false
          : selectedUser.roles.map(item => item.id).includes(role.id),
      })),
    )
  }, [roles])

  useEffect(() => {
    if (isCreatingMode) {
      setRolesCheckboxes(
        roles.map(role => ({
          ...role,
          checked: false,
        })),
      )
    }
  }, [isCreatingMode])

  const handleEditIconClick = () => {
    if (isEditMode) {
      setIsRoleDropdownOpened(false)
      setUserCardMode({
        open: true,
        mode: 'VIEW',
      })
      setUserEditData({
        email: selectedUser.email,
        fio: selectedUser.fio,
        id: selectedUser.id,
        password: selectedUser.password,
        phone: selectedUser.phone,
        roles: selectedUser.roles,
        currentOrganization: selectedUser.currentOrganization,
      })
      setRolesCheckboxes(
        roles.map(role => ({
          ...role,
          checked: selectedUser.roles.map(item => item.id).includes(role.id),
        })),
      )
    } else if (isCreatingMode) {
      setIsRoleDropdownOpened(false)
      setUserCardMode({
        open: false,
        mode: '',
      })
      setUserCreateData({
        email: '',
        fio: '',
        id: '',
        password: '',
        phone: '',
        roles: [],
        organizationId: '',
        position: '',
      })
      setRolesCheckboxes([])
    } else {
      setUserCardMode({
        open: true,
        mode: 'EDIT',
      })
    }
  }

  const handleUserRolesChange = role => {
    setRolesCheckboxes(
      rolesCheckboxes.map(item =>
        item.id === role.id
          ? {
              ...item,
              checked: !item.checked,
            }
          : item,
      ),
    )
    if (!isCreatingMode) {
      if (userEditData.roles.find(item => item.id === role.id)) {
        setUserEditData({
          ...userEditData,
          roles: userEditData.roles.filter(item => item.id !== role.id),
        })
      } else {
        setUserEditData({
          ...userEditData,
          roles: [...userEditData.roles, role],
        })
      }
    } else {
      if (userCreateData.roles.find(item => item.id === role.id)) {
        setUserCreateData({
          ...userCreateData,
          roles: userCreateData.roles.filter(item => item.id !== role.id),
        })
      } else {
        setUserCreateData({
          ...userCreateData,
          roles: [...userCreateData.roles, role],
        })
      }
    }
  }

  const handleIsRoleDropdownOpenedChange = () => {
    if (isEditMode || isCreatingMode) {
      setIsRoleDropdownOpened(!isRoleDropdownOpened)
    }
  }

  const setActiveItemFromSelectCreate = (name, item) => {
    setUserCreateData({
      ...userCreateData,
      [name]: item,
    })
  }
  const setActiveItemFromSelectEdit = (name, item) => {
    setIsChangeOrganizationUser(true)
    setUserEditData({
      ...userEditData,
      [name]: item,
    })
  }

  const handleChangeOrganization = () => {
    setReadyForChangeOrganization(true)
    setIsChangeOrganizationUser(false)
  }
  const handleDelete = () => {
    deleteUser({ userId: selectedUser.id })
    setIsDeletingUser(false)
    setUserCardMode({
      open: false,
      mode: '',
    })
    setUserCreateData({
      email: '',
      fio: '',
      id: '',
      password: '',
      phone: '',
      roles: [],
      organizationId: '',
      position: '',
    })
    setRolesCheckboxes([])
  }

  return (
    <Wrapper>
      <Modal
        open={isChangeOrganizationUser}
        onClose={() => setIsChangeOrganizationUser(false)}
      >
        <Container>
          <TextContainer>
            Точно хотите изменить организацию
            <br />
            на{' '}
            {
              organizations.filter(organization => {
                return organization.id === userEditData.currentOrganization
              })[0]?.title
            }
            ?
          </TextContainer>
          <ButtonsContainer>
            <ConfirmButton onClick={() => handleChangeOrganization()}>
              Да
            </ConfirmButton>
            <CancelButton
              onClick={() => {
                setUserEditData({
                  ...userEditData,
                  currentOrganization: selectedUser.currentOrganization,
                })
                setIsChangeOrganizationUser(false)
              }}
            >
              Отмена
            </CancelButton>
          </ButtonsContainer>
        </Container>
      </Modal>
      <Modal open={isDeletingUser} onClose={() => setIsDeletingUser(false)}>
        <Container>
          <TextContainer>
            Удалить пользователя <br />
            {selectedUser.fio} ?
          </TextContainer>
          <ButtonsContainer>
            <ConfirmButton
              onClick={() => (editAccess ? handleDelete() : setModalShow(true))}
            >
              Да
            </ConfirmButton>
            <CancelButton onClick={() => setIsDeletingUser(false)}>
              Отмена
            </CancelButton>
          </ButtonsContainer>
        </Container>
      </Modal>
      <TitleWrapper>
        {!isCreatingMode ? (
          <>
            <DeleteIcon onClick={() => setIsDeletingUser(true)} />
            <Title>Данные Пользователя</Title>
            <EditIconWrapper
              isActive={isEditMode}
              onClick={() =>
                editAccess ? handleEditIconClick() : setModalShow(true)
              }
            >
              {isEditMode ? <EditActiveIcon /> : <EditIcon />}
            </EditIconWrapper>
          </>
        ) : (
          <CreateTitle>Данные Нового пользователя</CreateTitle>
        )}
      </TitleWrapper>
      <FormWrapper onSubmit={handleSubmit}>
        <UserTextField
          onChange={e =>
            isCreatingMode
              ? setUserCreateData({ ...userCreateData, fio: e.target.value })
              : setUserEditData({ ...userEditData, fio: e.target.value })
          }
          disabled={!isEditMode && !isCreatingMode}
          label="ФИО"
          value={isCreatingMode ? userCreateData.fio : userEditData.fio}
        />
        <RoleFieldWrapper>
          <UserTextField
            readOnly
            onClick={handleIsRoleDropdownOpenedChange}
            disabled={!isEditMode && !isCreatingMode}
            label="Роль"
            value={
              isCreatingMode
                ? userCreateData.roles.map(item => item.name).join('; ')
                : userEditData.roles.map(item => item.name).join('; ')
            }
          />
          {isRoleDropdownOpened && (
            <UserRolesDropdown
              setIsRoleDropdownOpened={setIsRoleDropdownOpened}
              onChange={handleUserRolesChange}
              roles={rolesCheckboxes}
            />
          )}
        </RoleFieldWrapper>
        <UserTextField
          onChange={e =>
            isCreatingMode
              ? setUserCreateData({ ...userCreateData, email: e.target.value })
              : setUserEditData({ ...userEditData, email: e.target.value })
          }
          disabled={!isEditMode && !isCreatingMode}
          label="Email"
          value={isCreatingMode ? userCreateData.email : userEditData.email}
          type="email"
        />
        <UserTextField
          onChange={e =>
            isCreatingMode
              ? setUserCreateData({ ...userCreateData, phone: e.target.value })
              : setUserEditData({ ...userEditData, phone: e.target.value })
          }
          disabled={!isEditMode && !isCreatingMode}
          label="Телефон"
          value={isCreatingMode ? userCreateData.phone : userEditData.phone}
          isPhone
        />
        {!isEditMode && !isCreatingMode && selectedUser.createdAt && (
          <UserTextField
            disabled={!isEditMode && !isCreatingMode}
            label="Дата создания"
            value={format(new Date(selectedUser.createdAt), 'yyyy-MM-dd')}
          />
        )}
        {
          <UserTextField
            onChange={e =>
              isCreatingMode
                ? setUserCreateData({
                    ...userCreateData,
                    password: e.target.value,
                  })
                : setUserEditData({ ...userEditData, password: e.target.value })
            }
            disabled={!isEditMode && !isCreatingMode}
            label="Пароль для входа в приложение"
            value={
              isCreatingMode ? userCreateData.password : userEditData.password
            }
          />
        }

        <SearchSelectDropDown
          items={organizations}
          setActiveItem={
            isCreatingMode
              ? setActiveItemFromSelectCreate
              : setActiveItemFromSelectEdit
          }
          isSelectModalOpen={isSelectOrganizationModalOpen}
          setIsSelectModalOpen={setIsSelectOrganizationModalOpen}
          defaultValue={
            isCreatingMode
              ? ''
              : userEditData.currentOrganization ||
                selectedUser.currentOrganization
          }
          visibleObjectName={'title'}
          hideObjectName={'id'}
          name={isCreatingMode ? 'organizationId' : 'currentOrganization'}
          shrinkLabel={'Организация'}
          styles={{ marginBottom: '20px' }}
          disabled={!isEditMode && !isCreatingMode}
        />
        <UserTextField
          onChange={e =>
            isCreatingMode
              ? setUserCreateData({
                  ...userCreateData,
                  position: e.target.value,
                })
              : setUserEditData({ ...userEditData, position: e.target.value })
          }
          disabled={!isEditMode && !isCreatingMode}
          label="Должность"
          value={
            isCreatingMode ? userCreateData.position : userEditData.position
          }
        />
        {(errorText || error ) && <ErrorText>{error || errorText}</ErrorText>}
        {(isEditMode || isCreatingMode) && (
          <ButtonWrapper>
            <Button
              type="submit"
              classes={{
                root: classes.addButton,
              }}
            >
              {isCreatingMode ? 'Добавить' : 'Сохранить'}
            </Button>
            <Button
              onClick={handleEditIconClick}
              classes={{
                root: classes.cancelButton,
              }}
            >
              Отмена
            </Button>
          </ButtonWrapper>
        )}
      </FormWrapper>
    </Wrapper>
  )
}

export default connect(
  R.applySpec({
    isLoading: getIsLoading,
    error: getUsersError,
    userList: getAmoUsersList,
    selectedUser: getSelectedUser,
    roles: getAmoRoles,
    organizations: getAllAvaiableOrganisations,
  }),
  {
    updateUser: updateUserRequest,
    createUser: createUserRequest,
    deleteUser: deleteUserRequest,
    selectUser,
  },
)(UserCard)
