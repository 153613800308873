import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
  select: {
    width: 143,
    '& > .MuiSelect-select': {
      padding: '12px 28px 9px 10px',
    },
    fontSize: 14,
    fontFamily: 'FuturaBookC',
    '& > .MuiOutlinedInput-notchedOutline': {
      borderRadius: '0px 8px 8px 0px',

      borderColor: '#F49355',
    },
    '&:hover > .MuiOutlinedInput-notchedOutline': {
      borderColor: '#F49355',
    },
    '&.Mui-focused > .MuiOutlinedInput-notchedOutline': {
      borderColor: '#F49355',
      borderWidth: 1,
    },
    borderRadius: 8,
  },
  iconWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'linear-gradient(92.15deg, #F6A26D -38.69%, #F87C2E 107.08%)',
    width: 37.6,
    height: 37.6,
    borderRadius: '8px 0px 0px 8px',
    fontFamily: 'FuturaDemiC',
    color: '#ffffff',
    lineHeight: 16,
  },
  icon: {
    fill: '#F49355',
  },
  menu: {
    padding: '7px 0',
    boxShadow: '0px 6px 14px 0px rgba(2, 9, 75, 0.06)',
  },
}))

export default useStyles
