import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  addButton: {
    marginRight: 20,
    boxShadow: '0px 6px 14px 0px rgba(2, 9, 75, 0.06)',
    padding: '9px 11px',
    fontFamily: 'FuturaBookC',
    fontSize: 16,
    color: 'rgba(0, 0, 0, 0.4)',
    textTransform: 'initial',
    lineHeight: 'initial',
    borderRadius: 8,
  },
}))

export default useStyles
