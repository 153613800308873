import React, { useEffect, useMemo, useState } from 'react'
import { Field, reduxForm, change, getFormValues } from 'redux-form'
import { connect } from 'react-redux'
import * as R from 'ramda'
import { Box, Grid } from '@material-ui/core'
import styled, { css } from 'styled-components'

import BoardsSelect, { Option } from '../boards-select'
import AddBoard from '../add-board'
import AddOrder from '../add-order'
import SearchInput from '../search-input'
import SearchSelectInfo from '../search-select-info'
import InfoBlock from '../info-block'
import AddBoardModal from '../add-board-modal'
import { OrderModal } from '../index'
import { getTransportList } from '../../../modules/transport'
import {
  getAmoUsersList,
  getMeSelector,
  getMeRequest,
} from '../../../modules/users'
import { FORMS } from '../../../constants'
import {
  createBoardRequest,
  getBoardsList,
  getBoardsRequest,
} from '../../../modules/boards'
import { getOrganizationList } from '../../../modules/organizations'
import { getProductsList } from '../../../modules/products'
import {
  getOrdersRequest,
} from '../../../modules/orders'
import { getFreeTransport } from '../../../modules/transport'
import OrdersPaginationFooter from '../complete-orders-pagination'
import assets from '../../../assets'
import useStyles from './styles'

import AnalyticsPeriodFilter from '../../analytics/analytics-header/analytics-period-filter'
import moment from 'moment'
import SearchSelectDropDown from '../../common/SearchSelectDropDown'
import { useWebSockets } from '../../../context'
import { NoDataForDownloadModal } from '../../common/NoDataForDownloadModal'
import { getToken } from '../../../modules/auth'
import * as OrdersManager from '../../../modules/orders/OrdersManager'
import { every, isEmpty, toString } from 'lodash'
const TabWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 35px;
  width: 100%;
  border-bottom: 6px solid white;
  margin-bottom: 30px;
`

const Type = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
  box-sizing: border-box;
  border-radius: 8px 8px 0 0;
  padding: 10px 24px;
  height: 45px;
  border: 1px solid #ffffff;
  background: #ffffff;
  ${({ isActive }) =>
    !isActive &&
    css`
      border: none;
      background: none;
    `}
`

const WrapperDownloadButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 106px;
  height: 36px;
  background: linear-gradient(0deg, #ffffff, #ffffff),
    linear-gradient(92.15deg, #f6a26d -38.69%, #f87c2e 107.08%);
  box-shadow: 0 6px 14px rgba(2, 9, 75, 0.06);
  border-radius: 8px;
  padding: 9px 13px;
  box-sizing: border-box;
  font-family: FuturaBookC, sans-serif;
  font-size: 16px;
  letter-spacing: 0.02em;
  color: rgba(0, 0, 0, 0.4);
  text-decoration: none;
  cursor: pointer;
  float: right;
`

const DownloadIcon = styled.img.attrs({ src: assets.img.download })`
  margin-left: 5px;
`
const GraphRangeContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 9px 10px;
  border-radius: 8px;
  margin-left: 5px;
  font-family: 'FuturaBookC', sans-serif;
  font-size: 16px;
  line-height: 18px;
  color: #666666;
  cursor: pointer;

  ${({ isActive }) =>
    isActive
      ? css`
          border: 1px solid #f49355;
        `
      : css`
          border: 1px solid #ffffff;
          background: #ffffff;
          box-shadow: 0 6px 10px rgba(2, 9, 75, 0.06);
        `}
`
const TypeTitle = styled.div`
  font-family: FuturaMediumC;
  font-size: 16px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #f49355;
  font-style: normal;
  font-weight: 500;
  text-transform: uppercase;
  ${({ isActive }) =>
    isActive &&
    css`
      color: rgba(18, 22, 26, 0.7);
    `}
`
const Row = styled.div`
  display: flex;
`
const ChangeTypeButton = styled.div`
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  ${({ isFirst }) =>
    isFirst &&
    css`
      margin-right: 10px;
    `}

  ${({ isActive }) =>
    isActive
      ? css`
          background: linear-gradient(135deg, #f49355 0%, #f0640c 100%);
          border-radius: 8px;
        `
      : css`
          background: #ffffff;
          box-shadow: 0 2px 5px rgba(10, 21, 128, 0.1);
          border-radius: 6px;
        `}
`

const RolesContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 9px 10px;
  border-radius: 8px;
  margin-left: 5px;
  font-family: 'FuturaBookC', sans-serif;
  font-size: 16px;
  line-height: 18px;
  color: #666666;
  cursor: pointer;

  ${({ isActive }) =>
    isActive
      ? css`
          border: 1px solid #f49355;
        `
      : css`
          border: 1px solid #ffffff;
          background: #ffffff;
          box-shadow: 0 6px 10px rgba(2, 9, 75, 0.06);
        `}
`

const ListIcon = styled.img.attrs({ src: assets.img.listIcon })``
const ListIconActive = styled.img.attrs({ src: assets.img.listIconActive })``
const BoardIcon = styled.img.attrs({ src: assets.img.boardIcon })``
const BoardIconActive = styled.img.attrs({ src: assets.img.boardIconActive })``

const initialPaginationQuery = {
  organization: '',
  product: '',
  driver: '',
  orderState: '',
  ttnStatus: '',
  manager: '',
  activeRoleNames: [],
}

const OrdersFilter = ({
  token,
  drivers,
  organizations,
  products,
  boards,
  getOrders,
  initialValues,
  formValues,
  createBoard,
  getBoards,
  setModalOpen,
  modalState,
  setModalState,
  boardType,
  boardTypes,
  setBoardType,
  statusId,
  setIsFilterOpen,
  setFilterByType,
  filterByType,
  freeTransport,
  setModalShow,
  editAccess,
  setActiveTab,
  ActiveTab,
  users,
  currentUser,
  getCurrentUser,
}) => {
  useEffect(() => {
    getCurrentUser()
    let isAdmin =
      currentUser?.roles?.filter(role => {
        return role.name === 'Администратор'
      }) || []
    if (isAdmin.length) {
      setIsAdmin(true)
      setActiveItemFromSelect('activeRoleNames', ['Администратор'])
    }
  }, [])

  const [hasFilterValues, setHasFilterValues] = useState(false);

  const datePeriods = [
    {
      type: 'day',
      label: 'День',
    },
    {
      type: 'week',
      label: 'Неделя',
    },
    {
      type: 'all',
      label: 'Все',
    },
  ]
  const ttnStatuses = [
    { title: 'Есть' },
    { title: 'Нет' },
    { title: 'Проверено' },
    { title: 'На исправление' },
  ]

  const [isAdmin, setIsAdmin] = React.useState(false)

  const [isOpenNoDataModal,setIsOpenNoDataModal ] = React.useState(false)
  const [
    isSelectTtnStatusModalOpen,
    setIsSelectTtnStatusModalOpen,
  ] = React.useState(false)

  const [
    isSelectProductModalOpen,
    setIsSelectProductModalOpen,
  ] = React.useState(false)

  const [period, setPeriod] = useState({
    endDate: '',
    startDate: '',
    periodType: 'all',
  })

  const defaultRoles = ['Администратор', 'Бэк офис', 'Менеджер']

  const [rolePicker, setRolePicker] = useState(
    defaultRoles.map(role => {
      if (role === 'Администратор') {
        return {
          name: role,
          isActive: true,
        }
      }
      return {
        name: role,
        isActive: false,
      }
    }),
  )
  const changeRolePicker = ({ name, isActive }) => {
    let rolePickerCopy = [...rolePicker]
    for (let role of rolePickerCopy) {
      if (role.name === name) {
        role.isActive = isActive
      }
    }
    let activeRoles = rolePickerCopy
      .filter(role => {
        return role.isActive
      })
      .map(role => {
        return role.name
      })
    setActiveItemFromSelect('activeRoleNames', activeRoles)
    setRolePicker(rolePickerCopy)
  }
  const handleRangePicker = type => {
    let endDate
    let startDate
    let periodType
    switch (type) {
      case 'day': {
        let startDateObj = new Date()
        startDate = moment(
          startDateObj.setDate(startDateObj.getDate() - 1),
        ).format('YYYY-MM-DD')
        endDate = moment(new Date()).format('YYYY-MM-DD')
        periodType = 'day'
        break
      }
      case 'week': {
        let startDateObj = new Date()
        startDate = moment(
          startDateObj.setDate(startDateObj.getDate() - 7),
        ).format('YYYY-MM-DD')
        endDate = moment(new Date()).format('YYYY-MM-DD')
        periodType = 'week'
        break
      }
      default: {
        endDate = ''
        startDate = ''
        periodType = 'all'
      }
    }
    onChangePeriod({ startDate, endDate, periodType })
  }
  const [
    isSelectOrganizationModalOpen,
    setIsSelectOrganizationModalOpen,
  ] = React.useState(false)
  const [
    isSelectOrderStateModalOpen,
    setIsSelecOrderStateModalOpen,
  ] = React.useState(false)
  const [isSelectDriverModalOpen, setIsSelecDriverModalOpen] = React.useState(
    false,
  )
  const [isSelectManagerModalOpen, setIsSelecManagerModalOpen] = React.useState(
    false,
  )

  const [paginationQuery, setPaginationQuery] = useState(initialPaginationQuery)
  const setActiveItemFromSelect = (name, item) => {
    setPaginationQuery({
      ...paginationQuery,
      [name]: item,
    })
    // setTimeout(() => handleGetOrders(), 700)
  }

  const onChangePeriod = period => {
    let customPeriod = { ...period }
    handleGetOrders(period)
    if (!period.periodType) {
      const days = moment(period.endDate).diff(moment(period.startDate), 'days')
      if (days <= 1) {
        customPeriod.periodType = 'day'
      } else if (days > 1 && days <= 7) {
        customPeriod.periodType = 'week'
      } else {
        customPeriod.periodType = 'all'
      }
    }
    setPeriod(customPeriod)
  }

  const renderDatePeriods = () => (
    <Row>
      {datePeriods.map(({ label, type }) => (
        <GraphRangeContainer
          key={type}
          onClick={() => handleRangePicker(type)}
          isActive={type === period.periodType}
        >
          {label}
        </GraphRangeContainer>
      ))}
    </Row>
  )

  const renderRolesPicker = () => {
    return (
      <Row>
        {rolePicker.map(({ isActive, name }, index) => (
          <RolesContainer
            key={index}
            onClick={() => changeRolePicker({ name, isActive: !isActive })}
            isActive={isActive}
          >
            {name}
          </RolesContainer>
        ))}
      </Row>
    )
  }

  const [page, setPage] = useState(1)
  const [countOnPage, setCountOnPage] = useState(5)
  const [driverFilterItems, setDriverFilterItems] = useState([])

  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [boardValue, setBoardValue] = useState({
    title: '',
    organizationId: '',
  })

  const getFreeTransportOptions = R.pipe(
    () => freeTransport,
    R.groupBy(transport => transport.title),
    Object.entries,
    R.map(([title, value]) => ({
      title,
      count: value.length,
      transportCategory: R.head(value).transportCategoryId,
    })),
  )
  const getFreeTransportCount = useMemo(() => {
    const transportCategory = formValues?.transportCategory
    if (!transportCategory || transportCategory === '0')
      return freeTransport.length
    return R.find(
      R.propEq('transportCategory', transportCategory),
      getFreeTransportOptions(),
    ).count
  }, [formValues, freeTransport])


  useEffect(() => {
    const { activeRoleNames, ...rest} = paginationQuery
    // проверяем на включенные фильтры из пагинации и поля поиска
    const enabledFilters =
      !every(rest, (value) => isEmpty(toString(value)))
      || !!formValues?.search

    setHasFilterValues(enabledFilters)
  }, [JSON.stringify(paginationQuery), JSON.stringify(formValues)])

  const { updatedData } = useWebSockets()

  useEffect(() => {
    // хак - не даем вызвать обновление для Завершенных и если фильтры были применены
    if (ActiveTab !== 'completed') {
      if (updatedData && !hasFilterValues) {
        handleGetOrders()
      }
    }

  }, [updatedData, ActiveTab])

  useEffect(() => {
    if (initialValues?.board) {
      if (ActiveTab === 'completed') {
        getOrders({
          boardId: initialValues.board,
          query: {
            ...paginationQuery,
            isRealize: true,
            countOnPage,
            page,
            period: periodToArray(period),
          },
        })
      } else {
        getOrders({
          boardId: initialValues.board,
        })
      }
    }
  }, [page, countOnPage, getOrders, initialValues?.board])

  useEffect(() => {
    const newDriversFilterItems = drivers.map(d => ({
      title: `${d.fio} ${d.carNumber}`,
      value: d.id,
    }))

    setDriverFilterItems(newDriversFilterItems)
  }, [drivers])

  const periodToArray = period => {
    return Object.values(
      period || {
        endDate: '',
        startDate: '',
        periodType: 'all',
      },
    ).map(p => {
      if (p && !['day', 'week', 'all'].includes(p)) return moment(p).format()
      return p
    })
  }

  const handleGetOrders = period => {
    const { board, transportCategory, search } = formValues || {}
    const {
      organization,
      product,
      orderState,
      driver,
      ttnStatus,
      manager,
      activeRoleNames,
    } = paginationQuery || {}
    let query = {
      organization: organization === '0' ? '' : organization,
      product: product === '0' ? '' : product,
      transportCategory: transportCategory === '0' ? '' : transportCategory,
      search,
      statusId,
      manager,
      activeRoleNames,
    }
    if (ActiveTab === 'completed') {
      query = {
        ...query,
        isRealize: true,
        orderState,
        countOnPage,
        driver,
        page,
        ttnStatus,
        period: periodToArray(period),
      }
    }

    getOrders({
      boardId: board,
      query,
    })
  }

  useEffect(() => {
    if (formValues && formValues.board) {
      setIsFilterOpen(false)
      handleGetOrders(period)
    }
  }, [formValues, paginationQuery, boardType, statusId, setIsFilterOpen])

  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  const orderStates = [
    {
      value: 'realize',
      title: 'Успешно завершена',
    },
    { value: 'notRealize', title: 'Не реализована' },
  ]

  const updateActiveTab = tabName => {
    setActiveTab(tabName)
    if (tabName === 'incoming') {
      setBoardType(boardTypes.board)
    } else {
      setBoardType(boardTypes.list)
    }
    if (initialValues.board) {
      handleGetOrders(period)
    }
  }

  const downloadAfterClick = () => {
    const { board, transportCategory, search } = formValues || {}
    const {
      organization,
      product,
      orderState,
      driver,
      ttnStatus,
      manager,
    } = paginationQuery
    let query = {
      manager,
      organization: organization === '0' ? '' : organization,
      product: product === '0' ? '' : product,
      transportCategory: transportCategory === '0' ? '' : transportCategory,
      search,
      statusId,
      isRealize: true,
      orderState,
      ttnStatus,
      period: periodToArray(period),
      driver,
    }
    OrdersManager.downloadCompletedOrders({
      boardId: board,
      query,
      token
    }).catch(e=>setIsOpenNoDataModal(true))
  }

  const handleSubmit = () => {
    createBoard({
      organizationId: boardValue.organizationId,
      title: boardValue.title,
    })
    setOpen(false)
    setTimeout(() => {
      getBoards()
      setBoardValue({ title: '', organizationId: '' })
    }, 700)
  }

  return (
    <>

      <TabWrapper>
        <Type
          isActive={ActiveTab === 'incoming'}
          onClick={() => updateActiveTab('incoming')}
        >
          <TypeTitle isActive={ActiveTab === 'incoming'}>Входящие</TypeTitle>
        </Type>
        <Type
          isActive={ActiveTab === 'completed'}
          onClick={() => updateActiveTab('completed')}
        >
          <TypeTitle isActive={ActiveTab === 'completed'}>
            Завершенные
          </TypeTitle>
        </Type>
        {ActiveTab === 'completed' && (
          <div
            style={{
              width: '100%',
            }}
          >
            <WrapperDownloadButton onClick={() => downloadAfterClick()}>
              Выгрузить <DownloadIcon />
            </WrapperDownloadButton>
          </div>
        )}
      </TabWrapper>
      <OrderModal
        handleClose={() =>
          setModalState({
            ...modalState,
            open: false,
          })
        }
        setType={type =>
          setModalState({
            ...modalState,
            type,
          })
        }
        open={modalState.open}
        type={modalState.type}
        id={modalState.id}
        handleGetOrders={handleGetOrders}
        boardId={formValues && formValues.board}
        editAccess={editAccess}
        setModalShow={setModalShow}
        ActiveTab={ActiveTab}
      />
      {ActiveTab === 'incoming' && (
        <AddBoardModal
          value={boardValue}
          handleSubmit={handleSubmit}
          title="Введите название новой доски"
          handleClose={handleClose}
          open={open}
          setValue={setBoardValue}
        />
      )}
      <form className={classes.root}>
        <Grid
          classes={{
            root: classes.firstRow,
          }}
          container
          justify="space-between"
          alignItems="center"
        >
          {ActiveTab === 'incoming' && (
            <Grid
              item
              classes={{
                root: classes.firstRow,
              }}
              style={{ display: 'flex' }}
              alignItems="center"
            >
              <Grid item>
                <Field name="board" component={BoardsSelect}>
                  {boards.map(({ id, title }) => (
                    <Option key={id} value={id}>
                      {title}
                    </Option>
                  ))}
                </Field>
              </Grid>
              {isAdmin && <Grid item>{renderRolesPicker()}</Grid>}
            </Grid>
          )}
          {ActiveTab === 'incoming' && (
            <Grid item>
              <Grid container alignItems="center">
                <Grid item>
                  <AddBoard
                    onClick={() =>
                      editAccess ? handleOpen() : setModalShow(true)
                    }
                  />
                </Grid>
                <Grid item>
                  <AddOrder
                    onClick={() =>
                      editAccess ? setModalOpen() : setModalShow(true)
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid
          classes={{
            root: classes.secondRow,
          }}
          container
          spacing={2}
          alignItems="center"
          justify="space-evenly"
        >
          {ActiveTab === 'incoming' && (
            <Grid item>
              <Box display="flex" justifyContent="space-between">
                <ChangeTypeButton
                  isFirst
                  onClick={() => setBoardType(boardTypes.list)}
                  isActive={boardType === boardTypes.list}
                >
                  {boardType === boardTypes.list ? (
                    <ListIconActive />
                  ) : (
                    <ListIcon />
                  )}
                </ChangeTypeButton>
                <ChangeTypeButton
                  onClick={() => setBoardType(boardTypes.board)}
                  isActive={boardType === boardTypes.board}
                >
                  {boardType === boardTypes.board ? (
                    <BoardIconActive />
                  ) : (
                    <BoardIcon />
                  )}
                </ChangeTypeButton>
              </Box>
            </Grid>
          )}

          <Grid item md={ActiveTab === 'completed' ? 1.5 : 6}>
            <Field name="search" component={SearchInput} />
          </Grid>
          <Grid item>
            <SearchSelectDropDown
              items={users}
              setActiveItem={setActiveItemFromSelect}
              isSelectModalOpen={isSelectManagerModalOpen}
              setIsSelectModalOpen={setIsSelecManagerModalOpen}
              defaultValue={'Все менеджеры'}
              visibleObjectName={'fio'}
              hideObjectName={'id'}
              name={'manager'}
              defaultStyles={{ marginTop: '0px' }}
              styles={{ minWidth: '200px' }}
            />
          </Grid>
          <Grid item>
            <SearchSelectDropDown
              items={organizations}
              setActiveItem={setActiveItemFromSelect}
              isSelectModalOpen={isSelectOrganizationModalOpen}
              setIsSelectModalOpen={setIsSelectOrganizationModalOpen}
              defaultValue={'Все организации'}
              visibleObjectName={'title'}
              hideObjectName={'id'}
              name={'organization'}
              defaultStyles={{ marginTop: '0px' }}
              styles={{ minWidth: '200px' }}
            />
          </Grid>
          <Grid item>
            <SearchSelectDropDown
              items={products}
              setActiveItem={setActiveItemFromSelect}
              isSelectModalOpen={isSelectProductModalOpen}
              setIsSelectModalOpen={setIsSelectProductModalOpen}
              defaultValue={'Все товары'}
              visibleObjectName={'title'}
              hideObjectName={'id'}
              name={'product'}
              defaultStyles={{ marginTop: '0px' }}
              styles={{ minWidth: '200px' }}
            />
          </Grid>
          {ActiveTab === 'incoming' && (
            <Grid item>
              <Field
                name="transportCategory"
                count={getFreeTransportCount}
                component={SearchSelectInfo}
              >
                <Option value={'0'}>Свободных машин</Option>
                {getFreeTransportOptions().map(
                  ({ transportCategory, title }) => (
                    <Option key={transportCategory} value={transportCategory}>
                      {title}
                    </Option>
                  ),
                )}
              </Field>
            </Grid>
          )}
          {ActiveTab === 'completed' && (
            <>
              <Grid item>
                <SearchSelectDropDown
                  items={orderStates}
                  setActiveItem={setActiveItemFromSelect}
                  isSelectModalOpen={isSelectOrderStateModalOpen}
                  setIsSelectModalOpen={setIsSelecOrderStateModalOpen}
                  defaultValue={'Состояние заявки'}
                  visibleObjectName={'title'}
                  hideObjectName={'value'}
                  name={'orderState'}
                  defaultStyles={{ marginTop: '0px' }}
                  styles={{ minWidth: '200px' }}
                />
              </Grid>
              <Grid item>
                <SearchSelectDropDown
                  items={driverFilterItems}
                  setActiveItem={setActiveItemFromSelect}
                  isSelectModalOpen={isSelectDriverModalOpen}
                  setIsSelectModalOpen={setIsSelecDriverModalOpen}
                  defaultValue={'Все водители'}
                  visibleObjectName={'title'}
                  hideObjectName={'value'}
                  name={'driver'}
                  defaultStyles={{ marginTop: '0px' }}
                  styles={{ minWidth: '200px' }}
                />
              </Grid>
              <Grid item>
                <SearchSelectDropDown
                  items={ttnStatuses}
                  setActiveItem={setActiveItemFromSelect}
                  isSelectModalOpen={isSelectTtnStatusModalOpen}
                  setIsSelectModalOpen={setIsSelectTtnStatusModalOpen}
                  defaultValue={'ТТН статус'}
                  visibleObjectName={'title'}
                  hideObjectName={'title'}
                  name={'ttnStatus'}
                  defaultStyles={{ marginTop: '0px' }}
                  styles={{ minWidth: '200px' }}
                  withoutSearch={true}
                />
              </Grid>
            </>
          )}
          {ActiveTab === 'completed' && (
            <>
              <AnalyticsPeriodFilter
                period={period}
                setPeriod={onChangePeriod}
              />
              <Grid item>{renderDatePeriods()}</Grid>
            </>
          )}
        </Grid>
        {ActiveTab === 'incoming' && (
          <InfoBlock
            filterByType={filterByType}
            setFilterByType={setFilterByType}
          />
        )}
      </form>
      {ActiveTab === 'completed' && (
        <OrdersPaginationFooter
          countOnPage={countOnPage}
          setCountOnPage={setCountOnPage}
          setPage={setPage}
          page={page}
          query={paginationQuery}
          period={period}
        />
      )}
      <NoDataForDownloadModal isOpen={isOpenNoDataModal} setIsOpen={setIsOpenNoDataModal}/>
    </>
  )
}

export default R.compose(
  connect(
    R.applySpec({
      token: getToken,
      boards: getBoardsList,
      organizations: getOrganizationList,
      products: getProductsList,
      users: getAmoUsersList,
      freeTransport: getFreeTransport,
      currentUser: getMeSelector,
      drivers: getTransportList,
      formValues: state => getFormValues(FORMS.ORDERS_FILTER)(state),
      initialValues: {
        board: state => {
          const boards = getBoardsList(state)
          return boards.length > 0 ? boards[0].id : ''
        },
        organization: () => '0',
        product: () => '0',
        transportCategory: () => '0',
        search: () => '',
        orderState: () => '0',
        driverFilter: () => '0',
      },
    }),
    {
      setValue: change,
      getCurrentUser: getMeRequest,
      getOrders: getOrdersRequest,
      getBoards: getBoardsRequest,
      createBoard: createBoardRequest,
    },
  ),
  reduxForm({
    form: FORMS.ORDERS_FILTER,
    enableReinitialize: true,
  }),
)(OrdersFilter)
