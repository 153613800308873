import * as R from 'ramda'

export const getAdminUsers = R.prop('adminUsers')

export const getIsLoading = R.pipe(getAdminUsers, R.prop('isLoading'))

const getById = R.pipe(getAdminUsers, R.prop('byId'))

const getAllIds = R.pipe(getAdminUsers, R.prop('allIds'))

export const getAdminUserList = R.converge(
  (byId, allIds) => R.map(x => byId[x])(allIds),
  [getById, getAllIds],
)

export const getIsCreatingAdminUser = R.pipe(
  getAdminUsers,
  R.prop('isCreatingAdminUser'),
)

export const getOrgUsersSelect = R.pipe(getAdminUsers, R.prop('getOrgUsers'))

export const getIsPostingAdminUser = R.pipe(
  getAdminUsers,
  R.prop('isPostingAdminUser'),
)

export const getSelectedAdminUser = R.pipe(
  getAdminUsers,
  R.prop('selectedAdminUser'),
)

export const getAdminUserError = R.pipe(getAdminUsers, R.prop('adminUserError'))

export const getFormInitialValues = state => {
  const selectedAdminUser = getSelectedAdminUser(state)
  const isCreating = getIsCreatingAdminUser(state)

  if (selectedAdminUser) {
    return getById(state)[selectedAdminUser]
  }

  if (isCreating) {
    return {}
  }
}
