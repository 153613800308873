import * as R from 'ramda'

export const getStorages = R.prop('storages')

export const getIsLoading = R.pipe(getStorages, R.prop('isLoading'))
export const getIsProvidersLoading = R.pipe(getStorages, R.prop('isProvidersLoading'))
export const getIsProductsLoading = R.pipe(getStorages, R.prop('isProductsLoading'))

export const getStoragesList = R.pipe(
  getStorages,
  R.prop('getStorages'),
)

export const getStorageProvidersList = R.pipe(
  getStorages,
  R.prop('getStorageProviders'),
)

export const getStorageProductsList = R.pipe(
  getStorages,
  R.prop('getStorageProducts'),
)

