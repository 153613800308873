import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { PageBlock, Searchbar, Loader, CategoriesEmptyList } from '../../common'
import ProductElement from '../ProductElement'
import { useProduct } from '../../../effects/products'
import { updateProduct } from '../../../modules/products'

const StyledLoader = styled(Loader)`
  margin: auto;
  align-self: center;
`

const ProductsBlockDump = ({ updateProduct, setModalShow, editAccess }) => {
  const {
    onChangeSearchLine,
    searchLine,
    isLoading,
    products,
    selectedCategory,
  } = useProduct()

  return (
    <PageBlock>
      <Searchbar
        caption="Товары"
        onChange={onChangeSearchLine}
        value={searchLine}
        disabled={!selectedCategory}
        canAdd={false}
      />

      {isLoading && <StyledLoader />}

      {!isLoading && !products.length && (
        <CategoriesEmptyList title={`Выберите категорию или\nподкатегорию`} />
      )}

      {!isLoading &&
        products.map(props => (
          <ProductElement
            withRatingButtons={true}
            key={props.id}
            updateProduct={updateProduct}
            editAccess={editAccess}
            setModalShow={setModalShow}
            {...props}
          />
        ))}
    </PageBlock>
  )
}

const ProductsBlock = connect(null, { updateProduct })(ProductsBlockDump)

export default ProductsBlock
