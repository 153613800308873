import React, { useState } from 'react'
import * as R from 'ramda'
import styled from 'styled-components'
import TextField from '@material-ui/core/TextField'
import { withStyles } from '@material-ui/core/styles'

const styles = () => ({
  notchedOutline: {
    borderRadius: '8px',
    borderWidth: '1px',
    borderColor: '#F49355  !important',
    outline: 'none',
  },
  focused: {
    fontSize: 15,
    borderColor: '#F0640C !important',
    outline: 'none',
  },
})

const labelStyle = {
  color: '#000000',
  opacity: 0.4,
  fontFamily: 'FuturaBookC',
  fontSize: 17,
}

const Container = styled.div`
  position: relative;
`

const InputWrapper = React.memo(props => (
  <TextField {...R.dissoc('isFocusedOrHasValue', props)} />
))

const StyledTextField = styled(InputWrapper)`
  width: 100%;
  max-width: 265px;
  outline: none;

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
    outline: none;
  }

  label {
    margin-top: ${({ isFocusedOrHasValue }) =>
      isFocusedOrHasValue ? 0 : -7}px;
  }

  input {
    padding: 9.5px;
    border-color: #f0640c;
    outline: none;
    min-width: 265px;
  }

  .MuiInputBase-multiline {
    padding: 9.5px 15px;
  }

  .MuiFormHelperText-root {
    margin: 10px 43px 15px 0;
    font-family: 'FuturaBookC', sans-serif;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
    opacity: 0.4;
    outline: none;
  }
  input:focus {
    border-color: #f0640c;
    outline: none;
  }
`

const NewInputDump = ({
  value,
  inputProps,
  classes,
  labelPropsStyle,
  setBrandName,
  setCarSize,
  setCarNumber,
  setNewCategoryTitle,
  setEditBrand,
  label,
  setEditCapacity,
  setEditNumber,
  ...props
}) => {
  const [isFocused, setFocus] = useState(false)

  const onChangeHandler = value => {
    if (setBrandName) {
      setBrandName(value)
    }
    if (setCarSize) {
      setCarSize(Number(value))
    }
    if (setCarNumber) {
      setCarNumber(value)
    }
    if (setNewCategoryTitle) {
      setNewCategoryTitle(value)
    }
    if (setEditBrand) {
      setEditBrand(value)
    }
    if (setEditCapacity) {
      setEditCapacity(value)
    }
    if (setEditNumber) {
      setEditNumber(value)
    }
  }

  return (
    <Container>
      {!setCarSize && (
        <StyledTextField
          value={value}
          margin="normal"
          variant="outlined"
          color="#F0640C"
          isFocusedOrHasValue={isFocused || value}
          onChange={event => onChangeHandler(event.target.value)}
          onFocusCapture={() => {
            setFocus(true)
          }}
          onBlurCapture={() => {
            setFocus(false)
          }}
          label={label}
          InputLabelProps={{ style: { ...labelStyle, ...labelPropsStyle } }}
          InputProps={{
            ...inputProps,
            classes: {
              notchedOutline: classes.notchedOutline,
              focused: classes.cssFocused,
            },
          }}
        />
      )}
      {setCarSize && (
        <StyledTextField
          type="number"
          value={value}
          margin="normal"
          variant="outlined"
          color="#F0640C"
          isFocusedOrHasValue={isFocused || value}
          onChange={event => onChangeHandler(event.target.value)}
          onFocusCapture={() => {
            setFocus(true)
          }}
          onBlurCapture={() => {
            setFocus(false)
          }}
          label={label}
          InputLabelProps={{ style: { ...labelStyle, ...labelPropsStyle } }}
          InputProps={{
            ...inputProps,
            classes: {
              notchedOutline: classes.notchedOutline,
              focused: classes.cssFocused,
            },
          }}
        />
      )}
    </Container>
  )
}

const NewInput = withStyles(styles)(NewInputDump)

export default NewInput
