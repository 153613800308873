import React, { useState } from 'react'
import styled, { css } from 'styled-components'

import {
  BoardSelect,
  SearchBar,
  ProductSelect,
  OrganizationSelect,
  TransportCategorySelect,
  Statistic,
  RolePicker,
} from './filter/index'

const IncomingRequestContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: FuturaMediumC;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  text-transform: uppercase;
  color: #21313e;
  margin-bottom: 25px;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Dot = styled.div`
  width: 5px;
  height: 5px;
  background-color: #21313e;
  border-radius: 50%;
  margin-right: 5px;
  margin-bottom: 5px;

  ${({ isOpen }) =>
          isOpen
                  ? css`
                    margin-right: 5px;
                    margin-bottom: 0;
                  `
                  : css`
                    margin-right: 0;
                    margin-bottom: 5px;
                  `}
  &:last-child {
    margin-right: 0;
    margin-bottom: 0;
  }
`

const ToggleButton = styled.div`
  display: flex;
  flex-direction: ${({ isOpen }) => (isOpen ? 'row' : 'column')};
  width: 25px;
  height: 25px;
  align-items: center;
  justify-content: center;
`

const Filter = ({
                  boards,
                  freeTransport,
                  orders,
                  search,
                  setSearch,
                  selectedBoard,
                  setSelectedBoard,
                  organizationList,
                  organization,
                  setOrganization,
                  productsList,
                  product,
                  setProduct,
                  transportCategory,
                  setTransportCategory,
                  setActiveRoleNames,
                  activeRoleNames,
                  isAdmin,
                }) => {
  const [isFilterOpen, setFilterOpen] = useState(false)

  const toggleFilterOpen = () => setFilterOpen(!isFilterOpen)

  const renderToggleContainer = () => (
    <IncomingRequestContainer>
      <div>Входящие заявки</div>
      <ToggleButton isOpen={isFilterOpen} onClick={toggleFilterOpen}>
        <Dot isOpen={isFilterOpen} />
        <Dot isOpen={isFilterOpen} />
        <Dot isOpen={isFilterOpen} />
      </ToggleButton>
    </IncomingRequestContainer>
  )

  const renderContent = () => {
    if (!isFilterOpen) return null
    return (
      <Container>
        <BoardSelect
          selectedBoard={selectedBoard}
          boards={boards}
          setSelectedBoard={setSelectedBoard}
        />
        {
          isAdmin && <RolePicker
            setActiveRoleNames={setActiveRoleNames}
            activeRoleNames={activeRoleNames}
          />
        }

        <SearchBar value={search} onChange={setSearch} />
        <OrganizationSelect
          organizations={organizationList}
          organization={organization}
          setOrganization={setOrganization}
        />
        <ProductSelect
          products={productsList}
          product={product}
          setProduct={setProduct}
        />
        <TransportCategorySelect
          transportCategory={transportCategory}
          transports={freeTransport}
          setTransportCategory={setTransportCategory}
        />
        <Statistic orders={orders} />
      </Container>
    )
  }
  return (
    <div>
      {renderToggleContainer()}
      {renderContent()}
    </div>
  )
}

export default Filter
