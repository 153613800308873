import React from 'react'
import { createPortal } from 'react-dom'

import styled from 'styled-components'

const ModalWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  background: rgba(18, 24, 29, 0.7);
  z-index: 2;
`

const ModalContainer = styled.div`
  background-color: #fff;
  box-shadow: 0 6px 14px rgba(2, 9, 75, 0.06);
  border-radius: 5px;
  box-sizing: border-box;
  padding: 15px 20px;
  min-width: 250px;
  max-height: 95%;
  max-width: 95%;
  overflow: auto;
`

const ModalHeader = styled.div`
  font-family: FuturaMediumC;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  color: #000000;
  opacity: 0.4;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #ECF1F4;
  margin-bottom: 10px;
  padding-bottom: 10px
`

const ModalPortal = ({ children, close, header = null }) => {

  const modalRoot = document.getElementById('modal-portal')

  const renderHeader = () => {
    if (!header) return null
    return <ModalHeader>{header}</ModalHeader>
  }

  return createPortal(
    <ModalWrapper onClick={() => close()}>
      <ModalContainer onClick={(e) => {
        e.stopPropagation()
      }}>
        {renderHeader()}
        {children}
      </ModalContainer>
    </ModalWrapper>, modalRoot)
}

export default ModalPortal
