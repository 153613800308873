import { createAction, handleActions } from 'redux-actions'
import * as R from 'ramda'
import { combineReducers } from 'redux'

import { MODULES } from '../../constants'

export const getAnalyticsRequest = createAction(
  `${MODULES.ANALYTICS}/GET_ANALYTICS_REQUEST`,
)
export const getAnalyticsSuccess = createAction(
  `${MODULES.ANALYTICS}/GET_ANALYTICS_SUCCESS`,
)
export const getAnalyticsFailure = createAction(
  `${MODULES.ANALYTICS}/GET_ANALYTICS_FAILURE`,
)
export const getDownloadAnalyticsRequest = createAction(
  `${MODULES.ANALYTICS}/GET_DOWNLOAD_ANALYTICS_REQUEST`,
)

export const downloadAnalyticsRequest = createAction(
  `${MODULES.ANALYTICS}/GET_DOWNLOAD_ANALYTICS_REQUEST`,
)

export const getDownloadAnalyticsSuccess = createAction(
  `${MODULES.ANALYTICS}/GET_DOWNLOAD_ANALYTICS_SUCCESS`,
)
export const getDownloadAnalyticsFailure = createAction(
  `${MODULES.ANALYTICS}/GET_DOWNLOAD_ANALYTICS_FAILURE`,
)
export const getAnalyticsInfoRequest = createAction(
  `${MODULES.ANALYTICS}/GET_ANALYTICS_INFO_REQUEST`,
)
export const getAnalyticsInfoSuccess = createAction(
  `${MODULES.ANALYTICS}/GET_ANALYTICS_INFO_SUCCESS`,
)
export const getAnalyticsInfoFailure = createAction(
  `${MODULES.ANALYTICS}/GET_ANALYTICS_INFO_FAILURE`,
)
export const setTotalAnalytics = createAction(
  `${MODULES.ANALYTICS}/SET_TOTAL_ANALYTICS`,
)

export const getAnalyticsGraphs = createAction(
  `${MODULES.ANALYTICS}/GET_ANALYTICS_GRAPHS_REQUEST`,
)

export const getAnalyticsGraphsSuccess = createAction(
  `${MODULES.ANALYTICS}/GET_ANALYTICS_GRAPHS_SUCCESS`,
)

export const getAnalyticsGraphsFailure = createAction(
  `${MODULES.ANALYTICS}/GET_ANALYTICS_GRAPHS_FAILURE`,
)

const totalAnalytics = handleActions(
  {
    [setTotalAnalytics]: (state, { payload }) => payload,
  },
  0,
)

const graphs = handleActions(
  {
    [getAnalyticsGraphsSuccess]: (_, { payload }) => payload,
  },
  {},
)

const analyticsInfo = handleActions(
  {
    [getAnalyticsInfoSuccess]: (state, { payload }) => payload,
    [getAnalyticsInfoFailure]: R.always({}),
  },
  {},
)

const analyticsDownload = handleActions(
  {
    [getDownloadAnalyticsSuccess]: (state, { payload }) => payload,
    [getDownloadAnalyticsFailure]: R.always(''),
  },
  '',
)

const analyticsById = handleActions(
  {
    [getAnalyticsSuccess]: R.pipe(
      R.nthArg(1),
      R.prop('payload'),
      R.indexBy(R.prop('id')),
    ),
  },
  [],
)

const analyticsAllIds = handleActions(
  {
    [getAnalyticsSuccess]: R.pipe(
      R.nthArg(1),
      R.prop('payload'),
      R.map(R.prop('id')),
    ),
  },
  [],
)

const isLoading = handleActions(
  {
    [getAnalyticsRequest]: R.T,
    [getAnalyticsSuccess]: R.F,
    [getAnalyticsFailure]: R.F,
    [getAnalyticsInfoRequest]: R.T,
    [getAnalyticsInfoSuccess]: R.F,
    [getAnalyticsInfoFailure]: R.F,
  },
  false,
)

const analyticsReducer = combineReducers({
  analyticsById,
  analyticsAllIds,
  isLoading,
  totalAnalytics,
  analyticsInfo,
  analyticsDownload,
  graphs,
})

export default analyticsReducer
