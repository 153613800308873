import { connect } from 'react-redux'
import React, { useEffect } from 'react'
import * as R from 'ramda'
import { getByCity, getProductsByCityRequest } from '../../../modules/products'
import styled from 'styled-components'

import UploadButton from './UploadButton'
import Table from './Table'
import SearchInput from './SearchInput'
import DownloadExampleButton from './DownloadExampleButton'

const TabContentHead = styled.div`
  width: calc(100% - 30px);
  min-width: 625px;
  min-height: 6px;
  display: flex;
  align-items: center;
  background-color: #ffffff;
  padding: 5px 11px 10px;
`

const ProductsByCity = ({
  getProductsByCity,
  productsByCity,
  setModalShow,
  editAccess,
}) => {
  useEffect(() => {
    getProductsByCity()
  }, [])

  return (
    <>
      <TabContentHead>
        <SearchInput getProductsByCity={getProductsByCity} />
        <DownloadExampleButton />
        <UploadButton editAccess={editAccess} setModalShow={setModalShow} />
      </TabContentHead>
      <Table productsByCity={productsByCity} />
    </>
  )
}

export default connect(
  R.applySpec({
    productsByCity: getByCity,
  }),
  {
    getProductsByCity: getProductsByCityRequest,
  },
)(ProductsByCity)
