import React, { useState } from 'react'
import styled, { css } from 'styled-components'

const Row = styled.div`
  display: flex;
  margin-bottom: 23px;
`

const RolesContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 9px 10px;
  border-radius: 8px;
  margin-left: 5px;
  font-family: 'FuturaBookC', sans-serif;
  font-size: 16px;
  line-height: 18px;
  color: #666666;
  cursor: pointer;

  ${({ isActive }) =>
  isActive
    ? css`
          border: 1px solid #f49355;
        `
    : css`
          border: 1px solid #ffffff;
          background: #ffffff;
          box-shadow: 0 6px 10px rgba(2, 9, 75, 0.06);
        `}
`


const RolePicker = ({
                      setActiveRoleNames,
                      activeRoleNames,
                    }) => {
  const defaultRoles = ['Администратор', 'Бэк офис', 'Менеджер']
  const IsActiveRole = (roleName)=> activeRoleNames.includes(roleName)
  const [rolePicker, setRolePicker] = useState(
    defaultRoles.map(roleName => {
      return {
        name: roleName,
        isActive: IsActiveRole(roleName),
      }
    }),
  )



  const changeRolePicker = ({ name, isActive }) => {
    let rolePickerCopy = [...rolePicker]
    for (let role of rolePickerCopy) {
      if (role.name === name) {
        role.isActive = isActive
      }
    }
    let activeRoles = rolePickerCopy
      .filter(role => {
        return role.isActive
      })
      .map(role => {
        return role.name
      })

    setActiveRoleNames(activeRoles)
    setRolePicker(rolePickerCopy)
  }


  return (
    <Row>
      {rolePicker.map(({ isActive, name }, index) => (
        <RolesContainer
          key={index}
          isActive={isActive}
          onClick={() => changeRolePicker({ name, isActive: !isActive })}
        >
          {name}
        </RolesContainer>
      ))}
    </Row>
  )
}

export default RolePicker
