import React from 'react'
import styled from 'styled-components'
import * as R from 'ramda'
import { connect } from 'react-redux'
import {
  getSelectedOrganization,
  deleteOrganizationRequest,
} from '../../modules/organizations'
import { getOrganizationUsersRequest } from '../../modules/admin-users'

import { setOrganisationDeletionModalVisible } from '../../modules/ui'

const Container = styled.div`
  width: 516px;
  height: 245px;
  background: #ffffff;
  box-shadow: 0px 6px 14px rgba(2, 9, 75, 0.06);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 20px 10px;
`
const TextContainer = styled.div`
  height: 160px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-family: 'FuturaBookC', sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
`

const ButtonsContainer = styled.div`
  display: flex;
  background: #fff;
  padding: 20px 0px;
  border-radius: 8px;
  width: 100%;
  justify-content: center;
`

const ConfirmButton = styled.button`
  height: 36px;
  border: none;
  background: linear-gradient(135deg, #f49355 0%, #f0640c 100%);
  box-shadow: 0px 4px 10px rgba(247, 137, 68, 0.55);
  cursor: pointer;
  outline: none;
  border-radius: 3px;
  color: white;
  margin-right: 15px;
  width: 95px;
`

const CancelButton = styled(ConfirmButton)`
  background: linear-gradient(135deg, #b6b6b6 0%, #636363 100%);
`

const DeletionPopupDump = ({
  cancel,
  selectedOrganisation,
  deleteOrganisation,
}) => {
  const delete_organisation = React.useCallback(() => {
    deleteOrganisation(selectedOrganisation)
  }, [deleteOrganisation, selectedOrganisation])

  return (
    <Container>
      <TextContainer>
        Удалить организацию {selectedOrganisation?.title} ?
      </TextContainer>
      <ButtonsContainer>
        <ConfirmButton onClick={() => delete_organisation()}>Да</ConfirmButton>
        <CancelButton onClick={cancel}>Отмена</CancelButton>
      </ButtonsContainer>
    </Container>
  )
}

const DeletionPopup = R.compose(
  connect(
    R.applySpec({
      selectedOrganisation: getSelectedOrganization,
    }),
    {
      cancel: setOrganisationDeletionModalVisible,
      deleteOrganisation: deleteOrganizationRequest,
    },
  ),
)(DeletionPopupDump)

export default DeletionPopup
