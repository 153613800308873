import { createAction, handleActions } from 'redux-actions'
import * as R from 'ramda'
import { combineReducers } from 'redux'
import { moveOrderSuccess } from '../boards'

import { MODULES } from '../../constants'

export const getOrdersRequest = createAction(`${MODULES.ORDERS}/GET_ORDERS`)

export const getTotalOrdersRequest = createAction(
  `${MODULES.ORDERS}/GET_TOTAL_ORDERS`,
)

export const getTotalOrdersRequestSuccess = createAction(
  `${MODULES.ORDERS}/GET_TOTAL_ORDERS_SUCCESS`,
)

export const downloadCompletedOrders = createAction(
  `${MODULES.ORDERS}/DOWNLOAD_COMPLETED_ORDERS`,
)

export const getOrdersSuccess = createAction(
  `${MODULES.ORDERS}/GET_ORDERS_SUCCESS`,
)
export const getIsOrganizationSuccess = createAction(
  `${MODULES.ORDERS}/GET_IS_ORGANIZATION_SUCCESS`,
)

export const getOrdersFailure = createAction(
  `${MODULES.ORDERS}/GET_ORDERS_FAILURE`,
)

export const createOrderRequest = createAction(`${MODULES.ORDERS}/CREATE_ORDER`)
export const createOrderSuccess = createAction(
  `${MODULES.ORDERS}/CREATE_ORDER_SUCCESS`,
)
export const createOrderFailure = createAction(
  `${MODULES.ORDERS}/CREATE_ORDER_FAILURE`,
)

export const editOrderRequest = createAction(`${MODULES.ORDERS}/EDIT_ORDER`)
export const editOrderSuccess = createAction(
  `${MODULES.ORDERS}/EDIT_ORDER_SUCCESS`,
)
export const editOrderFailure = createAction(
  `${MODULES.ORDERS}/EDIT_ORDER_FAILURE`,
)

export const getOrderRequest = createAction(`${MODULES.ORDERS}/GET_ORDER`)
export const getOrderSuccess = createAction(
  `${MODULES.ORDERS}/GET_ORDER_SUCCESS`,
)
export const getOrderFailure = createAction(
  `${MODULES.ORDERS}/GET_ORDER_FAILURE`,
)

export const completeOrderRequest = createAction(
  `${MODULES.ORDERS}/COMPLETE_ORDER`,
)
export const completeOrderSuccess = createAction(
  `${MODULES.ORDERS}/COMPLETE_ORDER_SUCCESS`,
)
export const completeOrderFailure = createAction(
  `${MODULES.ORDERS}/COMPLETE_ORDER_FAILURE`,
)

export const notRealizeOrderRequest = createAction(
  `${MODULES.ORDERS}/NOT_REALIZE_ORDER`,
)
export const notRealizeOrderSuccess = createAction(
  `${MODULES.ORDERS}/NOT_REALIZE_ORDER_SUCCESS`,
)
export const notRealizeOrderFailure = createAction(
  `${MODULES.ORDERS}/NOT_REALIZE_ORDER_FAILURE`,
)

export const deleteOrderRequest = createAction(`${MODULES.ORDERS}/DELETE_ORDER`)
export const deleteOrderSuccess = createAction(
  `${MODULES.ORDERS}/DELETE_ORDER_SUCCESS`,
)
export const deleteOrderFailure = createAction(
  `${MODULES.ORDERS}/DELETE_ORDER_FAILURE`,
)

export const setPages = createAction(`${MODULES.ORDERS}/setPages`)
export const setPagination = createAction(`${MODULES.ORDERS}/setPagination`)

const removeOrder = (byId, { payload }) => {
  const { orderId, columnId } = payload
  if (!orderId || !columnId) return byId
  const newById = R.clone(byId)
  const column = newById[columnId]
  column.orders = R.filter(order => order.id !== Number(orderId), column.orders)
  return newById
}

const restoreOrder = (byId, { payload }) => {
  const { data, columnId } = payload
  const newById = R.clone(byId)
  const column = newById[columnId]
  column.orders.push(data)
  return newById
}

const moveOrder = (byId, { payload }) => {
  const { leadStatusId, id, columnId } = payload
  if (!columnId) return byId
  const newById = R.clone(byId)

  const prevColumn = newById[columnId]
  const nextColumn = newById[leadStatusId]
  const order = R.clone(R.find(R.propEq('id', id))(prevColumn.orders))
  prevColumn.orders = R.filter(
    order => order.id !== Number(id),
    prevColumn.orders,
  )
  nextColumn.orders.push(order)
  return newById
}

const totalOrders = handleActions(
  {
    [getTotalOrdersRequestSuccess]: (_, { payload }) => payload,
  },
  5,
)

const byId = handleActions(
  {
    [getOrdersSuccess]: R.pipe(
      R.nthArg(1),
      R.prop('payload'),
      R.indexBy(R.prop('id')),
    ),
    [deleteOrderRequest]: removeOrder,
    [deleteOrderFailure]: restoreOrder,
    [completeOrderRequest]: removeOrder,
    [completeOrderFailure]: restoreOrder,
    [notRealizeOrderSuccess]: removeOrder,
    [moveOrderSuccess]: moveOrder,
  },
  [],
)

const currentOrder = handleActions(
  {
    [getOrderSuccess]: R.pipe(R.nthArg(1), R.prop('payload')),
  },
  {},
)

const isOrganization = handleActions(
  {
    [getIsOrganizationSuccess]: (_, { payload }) => payload,
  },
  false,
)

const allIds = handleActions(
  {
    [getOrdersSuccess]: R.pipe(
      R.nthArg(1),
      R.prop('payload'),
      R.map(R.prop('id')),
    ),
  },
  [],
)

const isLoading = handleActions(
  {
    [getOrdersRequest]: R.T,
    [getOrdersSuccess]: R.F,
    [getOrdersFailure]: R.F,
  },
  false,
)

const ordersReducer = combineReducers({
  byId,
  allIds,
  isLoading,
  isOrganization,
  currentOrder,
  totalOrders,
})

export default ordersReducer
