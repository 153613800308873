import React from 'react'
import { ListHeaderBlock, ListTitleBlock, PageBlock } from '../../common'
import styled from 'styled-components'
import ProductElement from '../ProductElement'
import { connect } from 'react-redux'
import * as R from 'ramda'
import { getProductsList, updateProduct } from '../../../modules/products'

const ProductRatingsBlock = ({
  updateProduct,
  products,
  setModalShow,
  editAccess,
}) => {
  const StyledListHeaderBlock = styled(ListHeaderBlock)`
    overflow-y: unset;
    margin-bottom: 4px;
  `

  return (
    <PageBlock>
      <StyledListHeaderBlock>
        <ListTitleBlock>Топ категории</ListTitleBlock>
      </StyledListHeaderBlock>
      {products
        .filter(props => props.categoryTop && props.subcategoryTop)
        .map(props => (
          <ProductElement
            withRatingButtons={true}
            key={props.id}
            updateProduct={updateProduct}
            editAccess={editAccess}
            setModalShow={setModalShow}
            {...props}
          />
        ))}
    </PageBlock>
  )
}

export default connect(
  R.applySpec({
    products: getProductsList,
  }),
  { updateProduct },
)(ProductRatingsBlock)
