import { createAction, handleActions } from 'redux-actions'
import * as R from 'ramda'
import { combineReducers } from 'redux'
import { MODULES } from '../../constants'

export const getStorageRequest = createAction(
  `${MODULES.STORAGE}/getStorageRequest`,
)

export const getStorageSuccess = createAction(
  `${MODULES.STORAGE}/getStorageSuccess`,
)

export const getStorageFailure = createAction(
  `${MODULES.STORAGE}/getStorageFailure`,
)

export const createStorageRequest = createAction(
  `${MODULES.STORAGE}/createStorageRequest`,
)

const storage = handleActions(
  {
    [getStorageSuccess]: (_, { payload }) => payload,
  },
  [],
)

const isLoading = handleActions(
  {
    [getStorageRequest]: R.T,
    [getStorageFailure]: R.F,
    [getStorageSuccess]: R.F,
  },
  false,
)

const StorageReducer = combineReducers({
  storage,
  isLoading,
})

export default StorageReducer

const getStorageReducer = R.prop('storage')

export const getStorage = R.pipe(getStorageReducer, R.prop('storage'))

export const getStorageIsLoading = R.pipe(
  getStorageReducer,
  R.prop('isLoading'),
)
