import * as R from 'ramda'
import { createAction, handleActions } from 'redux-actions'
import { combineReducers } from 'redux'
import { MODULES } from '../../constants'

export const setError = createAction(
  `${MODULES.STORAGES}/SET_ERROR`,
)

export const getStoragesRequest = createAction(
  `${MODULES.STORAGES}/GET_STORAGES_REQUEST`,
)

export const getStoragesSuccess = createAction(
  `${MODULES.STORAGES}/GET_STORAGES_SUCCESS`,
)

export const getStorageProvidersRequest = createAction(
  `${MODULES.STORAGES}/GET_STORAGE_PROVIDERS_REQUEST`,
)

export const getStorageProvidersSuccess = createAction(
  `${MODULES.STORAGES}/GET_STORAGE_PROVIDERS_SUCCESS`,
)

export const getStorageProductsRequest = createAction(
  `${MODULES.STORAGES}/GET_STORAGE_PRODUCTS_REQUEST`,
)

export const getStorageProductsSuccess = createAction(
  `${MODULES.STORAGES}/GET_STORAGE_PRODUCTS_SUCCESS`,
)

export const updateStorageRequest = createAction(
  `${MODULES.STORAGES}/UPDATE_STORAGE_REQUEST`,
)

export const createStorageRequest = createAction(
  `${MODULES.STORAGES}/CREATE_STORAGE_REQUEST`,
)

export const deleteStorageRequest = createAction(
  `${MODULES.STORAGES}/DELETE_STORAGE_REQUEST`,
)

export const updateStorageProviderRequest = createAction(
  `${MODULES.STORAGES}/UPDATE_STORAGE_PROVIDER_REQUEST`,
)

export const createStorageProviderRequest = createAction(
  `${MODULES.STORAGES}/CREATE_STORAGE_PROVIDER_REQUEST`,
)

export const deleteStorageProviderRequest = createAction(
  `${MODULES.STORAGES}/DELETE_STORAGE_PROVIDER_REQUEST`,
)

export const updateStorageProductRequest = createAction(
  `${MODULES.STORAGES}/UPDATE_STORAGE_PRODUCT_REQUEST`,
)

export const createStorageProductRequest = createAction(
  `${MODULES.STORAGES}/CREATE_STORAGE_PRODUCT_REQUEST`,
)

export const deleteStorageProductRequest = createAction(
  `${MODULES.STORAGES}/DELETE_STORAGE_PRODUCT_REQUEST`,
)


const getStorages = handleActions(
  {
    [getStoragesSuccess]: (_, { payload }) => payload,
  },
  [],
)

const getStorageProviders = handleActions({
  [getStorageProvidersSuccess]: (_, { payload }) => payload,
}, [])

const getStorageProducts = handleActions(
  {
    [getStorageProductsSuccess]: (_, { payload }) => payload,
  },
  [],
)

const isLoading = handleActions({
    [getStoragesRequest]: R.T,
    [getStoragesSuccess]: R.F,
  }, null,
)

const isProvidersLoading = handleActions({
    [getStorageProvidersRequest]: R.T,
    [getStorageProvidersSuccess]: R.F,
  }, null,
)

const isProductsLoading = handleActions({
    [getStorageProductsRequest]: R.T,
    [getStorageProductsSuccess]: R.F,
  }, null,
)

const storagesReducer = combineReducers({
  getStorages,
  getStorageProviders,
  getStorageProducts,
  isLoading,
  isProvidersLoading,
  isProductsLoading,
})

export default storagesReducer