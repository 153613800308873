import React from 'react'
import styled from 'styled-components'
import { useFormik } from 'formik'
import ImagePicker from '../productEditor/ImagePicker'
import { Radio, withStyles, FormControl, RadioGroup } from '@material-ui/core'
import assets from '../../assets'
import { connect } from 'react-redux'
import { getToken } from '../../modules/auth'
import * as R from 'ramda'
import { createCategory } from '../../modules/categories/Manager'
import {
  getCategoriesRequest,
} from '../../modules/categories'


const Wrapper = styled.div`
  margin-top: 4px;
  display: flex;
`

const Container = styled.div`
  padding: 18px 20px;
  background-color: #fff;
  border-radius: 8px;
  width: 100%;
`

const Text = styled.div`
  font-family: 'FuturaMediumC', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  color: rgba(0, 0, 0, .2);
  margin-bottom: 13px;
`

const Button = styled.button`
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0 0 8px 0;
  box-shadow: 0 6px 14px rgb(2 9 75 / 6%);
  outline: none;
  border: none;
  background-color: #fff;

  &:first-child {
    background: linear-gradient(135deg, #F49355 0%, #F0640C 100%);

    &:disabled {
      background: rgba(0, 0, 0, 0.2);
    }

    border-radius: 0 8px 0 0;
    margin-bottom: 3px;
  }
`

const StyledInput = styled.input`
  height: 100%;
  width: 100%;
  background-color: #fff;
  box-sizing: border-box;
  margin-right: 4px;
  border: none;
  outline: none;
  font-family: 'FuturaBookC', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
  border-radius: 8px 0 0 8px;
  padding: 26px 20px 26px 20px;
  color: rgba(0, 0, 0, .6);

  &::placeholder {
    color: rgba(18, 22, 26, 0.4);
  }

  box-shadow: 0 6px 14px rgb(2 9 75 / 6%);
`

const StatusInput = styled(StyledInput)`
  padding: 11px 20px 11px 20px;
  margin: 0;
  border-radius: 8px;
`

const RadioLabel = styled.div`
  font-family: 'FuturaBookC', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 15px;
  color: #000000;
  margin-left: 10px;
`

const RadioContainer = styled.div`
  display: flex;
  align-items: center;

  &:last-child {
    margin-left: 60px;
  }
`

const OrangeRadio = withStyles({
  root: {
    color: '#F0640C',
    '&$checked': {
      color: '#F0640C',
    },
  },
  checked: {},
})(props => <Radio color='default' {...props} />)


const CreateCategory = ({ onCancel, token, getCategoriesRequest }) => {

  const onSubmit = (values) => {
    const { title, description, previewSize, photo, icon } = values
    const files = [photo[0], icon[0]]

    const data = {
      title, description, previewSize, files,
    }
    createCategory(data, token)
      .then(() => {
        getCategoriesRequest()
        onCancel()
      })
  }
  const formik = useFormik({
    initialValues: {
      title: '',
      description: '',
      photo: [],
      icon: [],
      previewSize: 'default',
    },
    onSubmit: onSubmit,
  })

  const onChangeImage = (values, field) => {
    const value = values.length > 0 ? [values[values.length - 1]] : []
    formik.setFieldValue(field, value)
  }

  const formValues = formik.values
  const isLargePreviewSize = formValues.previewSize === 'large'

  const disabled = !formValues.title || !formValues.icon.length || !formValues.photo.length


  return (
    <div>
      <Wrapper>
        <StyledInput
          placeholder='Название категории'
          name='title'
          value={formik.values.title}
          onChange={formik.handleChange}
        />
        <div>
          <Button type='submit' disabled={disabled} onClick={formik.handleSubmit}>
            <img src={assets.img.accept} alt='' />
          </Button>
          <Button type='button' onClick={onCancel}>
            <img src={assets.img.cancel} alt='' />
          </Button>
        </div>
      </Wrapper>
      <Wrapper>
        {isLargePreviewSize
        &&
        <StatusInput
          placeholder='Статусная строка'
          name='description'
          value={formik.values.description}
          onChange={formik.handleChange}
        />}

      </Wrapper>
      <Wrapper>
        <Container>
          <Text>Изображение для МП 160 x 130 px</Text>
          <ImagePicker
            multiple={false}
            input={{
              onChange: (v) => onChangeImage(v, 'photo'),
              name: 'photo',
              value: formik.values.photo,
            }}
            width='160px'
            height='130px'

          />
        </Container>

      </Wrapper>
      <Wrapper>
        <Container>
          <Text>Иконка для ВеБА 30 х 30 px</Text>
          <ImagePicker
            multiple={false}
            input={{
              onChange: (v) => onChangeImage(v, 'icon'),
              name: 'icon',
              value: formik.values.icon,
            }}
            width='60px'
            height='60px'
          />
        </Container>
      </Wrapper>
      <Wrapper>
        <Container>
          <Text>Размер представления</Text>
          <FormControl component='fieldset'>
            <RadioGroup
              style={{ flexDirection: 'row' }}
              onChange={formik.handleChange}
              value={formik.values.previewSize}
              aria-label='previewSize'
              defaultValue='default'
              name='previewSize'
            >
              <RadioContainer>
                <OrangeRadio value='default' />
                <RadioLabel>Обычный</RadioLabel>
              </RadioContainer>
              <RadioContainer>
                <OrangeRadio value='large' />
                <RadioLabel>Большой</RadioLabel>
              </RadioContainer>
            </RadioGroup>
          </FormControl>
        </Container>
      </Wrapper>
    </div>
  )
}

export default connect(
  R.applySpec({
    token: getToken
  }),
  {
    getCategoriesRequest
  },
)(CreateCategory)