import React from 'react'
import styled from 'styled-components'
import { RequestsGeocode } from '../../reatom/cartography'
import { mapMarker } from '../../assets/img'

const createMap = (element, coord) =>
  element === null
    ? null
    : new window.google.maps.Map(element, {
        center: {
          lat: coord.lat ? coord.lat : Number(defaultAddress.lat),
          lng: coord.lng ? coord.lng : Number(defaultAddress.lng),
        },
        zoom: 15,
        disableDefaultUI: true,
        gestureHandling: 'greedy',
      })

const MapStyle = styled.div`
  height: 100%;
  width: 100%;
`

const Marker = styled.img`
  width: 36px;
  height: 54px;
  position: absolute;
  left: 50%;
  top: 50%;

  transform: translate(-50%);
`

const Wrapper = styled.div`
  position: relative;
`

const Address = styled.div`
  max-width: 470px;
  padding: 0 15px;

  position: absolute;
  left: 50%;
  bottom: calc(50% + 23px);

  transform: translate(-50%);

  font-family: 'FuturaBookC', sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 130%;
  text-align: center;
  color: #35414c;
`

export const fetchLocationByCoords = (coord, options) =>
  RequestsGeocode.fetchLocationByCoords(coord, options).then(([head]) => {
    return {
      address: 'Что-то не так',
      ...coord,
      ...head,
    }
  })

export const defaultAddress = {
  address: 'Северная улица, 279',
  lat: 45.04139150229465,
  lng: 38.97425558312251,
}
export class Map extends React.Component {
  map = null
  removeListenerCenterChanged = null
  componentDidMount() {
    if (!this.props.value) {
      RequestsGeocode.fetchCurrentLocation()
        .then(fetchLocationByCoords)
        .then(this.props.onChange)
    }
  }
  componentDidUpdate(prevProps) {
    if (
      prevProps.value !== this.props.value &&
      this.props.value !== this.ownPrevValue
    ) {
      if (this.map) {
        this.map.setCenter(this.props.value)
      }
    }
  }

  ownPrevValue = null
  controllerAbortLocationByCoors = null
  factoryMap = div => {
    this.map = createMap(div, this.props.value ?? defaultAddress)
    if (this.map === null) {
      return
    }
    this.removeListenerCenterChanged = this.map.addListener(
      'center_changed',
      () => {
        if (this.map === null) {
          return
        }

        if (this.controllerAbortLocationByCoors) {
          this.controllerAbortLocationByCoors.abort()
        }
        this.controllerAbortLocationByCoors = new AbortController()
        fetchLocationByCoords(this.map.getCenter().toJSON(), {
          signal: this.controllerAbortLocationByCoors.signal,
        })
          .then(address => {
            this.ownPrevValue = address
            this.props.onChange(address)
          })
          .catch(() => {})
      },
    )
  }

  render() {
    const { className, value } = this.props
    return (
      <Wrapper className={className}>
        <MapStyle ref={this.factoryMap} />
        <Address>{value.address ?? defaultAddress.address}</Address>
        <Marker src={mapMarker} />
      </Wrapper>
    )
  }

  componentWillUnmount() {
    if (this.removeListenerCenterChanged) {
      this.removeListenerCenterChanged.remove()
    }
  }
}
