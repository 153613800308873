import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(() => ({
  addButton: {
    marginRight: 8,
    width: 95,
    height: 36,
    boxShadow: '0px 6px 14px 0px rgba(2, 9, 75, 0.06)',
    padding: '9px 11px',
    fontFamily: 'FuturaBookC',
    boxSizing: 'border-box',
    fontSize: 16,
    color: '#ffffff',
    textTransform: 'initial',
    lineHeight: 'initial',
    borderRadius: 8,
    background: 'linear-gradient(92.15deg, #F6A26D -38.69%, #F87C2E 107.08%)',
  },
  cancelButton: {
    boxSizing: 'border-box',
    width: 95,
    height: 36,
    background:
      'linear-gradient(0deg, #9BA1A6, #9BA1A6), linear-gradient(92.32deg, #F49355 0%, #F0640C 100%)',
    borderRadius: 8,
    boxShadow: '0px 6px 14px 0px rgba(2, 9, 75, 0.06)',
    padding: '9px 11px',
    fontFamily: 'FuturaBookC',
    fontSize: 16,
    color: '#ffffff',
    textTransform: 'initial',
    lineHeight: 'initial',
  },
}))
