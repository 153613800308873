import { combineReducers } from 'redux'
import { createAction, handleAction, handleActions } from 'redux-actions'
import { MODULES } from '../../constants'
import { selectDocument } from '../organizations'
import * as R from 'ramda'

export const setTaskModalVisible = createAction(
  `${MODULES}/SET_TASK_MODAL_VISIBLE`,
)

export const setUserDeletionModalVisible = createAction(
  `${MODULES}/SET_USER_DELETION_MODAL_VISIBLE`,
)

export const setOrganisationDeletionModalVisible = createAction(
  `${MODULES}/SET_ORGANISATION_DELETION_MODAL_VISIBLE`,
)

export const setOrganisationDocumentsModalVisible = createAction(
  `${MODULES}/SET_ORGANISATION_DOCUMENTS_MODAL_VISIBLE`,
)

const taskModalVisible = handleAction(
  setTaskModalVisible,
  (_, { payload }) => !!payload,
  false,
)

const userDeletionModalVisible = handleAction(
  setUserDeletionModalVisible,
  (state, { payload }) => !state,
  false,
)

const organisationDeletionModalVisible = handleAction(
  setOrganisationDeletionModalVisible,
  (state, { payload }) => !state,
  false,
)

const organisationDocumentsModalVisible = handleActions(
  {
    [selectDocument]: R.T,
    [setOrganisationDocumentsModalVisible]: (state, { payload }) => !state,
  },
  false,
)

const uiReducer = combineReducers({
  taskModalVisible,
  userDeletionModalVisible,
  organisationDeletionModalVisible,
  organisationDocumentsModalVisible,
})

export default uiReducer
