import React from 'react'
import styled, {css} from 'styled-components'

import AnalyticsPeriodFilter from './analytics-period-filter'
import AnalyticsDownload from '../analytics-download'

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const FilterWrapper = styled.div`
  display: flex;
  align-items: center;
`

const Input = styled.input`
  width: 204px;
  height: 36px;
  background: white;
  font-family: 'FuturaBookC', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #b9c2c8;
  padding-left: 11px;
  display: flex;
  align-items: center;
  outline: none;
  border-radius: 8px 8px 8px 8px;
  border: 1px solid #f49355;
  margin-left: 10px;
`

const Row = styled.div`
  display: flex;
`

const GraphRangeContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 9px 10px;
  border-radius: 8px;
  margin-left: 5px;
  font-family: 'FuturaBookC', sans-serif;
  font-size: 16px;
  line-height: 18px;
  color: #666666;
  cursor: pointer;
  ${({ isActive }) =>
          isActive
                  ? css`
          border: 1px solid #f49355;
        `
                  : css`
          border: 1px solid #ffffff;
          background: #ffffff;
          box-shadow: 0 6px 10px rgba(2, 9, 75, 0.06);
        `}
  &:first-child{
    margin-left: 20px;
  }
`

const Text = styled.div`
  display: flex;
  align-items: center;
  font-family: FuturaMediumC, sans-serif;
  font-size: 14px;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.4);
  margin-left: 40px;
`

const graphsRanges = [
  {
    type: 'day',
    label: 'День',
  },
  {
    type: 'week',
    label: 'Неделя',
  },
  {
    type: 'month',
    label: 'Месяц',
  },
  {
    type: 'year',
    label: 'Год',
  },
]

const AnalyticsHeader = ({
                           type,
                           period,
                           setPeriod,
                           setSearchedDriver,
                           setSearchedOrg,
                           searchedDriver,
                           searchedOrg,
                           downloadOnClick,
                           graphsRange,
                           setGraphsRange,
                         }) => {

  const renderGraphsRanges = () => (
    <Row>
      {graphsRanges.map(item =>(
        <GraphRangeContainer key={item.type} isActive={graphsRange===item.type} onClick={()=>setGraphsRange(item.type)}>
          {item.label}
        </GraphRangeContainer>
      ))}
    </Row>
  )

  const renderContent = () => {
    switch (type) {
      case 'organization':
        return (
          <Row>
            <Text>Поиск</Text>
            <Input
              value={searchedOrg}
              onChange={event => setSearchedOrg(event.target.value)}
            />
          </Row>
        )
      case 'driver' :
        return (
          <Row>
            <Text>Поиск</Text>
            <Input
              value={searchedDriver}
              onChange={event => setSearchedDriver(event.target.value)}
            />
          </Row>
        )
      case 'graphs':
        return renderGraphsRanges()
      default :
        return null
    }
  }

  return (
    <Wrapper>
      <FilterWrapper>
        <AnalyticsPeriodFilter period={period} setPeriod={setPeriod} />
        {renderContent()}
      </FilterWrapper>
      <AnalyticsDownload downloadOnClick={()=>downloadOnClick(type)} />
    </Wrapper>
  )
}

export default AnalyticsHeader
