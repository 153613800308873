import React from 'react'
import { FormControl, Select, MenuItem } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import useStyles from './styles'

const StyledMenuItem = withStyles(theme => ({
  root: {
    padding: '5px 20px',
    fontSize: 20,
    fontFamily: 'FuturaMediumC',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&:focus': {
      backgroundColor: 'transparent',
    },
    '&:not(.Mui-selected)': {
      fontFamily: 'FuturaBookC',
    },
    '&.MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover': {
      backgroundColor: 'transparent',
    },
  },
}))(MenuItem)

const BoardsSelect = ({
  input,
  label,
  meta: { touched, error },
  children,
  ...custom
}) => {
  const classes = useStyles()
  return (
    <FormControl error={touched && error}>
      <Select
        className={classes.select}
        autoWidth
        {...input}
        {...custom}
        inputProps={{
          name: 'board',
          id: 'boards-select',
          classes: {
            icon: classes.icon,
          },
        }}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
          classes: {
            paper: classes.menu,
          },
        }}
      >
        {children}
      </Select>
    </FormControl>
  )
}

export const Option = StyledMenuItem

export default BoardsSelect
