import * as R from 'ramda'

export const getTransportSelect = R.prop('transport')

export const getIsLoading = R.pipe(getTransportSelect, R.prop('isLoading'))

export const getById = R.pipe(getTransportSelect, R.prop('byId'))

const getAllIds = R.pipe(getTransportSelect, R.prop('allIds'))

export const getTransportList = R.converge(
  (byId, allIds) => R.map(x => byId[x])(allIds),
  [getById, getAllIds],
)

export const getTransportCategorySelect = state =>
  state.transport.getTransportCategory

export const getDriversList = state => state.transport.byId

export const getState = state => state

export const getTransport = R.pipe(getTransportSelect, R.prop('getTransport'))

export const getSelectedCategory = R.pipe(
  getTransportSelect,
  R.prop('selectedCategory'),
)

export const getSearchedTransport = R.pipe(
  getTransportSelect,
  R.prop('searchedTransport'),
)

export const getStatusesTransport = R.pipe(
  getTransportSelect,
  R.prop('getStatuses'),
)

export const getSelectedStatus = R.pipe(
  getTransportSelect,
  R.prop('selectedStatus'),
)

export const getSortTransport = R.pipe(
  getTransportSelect,
  R.prop('sortTransport'),
)

export const getFreeTransport = R.pipe(
  getTransport,
  R.filter(R.propEq('status', 'free')),
)