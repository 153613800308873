import { withStyles } from '@material-ui/core/styles'
import { TableCell } from '@material-ui/core'

export const FooterTableCell = withStyles(() => ({
  body: {
    height: 46,
    background: 'transparent',
    padding: '0 20px',
    textAlign: ({ textAlign }) => textAlign,
    fontFamily: 'FuturaBookC',
    fontSize: 14,
    color: 'rgba(0,0,0,1)',
  },
}))(TableCell)
