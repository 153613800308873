import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'

const TextFieldWrapper = styled.div`
  position: relative;
  height: 36px;
  width: 260px;
  margin-bottom: 20px;
  @media screen and (min-width: 320px) and (max-width: 767px) {
    width: 100%;
  }
`

const OptionsWrapper = styled.div`
  position: absolute;
  z-index: 10;
  left: 0;
  right: 0;
  background-color: #fff;
  box-shadow: 0 6px 14px rgba(2, 9, 75, 0.06);
`

const Option = styled.div`
  cursor: pointer;
  width: 100%;
  box-sizing: border-box;
  height: 36px;
  padding: 10px 12px 8px 12px;
  font-family: FuturaBookC, sans-serif;
  font-size: 14px;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.8);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: rgba(0, 0, 0, .05);
  }

  &:last-child {
    border-bottom: none;
  }
`

const TextField = styled.input`
  height: 36px;
  width: 260px;
  background: #ffffff;
  border: 1px solid #f49355;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 10px 12px 8px 12px;
  outline: 0;
  font-family: FuturaBookC, sans-serif;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.8);

  ::placeholder {
    font-family: FuturaBookC, sans-serif;
    font-size: 14px;
    line-height: 15px;
    color: rgba(18, 22, 26, 0.4);
    opacity: 0.8;
  }

  @media screen and (min-width: 320px) and (max-width: 767px) {
    width: 100%;
  }
`

const Label = styled.div`
  position: absolute;
  top: -7px;
  left: 9px;
  padding: 0 4px;
  background: #ffffff;
  font-family: FuturaBookC, sans-serif;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.4);
  text-transform: initial;
`

const OrganizationTextField = ({ label, isPhone, onClick, options, onChange, ...rest }) => {
  const [showHelp, setIsShowHelp] = useState(false)
  const wrapperRef = useRef(null)
  const renderHelp = () => {
    if (!options || !showHelp) return null

    const onClickOption = (o) => {
      setIsShowHelp(false)
      onChange({ target: { value: o } })

    }

    return (
      <OptionsWrapper>
        {
          options
            .filter(o => o?.toLowerCase().includes(rest.value?.toLowerCase() || ''))
            .map(o => (
              <Option key={o} onClick={() => onClickOption(o)}>{o}</Option>
            ))
        }
      </OptionsWrapper>
    )
  }
  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsShowHelp(null)
      }
    }

    if (options && showHelp) {
      document.addEventListener('mousedown', handleClickOutside)
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [wrapperRef, options, showHelp])
  return (
    <TextFieldWrapper onClick={onClick} ref={wrapperRef}>
      {rest.value && <Label>{label}</Label>}
      <TextField placeholder={rest.value ? '' : label}
                 onFocus={() => setIsShowHelp(true)}
                 onChange={onChange}
                 {...rest} />
      {renderHelp()}
    </TextFieldWrapper>
  )
}

export default OrganizationTextField
