import React from 'react'

import styled from 'styled-components'
import { useAction, useAtom } from '@reatom/react'

import { RequestsGeocode } from '../../reatom/cartography'
import {
  InputTextArea as InputTextAreaBase,
  Button as ButtonBase,
  ModalDark,
  useMediaQuery,
  ButtonWhite,
} from './commons'
import { ArrowLong, Direction } from '../../assets/img/arrows'

import {
  addFavoriteAddresses as addFavoriteAddressesAction,
  favoriteAddresses as favoriteAddressesAtom,
} from '../../reatom/basket'
import { defaultAddress, fetchLocationByCoords } from './map'
import { ltTablet, widthMobileScreen } from './utils'

import { navigation } from '../../assets/img'
import { add } from 'ramda'

const Title = styled.div`
  margin-bottom: 10px;

  font-family: 'FuturaBookC', sans-serif;
  font-weight: 600;
  font-size: 22px;
  color: #35414c;
  ${ltTablet} {
    font-size: 15px;
    margin-bottom: 5px;
  }
`

const List = styled.div`
  height: 100%;
  max-height: 300px;
  overflow-y: auto;
  @media screen and (min-width: 320px) and (max-width: 767px) {
    max-height: 300px;
  }
`

const Item = styled.div`
  padding: 7px 14px;

  cursor: pointer;

  font-family: 'FuturaBookC', sans-serif;
  font-size: 14px;
  line-height: 140%;
`

const ModalItem = styled.div`
  width: 400px;
  height: 66px;
  box-shadow: 0px 2px 5px rgba(10, 21, 128, 0.1);
  border-radius: 6px;
  cursor: pointer;
  padding-top: 12px;
  padding-left: 20px;
  @media screen and (min-width: 320px) and (max-width: 767px) {
    width: 270px;
  }
`

const ModalItemText = styled.div`
  font-family: 'FuturaBookC', sans-serif;
  font-size: 14px;
  line-height: 140%;
  color: #495560;
  max-width: 360px;
  @media screen and (min-width: 320px) and (max-width: 767px) {
    font-size: 12px;
    line-height: 120%;
  }
`

const Button = styled(ButtonBase)`
  margin-right: 15px;
  flex: 1;
  height: 38px;
`

const BackCatalogue = styled.div`
  margin-bottom: 15px;
  cursor: pointer;
  ${ltTablet} {
    display: none;
  }

  text-decoration: none;
  font-family: 'FuturaBookC', sans-serif;
  font-size: 14px;
  color: #8b949b;
`

const Description = styled.div`
  margin-bottom: 20px;

  font-family: 'FuturaBookC', sans-serif;
  font-size: 14px;
  ${ltTablet} {
    font-size: 12px;
    margin-bottom: 15px;
  }
  line-height: 130%;
  color: #8b949b;
`

const InputTextArea = styled(InputTextAreaBase)`
  width: 90%;
  min-height: 52px;
  margin-bottom: 22px;
  ${ltTablet} {
    margin-bottom: 15px;
  }

  resize: none;
`

const Arrow = styled(ArrowLong)`
  height: 8px;
  margin-right: 10px;

  stroke: #8b949b;
`

const Hr = styled.div`
  height: 1px;
  width: calc(100% + 30px);
  margin-right: -30px;
  ${ltTablet} {
    width: calc(100% + 30px);
    margin-right: -15px;
    margin-left: -15px;
  }

  background: #ecf1f4;
`

const FirstHr = styled(Hr)`
  margin-bottom: 20px;
  ${ltTablet} {
    display: none;
  }
`
const SecondHr = styled(Hr)`
  margin-bottom: 7px;
  ${ltTablet} {
    display: none;
  }
`

const ThirtyHr = styled(Hr)`
  margin-bottom: 30px;
  ${ltTablet} {
    display: none;
  }
`

const MobileHr = styled(Hr)`
  margin-bottom: 15px;
`

const Container = styled.div`
  padding: 30px;
  ${ltTablet} {
    padding: 15px;
  }

  display: flex;
  flex-direction: column;

  background: #ffffff;
  ${ltTablet} {
    .is-open {
    }
  }
`

const WrapperListMobile = styled.div`
  height: calc(100% - 37px);
  padding: 15px 15px 0;

  display: flex;
  flex-direction: column;
  flex: 1;

  background: #ffffff;
`

const CloseTextMobile = styled.div`
  margin: 10px 0;

  cursor: pointer;
  font-family: 'FuturaBookC', sans-serif;
  text-align: center;
  font-size: 15px;
  color: #ffffff;
`

const Modal = styled(ModalDark)`
  padding-top: 83px;

  flex-direction: column;
`

const ButtonNavigation = styled(ButtonWhite)`
  width: 38px;
  height: 38px;
  padding: 0;
`

const NavigationIcon = styled.img`
  margin-bottom: 4px;
  width: 25px;
  margin-left: 5px;
`

const WrapperButtons = styled.div`
  display: flex;
`

export const Suggestions = ({
  value,
  onSubmit,
  onChange,
  className,
  isShowSuggestions,
  inputValue,
  setIsOpen,
  setIsShowAddress,
  setInputValue,
}) => {
  const [tmpValue, setTmpValue] = React.useState(
    value.address ?? defaultAddress.address,
  )
  const [suggestions, setSuggestions] = React.useState([])
  const [isShowList, setIsShowList] = React.useState(false)
  const favoriteAddresses = useAtom(favoriteAddressesAtom)
  const addFavoriteAddresses = useAction(addFavoriteAddressesAction)

  const unmounted = React.useRef(false)
  React.useEffect(() => {
    if (inputValue !== '') {
      setTmpValue(inputValue)
    }
  }, [inputValue])

  React.useEffect(() => {
    if (value.address !== undefined) {
      setTmpValue(value.address)
    }
    setSuggestions([])
    setIsShowList(false)
  }, [value])

  React.useEffect(() => {
    if (tmpValue === value.address || tmpValue === defaultAddress.address) {
      return
    }
    const controller = new AbortController()
    RequestsGeocode.fetchSuggestions(
      { part: tmpValue },
      { signal: controller.signal },
    )
      .then(res => {
        if (unmounted.current) return
        setSuggestions(res)
      })
      .catch(() => {})
    return () => controller.abort()
  }, [tmpValue, value.address])

  React.useEffect(() => {
    if (isShowList === false) {
      setSuggestions([])
    }
  }, [isShowList])

  React.useEffect(
    () => () => {
      unmounted.current = true
    },
    [],
  )

  const isMobile = useMediaQuery(`(max-width: ${widthMobileScreen - 1}px)`)

  const list = (
    <List>
      {(suggestions.length === 0 ? favoriteAddresses : suggestions).map(
        address =>
          isShowSuggestions ? (
            <ModalItem
              key={String(address.lng) + String(address.lng)}
              onClick={() => {
                onChange(address)
                onSubmit(address)
                addFavoriteAddresses([address])
                setInputValue(address.address)
                setIsOpen(false)
              }}
            >
              <ModalItemText>{address.address}</ModalItemText>
            </ModalItem>
          ) : (
            <Item
              key={String(address.lng) + String(address.lng)}
              onClick={() => {
                onChange(address)
                setIsOpen(false)
                onSubmit(address)
                addFavoriteAddresses([address])
              }}
            >
              {address.address}
            </Item>
          ),
      )}
    </List>
  )
  const wrapperList = isShowSuggestions ? (
    <>{list}</>
  ) : isShowList && isMobile ? (
    <>
      <InputTextArea
        value={tmpValue}
        onChange={setTmpValue}
        onFocus={() => setIsShowList(true)}
      />
      <Modal visible onRequestClose={setIsShowList}>
        <CloseTextMobile onClick={() => setIsShowList(false)}>
          Закрыть
        </CloseTextMobile>
        <WrapperListMobile>
          <InputTextArea
            value={tmpValue}
            onChange={setTmpValue}
            // @ts-ignore
            ref={ref => {
              if (ref) {
                ref.focus()
                const value = ref.value
                ref.value = ''
                ref.value = value
              }
            }}
          />
          <MobileHr />
          {list}
        </WrapperListMobile>
      </Modal>
    </>
  ) : (
    <>
      <InputTextArea
        value={tmpValue}
        onChange={setTmpValue}
        onFocus={() => setIsShowList(true)}
      />
      <SecondHr />
      {!isMobile && list}
    </>
  )
  return (
    <Container className={className}>
      {isShowSuggestions ? (
        <div>{wrapperList}</div>
      ) : (
        <>
          <Title>Куда доставить заказ?</Title>
          <BackCatalogue onClick={() => setIsShowAddress(false)}>
            <Arrow direct={Direction.Left} />
            Назад в каталог
          </BackCatalogue>
          <FirstHr />
          <Description>
            Введите адрес или выберите на карте и мы подберем для вас возможные
            варианты доставки.
          </Description>
          {wrapperList}
          <ThirtyHr />
          <WrapperButtons>
            <Button
              onClick={() => {
                onSubmit(value || defaultAddress)
                addFavoriteAddresses([value || defaultAddress])
                setIsShowAddress(false)
              }}
            >
              Подтвердить
            </Button>
            <ButtonNavigation
              onClick={() =>
                RequestsGeocode.fetchCurrentLocation()
                  .then(fetchLocationByCoords)
                  .then(onChange)
              }
            >
              <NavigationIcon src={navigation} />
            </ButtonNavigation>
          </WrapperButtons>
        </>
      )}
    </Container>
  )
}
