import { ReactComponent as arrow } from './arrow.svg'
import { ReactComponent as arrowLong } from './arrowLong.svg'
import styled from 'styled-components'

export const Direction = {
  Up: 0,
  Down: 1,
  Left: 2,
  Right: 3,
}

const rotateArrow = ({ direct }) => {
  switch (direct) {
    case Direction.Up:
      return '180'
    case Direction.Right:
      return '-90'
    case Direction.Down:
      return '0'
    case Direction.Left:
      return '90'
  }
}

export const Arrow = styled(arrow)`
  transform: rotate(${rotateArrow}deg);
`

const rotateArrowLong = ({ direct }) => {
  switch (direct) {
    case Direction.Up:
      return '90'
    case Direction.Right:
      return '0'
    case Direction.Down:
      return '-90'
    case Direction.Left:
      return '180'
  }
}
export const ArrowLong = styled(arrowLong)`
  transform: rotate(${rotateArrowLong}deg);
`
