import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { connect } from 'react-redux'
import * as R from 'ramda'

import {
  getAnalyticsInfo,
  getTotalAnalytics,
} from '../../../../modules/analytics'

import assets from '../../../../assets'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`

const FirstPaginatorArrowsLeft = styled.img.attrs({
  src: assets.img.paginatorArrows,
})`
  cursor: pointer;
`
const LastPaginatorArrowsRight = styled.img.attrs({
  src: assets.img.paginatorArrows,
})`
  cursor: pointer;
  transform: rotate(180deg);
`
const PrevPaginatorArrowsLeft = styled.img.attrs({
  src: assets.img.arrow,
})`
  cursor: pointer;
  width: 14px;
  height: 14px;
  transform: rotate(90deg);
  margin-right: 10px;
`
const NextPaginatorArrowsRight = styled.img.attrs({
  src: assets.img.arrow,
})`
  cursor: pointer;
  width: 14px;
  height: 14px;
  transform: rotate(270deg);
  margin-left: 10px;
`

const Count = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 38px;
  height: 38px;
  font-family: FuturaBookC, sans-serif;
  font-size: 16px;
  ${({ isActive }) =>
    isActive
      ? css`
          background: #ffffff;
          box-shadow: 0 6px 14px rgba(10, 21, 128, 0.1);
          color: #f0640c;
        `
      : css`
          color: #495560;
        `}
  border-radius: 6px;
`

const AnalyticsPagination = ({
  page,
  setPage,
  total,
  countOnPage,
  analyticsInfo,
  infoTotal,
  isMain,
}) => {
  const [pages, setPages] = useState([])
  const [paginator, setPaginator] = useState({
    start: 0,
    end: 5,
  })
  useEffect(() => {
    if (analyticsInfo && Object.keys(analyticsInfo).length > 0 && !isMain) {
      setPages(
        Array.from(
          {
            length:
              analyticsInfo.orders.length > 0
                ? Math.ceil(infoTotal / countOnPage)
                : 1,
          },
          (_, i) => i + 1,
        ),
      )
    } else {
      setPages(
        Array.from(
          {
            length: Math.ceil(total / countOnPage),
          },
          (_, i) => i + 1,
        ),
      )
    }
  }, [total, analyticsInfo, page])

  useEffect(() => {
    if (page === paginator.end + 1) {
      setPaginator({
        start: paginator.start + 5,
        end: paginator.end + 5,
      })
    }
    if (page === paginator.start && page !== 1) {
      setPaginator({
        start: paginator.start - 5,
        end: paginator.end - 5,
      })
    }
    if (page === 1) {
      setPaginator({
        start: 0,
        end: 5,
      })
    }
  }, [page])

  return (
    <Wrapper>
      <FirstPaginatorArrowsLeft
        onClick={() => {
          if (page !== 1) {
            setPage(1)
          }
        }}
      />
      <PrevPaginatorArrowsLeft
        onClick={() => {
          if (page !== 1) {
            setPage(page - 1)
          }
        }}
      />
      {pages.slice(paginator.start, paginator.end).map(item => (
        <Count
          key={item}
          onClick={() => setPage(item)}
          isActive={item === page}
        >
          {item}
        </Count>
      ))}
      <NextPaginatorArrowsRight
        onClick={() => {
          if (page !== pages[pages.length - 1]) {
            setPage(page + 1)
          }
        }}
      />
      <LastPaginatorArrowsRight
        onClick={() => {
          if (page !== pages[pages.length - 1]) {
            setPage(pages[pages.length - 1])
          }
        }}
      />
    </Wrapper>
  )
}

export default connect(
  R.applySpec({
    total: getTotalAnalytics,
    analyticsInfo: getAnalyticsInfo,
  }),
)(AnalyticsPagination)
