import { ENDPOINTS } from '../../API'
import { handleStatuses } from '../../utils'

export const getAdminUsers = ({ accessToken }) => {
  return fetch(ENDPOINTS.ADMINUSERS(), {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  })
    .then(handleStatuses())
    .then(res => res.json())
}

export const getOrganizationUsers = (id, { accessToken }) => {
  return fetch(ENDPOINTS.ORGANIZATIONS() + `/${id}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  })
    .then(handleStatuses())
    .then(res => res.json())
}

export const createAdminUser = ({ body, accessToken }) => {
  return fetch(ENDPOINTS.ADMINUSERS(), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(body),
  })
    .then(handleStatuses())
    .then(res => res.json())
}

export const updateAdminUser = ({ body, accessToken }, id) =>
  fetch(ENDPOINTS.ADMINUSERS() + `/${id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(body),
  }).then(handleStatuses())

export const deleteAdminUserRequest = id =>
  fetch(ENDPOINTS.ADMINUSERS() + `/${id.id}`, {
    method: 'DELETE',
    body: JSON.stringify(id),
    headers: {
      'Content-Type': 'application/json',
    },
  }).then(handleStatuses())
