import React, { useCallback } from 'react'
import { Grid } from '@material-ui/core'
import { connect } from 'react-redux'
import * as R from 'ramda'
import moment from 'moment'

import { getOrdersList } from '../../../modules/orders'

import useStyles from './styles'

const InfoBlock = ({ orders, setFilterByType, filterByType }) => {
  const classes = useStyles()
  const allOrders = useCallback(
    () =>
      orders.reduce(
        (previousValue, currentValue) => [
          ...previousValue,
          ...currentValue.orders,
        ],
        [],
      ).filter(order => !!order),
    [orders],
  )

  const daysDiffFromOrderDate = allOrders().map(item => {
    const today = moment()
    const dateObj = JSON.parse(item.date);
    const orderDate = moment(!!dateObj ? dateObj?.date : undefined)
    return Math.ceil(moment.duration(orderDate.diff(today)).asDays())
  })

  const daysDiffFromToday = allOrders().map(item => {
    const today = moment()
    const orderDate = moment(item.createdAt)
    return Math.ceil(moment.duration(orderDate.diff(today)).asDays())
  })

  const totalPrice = allOrders().reduce(
    (previousValue, currentValue) => previousValue + Number(currentValue.price),
    0,
  )

  const noManager = allOrders().filter(item => !item.managerId).length
  const today = daysDiffFromOrderDate.filter(item => item === -0).length
  const overdue = daysDiffFromOrderDate.filter(item => item < 0).length
  const newToday = daysDiffFromToday.filter(item => item === -0).length
  const newYesterday = daysDiffFromToday.filter(item => item === -1).length

  return (
    <Grid container wrap="nowrap" alignItems="center">
      <Grid
        classes={{
          root: classes.item,
        }}
        item
      >
        <div className={classes.title}>
          <span>Неназначенные:</span>
        </div>
        <div
          style={{
            color: '#0BD781',
          }}
          className={classes.count}
          onClick={() => {
            if (filterByType === 0) {
              setFilterByType(1)
            } else {
              setFilterByType(0)
            }
          }}
        >
          {noManager}
        </div>
      </Grid>
      <Grid
        classes={{
          root: classes.item,
        }}
        item
      >
        <div className={classes.title}>
          <span>На сегодня:</span>
        </div>
        <div
          style={{
            color: '#FAB400',
          }}
          className={classes.count}
          onClick={() => {
            if (filterByType === 0) {
              setFilterByType(2)
            } else {
              setFilterByType(0)
            }
          }}
        >
          {today}
        </div>
      </Grid>
      <Grid
        classes={{
          root: classes.item,
        }}
        item
      >
        <div className={classes.title}>
          <span>Просроченных:</span>
        </div>
        <div
          style={{
            color: '#F55555',
          }}
          className={classes.count}
          onClick={() => {
            if (filterByType === 0) {
              setFilterByType(3)
            } else {
              setFilterByType(0)
            }
          }}
        >
          {overdue}
        </div>
      </Grid>
      <Grid
        classes={{
          root: classes.item,
        }}
        item
      >
        <div className={classes.title}>
          <span>Новых сегодня/вчера:</span>
        </div>
        <div
          onClick={() => {
            if (filterByType === 0) {
              setFilterByType(4)
            } else {
              setFilterByType(0)
            }
          }}
          style={{
            color: 'rgba(0,0,0,0.4)',
          }}
          className={classes.count}
        >
          {newToday}/{newYesterday}
        </div>
      </Grid>
      <Grid
        classes={{
          root: classes.sales,
        }}
        item
      >
        <div className={classes.salesTitle}>
          <span>Прогноз продаж:</span>
        </div>
        <div
          style={{
            color: 'rgba(0,0,0,0.4)',
          }}
          className={classes.salesCount}
        >
          {allOrders().length} сделок: <span>{totalPrice} ₽</span>
        </div>
      </Grid>
    </Grid>
  )
}

export default connect(
  R.applySpec({
    orders: getOrdersList,
  }),
)(InfoBlock)
