import * as R from 'ramda'
import { getAmoRoles } from '../users/selectors'

export const getOrganizations = R.prop('organizations')

export const getIsLoading = R.pipe(getOrganizations, R.prop('isLoading'))

const getById = R.pipe(getOrganizations, R.prop('byId'))

const getAllIds = R.pipe(getOrganizations, R.prop('allIds'))

export const getActiveSort = R.pipe(getOrganizations, R.prop('activeSort'))

export const getOrganizationList = R.converge(
  (byId, allIds) => R.map(x => byId[x])(allIds),
  [getById, getAllIds],
)

export const getOrganizationsList = R.pipe(
  getOrganizations,
  R.prop('getOrganizations'),
)

export const getIsCreatingOrganization = R.pipe(
  getOrganizations,
  R.prop('isCreatingOrganization'),
)

export const getIsCreatingOrganizationUser = R.pipe(
  getOrganizations,
  R.prop('isCreatingOrganizationUser'),
)

export const getIsPostingOrganization = R.pipe(
  getOrganizations,
  R.prop('isPostingOrganization'),
)

export const getSelectedOrganization = R.pipe(
  getOrganizations,
  R.prop('selectedOrganization'),
)

export const getSelectedUser = R.pipe(getOrganizations, R.prop('selectedUser'))

export const getIsUserLoading = R.pipe(
  getOrganizations,
  R.prop('isUserLoading'),
)

export const getSelectedDocument = R.pipe(
  getOrganizations,
  R.prop('selectedDocument'),
)

export const getSelectedOrganizationUsers = R.pipe(
  getOrganizations,
  R.prop('selectedOrganizationUsers'),
)

export const getOrganizationError = R.pipe(
  getOrganizations,
  R.prop('organizationError'),
)

export const getOrganisationDocuments = state => {
  const selectedOrganization = getSelectedOrganization(state)

  if (selectedOrganization) {
    const val = getById(state)[selectedOrganization]
    return val.documents
  }
}

export const getFormInitialValues = state => {
  const selectedOrganization = getSelectedOrganization(state)
  const isCreating = getIsCreatingOrganization(state)

  if (selectedOrganization) {
    const val = getById(state)[selectedOrganization]
    return val
  }

  if (isCreating) {
    return {}
  }
}

export const getUserFormInitialValues = state => {
  try {
    const selectedUser = getSelectedUser(state)

    if (selectedUser) {
      if (selectedUser.roles.map) {
        const user = { ...selectedUser, roles: [...selectedUser.roles] }
        const roles = getAmoRoles(state)
        const userRoles = user.roles.map(role => role.id)
        const map = new Map()
        if (roles.forEach)
          roles.forEach(role => {
            map.set(role.id, { ...role, checked: userRoles.includes(role.id) })
          })
        user.roles = [...map.values()]
        user.phone = user.phone.slice(2)
        return user
      }
      return selectedUser
    }
  } catch (e) {
    console.log(e)
  }

  return {}
}

export const getSearchedOrganization = R.pipe(
  getOrganizations,
  R.prop('searchedOrganization'),
)
