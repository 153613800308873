import React, { useEffect, useState } from 'react'
import { Modal } from '@material-ui/core'
import styled, { css } from 'styled-components'
import 'react-datepicker/dist/react-datepicker.css'
import { connect } from 'react-redux'
import * as R from 'ramda'

import { MONTHS, DAYS, DAYS_LEAP } from './constants'

import { ReactComponent as Arrow } from '../../../../assets/img/arrow.svg'
import { getSchedule } from '../../../../modules/schedule'

const Wrapper = styled.div`
  width: 280px;
  height: 280px;
  background: #ffffff;
  box-shadow: 0 6px 14px rgba(10, 21, 128, 0.08);
  border-radius: 10px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  display: flex;
  flex-direction: column;
  outline: 0;
  padding: 20px 30px;
`

const Title = styled.div`
  font-family: FuturaBookC, sans-serif;
  font-size: 16px;
  color: #35414c;
  text-align: center;
  margin-bottom: 15px;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`

const LeftArrow = styled(Arrow)`
  transform: rotate(90deg);
  width: 8px;
  height: 8px;
`
const RightArrow = styled(Arrow)`
  transform: rotate(-90deg);
  width: 8px;
  height: 8px;
`
const ArrowButton = styled.button`
  width: 36px;
  height: 36px;
  background: #ffffff;
  box-shadow: 0 2px 5px rgba(10, 21, 128, 0.1);
  border-radius: 4px;
  border: none;
  outline: 0;
  cursor: pointer;
`

const DateTitle = styled.div`
  display: flex;
  align-items: center;
  font-family: FuturaBookC, sans-serif;
  font-size: 18px;
  text-align: center;
  color: #495560;
`

const DaysWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 40px);
  grid-template-rows: repeat(5, 40px);
`

const Day = styled.div`
  font-family: FuturaBookC, sans-serif;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #35414c;
  opacity: ${({ isDisabled }) => (isDisabled ? 0.5 : 0.8)};
  cursor: pointer;

  ${({ isActive }) =>
    isActive
      ? css`
          background: #f0640c;
          box-shadow: 0 6px 14px rgba(2, 9, 75, 0.06);
          border-radius: 6px;
          color: #ffffff;
        `
      : css`
          :hover {
            background: #ffffff;
            box-shadow: 0 2px 5px rgba(10, 21, 128, 0.1);
            border-radius: 6px;
          }
        `}
`

const isLeapYear = year =>
  (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0

const CalendarModal = ({
  open,
  handleClose,
  initialValue,
  handleChange,
  schedule,
}) => {
  const [value, setValue] = useState(new Date(initialValue))

  const [year, setYear] = useState(value.getFullYear())
  const [month, setMonth] = useState(value.getMonth())
  const [day, setDay] = useState(Number(initialValue.split('-')[2]))
  const [daysForMap, setDaysForMap] = React.useState([])

  const days = isLeapYear(year) ? DAYS_LEAP : DAYS


  useEffect(() => {
    const scheduleData = schedule.map(item => ({
      date: new Date(item.date).setHours(0, 0, 0, 0),
      isOpened: item.time !== 'выходной',
    }))
    setDaysForMap(
      Array(days[month])
        .fill({})
        .map((_, index) => {
          const currDate = new Date(year, month, index + 1).setHours(0, 0, 0, 0)
          const isOpened = scheduleData.find(item => item.date === currDate)
            ? scheduleData.find(item => item.date === currDate).isOpened
            : true

          return {
            isDisabled: currDate < new Date().setHours(0, 0, 0, 0) || !isOpened,
            dayValue: index + 1,
          }
        }),
    )
  }, [month])

  useEffect(() => {
    setValue(new Date(year, month, day))
  }, [day])

  useEffect(() => {
    handleChange(`${year}-${month + 1}-${day}`)
    handleClose()
  }, [value])

  const handleButtonClick = type => {
    if (type === 'left') {
      if (month === 0) {
        setYear(year - 1)
        setMonth(11)
      } else {
        setMonth(month - 1)
      }
    } else {
      if (month === 11) {
        setYear(year + 1)
        setMonth(0)
      } else {
        setMonth(month + 1)
      }
    }
  }

  return (
    <Modal open={open} onClose={handleClose}>
      <Wrapper>
        <Title>Дата доставки</Title>
        <Header>
          <ArrowButton onClick={() => handleButtonClick('left')}>
            <LeftArrow />
          </ArrowButton>
          <DateTitle>
            {MONTHS[month]} {year}
          </DateTitle>
          <ArrowButton onClick={() => handleButtonClick('right')}>
            <RightArrow />
          </ArrowButton>
        </Header>
        <DaysWrapper>
          {daysForMap.map(({ dayValue, isDisabled }) => (
            <Day
              isActive={
                new Date(initialValue).setHours(0, 0, 0, 0) ===
                new Date(year, month, dayValue).setHours(0, 0, 0, 0)
              }
              onClick={() => !isDisabled && setDay(dayValue)}
              isDisabled={isDisabled}
              key={dayValue}
            >
              {dayValue}
            </Day>
          ))}
        </DaysWrapper>
      </Wrapper>
    </Modal>
  )
}

export default connect(
  R.applySpec({
    schedule: getSchedule,
  }),
)(CalendarModal)
