import React, { useState } from 'react'
import { TextField, Button, Modal } from '@material-ui/core'
import { connect } from 'react-redux'
import * as R from 'ramda'

import { useStyles } from './styles'
import { getOrganizationList } from '../../../modules/organizations'
import SearchSelectDropDown from '../../common/SearchSelectDropDown'

const getModalStyle = () => {
  const top = 50
  const left = 50

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

const AddBoardModal = ({
  organizations,
  open,
  handleClose,
  value,
  setValue,
  handleSubmit,
  title,
}) => {
  const classes = useStyles()
  const [modalStyle] = React.useState(getModalStyle)
  const [organizationSelectIsOpen, setIsOrganizationSelectIsOpen] = useState(false)

  const setOrganization = (name, id) => {
    setValue({
      ...value,
      organizationId: id,
    })
  }


  return (
    <Modal open={open} onClose={handleClose}>
      <div style={modalStyle} className={classes.paper}>
        <div className={classes.title}>{title}</div>
        <TextField
          classes={{
            root: classes.input,
          }}
          variant="outlined"
          value={value.title}
          onChange={e =>
            setValue({
              ...value,
              title: e.target.value,
            })
          }
        />
        <SearchSelectDropDown
          name={'organization'}
          items={organizations}
          isSelectModalOpen={organizationSelectIsOpen}
          setIsSelectModalOpen={setIsOrganizationSelectIsOpen}
          visibleObjectName={'title'}
          hideObjectName={'id'}
          defaultStyles={{ marginTop: '0px' }}
          shrinkLabel={'Организация'}
          styles={{ minWidth: '260px', marginTop: '20px' }}
          setActiveItem={setOrganization}
        />
        <div className={classes.buttonWrapper}>
          <Button
            onClick={handleSubmit}
            classes={{
              root: classes.addButton,
            }}
          >
            Создать
          </Button>
          <Button
            onClick={handleClose}
            classes={{
              root: classes.cancelButton,
            }}
          >
            Отмена
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default connect(
  R.applySpec({
    organizations: getOrganizationList,
  }),
)(AddBoardModal)
