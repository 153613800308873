import React from 'react'
import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import useStyles from './styles'
import { connect } from 'react-redux'
import * as R from 'ramda'
import { getUploadProductsResult } from '../../../modules/products'

const ModalWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 1000;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
`

const ModalContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 516px;
  height: 245px;
  max-width: 100%;
  max-height: 100%;
  background: #ffffff;
  box-shadow: 6px 14px rgba(2, 9, 75, 0.06);
  border-radius: 8px;
`
const MessageContainer = styled.div`
  display: flex;
  font-size: 25px;
  font-family: 'FuturaBookC', sans-serif;
  margin-top: 85px;
  margin-bottom: 75px;
`

const StyledLink = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  box-shadow: 0 6px 14px 0 rgba(2, 9, 75, 0.06);
  padding: 9px 11px;
  font-family: 'FuturaBookC', sans-serif;
  font-size: 17px;
  font-weight: bold;
  color: #ffffff;
  text-transform: initial;
  line-height: initial;
  border-radius: 8px;
  background: linear-gradient(92.15deg, #F6A26D -38.69%, #F87C2E 107.08%);
  margin-right: 20px;
`

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const UploadResultModal = ({ isShow, close, uploadProductsResult }) => {
  const classes = useStyles()
  const { message, uri } = uploadProductsResult
  const renderContent = () => {
    if (isShow) return (
      <ModalWrapper>
        <ModalContainer>
          <MessageContainer>{message}</MessageContainer>
          <ButtonsWrapper>
            {uri ? <StyledLink href={uri}  onClick={close}>Скачать отчет</StyledLink> : null}
            <Button
              classes={{ root: classes.closeModalButton }}
              onClick={close}>
              Закрыть
            </Button>
          </ButtonsWrapper>
        </ModalContainer>
      </ModalWrapper>
    )
    return null
  }

  return renderContent()
}

export default connect(
  R.applySpec({
    uploadProductsResult: getUploadProductsResult,
  }),
)(UploadResultModal)

