import { call, all, put, takeLatest, select } from 'redux-saga/effects'
import {
  getAdminUsersRequest,
  getAdminUsersSuccess,
  getAdminUsersFailure,
  createAdminUserRequest,
  createAdminUserFailure,
  createAdminUserSuccess,
  updateAdminUserRequest,
  updateAdminUserFailure,
  updateAdminUserSuccess,
  deleteAdminUserSuccess,
  deleteAdminUserRequest,
  getOrganizationUsersRequest,
  getOrganizationUsersSuccess,
  getOrganizationUsersFailure,
} from './duck'

import { setTaskModalVisible } from '../ui'
import * as AdminUsersManager from './AdminUsersManager'
import { getToken } from '../auth'

function* getAdminUsersSaga() {
  try {
    const accessToken = yield select(getToken)
    const adminUsers = yield call(AdminUsersManager.getAdminUsers, {
      accessToken,
    })
    yield put(getAdminUsersSuccess(adminUsers))
  } catch (ex) {
    yield put(getAdminUsersFailure(ex.localeMessage))
  }
}

function* getOrganizationUsersSaga(id) {
  try {
    const accessToken = yield select(getToken)
    const organizationUsers = yield call(
      AdminUsersManager.getOrganizationUsers,
      id.payload,
      { accessToken },
    )
    yield put(getOrganizationUsersSuccess(organizationUsers))
  } catch (ex) {
    yield put(getOrganizationUsersFailure(ex.localeMessage))
  }
}

function* createAdminUserSaga({ payload: { title, form } }) {
  try {
    const accessToken = yield select(getToken)
    const body = { title, form }

    yield call(AdminUsersManager.createAdminUser, {
      body,
      accessToken,
    })

    yield put(createAdminUserSuccess({ title, form }))
  } catch (ex) {
    yield put(createAdminUserFailure(ex))
  }

  yield put(setTaskModalVisible(false))
}

function* updateAdminUserSaga({ payload: { id, title, form } }) {
  try {
    const accessToken = yield select(getToken)
    const body = { id, title, form }

    yield call(
      AdminUsersManager.updateAdminUser,
      {
        body,
        accessToken,
      },
      id,
    )

    yield put(updateAdminUserSuccess())
  } catch (ex) {
    yield put(updateAdminUserFailure(ex))
  }

  yield put(setTaskModalVisible(false))
}

function* removeAdminUserSaga({ payload: id }) {
  try {
    yield call(AdminUsersManager.deleteAdminUserRequest, {
      id,
    })
    yield put(deleteAdminUserSuccess(id))
  } catch (ex) {
    yield put(updateAdminUserFailure())
  }
}

function* adminUsersSaga() {
  yield all([
    takeLatest(createAdminUserRequest, createAdminUserSaga),
    takeLatest(updateAdminUserRequest, updateAdminUserSaga),
    takeLatest(
      [getAdminUsersRequest, updateAdminUserSuccess, createAdminUserSuccess],
      getAdminUsersSaga,
    ),
    takeLatest(getOrganizationUsersRequest, getOrganizationUsersSaga),
    takeLatest(deleteAdminUserRequest, removeAdminUserSaga),
  ])
}

export default adminUsersSaga
