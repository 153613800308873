import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  title: {
    fontFamily: 'FuturaBookC',
    fontSize: 14,
    marginBottom: 5,
    color: 'rgba(0,0,0,0.4)',
  },
  salesTitle: {
    fontFamily: 'FuturaBookC',
    fontSize: 14,
    marginBottom: 5,
    color: 'rgba(18,22,26,0.9)',
    textAlign: 'right',
  },
  count: {
    fontFamily: 'FuturaMediumC',
    fontSize: 16,
    cursor: 'pointer',
  },
  salesCount: {
    textAlign: 'right',
    fontFamily: 'FuturaMediumC',
    fontSize: 16,
    color: 'rgba(18, 22, 26, 0.4)',
    '& > span': {
      color: 'rgba(18, 22, 26, 0.8)',
    },
  },
  item: {
    marginRight: 60,
  },
  sales: {
    marginLeft: 'auto',
  },
}))

export default useStyles
