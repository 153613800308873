import React, { useState } from 'react'
import { Grid } from '@material-ui/core'
import styled, { css } from 'styled-components'
import { connect } from 'react-redux'
import * as R from 'ramda'
import assets from '../../../assets'
import {
  getAmoRoles,
  getSelectedRole,
  selectRole,
  getAmoUsersRequest,
  getActiveSort,
} from '../../../modules/users'
import ModalPortal from '../../orders/mobile/ModalPortal'
import CreateRoleModal from './CreateRoleModal'

const SortIcon = styled.img.attrs({ src: assets.img.sortIcon })``

const Wrapper = styled.div`
  margin-bottom: 30px;

  @media screen and (min-width: 320px) and (max-width: 767px) {
    margin-bottom: 15px;
  }
`

const Title = styled.div`
  font-family: FuturaMediumC;
  font-size: 14px;
  text-transform: uppercase;
  color: #000000;
  opacity: 0.4;
  padding-top: 11px;
  margin-right: 20px;
`

const TitleWrapper = styled.div`
  display: flex;
  margin-bottom: 15px;
`

const RolesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`
const Role = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 34px;
  ${({ isActive }) =>
    isActive
      ? css`
          border: 1px solid #f49355;
        `
      : css`
          border: 1px solid #ffffff;
          background: #ffffff;
          box-shadow: 0 6px 10px rgba(2, 9, 75, 0.06);
        `}
  border-radius: 8px;
  padding: 0 10px;
  font-family: 'FuturaBookC', sans-serif;
  font-size: 16px;
  letter-spacing: 0.02em;
  color: #000000;
  opacity: 0.6;
  cursor: pointer;
  margin: 0 5px 5px 0;
`

const initialRole = {
  id: 0,
  name: 'Все',
}

const sortDirectionShuffle = {
  asc: 'desc',
  desc: 'asc',
}

const initialSortKeys = [
  {
    id: 0,
    name: 'По алфавиту',
    sortKey: 'fio',
  },
  {
    id: 1,
    name: 'По дате регистрации',
    sortKey: 'createdAt',
  },
]

const Roles = ({
  roles,
  selectedRole,
  selectRole,
  handleCloseUserCard,
  getUsers,
  activeSort,
}) => {
  const handleChangeRole = role => {
    handleCloseUserCard()
    selectRole(role)
  }

  const handleChangeSort = sortData => {
    getUsers(sortData)
  }

  const [modalOpen, setModalOpen] = useState(false)

  const renderModal = () => {
    if (!modalOpen) return null
    const close = () => {
      setModalOpen(false)
    }

    return (
      <ModalPortal close={close} header="Создать Роль">
        <CreateRoleModal close={close} />
      </ModalPortal>
    )
  }

  return (
    <>
      <Wrapper>
        <div>
          <TitleWrapper>
            <Title onClick={() => setModalOpen(true)}>РОЛИ</Title>
            <Title
              onClick={() => setModalOpen(true)}
              style={{ color: '#F0640C', opacity: 1 }}
            >
              Создать
            </Title>
          </TitleWrapper>
        </div>
        <Grid container>
          <Grid item>
            <RolesWrapper>
              {[initialRole, ...roles].map(item => (
                <Role
                  onClick={() => handleChangeRole(item.name)}
                  isActive={selectedRole === item.name}
                  key={item.id}
                >
                  {item.name}
                </Role>
              ))}
            </RolesWrapper>
          </Grid>
        </Grid>
      </Wrapper>
      <Wrapper>
        <div>
          <TitleWrapper>
            <Title>Сортировка:</Title>
          </TitleWrapper>
        </div>
        <Grid container>
          <Grid item>
            <RolesWrapper>
              {initialSortKeys.map(item => (
                <Role
                  onClick={() =>
                    handleChangeSort({
                      sortKey: item.sortKey,
                      sortDirection:
                        sortDirectionShuffle[activeSort.sortDirection],
                    })
                  }
                  isActive={activeSort.sortKey === item.sortKey}
                  key={item.id}
                >
                  <span>{item.name}</span>
                  <SortIcon
                    style={{
                      marginLeft: '5px',
                      transform: `${
                        activeSort.sortDirection === 'desc' &&
                        activeSort.sortKey === item.sortKey
                          ? 'rotate(0deg)'
                          : 'rotate(180deg)'
                      }`,
                    }}
                  />
                </Role>
              ))}
            </RolesWrapper>
          </Grid>
        </Grid>
      </Wrapper>
      {renderModal()}
    </>
  )
}

export default connect(
  R.applySpec({
    selectedRole: getSelectedRole,
    roles: getAmoRoles,
    activeSort: getActiveSort,
  }),
  { selectRole: selectRole, getUsers: getAmoUsersRequest },
)(Roles)
