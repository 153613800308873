import React, { useState, useEffect, useCallback, useRef } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { FormGroup, Checkbox, TextField } from '@material-ui/core'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import * as R from 'ramda'
import styled from 'styled-components'
import assets from '../../assets'

import { connect } from 'react-redux'

import { getIsCreatingProduct } from '../../modules/products'
const OrangeCheckbox = withStyles({
  root: {
    color: '#F0640C',
    '&$checked': {
      color: '#F0640C',
    },
  },
  checked: {},
})(props => <Checkbox color="default" {...props} name={props.name?.toString()} />)
const styles = () => ({
  notchedOutline: {
    borderRadius: '8px',
    borderWidth: '1px',
    borderColor: '#F49355  !important',
  },
  focused: {
    fontSize: 15,
    borderColor: '#F0640C !important',
  },
  inputTypeSearch: {
    border: 'none',
    outline: 'none',
    borderBottom: '#F49355  !important',
    backgroundColor: 'white',
    backgroundImage: `url(${assets.img.searchIcon})`,
    backgroundPosition: '10px 10px',
    backgroundRepeat: 'no-repeat',
    float: 'right',
  },
})

const InputWrapper = React.memo(props => (
  <TextField {...R.dissoc('isFocusedOrHasValue', props)} />
))

const Container = styled.div`
  position: relative;
`
const FormContainer = styled.div`
  width: 100%;
`
const Error = styled.span`
  font-family: 'FuturaBookC', sans-serif;
  font-size: 15px;
  line-height: 17px;

  color: #f55555;
`

const StyledTextField = styled(InputWrapper)`
  width: 100%;

  input[type='text']::-webkit-inner-spin-button,
  input[type='text']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  label {
    margin-top: ${({ isFocusedOrHasValue }) =>
      isFocusedOrHasValue ? 0 : -10}px;
  }

  input {
    padding: 9.5px;
  }

  .MuiInputBase-multiline {
    padding: 9.5px 15px;
  }

  .MuiFormHelperText-root {
    margin: 10px 43px 15px 0;
    font-family: 'FuturaBookC', sans-serif;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
    opacity: 0.4;
  }
`

const SelectedRelatedProductsWrap = styled.div`
  font-family: FuturaMediumC;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
`

const SelectedRelatedProductsContainer = styled.div`
  display: flex;
  flex-direction: row;
  font-family: FuturaMediumC;
  background-color: whitesmoke;
  padding: 10px;
  margin: 3px;
  border-radius: 10px;
  :hover {
    background-color: gainsboro;
  }
`

const RelatedProductLabel = styled.label`
  color: gray;
  cursor: pointer;
`

const DeleteRelatedProduct = styled.span`
  color: coral;
  margin-left: 8px;
  font-size: 13px;
  display: flex;
  align-items: center;
  vertical-align: center;
  cursor: pointer;
`

const RelatedProductsCheckBoxDump = ({
  relatedProducts,
  selectedProduct,
  input: { onChange, value },
  meta: { touched, error },
  inputProps,
  classes,
  label,
  isCreatingProduct,
  ...props
}) => {
  const createInitialState = useCallback(() => {
    const map = new Map()
    if (relatedProducts)
      relatedProducts.forEach(relatedProduct => {
        if (
          !isCreatingProduct &&
          selectedProduct.relatedProduct &&
          selectedProduct.relatedProduct.includes(relatedProduct.id)
        ) {
          map.set(relatedProduct.id, {
            ...relatedProduct,
            checked: true,
            show: true,
          })
        } else {
          map.set(relatedProduct.id, {
            ...relatedProduct,
            checked: false,
            show: true,
          })
        }
      })
    return map
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [relatedProducts,isCreatingProduct, selectedProduct])

  const [isFocused, setFocus] = useState(false)
  const [state, setState] = useState(new Map())


  const search = value => {
    let newState = new Map()
    state.forEach(product => {
      if (product.title.toLowerCase().indexOf(value.toLowerCase()) !== -1) {
        newState.set(product.id, { ...product, show: true })
      } else {
        newState.set(product.id, { ...product, show: false })
      }
    })
    setState(newState)
  }

  useEffect(() => {
    setState(createInitialState())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [relatedProducts,isCreatingProduct])

  const formRef = useRef(null)

  const handleFocus = useCallback(() => {
    setFocus(true)
  }, [setFocus])

  const handleBlur = useCallback(() => {
    setTimeout(() => {
      setFocus(false)
      if (formRef.current) {
        formRef.current.dispatchEvent(new Event('submit'))
      }
    }, 150)
  }, [setFocus])

  const handleChange = ({ id, checked }) => {
    const relatedProduct = state.get(id)
    state.set(relatedProduct.id, { ...relatedProduct, checked })
    setState(new Map(state))
    const checkedProducts = [...state.values()].filter(relatedProduct => {
      return relatedProduct.checked
    })
    onChange(checkedProducts.map(product => product.id))
  }

  const getTextValue = () => {
    if (state) {
      let text = ''
      for (const [, _value] of state.entries()) {
        if (_value.checked) text += `${_value.title}; `
      }
      return text
    }
    return ''
  }

  return (
    <Container>
      <SelectedRelatedProductsWrap>
        {[...state.values()].map(relatedProduct => {
          if (relatedProduct.checked)
            return (
              <SelectedRelatedProductsContainer key={relatedProduct.id}>
                <RelatedProductLabel>
                  {relatedProduct.title}
                </RelatedProductLabel>
                <DeleteRelatedProduct
                  onClick={event => {
                    event.stopPropagation()
                    handleChange({
                      id: relatedProduct.id,
                      checked: false
                    })
                  }}
                >
                  &times;
                </DeleteRelatedProduct>
              </SelectedRelatedProductsContainer>
            )
        })}
      </SelectedRelatedProductsWrap>
      <FormContainer onClick={handleFocus} onMouseLeave={handleBlur}>
        <StyledTextField
          margin="normal"
          variant="outlined"
          color="#F0640C"
          value={getTextValue()}
          type={'text'}
          InputProps={{
            ...inputProps,
            classes: {
              notchedOutline: classes.notchedOutline,
              focused: classes.cssFocused,
            },
          }}
          {...value}
          {...props}
          label={label}
        />
        {touched && !!error && <Error>{error}</Error>}
        {
          <FormGroup
            style={{
              display: isFocused ? 'block' : 'none',
              overflowY: 'scroll',
              maxHeight: '10em',
            }}
            onClick={e => {
              e.stopPropagation()
            }}
          >
            <StyledTextField
              margin="normal"
              variant="filled"
              color="#F0640C"
              placeholder="Поиск"
              type={'text'}
              InputProps={{
                ...inputProps,
                style: {
                  border: 'none',
                  outline: 'none',
                  borderBottom: '#F49355  !important',
                  backgroundColor: 'white',
                  backgroundImage: `url(${assets.img.searchIcon})`,
                  backgroundPosition: '10px 10px',
                  backgroundRepeat: 'no-repeat',
                  float: 'right',
                },
              }}
              {...props}
              label={label}
              onChange={event => {
                event.stopPropagation()
                search(event.target.value)
              }}
            />
            {[...state.values()].map(relatedProduct => {
              if (relatedProduct.show) {
                return (
                  <FormControlLabel
                    key={relatedProduct.id}
                    control={
                      <OrangeCheckbox
                        checked={relatedProduct.checked}
                        onChange={event => {
                          event.stopPropagation()
                          handleChange({
                            id: relatedProduct.id,
                            checked: event.target.checked,
                          })
                        }}
                        name={relatedProduct.id}
                      />
                    }
                    label={relatedProduct.title}
                  />
                )
              }
            })}
          </FormGroup>
        }
      </FormContainer>
    </Container>
  )
}

const RelatedProductsCheckBox = connect(
  R.applySpec({
    isCreatingProduct: getIsCreatingProduct,
  }),
)(RelatedProductsCheckBoxDump)

export default withStyles(styles)(RelatedProductsCheckBox)
