import React from 'react'
import * as R from 'ramda'
import { connect } from 'react-redux'
import styled, { css } from 'styled-components'
import * as _ from 'lodash'

const Container = styled.div`
  display: flex;
  gap: 1px;
  margin-bottom: 20px;
`

const Day = styled.div`
  width: 36px;
  height: 36px;
  border: 1px solid #ffffff;
  box-shadow: 0 6px 10px 0 rgba(2, 9, 75, 0.06);

  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font: 400 16px/18px 'FuturaBookC', sans-serif;
  text-align: center;

  ${({ isSelected }) =>
          isSelected &&
          css`
            box-shadow: 0 6px 10px 0 rgba(247, 137, 68, 0.25);
            border-color: rgba(244, 147, 85, 1);
          `}
`

const week = ['пн', 'вт', 'ср', 'чт', 'пт', 'сб', 'вс']

const WeekField = ({}) => {
  const [value, setValue] = React.useState([])

  return (
    <Container>
      {week.map((item, index) =>
        <Day
          onClick={() => setValue(_.xor(value, [index]))}
          key={item}
          isSelected={_.includes( value, index)}
        >
          {item}
        </Day>,
      )}
    </Container>
  )
}

export default connect(R.applySpec({}), {})(WeekField)

