import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  select: {
    width: '100%',
    marginBottom: 20,
    boxSizing: 'border-box',
    '& > .MuiSelect-select': {
      padding: '12px 28px 9px 15px',
    },
    fontSize: 14,
    fontFamily: 'FuturaBookC',
    '& > .MuiOutlinedInput-notchedOutline': {
      borderColor: '#F49355',
    },
    '&.Mui-disabled > .MuiOutlinedInput-notchedOutline': {
      borderColor: '#F49355',
    },
    '&:hover > .MuiOutlinedInput-notchedOutline': {
      borderColor: '#F49355',
    },
    '&.Mui-focused > .MuiOutlinedInput-notchedOutline': {
      borderColor: '#F49355',
      borderWidth: 1,
    },
    borderRadius: 8,
  },
  icon: {
    fill: '#F49355',
  },
  menu: {
    padding: '7px 0',
    boxShadow: '0px 6px 14px 0px rgba(2, 9, 75, 0.06)',
  },
  labelFormControl: {
    zIndex: 2,
  },
  labelShrink: {
    transform: 'translate(10px, -5px) scale(0.75)',
    padding: '0 5px',
    background: '#fff',
  },
  labelRoot: {
    fontFamily: 'FuturaBookC,sans-serif',
    fontSize: 18,
    color: 'rgba(0, 0, 0, 0.4)',
    '&.Mui-focused': {
      color: 'rgba(0, 0, 0, 0.4)',
    },
  },
}))

export default useStyles
