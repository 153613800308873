import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import * as R from 'ramda'
import { connect } from 'react-redux'
import {
  Button,
  Modal,
  Radio,
  RadioGroup,
  FormControlLabel,
  withStyles,
} from '@material-ui/core'

import OrganizationTextField from '../organization-text-field'
import Documents from '../documents'
import { Option } from '../../orders/boards-select'
import OrganizationsSelect from './organizations-select'

import {
  createOrganizationRequest,
  deleteOrganizationRequest,
  getSelectedOrganization,
  selectOrganization,
  updateOrganizationRequest,
} from '../../../modules/organizations'

import { useStyles } from './styles'
import assets from '../../../assets'
import { getCities } from '../../../modules/organizations/OrganizationsManager'

const Wrapper = styled.div`
  width: 300px;
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  @media screen and (min-width: 320px) and (max-width: 767px) {
    width: 100%;
    margin-right: 0;
    overflow: auto;
    margin-bottom: 25px;
    box-sizing: border-box;
  }
`

const TitleWrapper = styled.div`
  display: flex;
  position: relative;
  margin-bottom: 10px;
`

const CreateTitle = styled.div`
  padding: 11px 36px 9px 20px;
  background: #ffffff;
  width: 300px;
  height: 36px;
  box-shadow: 0 6px 14px rgba(2, 9, 75, 0.06);
  border-radius: 8px;
  font-family: FuturaMediumC, sans-serif;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.4);
`

const Title = styled.div`
  padding: 11px 36px 9px 20px;
  background: #ffffff;
  width: 262px;
  height: 36px;
  box-shadow: 0 6px 14px rgba(2, 9, 75, 0.06);
  border-radius: 8px 0 0 8px;
  font-family: FuturaMediumC, sans-serif;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.4);
  @media screen and (min-width: 320px) and (max-width: 767px) {
    width: 100%;
    margin-right: 0;
    overflow: auto;
    height: 100%;
  }
`

const DeleteIcon = styled.img.attrs({ src: assets.img.deleteColorIcon })`
  position: absolute;
  right: 50px;
  top: 8px;
  cursor: pointer;
`

const EditIcon = styled.img.attrs({ src: assets.img.pencilRed })``
const EditActiveIcon = styled.img.attrs({ src: assets.img.pencil })``
const EditIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  margin-left: 2px;
  background: linear-gradient(0deg, #ffffff, #ffffff),
  linear-gradient(92.15deg, #f6a26d -38.69%, #f87c2e 107.08%);
  box-shadow: 0 6px 14px rgba(2, 9, 75, 0.06);
  border-radius: 0 8px 8px 0;
  cursor: pointer;
  ${({ isActive }) =>
          isActive &&
          css`
            background: linear-gradient(92.15deg, #f6a26d -38.69%, #f87c2e 107.08%);
          `}
`

const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  padding: 25px 20px 5px 20px;
  width: 300px;
  background: #ffffff;
  box-shadow: 0 6px 14px rgba(2, 9, 75, 0.06);
  border-radius: 8px;
  box-sizing: border-box;
  @media screen and (min-width: 320px) and (max-width: 767px) {
    width: 100%;
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  margin-bottom: 20px;
  margin-top: -2px;
`

const Container = styled.div`
  width: 516px;
  height: 245px;
  background: #ffffff;
  box-shadow: 0 6px 14px rgba(2, 9, 75, 0.06);
  border-radius: 8px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
`
const TextContainer = styled.div`
  margin-top: 70px;
  font-family: FuturaBookC, sans-serif;
  font-size: 25px;
  line-height: 30px;
  text-align: center;
  color: #3d4751;
  font-weight: normal;
`

const ButtonsContainer = styled.div`
  margin-top: auto;
  margin-bottom: 20px;
  display: flex;
  background: #fff;
  justify-content: center;
`

const ConfirmButton = styled.button`
  width: 95px;
  height: 36px;
  background: linear-gradient(92.32deg, #f49355 0%, #f0640c 100%);
  border-radius: 8px;
  outline: 0;
  border: 0;
  font-family: FuturaMediumC, sans-serif;
  font-size: 17px;
  color: rgba(255, 255, 255, 1);
  cursor: pointer;
`

const CancelButton = styled(ConfirmButton)`
  background: linear-gradient(0deg, #9ba1a6, #9ba1a6),
  linear-gradient(92.32deg, #f49355 0%, #f0640c 100%);
  margin-left: 8px;
  cursor: pointer;
`

const DocumentsTitle = styled.div`
  font-family: FuturaMediumC, sans-serif;
  font-size: 12px;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.2);
  margin-top: 10px;
  margin-bottom: 20px;
`

const OrangeRadio = withStyles({
  root: {
    color: '#F0640C',
    '&$checked': {
      color: '#F0640C',
    },
  },
  checked: {},
})(props => <Radio color='default' {...props} />)

const OrganizationCard = ({
                            selectedOrganization,
                            setCardMode,
                            cardMode,
                            updateOrganization,
                            createOrganization,
                            deleteOrganization,
                            selectOrganization,
                            handleCloseUserCard,
                            setModalShow,
                            editAccess,
                          }) => {
  const classes = useStyles()
  const isEditMode = cardMode.open && cardMode.mode === 'EDIT'
  const isCreatingMode = cardMode.open && cardMode.mode === 'CREATE'

  const [isDeletingOrganization, setIsDeletingOrganization] = useState(false)
  const [isAddingDocument, setIsAddingDocument] = useState(false)
  const [cityList, setCityList] = useState([])

  const [organizationEditData, setOrganizationEditData] = useState({
    id: '',
    title: '',
    form: '',
    documents: [],
    VAT: false,
    percentageOfPayment: '',
    type: 'seller',
    nativeTown: ''
  })
  const [organizationCreateData, setOrganizationCreateData] = useState({
    title: '',
    form: '',
    documents: [],
    VAT: false,
    percentageOfPayment: '',
    type: 'seller',
    nativeTown: ''
  })

  useEffect(()=>{
    getCities().then(res=>setCityList(res))
  },[])

  useEffect(() => {
    if (isCreatingMode) {
      setOrganizationCreateData({
        title: '',
        form: '',
        documents: [],
        VAT: false,
        percentageOfPayment: '',
        type: 'seller',
      })
    }
  }, [isCreatingMode, isEditMode])

  const handleSubmit = e => {
    e.preventDefault()
    setCardMode({
      open: false,
      mode: '',
    })
    if (!isCreatingMode) {
      updateOrganization(organizationEditData)
      setOrganizationEditData({
        id: '',
        title: '',
        form: '',
        documents: [],
        VAT: false,
        percentageOfPayment: '',
        type: 'seller',
        nativeTown:''
      })
    } else {
      if (organizationCreateData.title) {
        createOrganization(organizationCreateData)
        setOrganizationCreateData({
          title: '',
          form: '',
          documents: [],
          VAT: false,
          percentageOfPayment: '',
          type: 'seller',
          nativeTown:''
        })
      }
    }
    selectOrganization({})
    handleCloseUserCard()
  }

  const changeVat = e => {
    let type = e.target.value === 'true'

    if (isCreatingMode) {
      return setOrganizationCreateData({ ...organizationCreateData, VAT: type })
    }
    return setOrganizationEditData({ ...organizationEditData, VAT: type })
  }

  const changeType = e => {
    let type = e.target.value

    if (isCreatingMode) {
      return setOrganizationCreateData({ ...organizationCreateData, type })
    }
    return setOrganizationEditData({ ...organizationEditData, type })
  }

  useEffect(() => {
    if (!isCreatingMode) {
      setOrganizationEditData({
        id: selectedOrganization.id || '',
        title: selectedOrganization.title || '',
        form: selectedOrganization.form || '',
        documents: selectedOrganization.documents || [],
        VAT: selectedOrganization.VAT || false,
        type: selectedOrganization.type || 'seller',
        percentageOfPayment: selectedOrganization.percentageOfPayment || '',
        nativeTown: selectedOrganization.nativeTown || '',
      })
    }
  }, [selectedOrganization])

  const handleEditIconClick = () => {
    if (isEditMode) {
      setCardMode({
        open: true,
        mode: 'VIEW',
      })
      setOrganizationEditData({
        id: selectedOrganization.id,
        title: selectedOrganization.title,
        form: selectedOrganization.form,
        documents: selectedOrganization.documents,
        VAT: selectedOrganization.VAT,
        type: selectedOrganization.type,
        percentageOfPayment: selectedOrganization.percentageOfPayment || '',
        nativeTown: selectedOrganization.nativeTown || '',
      })
    } else if (isCreatingMode) {
      setCardMode({
        open: false,
        mode: '',
      })
      setOrganizationCreateData({
        title: '',
        form: '',
        documents: [],
        VAT: false,
        type: 'seller',
        percentageOfPayment: '',
        nativeTown:  '',
      })
    } else {
      setCardMode({
        open: true,
        mode: 'EDIT',
      })
    }
  }

  const handleDelete = () => {
    deleteOrganization({ organizationId: selectedOrganization.id })
    setIsDeletingOrganization(false)
    setCardMode({
      open: false,
      mode: '',
    })
    setOrganizationCreateData({
      title: '',
      form: '',
      documents: [],
      VAT: false,
      type: 'seller',
      percentageOfPayment: '10',
      selectedOrganization: ''
    })
  }

  return (
    <Wrapper>
      <Modal
        open={isDeletingOrganization}
        onClose={() => setIsDeletingOrganization(false)}
      >
        <Container>
          <TextContainer>
            Удалить организацию <br />
            {selectedOrganization.title} ?
          </TextContainer>
          <ButtonsContainer>
            <ConfirmButton
              onClick={() =>
                editAccess ? handleDelete() : setModalShow(true)
              }
            >
              Да
            </ConfirmButton>
            <CancelButton onClick={() => setIsDeletingOrganization(false)}>
              Отмена
            </CancelButton>
          </ButtonsContainer>
        </Container>
      </Modal>
      <TitleWrapper>
        {!isCreatingMode ? (
          <>
            <DeleteIcon onClick={() => setIsDeletingOrganization(true)} />
            <Title>Данные Организации</Title>
            <EditIconWrapper
              isActive={isEditMode}
              onClick={() =>
                editAccess ? handleEditIconClick() : setModalShow(true)
              }
            >
              {isEditMode ? <EditActiveIcon /> : <EditIcon />}
            </EditIconWrapper>
          </>
        ) : (
          <CreateTitle>Данные Новой организации</CreateTitle>
        )}
      </TitleWrapper>
      <FormWrapper>
        <OrganizationTextField
          label='Название'
          onChange={e =>
            isCreatingMode
              ? setOrganizationCreateData({
                ...organizationCreateData,
                title: e.target.value,
              })
              : setOrganizationEditData({
                ...organizationEditData,
                title: e.target.value,
              })
          }
          value={
            isCreatingMode
              ? organizationCreateData.title
              : organizationEditData.title
          }
          disabled={!isEditMode && !isCreatingMode}
        />
        <OrganizationTextField
          label='Процент комиссии Prostore'
          onChange={e =>
            isCreatingMode
              ? setOrganizationCreateData({
                ...organizationCreateData,
                percentageOfPayment: e.target.value,
              })
              : setOrganizationEditData({
                ...organizationEditData,
                percentageOfPayment: e.target.value,
              })
          }
          value={
            isCreatingMode
              ? organizationCreateData.percentageOfPayment
              : organizationEditData.percentageOfPayment
          }
          disabled={!isEditMode && !isCreatingMode}
        />
        <OrganizationTextField
          options={cityList}
          label='Город'
          onChange={e =>
            isCreatingMode
              ? setOrganizationCreateData({
                ...organizationCreateData,
                nativeTown: e.target.value,
              })
              : setOrganizationEditData({
                ...organizationEditData,
              nativeTown: e.target.value,
              })
          }
          value={
            isCreatingMode
              ? organizationCreateData.nativeTown
              : organizationEditData.nativeTown
          }
          disabled={!isEditMode && !isCreatingMode}
        />
        <OrganizationsSelect
          onChange={e =>
            isCreatingMode
              ? setOrganizationCreateData({
                ...organizationCreateData,
                form: e.target.value,
              })
              : setOrganizationEditData({
                ...organizationEditData,
                form: e.target.value,
              })
          }
          value={
            isCreatingMode
              ? organizationCreateData.form
              : organizationEditData.form
          }
          label='Форма'
          placeholder='Нет'
        >
          {[
            {
              id: '',
              title: 'Нет',
            },
            { id: 'ООО', title: 'ООО' },
            { id: 'ОАО', title: 'ОАО' },
          ].map(item => (
            <Option key={item.id} value={item.id}>
              {item.title}
            </Option>
          ))}
        </OrganizationsSelect>
        <DocumentsTitle>Цена</DocumentsTitle>
        <RadioGroup
          aria-label='vat'
          name='VAT'
          value={
            isCreatingMode
              ? organizationCreateData.VAT
              : organizationEditData.VAT
          }
          onChange={changeVat}
        >
          <FormControlLabel
            value={true}
            control={
              <OrangeRadio style={{ fontFamily: 'FuturaBookC,sans-serif' }} />
            }
            label='С НДС'
          />
          <FormControlLabel
            value={false}
            control={
              <OrangeRadio style={{ fontFamily: 'FuturaBookC,sans-serif' }} />
            }
            label='Без НДС'
          />
        </RadioGroup>
        <DocumentsTitle>Тип организации</DocumentsTitle>
        <RadioGroup
          aria-label='type'
          name='type'
          value={
            isCreatingMode
              ? organizationCreateData.type
              : organizationEditData.type
          }
          onChange={changeType}
        >
          <FormControlLabel
            value='seller'
            control={
              <OrangeRadio style={{ fontFamily: 'FuturaBookC,sans-serif' }} />
            }
            label='Продавец'
          />
          <FormControlLabel
            value='customer'
            control={
              <OrangeRadio style={{ fontFamily: 'FuturaBookC,sans-serif' }} />
            }
            label='Покупатель'
          />
          <FormControlLabel
            value='mixed'
            control={
              <OrangeRadio style={{ fontFamily: 'FuturaBookC,sans-serif' }} />
            }
            label='Микс'
          />
          <FormControlLabel
            value='driver'
            control={
              <OrangeRadio style={{ fontFamily: 'FuturaBookC,sans-serif' }} />
            }
            label='Водитель'
          />
        </RadioGroup>
        <DocumentsTitle>Документы</DocumentsTitle>
        <Documents
          isAddingDocument={isAddingDocument}
          setIsAddingDocument={setIsAddingDocument}
          documents={
            isCreatingMode
              ? organizationCreateData.documents
              : organizationEditData.documents
          }
          setOrganizationEditData={setOrganizationEditData}
          setOrganizationCreateData={setOrganizationCreateData}
          isEditMode={isEditMode}
          isCreatingMode={isCreatingMode}
          organizationCreateData={organizationCreateData}
          organizationEditData={organizationEditData}
        />
        {(isEditMode || isCreatingMode) && !isAddingDocument && (
          <ButtonWrapper>
            <Button
              onClick={handleSubmit}
              classes={{
                root: classes.addButton,
              }}
            >
              {isCreatingMode ? 'Добавить' : 'Сохранить'}
            </Button>
            <Button
              onClick={handleEditIconClick}
              classes={{
                root: classes.cancelButton,
              }}
            >
              Отмена
            </Button>
          </ButtonWrapper>
        )}
      </FormWrapper>
    </Wrapper>
  )
}

export default connect(
  R.applySpec({
    selectedOrganization: getSelectedOrganization,
  }),
  {
    updateOrganization: updateOrganizationRequest,
    createOrganization: createOrganizationRequest,
    deleteOrganization: deleteOrganizationRequest,
    selectOrganization,
  },
)(OrganizationCard)
