import React from 'react'
import { TextField } from '@material-ui/core'
import styled from 'styled-components'
import useStyles from './styles'
import assets from '../../../assets'

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  max-width: 1300px;
`

const IconWrapper = styled.div`
  height: 37px;
  width: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(92.15deg, #F6A26D -38.69%, #F87C2E 107.08%);
  border-radius: 0 8px 8px 0;
`

const SearchIcon = styled.img.attrs({ src: assets.img.searchWhite })``

const SearchInput = ({ getProductsByCity }) => {

  const classes = useStyles()

  return (
    <Wrapper>
      <TextField
        onChange={(e) => getProductsByCity({ search: e.target.value })}
        placeholder='Поиск'
        classes={{
          root: classes.searchInput,
        }}
        variant='outlined'
      />
      <IconWrapper>
        <SearchIcon />
      </IconWrapper>
    </Wrapper>
  )
}


export default SearchInput