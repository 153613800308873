import React, { useCallback, useState, useEffect } from 'react'
import styled from 'styled-components'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import { connect } from 'react-redux'
import * as R from 'ramda'
import { getFormValues } from 'redux-form'
import Column from './column'
import BottomButtonsGroup from './bottom-buttons-group'
import AddValueModal from '../add-value-modal'
import { useWebSockets } from '../../../context'
import { getAmoRoles, getAmoRolesRequest } from '../../../modules/users'
import {
  createBoardsColumnRequest,
  moveOrderRequest,
} from '../../../modules/boards'
import { FORMS } from '../../../constants'
import {
  getIsOrganization,
  getOrdersList,
  getOrdersRequest,
  deleteOrderRequest,
  notRealizeOrderRequest,
  completeOrderRequest,
} from '../../../modules/orders'

import { addIcon } from '../../../assets/img'
import { onDragEndOrders } from '../../../utils/onDragEndOrders'

const ParentContainer = styled.div`
  height: ${({ height }) => height}px;
  overflow-x: hidden;
  overflow-y: auto;
`

const Container = styled.div`
  width: 100%;
  display: flex;
  margin: 15px 0;
  overflow-x: auto;
`

const AddBoardButton = styled.div`
  display: flex;
  align-items: center;
  height: 36px;
  padding: 0 11px 1px 11px;
  background: linear-gradient(0deg, #ffffff, #ffffff),
    linear-gradient(92.15deg, #f6a26d -38.69%, #f87c2e 107.08%);
  box-shadow: 0 6px 14px rgba(2, 9, 75, 0.06);
  border-radius: 8px;
  cursor: pointer;
  margin-top: 10px;
`

const Board = ({
  containerHeight,
  useClone,
  isCombineEnabled = false,
  initial,
  createColumn,
  formValues,
  getOrders,
  deleteOrder,
  completeOrder,
  notRealizeOrder,
  moveOrder,
  orders,
  isOrganization,
  setModalState,
  setModalShow,
  editAccess,
  roles,
  getRoles,
}) => {
  const [state, setState] = useState({})
  const [open, setOpen] = useState(false)
  const [boardValue, setBoardValue] = useState('')
  const [bottomIsHidden, setBottomIsHidden] = useState(true)

  useEffect(() => {
    getRoles()
  }, [roles.length])

  useEffect(() => {
    setState({
      columns: initial,
      ordered: Object.keys(initial),
    })
  }, [initial])

  const { updatedData } = useWebSockets()

  const onDragStart = result => {
    if (result.type === 'QUOTE') {
      setBottomIsHidden(false)
    }
  }
  const onDragEnd = result =>
    onDragEndOrders(
      result,
      orders,
      isOrganization,
      setBottomIsHidden,
      state,
      setState,
      notRealizeOrder,
      completeOrder,
      moveOrder,
      deleteOrder,
    )

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleSubmit = () => {
    createColumn({
      boardId: formValues.board,
      title: boardValue.columnName,
      roleId: boardValue.roleId,
    })
    setOpen(false)
    if (formValues && formValues.board) {
      const { board, organization, product, driver, search } = formValues
      setTimeout(() => {
        getOrders({
          boardId: board,
          query: {
            organization: organization === '0' ? '' : organization,
            product: product === '0' ? '' : product,
            driver: driver === '0' ? '' : driver,
            search,
          },
        })
      }, 700)
    }
  }

  // useEffect(() => {
  //   if (updatedData) {
  //     const { board, organization, product, driver, search } = formValues
  //     getOrders({
  //       boardId: board,
  //       query: {
  //         organization: organization === '0' ? '' : organization,
  //         product: product === '0' ? '' : product,
  //         driver: driver === '0' ? '' : driver,
  //         search,
  //       },
  //     })
  //   }
  // }, [updatedData])

  const getTotalPrice = useCallback(
    key =>
      state.columns[key].reduce(
        (accumulator, currentValue) => accumulator + Number(currentValue.price),
        0,
      ),
    [state.columns],
  )

  const board = (
    <Droppable
      droppableId="board"
      type="COLUMN"
      direction="horizontal"
      ignoreContainerClipping={Boolean(containerHeight)}
      isCombineEnabled={isCombineEnabled}
    >
      {provided => (
        <Container ref={provided.innerRef} {...provided.droppableProps}>
          {state.ordered?.map((key, index) => (
            <Column
              setModalState={setModalState}
              formValues={formValues}
              getOrders={getOrders}
              columnObj={orders && orders.find(item => item.title === key)}
              count={state?.columns[key].length}
              totalPrice={getTotalPrice(key)}
              key={key}
              index={index}
              title={key}
              quotes={state?.columns[key]}
              isCombineEnabled={isCombineEnabled}
              useClone={useClone}
              editAccess={editAccess}
              setModalShow={setModalShow}
            />
          ))}
          {provided.placeholder}
          <AddBoardButton
            onClick={() => (editAccess ? handleOpen() : setModalShow(true))}
          >
            <img src={addIcon} alt="" />
          </AddBoardButton>
          <BottomButtonsGroup hidden={bottomIsHidden} />
        </Container>
      )}
    </Droppable>
  )

  return (
    <>
      <AddValueModal
        value={boardValue}
        handleSubmit={handleSubmit}
        title="Введите название новой колонки"
        handleClose={handleClose}
        open={open}
        setValue={setBoardValue}
        roles={roles}
      />
      <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
        {containerHeight ? (
          <ParentContainer height={containerHeight}>{board}</ParentContainer>
        ) : (
          board
        )}
      </DragDropContext>
    </>
  )
}

export default connect(
  R.applySpec({
    formValues: state => getFormValues(FORMS.ORDERS_FILTER)(state),
    orders: getOrdersList,
    isOrganization: getIsOrganization,
    roles: getAmoRoles,
  }),
  {
    getRoles: getAmoRolesRequest,
    getOrders: getOrdersRequest,
    deleteOrder: deleteOrderRequest,
    completeOrder: completeOrderRequest,
    notRealizeOrder: notRealizeOrderRequest,
    createColumn: createBoardsColumnRequest,
    moveOrder: moveOrderRequest,
  },
)(Board)
