import { createAction, handleActions } from 'redux-actions'
import * as R from 'ramda'
import { combineReducers } from 'redux'
import { MODULES } from '../../constants'

export const getScheduleRequest = createAction(`${MODULES}/getScheduleRequest`)

export const getScheduleSuccess = createAction(`${MODULES}/getScheduleSuccess`)

export const getScheduleFailure = createAction(`${MODULES}/getScheduleFailure`)

export const createScheduleRequest = createAction(`${MODULES}/createScheduleRequest`)

const schedule = handleActions(
  {
    [getScheduleSuccess]: (_, { payload }) => payload,
  },
  [],
)

const isLoading = handleActions(
  {
    [getScheduleRequest]: R.T,
    [getScheduleFailure]: R.F,
    [getScheduleSuccess]: R.F,
  },
  false,
)

const scheduleReducer = combineReducers({
  schedule,
  isLoading,
})

export default scheduleReducer

const getScheduleReducer = R.prop('schedule')

export const getSchedule = R.pipe(getScheduleReducer, R.prop('schedule'))

export const getScheduleIsLoading = R.pipe(
  getScheduleReducer,
  R.prop('isLoading'),
)
