import { createAction, handleActions } from 'redux-actions'
import * as R from 'ramda'
import { combineReducers } from 'redux'
import { MODULES } from '../../constants'

export const getBoardsRequest = createAction(`${MODULES.BOARDS}/GET_BOARDS`)
export const getBoardsSuccess = createAction(
  `${MODULES.BOARDS}/GET_BOARDS_SUCCESS`,
)

export const getBoardsFailure = createAction(
  `${MODULES.BOARDS}/GET_BOARDS_FAILURE`,
)

export const createBoardsColumnRequest = createAction(
  `${MODULES.BOARDS}/CREATE_BOARDS_COLUMN_REQUEST`,
)

export const createBoardsColumnSuccess = createAction(
  `${MODULES.BOARDS}/CREATE_BOARDS_COLUMN_SUCCESS`,
)

export const createBoardsColumnFailure = createAction(
  `${MODULES.BOARDS}/CREATE_BOARDS_COLUMN_FAILURE`,
)

export const moveOrderRequest = createAction(
  `${MODULES.BOARDS}/MOVE_ORDER_REQUEST`,
)

export const moveOrderSuccess = createAction(
  `${MODULES.BOARDS}/MOVE_ORDER_SUCCESS`,
)

export const moveOrderFailure = createAction(
  `${MODULES.BOARDS}/MOVE_ORDER_FAILURE`,
)

export const deleteColumnRequest = createAction(
  `${MODULES.BOARDS}/DELETE_COLUMN_REQUEST`,
)

export const deleteColumnSuccess = createAction(
  `${MODULES.BOARDS}/DELETE_COLUMN_SUCCESS`,
)

export const deleteColumnFailure = createAction(
  `${MODULES.BOARDS}/DELETE_COLUMN_FAILURE`,
)

export const createBoardRequest = createAction(
  `${MODULES.BOARDS}/CREATE_BOARD_REQUEST`,
)

export const createBoardFailure = createAction(
  `${MODULES.BOARDS}/CREATE_BOARD_FAILURE`,
)

export const createBoardSuccess = createAction(
  `${MODULES.BOARDS}/CREATE_BOARD_SUCCESS`,
)

const byId = handleActions(
  {
    [getBoardsSuccess]: R.pipe(
      R.nthArg(1),
      R.prop('payload'),
      R.indexBy(R.prop('id')),
    ),
  },
  [],
)

const allIds = handleActions(
  {
    [getBoardsSuccess]: R.pipe(
      R.nthArg(1),
      R.prop('payload'),
      R.map(R.prop('id')),
    ),
  },
  [],
)

const isLoading = handleActions(
  {
    [getBoardsRequest]: R.T,
    [getBoardsSuccess]: R.F,
    [getBoardsFailure]: R.F,
  },
  false,
)

const boardsReducer = combineReducers({
  byId,
  allIds,
  isLoading,
})

export default boardsReducer
