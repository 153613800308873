import React, { useEffect } from 'react'
import { Modal } from '@material-ui/core'
import styled from 'styled-components'
import { connect } from 'react-redux'
import * as R from 'ramda'

import AnalyticsHeader from '../analytics-header'
import AnalyticsFooter from '../analytics-footer'
import { DriversInfo, OrganizationsInfo } from '../analytics-table'

import { getAnalyticsInfo } from '../../../modules/analytics'
import assets from '../../../assets'

const Wrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  height: 80%;
  background: #ecf1f4;
  border-radius: 8px;
  padding: 20px;
`
const Title = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  font-family: FuturaMediumC, sans-serif;
  font-size: 16px;
  color: rgba(18, 22, 26, 0.9);
`

const Close = styled.img.attrs({ src: assets.img.closeMenu })`
  opacity: 0.4;
  cursor: pointer;
`

const AnalyticsInfoModal = ({
  open,
  handleClose,
  page,
  setPage,
  countOnPage,
  setCountOnPage,
  setPeriod,
  period,
  type,
  handleGetAnalyticsInfo,
  analyticsInfo,
  infoTotal,
}) => {
  useEffect(() => {
    if (analyticsInfo.id) {
      handleGetAnalyticsInfo(analyticsInfo.id)
    }
  }, [page, countOnPage, period])

  return (
    <Modal open={open} onClose={handleClose}>
      <Wrapper>
        <Title>
          {analyticsInfo.fio
            ? analyticsInfo.fio
            : `${analyticsInfo.form} "${analyticsInfo.title}"`}
          <Close onClick={handleClose} />
        </Title>
        <AnalyticsHeader setPeriod={setPeriod} period={period} />
        {type === 'organization' ? <OrganizationsInfo /> : <DriversInfo />}
        <AnalyticsFooter
          infoTotal={infoTotal}
          page={page}
          setPage={setPage}
          countOnPage={countOnPage}
          setCountOnPage={setCountOnPage}
        />
      </Wrapper>
    </Modal>
  )
}

export default connect(
  R.applySpec({
    analyticsInfo: getAnalyticsInfo,
  }),
)(AnalyticsInfoModal)
