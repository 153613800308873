import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import * as R from 'ramda'
import { connect } from 'react-redux'
import { Button, Checkbox, FormControlLabel, Modal } from '@material-ui/core'
import assets from '../../../assets'

import RoleTextField from '../../roles/role-card/role-text-field'

import {
  createRoleRequest,
  deleteRoleRequest,
  getAccessList,
  getRole,
  getRoleRequest,
  updateRoleRequest,
  getAccessListForUserRequest, getAccessListRequest,
} from '../../../modules/users'

import { useStyles } from '../../roles/role-card/styles'
import clsx from 'clsx'

const Wrapper = styled.div`
  width: 100%;
  max-width: 452px;
  min-width: 320px;
  display: flex;
  flex-direction: column;

  ::-webkit-scrollbar {
    display: none;
  }
`

const EyeImg = styled.img.attrs({ src: assets.img.eye })`
  width: 20px;
  margin-right: 106px;
  @media screen and (min-width: 320px) and (max-width: 767px) {
    margin-right: 17px;
  }
`

const EditImg = styled.img.attrs({ src: assets.img.edit })`
  width: 20px;
  margin-right: 51px;
  @media screen and (min-width: 320px) and (max-width: 767px) {
    margin-right: 7px;
  }
`


const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  padding: 25px 20px 5px 20px;
  width: 100%;
  background: #ffffff;
  border-radius: 8px;
  box-sizing: border-box;
  max-height: 700px;

  ::-webkit-scrollbar {
    display: none;
  }

  @media screen and (min-width: 320px) and (max-width: 767px) {
    padding: 0;
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  margin-bottom: 20px;
  margin-top: 10px;
`


const AccessWrapper = styled.div`
  padding-left: 20px;
  display: flex;
  margin-bottom: 20px;
  justify-content: space-between;
  align-items: center;
`
const AccessTitle = styled.div`
  font-family: FuturaMediumC, sans-serif;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.9);
`
const AccessCheckWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  @media screen and (min-width: 320px) and (max-width: 767px) {
    width: unset;
  }
`
const AccessViewTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 125px;
  font-family: FuturaBookC, sans-serif;
  font-size: 12px;
  color: rgba(18, 22, 26, 0.4);
  @media screen and (min-width: 320px) and (max-width: 767px) {
    width: unset;
  }
`
const AccessCreateTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 125px;
  font-family: FuturaBookC, sans-serif;
  font-size: 12px;
  color: rgba(18, 22, 26, 0.4);
  @media screen and (min-width: 320px) and (max-width: 767px) {
    width: unset;
  }
`

const setRoleName = role => {
  switch (role) {
    case 'users':
      return 'Пользователи'
    case 'organizations':
      return 'Организации'
    case 'transports':
      return 'Транспорт'
    case 'orders':
      return 'Заявки'
    case 'schedule':
      return 'Расписание'
    case 'promotions':
      return 'Акции'
    case 'categories':
      return 'Категории'
    case 'roles':
      return 'Роли'
    case 'analytics':
      return 'Аналитика'
    default:
      return role.charAt(0).toUpperCase() + role.slice(1)
  }
}

const CreateRoleModal = ({
                           createRole,
                           accessList,
                           role,
                           accessListForUser,
                           close,
                         }) => {
  const classes = useStyles()

  const [accessListData, setAccessListData] = useState({})
  const [deleteAccess, setDeleteAccess] = useState([])
  const [addAccess, setAddAccess] = useState([])
  const [roleCreateData, setRoleCreateData] = useState({
    name: '',
  })

  useEffect(() => {
    setAccessListData(groupBy(accessList, 'module'))
  }, [accessList, role])

  const groupBy = (xs, key) =>
    xs.reduce((rv, x) => {
      ;(rv[x[key]] = rv[x[key]] || []).push({
        ...x,
        checked: false,
      })
      return rv
    }, {})


  const handleSubmit = e => {
    e.preventDefault()
    createRole({ body: { ...roleCreateData, accesses: [...addAccess] } })
    setRoleCreateData({
      name: '',
    })
    accessListForUser()
  }


  const handleViewCheckboxChange = (item, viewObj, createObj) => {
    const newView = {
      ...viewObj,
      checked: !viewObj.checked,
    }
    if (viewObj.checked) {
      if (
        role.length > 0 &&
        role.find(
          item =>
            item.module === viewObj.module &&
            item['accessType'] === viewObj['accessType'],
        )
      ) {
        setDeleteAccess([...deleteAccess, viewObj.id])
      } else {
        setAddAccess(addAccess.filter(item => item !== viewObj.id))
      }
    } else {
      setAddAccess([...addAccess, viewObj.id])
    }
    setAccessListData({
      ...accessListData,
      [item]: [newView, createObj],
    })
    accessListForUser()
  }
  const handleCreateCheckboxChange = (item, viewObj, createObj) => {
    const newCreate = {
      ...createObj,
      checked: !createObj.checked,
    }
    if (createObj.checked) {
      if (
        role.length > 0 &&
        role.find(
          item =>
            item.module === createObj.module &&
            item['accessType'] === createObj['accessType'],
        )
      ) {
        setDeleteAccess([...deleteAccess, createObj.id])
      } else {
        setAddAccess(addAccess.filter(item => item !== createObj.id))
      }
    } else {
      setAddAccess([...addAccess, createObj.id])
    }
    setAccessListData({
      ...accessListData,
      [item]: [newCreate, viewObj],
    })
    accessListForUser()
  }

  return (
    <Wrapper>
      <FormWrapper onSubmit={handleSubmit}>
        <RoleTextField
          onChange={e => setRoleCreateData({ ...roleCreateData, name: e.target.value })}
          label='Название'
          value={roleCreateData.name}
        />
        <AccessCheckWrapper>
          <EyeImg />
          <EditImg />
        </AccessCheckWrapper>
        {accessListData &&
        Object.keys(accessListData).map(item => {
          const viewObj = accessListData[item].find(
            item => item['accessType'] === 'view',
          )
          const createObj = accessListData[item].find(
            item => item['accessType'] === 'edit',
          )

          return (
            <AccessWrapper key={item}>
              <AccessTitle>{setRoleName(item)}</AccessTitle>
              <AccessCheckWrapper>
                <AccessViewTitle>
                  <FormControlLabel
                    label={null}
                    classes={{
                      root: classes.labelRoot,
                    }}
                    control={
                      <Checkbox
                        checkedIcon={
                          <span
                            className={clsx(
                              classes.icon,
                              classes.checkedIcon,
                            )}
                          />
                        }
                        icon={<span className={classes.icon} />}
                        className={classes.root}
                        onChange={() =>
                          handleViewCheckboxChange(item, viewObj, createObj)
                        }
                        checked={viewObj.checked}
                        name={viewObj.id.toString()}
                      />
                    }
                  />
                </AccessViewTitle>
                <AccessCreateTitle>
                  <FormControlLabel
                    label={null}
                    classes={{
                      root: classes.labelRoot,
                    }}
                    key={item.id}
                    control={
                      <Checkbox
                        checkedIcon={
                          <span
                            className={clsx(
                              classes.icon,
                              classes.checkedIcon,
                            )}
                          />
                        }
                        onChange={() =>
                          handleCreateCheckboxChange(item, viewObj, createObj)
                        }
                        icon={<span className={classes.icon} />}
                        className={classes.root}
                        checked={createObj.checked}
                        name={createObj.id.toString()}
                      />
                    }
                  />
                </AccessCreateTitle>
              </AccessCheckWrapper>
            </AccessWrapper>
          )
        })}
        <ButtonWrapper>
          <Button
            type='submit'
            classes={{
              root: classes.addButton,
            }}
          >
            Создать
          </Button>
          <Button
            classes={{
              root: classes.cancelButton,
            }}
            onClick={close}
          >
            Отмена
          </Button>
        </ButtonWrapper>
      </FormWrapper>
    </Wrapper>
  )
}

export default connect(
  R.applySpec({
    accessList: getAccessList,
    role: getRole,
  }),
  {
    updateRole: updateRoleRequest,
    createRole: createRoleRequest,
    deleteRole: deleteRoleRequest,
    getRole: getRoleRequest,
    accessListForUser: getAccessListForUserRequest,
    getAccessList: getAccessListRequest,
  },
)(CreateRoleModal)
