import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    borderRadius: 3,
    width: 16,
    height: 16,
    border: '1px solid #F49355',
    backgroundColor: 'transparent',
    'input:hover ~ &': {
      backgroundColor: 'transparent',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'transparent',
    },
  },
  checkedIcon: {
    border: '1px solid #F49355',
    backgroundColor: 'transparent',
    'input:hover ~ &': {
      backgroundColor: 'transparent',
    },
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23F49355'/%3E%3C/svg%3E\")",
      content: '""',
    },
  },
})

export default useStyles
