import reorder, { reorderQuoteMap } from '../components/orders/board/reorder'
export const onDragEndOrders = (
  result,
  orders,
  isOrganization,
  setBottomIsHidden,
  state,
  setState,
  notRealizeOrder,
  completeOrder,
  moveOrder,
  deleteOrder

) => {
  setBottomIsHidden(true)

  if (result.combine) {
    if (result.type === 'COLUMN') {
      const shallow = [...state.ordered]
      shallow.splice(result.source.index, 1)
      setState({ columns: state.columns, ordered: shallow })
      return
    }
    const column = state.columns[result.source.droppableId]
    const withQuoteRemoved = [...column]
    withQuoteRemoved.splice(result.source.index, 1)
    const columns = {
      ...state.columns,
      [result.source.droppableId]: withQuoteRemoved,
    }
    setState({ columns, ordered: state.ordered })
    return
  }

  if (!result.destination) {
    return
  }

  const source = result.source
  const destination = result.destination

  if (result.type === 'QUOTE') {
    const columnId = orders.find(
      item => item.title === source.droppableId,
    ).id

    if (destination.droppableId === 'notRealized') {
      return notRealizeOrder({ orderId: result.draggableId, columnId })
    }

    if (destination.droppableId === 'completed') {
      return completeOrder({
        orderId: result.draggableId,
        columnId,
        boardId: '',
      })
    }

    if (destination.droppableId === 'delete') {
      return deleteOrder({ orderId: result.draggableId, columnId })
    }
  }

  if (
    source.droppableId === destination.droppableId &&
    source.index === destination.index
  ) {
    return
  }

  if (result.type === 'COLUMN') {
    const ordered = reorder(state.ordered, source.index, destination.index)
    setState({
      ordered,
      columns: state.columns,
    })

    return
  }

  const data = reorderQuoteMap({
    quoteMap: state.columns,
    source,
    destination,
  })

  const leadStatusId = orders.find(
    item => item.title === destination.droppableId,
  ).id
  moveOrder({
    leadStatusId,
    id: result.draggableId,
    isOrganization,
  })

  setState({
    columns: data.quoteMap,
    ordered: state.ordered,
  })
}


