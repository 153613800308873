import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
  },
  input: {
    height: 37,
    width: '100%',
    minWidth: 100,
    '& > .MuiOutlinedInput-root': {
      borderRadius: '8px 0px 0px 8px',
    },
    '& > .MuiOutlinedInput-root > input': {
      fontFamily: 'FuturaBookC',
      fontSize: 14,
      padding: '11px 0 9px 11px',
    },
    '& > .MuiOutlinedInput-root > .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #F49355',
    },
  },
  iconWrapper: {
    height: 37,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'linear-gradient(92.15deg, #F6A26D -38.69%, #F87C2E 107.08%)',
    width: 36,
    borderRadius: '0px 8px 8px 0px',
  },
}))

export default useStyles
