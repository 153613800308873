import * as R from 'ramda'
import { createAction, handleActions, handleAction } from 'redux-actions'
import { combineReducers } from 'redux'
import { MODULES } from '../../constants'

export const getTransportRequest = createAction(
  `${MODULES.TRANSPORT}/GET_TRANSPORT_REQUEST`,
)

export const getTransportSuccess = createAction(
  `${MODULES.TRANSPORT}/GET_TRANSPORT_SUCCESS`,
)

export const getTransportFailure = createAction(
  `${MODULES.TRANSPORT}/GET_TRANSPORT_FAILURE`,
)

export const getTransportCategoryRequest = createAction(
  `${MODULES.TRANSPORT}/GET_TRANSPORT_CATEGORY_REQUEST`,
)

export const getTransportCategorySuccess = createAction(
  `${MODULES.TRANSPORT}/GET_TRANSPORT_CATEGORY_SUCCESS`,
)

export const getTransportCategoryFailure = createAction(
  `${MODULES.TRANSPORT}/GET_TRANSPORT_CATEGORY_FAILURE`,
)

export const createTransportRequest = createAction(
  `${MODULES.TRANSPORT}/CREATE_TRANSPORT_REQUEST`,
)

export const createTransportSuccess = createAction(
  `${MODULES.TRANSPORT}/CREATE_TRANSPORT_SUCCESS`,
)

export const createTransportFailure = createAction(
  `${MODULES.TRANSPORT}/CREATE_TRANSPORT_Failure`,
)

export const deleteTransportRequest = createAction(
  `${MODULES.TRANSPORT}/DELETE_TRANSPORT_REQUEST`,
)

export const deleteTransportSuccess = createAction(
  `${MODULES.TRANSPORT}/DELETE_TRANSPORT_SUCCESS`,
)

export const deleteTransportFailure = createAction(
  `${MODULES.TRANSPORT}/DELETE_TRANSPORT_Failure`,
)

export const createCategoryRequest = createAction(
  `${MODULES.TRANSPORT}/CREATE_CATEGORY_REQUEST`,
)

export const createCategorySuccess = createAction(
  `${MODULES.TRANSPORT}/CREATE_CATEGORY_SUCCESS`,
)

export const createCategoryFailure = createAction(
  `${MODULES.TRANSPORT}/CREATE_CATEGORY_Failure`,
)

export const deleteCategoryRequest = createAction(
  `${MODULES.TRANSPORT}/DELETE_CATEGORY_REQUEST`,
)

export const deleteCategorySuccess = createAction(
  `${MODULES.TRANSPORT}/DELETE_CATEGORY_SUCCESS`,
)

export const deleteCategoryFailure = createAction(
  `${MODULES.TRANSPORT}/DELETE_CATEGORY_FAILURE`,
)

export const editTransportRequest = createAction(
  `${MODULES.TRANSPORT}/UPDATE_DRIVER_OF_TRANSPORT_REQUEST`,
)

export const editTransportSuccess = createAction(
  `${MODULES.TRANSPORT}/UPDATE_DRIVER_OF_TRANSPORT_SUCCESS`,
)

export const editTransportFailure = createAction(
  `${MODULES.TRANSPORT}/UPDATE_DRIVER_OF_TRANSPORT_FAILURE`,
)

export const selectCategoryRequest = createAction(
  `${MODULES.TRANSPORT}/SELECT_CATEGORY_REQUEST`,
)

export const selectCategorySuccess = createAction(
  `${MODULES.TRANSPORT}/SELECT_CATEGORY_Success`,
)

export const selectCategoryFailure = createAction(
  `${MODULES.TRANSPORT}/SELECT_CATEGORY_Failure`,
)

export const selectStatusRequest = createAction(
  `${MODULES.TRANSPORT}/SELECT_STATUS_REQUEST`,
)

export const selectStatusSuccess = createAction(
  `${MODULES.TRANSPORT}/SELECT_STATUS_SUCCESS`,
)

export const selectStatusFailure = createAction(
  `${MODULES.TRANSPORT}/SELECT_STATUS_FAILURE`,
)

export const transportSearchRequest = createAction(
  `${MODULES.TRANSPORT}/TRANSPORT_SEARCH_REQUEST`,
)

export const transportSearchSuccess = createAction(
  `${MODULES.TRANSPORT}/TRANSPORT_SEARCH_SUCCESS`,
)

export const transportSearchFailure = createAction(
  `${MODULES.TRANSPORT}/TRANSPORT_SEARCH_FAILURE`,
)

export const editCategoryRequest = createAction(
  `${MODULES.TRANSPORT}/EDIT_CATEGORY_REQUEST`,
)

export const editCategorySuccess = createAction(
  `${MODULES.TRANSPORT}/EDIT_CATEGORY_SUCCESS`,
)

export const editCategoryFailure = createAction(
  `${MODULES.TRANSPORT}/EDIT_CATEGORY_FAILURE`,
)

export const getTransportStatusesRequest = createAction(
  `${MODULES.TRANSPORT}/GET_STATUSES_REQUEST`,
)

export const getTransportStatusesSuccess = createAction(
  `${MODULES.TRANSPORT}/GET_STATUSES_Success`,
)

export const getTransportStatusesFailure = createAction(
  `${MODULES.TRANSPORT}/GET_STATUSES_FAILURE`,
)

export const sortTranportRequest = createAction(
  `${MODULES.TRANSPORT}/SORT_TRANSPORT_REQUEST`,
)

export const sortTranportSuccess = createAction(
  `${MODULES.TRANSPORT}/SORT_TRANSPORT_SUCCESS`,
)

export const sortTranportFailure = createAction(
  `${MODULES.TRANSPORT}/SORT_TRANSPORT_FAILURE`,
)

const byId = handleActions(
  {
    [getTransportSuccess]: (state, { payload }) => ({
      ...state,
      ...R.indexBy(R.prop('id'))(payload),
    }),
  },
  {},
)

const allIds = handleActions(
  {
    [getTransportSuccess]: (state, { payload }) =>
      R.uniq([...state, ...R.map(R.prop('id'))(payload)]),
  },
  [],
)

const selectedCategory = handleActions(
  {
    [selectCategoryRequest]: (_, { payload }) => payload,
  },
  'Все',
)

const selectedStatus = handleActions(
  {
    [selectStatusRequest]: (_, { payload }) => payload,
  },
  '',
)

const searchedTransport = handleActions(
  {
    [transportSearchRequest]: (_, { payload }) => payload,
  },
  '',
)

const sortTransport = handleActions(
  {
    [sortTranportRequest]: (_, { payload }) => payload,
  },
  '',
)

const getTransport = handleActions(
  {
    [getTransportSuccess]: (_, { payload }) => payload,
  },
  [],
)

const getStatuses = handleActions(
  {
    [getTransportStatusesSuccess]: (_, { payload }) => payload,
    [getTransportStatusesRequest]: state => state,
    [getTransportStatusesFailure]: R.always([]),
  },
  {},
)

const getTransportCategory = handleActions(
  {
    [getTransportCategorySuccess]: (_, { payload }) => payload,
    [getTransportCategoryRequest]: state => state,
    [getTransportCategoryFailure]: R.always([]),
  },
  [],
)

const isLoading = handleActions(
  {
    [getTransportRequest]: R.T,
    [getTransportSuccess]: R.F,
    [getTransportFailure]: R.F,
  },
  false,
)

const transportReducer = combineReducers({
  byId,
  allIds,
  isLoading,
  getTransportCategory,
  selectedCategory,
  searchedTransport,
  getTransport,
  getStatuses,
  selectedStatus,
  sortTransport,
})

export default transportReducer
