import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  addProductButton: {
    boxShadow: '0px 6px 14px 0px rgba(2, 9, 75, 0.06)',
    padding: '9px 11px',
    fontFamily: 'FuturaBookC',
    fontSize: 16,
    color: '#ffffff',
    textTransform: 'initial',
    lineHeight: 'initial',
    borderRadius: 8,
    background: 'linear-gradient(92.15deg, #F6A26D -38.69%, #F87C2E 107.08%)',
  },
}))

export default useStyles
