import { declareAction, declareAtom, combine, map } from '@reatom/core'

export const postBasket = declareAction('postBasket')
export const putBasketByProductId = declareAction('putBasketByProductId')
export const patchDelivery = declareAction('patchDelivery')
export const patchDeliveries = declareAction('patchDeliveries')
export const deleteDeliveries = declareAction('deleteDeliveries')
export const deleteAllDeliveries = declareAction('deleteAllDeliveries')

export const selectReorder = declareAction('selectReorder')

export const removeReorder = declareAction('removeReorder')

export const selectGlobalCategory = declareAction('selectGlobalCategory')

export const setSignedContract = declareAction('setSignedContract')

export const setPopUp = declareAction('setPopUp')

export const items = declareAtom('basket', [], on => [
  on(postBasket, (items, newItem) => [...items, newItem]),
  on(putBasketByProductId, (items, newItem) =>
    items.map(item =>
      item.product.id === newItem.product.id ? newItem : item,
    ),
  ),
  on(deleteDeliveries, (items, ids) =>
    items
      .map(item => ({
        ...item,
        deliveries: item.deliveries.filter(({ id }) => !ids.includes(id)),
      }))
      .filter(({ deliveries }) => deliveries.length !== 0),
  ),
  on(deleteAllDeliveries, () => []),
  on(patchDelivery, (items, newDelivery) =>
    items.map(({ deliveries, ...item }) => ({
      deliveries: deliveries.map(delivery =>
        delivery.id === newDelivery.id
          ? { ...delivery, ...newDelivery }
          : delivery,
      ),
      ...item,
    })),
  ),
  on(patchDeliveries, (items, newDelivery) =>
    items.map(({ deliveries, ...item }) => ({
      deliveries: deliveries.map(delivery => ({
        ...delivery,
        ...newDelivery,
      })),
      ...item,
    })),
  ),
])

export const deliveries = map('deliveries', items, items =>
  items.flatMap(({ product, deliveries }) =>
    deliveries.map(delivery => ({ ...delivery, product })),
  ),
)

export const addFavoriteAddresses = declareAction('addFavoriteAddresses')

export const favoriteAddresses = declareAtom('favoriteAddresses', [], on => [
  on(addFavoriteAddresses, (items, newItem) => [
    ...newItem,
    ...items.filter(
      ({ lat, lng }) =>
        !newItem.some(({ lat: x, lng: y }) => lat === x && lng === y),
    ),
  ]),
])

export const reorder = declareAtom('reorder', {}, on => [
  on(selectReorder, (_, payload) => payload),
  on(removeReorder, () => ({})),
])

export const globalCategory = declareAtom('globalCategory', [], on => [
  on(selectGlobalCategory, (_, payload) => payload),
])

export const contract = declareAtom('contract', false, on => [
  on(setSignedContract, (_, payload) => payload),
])

export const popUp = declareAtom('popUp', false, on => [
  on(setPopUp, (_, payload) => payload),
])

export const basket = combine({
  items,
  reorder,
  globalCategory,
  favoriteAddresses,
  contract,
  popUp,
})
