import { handleStatuses } from '../../utils'
import { ENDPOINTS } from '../../API'

export const getCategories = ({token}) =>
  fetch(ENDPOINTS.CATEGORIES(),{
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
    .then(handleStatuses())
    .then(res => res.json())
    .catch(ex => {
      throw ex
    })

export const createSubcategory = ({ token, body }) =>
  fetch(ENDPOINTS.SUBCATEGORY(), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  })
    .then(handleStatuses())
    .then(res => res.json())
    .catch(ex => {
      throw ex
    })

export const deleteSubcategory = ({ token, id }) =>
  fetch(`${ENDPOINTS.SUBCATEGORY()}/${id}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
    .then(handleStatuses())
    .catch(ex => {
      throw ex
    })

export const updateSubcategory = ({ token, body }) =>
  fetch(`${ENDPOINTS.SUBCATEGORY()}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  }).then(handleStatuses())

// export const updateSubcategoryMultiplicity = ({ token, body }) =>
// fetch(`${ENDPOINTS.SUBCATEGORY()}`, {
//   method: 'PUT',
//   headers: {
//     'Content-Type': 'application/json',
//     Authorization: `Bearer ${token}`,
//   },
//   body: JSON.stringify(body),
// }).then(handleStatuses())


export const createCategory = (data, token) => {
  const  body = new FormData()
  Object.entries(data).forEach(([key,value])=>{
    if(key!=='files') {
      return  body.append(key, value)
    }
    value.forEach(file => {
      body.append('files', file)
    })

  })
  return fetch(`${ENDPOINTS.CATEGORY()}`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body
  }).then(handleStatuses())
}
