import { call, all, put, takeLatest, select, delay } from 'redux-saga/effects'
import {
  getStorageRequest,
  getStorageFailure,
  getStorageSuccess,
} from './duck'
import * as StorageManager from './StorageManager'
import { getToken } from '../auth/selectors'

const getStorage = function*() {
  try {
    const token = yield select(getToken)
    const storage = yield call(StorageManager.getStorage, token)
    yield put(getStorageSuccess(storage))
  } catch (err) {
    console.log('err', err)
    yield put(getStorageFailure())
  }
}

function* storageSaga() {
  yield all([takeLatest(getStorageRequest, getStorage)])
}

export default storageSaga
