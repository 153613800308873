import { call, put, all, takeLatest, delay, select } from 'redux-saga/effects'

import {
  getBoardsRequest,
  getBoardsSuccess,
  getBoardsFailure,
  createBoardsColumnRequest,
  createBoardsColumnSuccess,
  createBoardsColumnFailure,
  moveOrderFailure,
  moveOrderRequest,
  moveOrderSuccess,
  deleteColumnSuccess,
  deleteColumnFailure,
  deleteColumnRequest,
  createBoardSuccess,
  createBoardFailure,
  createBoardRequest,
} from './duck'
import * as BoardsManager from './BoardsManager'
import { getToken } from '../auth'

function* getBoardsSaga() {
  try {
    const token = yield select(getToken)
    const [boards] = yield all([
      call(BoardsManager.getBoards, { token }),
      delay(1000),
    ])

    yield put(getBoardsSuccess(boards))
  } catch (ex) {
    yield put(getBoardsFailure())
  }
}

function* createBoardsColumnSaga({ payload: { title, boardId, roleId } }) {
  try {
    const accessToken = yield select(getToken)
    const body = { title, boardId, roleId }

    yield call(BoardsManager.createBoardColumn, {
      body,
      accessToken,
    })

    yield put(createBoardsColumnSuccess())
  } catch (ex) {
    yield put(createBoardsColumnFailure(ex))
  }
}

function* moveOrderSaga({ payload: { leadStatusId, id, isOrganization, columnId } }) {
  try {
    const accessToken = yield select(getToken)
    const body = isOrganization
      ? { organizationLeadStatusId: leadStatusId, id }
      : { leadStatusId, id }

    yield call(BoardsManager.moveOrder, {
      body,
      accessToken,
    })

    yield put(moveOrderSuccess({ leadStatusId, id, isOrganization, columnId }))
  } catch (ex) {
    yield put(moveOrderFailure(ex))
  }
}

function* deleteColumnSaga({ payload: { statusId } }) {
  try {
    const accessToken = yield select(getToken)
    const body = { statusId }

    yield call(BoardsManager.deleteColumn, {
      body,
      accessToken,
    })

    yield put(deleteColumnSuccess())
  } catch (ex) {
    yield put(deleteColumnFailure(ex))
  }
}

function* createBoardSaga({ payload: { title, organizationId } }) {
  try {
    const accessToken = yield select(getToken)
    const body = { title, organizationId }

    yield call(BoardsManager.createBoard, {
      body,
      accessToken,
    })

    yield put(createBoardSuccess())
  } catch (ex) {
    yield put(createBoardFailure(ex))
  }
}

function* boardsSaga() {
  yield all([
    takeLatest(getBoardsRequest, getBoardsSaga),
    takeLatest(createBoardsColumnRequest, createBoardsColumnSaga),
    takeLatest(moveOrderRequest, moveOrderSaga),
    takeLatest(deleteColumnRequest, deleteColumnSaga),
    takeLatest(createBoardRequest, createBoardSaga),
  ])
}

export default boardsSaga
