import React from 'react'
import styled from 'styled-components'
import CategoriesBlock from '../../categories/CategoriesBlock'
import ProductsBlock from './ProductsBlock'
import ProductRatingsBlock from './ProductRatingsBlock'

const TabContentHead = styled.div`
  width: calc(100% - 30px);
  min-height: 6px;
  display: flex;
  background-color: #ffffff;
`

const ProductRatingsWrapper = styled.div`
  display: flex;
  flex: 1;
`

const ProductRatings = ({ setModalShow, editAccess }) => (
  <>
    <TabContentHead />
    <ProductRatingsWrapper>
      <CategoriesBlock
        isEditable={false}
        editAccess={editAccess}
        setModalShow={setModalShow}
      />
      <ProductsBlock editAccess={editAccess} setModalShow={setModalShow} />
      <ProductRatingsBlock
        editAccess={editAccess}
        setModalShow={setModalShow}
      />
    </ProductRatingsWrapper>
  </>
)

export default ProductRatings
