import React from 'react'
import styled from 'styled-components'
import { Droppable, Draggable } from 'react-beautiful-dnd'

import QuoteItem from '../quote-item'

import { grid } from '../constants'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 0;
  user-select: none;
  width: 277px;
`

const DropZone = styled.div`
  padding-bottom: ${grid}px;
`

const ScrollContainer = styled.div`
  overflow-x: hidden;
`

const Container = styled.div``

const InnerQuoteList = React.memo(props =>
  props.quotes.map((quote, index) => (
    <Draggable key={quote.id} draggableId={String(quote.id)} index={index}>
      {(dragProvided, dragSnapshot) => (
        <QuoteItem
          onClick={() =>
            props.setModalState({
              open: true,
              type: 'VIEW',
              id: quote.id,
            })
          }
          key={quote.id}
          quote={quote}
          isDragging={dragSnapshot.isDragging}
          isGroupedOver={Boolean(dragSnapshot.combineTargetFor)}
          provided={dragProvided}
          {...props}
        />
      )}
    </Draggable>
  )),
)

const InnerList = props => {
  const {
    quotes,
    dropProvided,
    setModalState,
    setModalShow,
    editAccess,
  } = props
  const title = props.title ? <span>{props.title}</span> : null

  return (
    <Container>
      {title}
      <DropZone ref={dropProvided.innerRef}>
        <InnerQuoteList
          setModalState={setModalState}
          quotes={quotes}
          editAccess={editAccess}
          setModalShow={setModalShow}
        />
        {dropProvided.placeholder}
      </DropZone>
    </Container>
  )
}

const QuoteList = props => {
  const {
    ignoreContainerClipping,
    internalScroll = true,
    scrollContainerStyle,
    isDropDisabled,
    isCombineEnabled,
    listId = 'LIST',
    listType,
    style,
    quotes,
    title,
    useClone,
    setModalState,
    setModalShow,
    editAccess,
  } = props
  return (
    <Droppable
      droppableId={listId}
      type={listType}
      ignoreContainerClipping={ignoreContainerClipping}
      isDropDisabled={isDropDisabled}
      isCombineEnabled={isCombineEnabled}
      renderClone={
        useClone
          ? (provided, snapshot, descriptor) => (
              <QuoteItem
                quote={quotes[descriptor.source.index]}
                provided={provided}
                isDragging={snapshot.isDragging}
                editAccess={editAccess}
                setModalShow={setModalShow}
              />
            )
          : null
      }
    >
      {(dropProvided, dropSnapshot) => (
        <Wrapper
          style={style}
          isDraggingOver={dropSnapshot.isDraggingOver}
          isDropDisabled={isDropDisabled}
          isDraggingFrom={Boolean(dropSnapshot.draggingFromThisWith)}
          {...dropProvided.droppableProps}
        >
          {internalScroll ? (
            <ScrollContainer style={scrollContainerStyle}>
              <InnerList
                setModalState={setModalState}
                quotes={quotes}
                title={title}
                dropProvided={dropProvided}
                editAccess={editAccess}
                setModalShow={setModalShow}
              />
            </ScrollContainer>
          ) : (
            <InnerList
              setModalState={setModalState}
              quotes={quotes}
              title={title}
              dropProvided={dropProvided}
              editAccess={editAccess}
              setModalShow={setModalShow}
            />
          )}
        </Wrapper>
      )}
    </Droppable>
  )
}

export default QuoteList
