import moment from 'moment'

export const generateOrdersColumns = (orders, filterByType) =>
  orders.reduce(
    (previous, column) => ({
      ...previous,
      [column.title]: column.orders
        .filter(order => {
          if (!order?.date || !JSON.parse(order.date)?.date) {
            return false;
          }
          const today = moment()
          const orderDate = moment(JSON.parse(order.date).date)
          const orderCreatedDate = moment(order.createdAt)

          switch (filterByType) {
            case 0:
              return order
            case 1:
              return !order.managerId
            case 2:
              return (
                Math.ceil(moment.duration(orderDate.diff(today)).asDays()) ===
                -0
              )
            case 3:
              return (
                Math.ceil(moment.duration(orderDate.diff(today)).asDays()) < 0
              )
            case 4:

              return (
                Math.ceil(orderCreatedDate.diff(today, 'days')) >= -2
              )
          }
        })
        .sort((a, b) => b.id - a.id),
    }),
    {},
  )
