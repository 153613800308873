import { createAction, handleActions } from 'redux-actions'
import * as R from 'ramda'
import { combineReducers } from 'redux'
import { MODULES } from '../../constants'

import { selectUser as selectOrganisationUser } from '../organizations/duck'

export const getUsersRequest = createAction(
  `${MODULES.USERS}/GET_USERS_REQUEST`,
)
export const getUsersSuccess = createAction(
  `${MODULES.USERS}/GET_USERS_SUCCESS`,
)
export const getUsersFailure = createAction(
  `${MODULES.USERS}/GET_USERS_FAILURE`,
)

export const getAccessListRequest = createAction(
  `${MODULES.USERS}/GET_ACCESS_LIST_REQUEST`,
)
export const getAccessListSuccess = createAction(
  `${MODULES.USERS}/GET_ACCESS_LIST_SUCCESS`,
)
export const getAccessListFailure = createAction(
  `${MODULES.USERS}/GET_ACCESS_LIST_FAILURE`,
)

export const getAccessListForUserRequest = createAction(
  `${MODULES.USERS}/GET_ACCESS_LIST_FOR_USER_REQUEST`,
)

export const getAccessListForUserSuccess = createAction(
  `${MODULES.USERS}/GET_ACCESS_LIST_FOR_USER_SUCCESS`,
)

export const getAccessListForUserFailure = createAction(
  `${MODULES.USERS}/GET_ACCESS_LIST_FOR_USER_FAILURE`,
)

export const getRoleRequest = createAction(`${MODULES.USERS}/GET_ROLE_REQUEST`)
export const getRoleSuccess = createAction(`${MODULES.USERS}/GET_ROLE_SUCCESS`)
export const getRoleFailure = createAction(`${MODULES.USERS}/GET_ROLE_FAILURE`)

export const deleteRoleRequest = createAction(
  `${MODULES.USERS}/DELETE_ROLE_REQUEST`,
)
export const deleteRoleSuccess = createAction(
  `${MODULES.USERS}/DELETE_ROLE_SUCCESS`,
)
export const deleteRoleFailure = createAction(
  `${MODULES.USERS}/DELETE_ROLE_FAILURE`,
)

export const createRoleRequest = createAction(
  `${MODULES.USERS}/CREATE_ROLE_REQUEST`,
)
export const createRoleSuccess = createAction(
  `${MODULES.USERS}/CREATE_ROLE_SUCCESS`,
)
export const createRoleFailure = createAction(
  `${MODULES.USERS}/CREATE_ROLE_FAILURE`,
)
export const setAccessRequest = createAction(
  `${MODULES.USERS}/SET_ACCESS_REQUEST`,
)
export const setAccessSuccess = createAction(
  `${MODULES.USERS}/SET_ACCESS_SUCCESS`,
)
export const setAccessFailure = createAction(
  `${MODULES.USERS}/SET_ACCESS_FAILURE`,
)
export const deleteAccessRequest = createAction(
  `${MODULES.USERS}/DELETE_ACCESS_REQUEST`,
)
export const deleteAccessSuccess = createAction(
  `${MODULES.USERS}/DELETE_ACCESS_SUCCESS`,
)
export const deleteAccessFailure = createAction(
  `${MODULES.USERS}/DELETE_ACCESS_FAILURE`,
)
export const updateRoleRequest = createAction(
  `${MODULES.USERS}/UPDATE_ROLE_REQUEST`,
)
export const updateRoleSuccess = createAction(
  `${MODULES.USERS}/UPDATE_ROLE_SUCCESS`,
)
export const updateRoleFailure = createAction(
  `${MODULES.USERS}/UPDATE_ROLE_FAILURE`,
)

export const setUserStatusRequest = createAction(
  `${MODULES.USERS}/SET_USER_STATUS_REQUEST`,
)
export const setUserStatusSuccess = createAction(
  `${MODULES.USERS}/SET_USER_STATUS_SUCCESS`,
)
export const setUserStatusFailure = createAction(
  `${MODULES.USERS}/SET_USER_STATUS_FAILURE`,
)

export const setUserRequest = createAction(`${MODULES.USERS}/SET_USER_REQUEST`)
export const setUserSuccess = createAction(`${MODULES.USERS}/SET_USER_SUCCESS`)
export const setUserFailure = createAction(`${MODULES.USERS}/SET_USER_FAILURE`)

export const getAmoUsersRequest = createAction(
  `${MODULES.USERS}/GET_AMO_USERS_REQUEST`,
)
export const getAmoUsersSuccess = createAction(
  `${MODULES.USERS}/GET_AMO_USERS_SUCCESS`,
)
export const getAmoUsersFailure = createAction(
  `${MODULES.USERS}/GET_AMO_USERS_FAILURE`,
)

export const getAmoRolesRequest = createAction(
  `${MODULES.USERS}/GET_AMO_ROLES_REQUEST`,
)
export const getAmoRolesSuccess = createAction(
  `${MODULES.USERS}/GET_AMO_ROLES_SUCCESS`,
)
export const getAmoRolesFailure = createAction(
  `${MODULES.USERS}/GET_AMO_ROLES_FAILURE`,
)

export const getAvaiableOrganisationsRequest = createAction(
  `${MODULES.USERS}/GET_AVAIABLE_ORGANISATIONS_REQUEST`,
)
export const getAvaiableOrganisationsSuccess = createAction(
  `${MODULES.USERS}/GET_AVAIABLE_ORGANISATIONS_SUCCESS`,
)
export const getAvaiableOrganisationsFailure = createAction(
  `${MODULES.USERS}/GET_AVAIABLE_ORGANISATIONS_FAILURE`,
)

export const selectRole = createAction(`${MODULES.USERS}/SELECT_ROLE`)

export const deleteUserRequest = createAction(
  `${MODULES.DRIVERS}/DELETE_USER_REQUEST`,
)

export const updateUserRequest = createAction(
  `${MODULES.DRIVERS}/UPDATE_USER_REQUEST`,
)

export const updateUserSuccess = createAction(
  `${MODULES.DRIVERS}/UPDATE_USER_SUCCESS`,
)

export const updateUserFailure = createAction(
  `${MODULES.DRIVERS}/UPDATE_USER_FAILURE`,
)

export const selectUser = createAction(`${MODULES.USERS}/SELECT_USER`)

export const createUserRequest = createAction(
  `${MODULES.USERS}/CREATE_USER_REQUEST`,
)

export const createUserSuccess = createAction(
  `${MODULES.USERS}/CREATE_USER_SUCCESS`,
)

export const createUserFailure = createAction(
  `${MODULES.USERS}/CREATE_USER_FAILURE`,
)

export const startCreatingUser = createAction(
  `${MODULES.USERS}/START_CREATING_USER`,
)

export const stopCreatingUser = createAction(
  `${MODULES.USERS}/STOP_CREATING_USER`,
)

export const getActiveSortSuccess = createAction(
  `${MODULES.USERS}/GET_ACTIVE_SORT_KEY_SUCCESS`,
)


export const getMeRequest = createAction(`${MODULES.USERS}/GET_ME_REQUEST`)
export const getMeSuccess = createAction(`${MODULES.USERS}/GET_ME_SUCCESS`)

export const clearUsers = createAction(`${MODULES.USERS}/CLEAR_USERS`)

const me = handleActions(
  {
    [getMeSuccess]: (_, { payload }) => payload,
  },
  {},
)

const roles = handleActions(
  {
    [getAmoRolesSuccess]: (_, { payload }) => payload,
    [getAmoRolesFailure]: R.always([]),
  },
  [],
)

const activeSort = handleActions(
  {
    [getActiveSortSuccess]: (_, { payload }) => payload,
  },
  {},
)

const accessList = handleActions(
  {
    [getAccessListSuccess]: (_, { payload }) => payload,
    [getAccessListFailure]: R.always([]),
  },
  [],
)

const accessListForUser = handleActions(
  {
    [getAccessListForUserSuccess]: (_, { payload }) => payload,
    [getAccessListForUserFailure]: R.always([]),
  },
  [],
)

const role = handleActions(
  {
    [getRoleSuccess]: (_, { payload }) => payload,
    [getRoleFailure]: R.always({}),
  },
  {},
)

const avaiableOrganisations = handleActions(
  {
    [getAvaiableOrganisationsSuccess]: (_, { payload }) => payload,
    [getAvaiableOrganisationsFailure]: R.always([]),
  },
  [],
)

const selectedRole = handleActions(
  {
    [selectRole]: (_, { payload }) => payload,
  },
  'Все',
)

const users = handleActions(
  {
    [getAmoUsersSuccess]: (_, { payload }) => payload,
    [clearUsers]: R.always([]),
  },
  [],
)

const selectedUser = handleActions(
  {
    [selectOrganisationUser]: (_, { payload }) => payload,
    [selectUser]: (_, { payload }) => payload,
    [startCreatingUser]: R.always(null),
    [stopCreatingUser]: R.always(null),
    [selectRole]: R.always(null),
    [getAmoRolesRequest]: R.always(null),
    [updateUserSuccess]: R.always(null),
  },
  null,
)

const isCreatingUser = handleActions(
  {
    [startCreatingUser]: R.T,
    [stopCreatingUser]: R.F,
    [createUserSuccess]: R.F,
    [selectUser]: R.F,
  },
  null,
)

const error = handleActions(
  {
    [createUserSuccess]: () => '',
    [createUserFailure]: (_, { payload }) => payload.localeMessage || '',
  },
  '',
)

const byId = handleActions(
  {
    [getUsersSuccess]: (state, { payload }) => ({
      ...state,
      ...R.indexBy(R.prop('id'))(payload),
    }),
    [clearUsers]: R.always({}),
  },
  {},
)

const allIds = handleActions(
  {
    [getUsersSuccess]: (state, { payload }) =>
      R.uniq([...state, ...R.map(R.prop('id'))(payload)]),
    [clearUsers]: R.always([]),
  },
  [],
)

const isLoading = handleActions(
  {
    [getUsersRequest]: R.T,
    [createUserSuccess]: R.F,
    [createUserFailure]: R.F,
    [getUsersSuccess]: R.F,
    [getUsersFailure]: R.F,
    [getAmoUsersRequest]: R.T,
    [getAmoUsersSuccess]: R.F,
    [getAmoUsersFailure]: R.F,
    [getAmoRolesRequest]: R.T,
    [selectRole]: R.T,
    [getAmoRolesSuccess]: R.F,
    [getAmoRolesFailure]: R.F,
  },
  false,
)

const isUpdating = handleActions(
  {
    [updateUserRequest]: R.T,
    [updateUserSuccess]: R.F,
    [updateUserFailure]: R.F,
  },
  false,
)

const usersReducer = combineReducers({
  byId,
  allIds,
  isLoading,
  selectedUser,
  isCreatingUser,
  users,
  roles,
  accessList,
  selectedRole,
  isUpdating,
  avaiableOrganisations,
  role,
  error,
  accessListForUser,
  activeSort,
  me
})

export default usersReducer
