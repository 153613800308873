import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { connect } from 'react-redux'
import * as R from 'ramda'
import { Grid } from '@material-ui/core'

import { EntityList, Loader } from '../../components/shared'
import { OrganizationCard } from '../../components/organizations'
import { UserCard } from '../../components/users'
import { useWebSockets } from '../../context'
import AccessForbiddenPopup from '../../components/common/AccessForbidden'
import {
  getIsLoading,
  getOrganizationsList,
  getOrganizationsRequest,
  getSelectedOrganization,
  selectOrganization,
  getActiveSort,
  getActiveSortSuccess,
} from '../../modules/organizations'
import {
  getAmoRolesRequest,
  getAmoUsersList,
  getAmoUsersRequest,
  getSelectedUser,
  selectUser,
  getAvaiableOrganisationsRequest,
  selectRole,
  getAccessListForUser,
} from '../../modules/users'
import { useMediaQuery } from '../../components/address/commons'
import { widthMobileScreen } from '../../components/address/utils'
import assets from '../../assets'

const Wrap = styled.div`
  width: 100%;
  padding: 20px;
  background-color: #ecf1f4;
  box-sizing: border-box;
  @media screen and (min-width: 320px) and (max-width: 767px) {
    overflow: auto;
    height: 100%;
  }
`

const MobileWrap = styled.div`
  width: 100%;
  box-sizing: border-box;
  height: 100%;
`

const SortIcon = styled.img.attrs({ src: assets.img.sortIcon })``

const Wrapper = styled.div`
  margin-bottom: 30px;

  @media screen and (min-width: 320px) and (max-width: 767px) {
    margin-bottom: 15px;
  }
`

const Title = styled.div`
  font-family: FuturaMediumC;
  font-size: 14px;
  text-transform: uppercase;
  color: #000000;
  opacity: 0.4;
  padding-top: 11px;
  margin-right: 20px;
`

const TitleWrapper = styled.div`
  display: flex;
  margin-bottom: 15px;
`

const RolesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`
const Role = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 34px;
  ${({ isActive }) =>
    isActive
      ? css`
          border: 1px solid #f49355;
        `
      : css`
          border: 1px solid #ffffff;
          background: #ffffff;
          box-shadow: 0 6px 10px rgba(2, 9, 75, 0.06);
        `}
  border-radius: 8px;
  padding: 0 10px;
  font-family: 'FuturaBookC', sans-serif;
  font-size: 16px;
  letter-spacing: 0.02em;
  color: #000000;
  opacity: 0.6;
  cursor: pointer;
  margin: 0 5px 5px 0;
`

const StyledLoader = styled(Loader)`
  margin: auto;
  align-self: center;
`

const HeaderTitle = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  font-family: FuturaMediumC;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  text-transform: uppercase;
  color: #283642;
  margin-bottom: 25px;
`

const ArrowLeft = styled.img.attrs({ src: assets.img.arrowLeft })`
  width: 20px;
  margin-right: 10px;
`

const Organizations = ({
  isLoading,
  getOrganizations,
  getAllOrganizations,
  organizationsList,
  selectedOrganization,
  selectOrganization,
  selectedUser,
  userList,
  selectUser,
  getUsers,
  getRoles,
  selectRole,
  accessList,
  activeSort,
}) => {
  const [modalShow, setModalShow] = React.useState(false)

  const [editAccess, setEditAccesses] = React.useState([])

  const [viewAccess, setViewAccess] = React.useState([])

  const [modalViewShow, setModalViewShow] = React.useState(false)

  const { updatedData } = useWebSockets()

  const organizationEvents = [
    'newOrganization',
    'deleteOrganization',
    'updateOrganization',
  ]

  React.useEffect(() => {
    if (updatedData) {
      if (organizationEvents.includes(updatedData.action)) {
        getOrganizations()
      }
    }
  }, [updatedData])

  React.useEffect(() => {
    if (!accessList.length) return
    let view = accessList.filter(
      access =>
        access.module === 'organizations' && access.accessType === 'view',
    )
    let edit = accessList.filter(
      access =>
        access.module === 'organizations' && access.accessType === 'edit',
    )
    setEditAccesses(edit)
    setViewAccess(view)
    setModalViewShow(!view.length)
  }, [accessList, accessList.length])

  const [cardMode, setCardMode] = useState({
    open: false,
    mode: '',
  })
  const [userCardMode, setUserCardMode] = useState({
    open: false,
    mode: '',
  })

  const isMobile = useMediaQuery(`(max-width: ${widthMobileScreen - 1}px)`)

  useEffect(() => {
    selectRole('Все')
    getUsers()
    selectOrganization({})
    getOrganizations()
    getRoles()
    getAllOrganizations()
  }, [])
  const handleCloseUserCard = () => {
    setUserCardMode({
      open: false,
      mode: '',
    })
  }
  const handleChangeSort = sortData => {
    getOrganizations(sortData)
  }

  const sortDirectionShuffle = {
    asc: 'desc',
    desc: 'asc',
  }

  const initialSortKeys = [
    {
      id: 0,
      name: 'По алфавиту',
      sortKey: 'title',
    },
    {
      id: 1,
      name: 'По дате регистрации',
      sortKey: 'createdAt',
    },
  ]

  const handleCloseCard = () => {
    setCardMode({
      open: false,
      mode: '',
    })
  }

  useEffect(() => {
    handleCloseUserCard()
  }, [selectedOrganization])

  const renderAsDesktop = () => (
    <>
      <Wrapper>
        <div>
          <TitleWrapper>
            <Title>Сортировка:</Title>
          </TitleWrapper>
        </div>
        <Grid container>
          <Grid item>
            <RolesWrapper>
              {initialSortKeys.map(item => (
                <Role
                  onClick={() =>
                    handleChangeSort({
                      sortKey: item.sortKey,
                      sortDirection:
                        sortDirectionShuffle[activeSort.sortDirection],
                    })
                  }
                  isActive={activeSort.sortKey === item.sortKey}
                  key={item.id}
                >
                  <span>{item.name}</span>
                  <SortIcon
                    style={{
                      marginLeft: '5px',
                      transform: `${
                        activeSort.sortDirection === 'desc' &&
                        activeSort.sortKey === item.sortKey
                          ? 'rotate(0deg)'
                          : 'rotate(180deg)'
                      }`,
                    }}
                  />
                </Role>
              ))}
            </RolesWrapper>
          </Grid>
        </Grid>
      </Wrapper>
      <Grid container wrap="nowrap">
        <Grid item>
          <EntityList
            searchPlaceholder="ОРГАНИЗАЦИИ"
            entityList={organizationsList}
            selectEntity={selectOrganization}
            selectedEntity={selectedOrganization}
            handleCloseCard={handleCloseCard}
            setCardMode={setCardMode}
            cardMode={cardMode}
            entityItemTitle="title"
            editAccess={editAccess}
            setModalShow={setModalShow}
          />
        </Grid>
        {cardMode.open && (
          <Grid item>
            <OrganizationCard
              selectOrganization={selectOrganization}
              handleCloseUserCard={handleCloseUserCard}
              cardMode={cardMode}
              setCardMode={setCardMode}
              editAccess={editAccess}
              setModalShow={setModalShow}
            />
          </Grid>
        )}
        {Object.keys(selectedOrganization).length > 0 && (
          <Grid item>
            <EntityList
              searchPlaceholder="СОТРУДНИКИ ОРГАНИЗАЦИИ"
              handleCloseCard={handleCloseUserCard}
              cardMode={userCardMode}
              setCardMode={setUserCardMode}
              entityItemTitle="fio"
              entityList={userList.filter(
                item =>
                  item.organization &&
                  item.organization.title === selectedOrganization.title,
              )}
              selectedEntity={selectedUser}
              selectEntity={selectUser}
              editAccess={editAccess}
              setModalShow={setModalShow}
            />
          </Grid>
        )}
        {Object.keys(selectedOrganization).length > 0 && (
          <Grid item spacing={1}>
            <EntityList
              searchPlaceholder="Пользователи"
              handleCloseCard={handleCloseUserCard}
              cardMode={userCardMode}
              setCardMode={setUserCardMode}
              entityItemTitle="fio"
              entityList={userList}
              selectedEntity={selectedUser}
              selectEntity={selectUser}
              editAccess={editAccess}
              setModalShow={setModalShow}
            />
          </Grid>
        )}
        {userCardMode.open && selectedUser && (
          <Grid item>
            <UserCard
              organization={selectedOrganization}
              userCardMode={userCardMode}
              setUserCardMode={setUserCardMode}
              editAccess={editAccess}
              setModalShow={setModalShow}
            />
          </Grid>
        )}
      </Grid>
    </>
  )

  const renderHeaderButton = () => (
    <HeaderTitle onClick={onClickBack}>
      {' '}
      {cardMode.open || userCardMode.open ? <ArrowLeft /> : null}Организации
    </HeaderTitle>
  )

  const onClickBack = () => {
    if (userCardMode.open) {
      selectUser({})
      return handleCloseUserCard()
    }
    if (cardMode.open) {
      selectOrganization({})
      return handleCloseCard()
    }
  }

  const renderAsMobile = () => {
    if (userCardMode.open && selectedUser) {
      return (
        <UserCard
          organization={selectedOrganization}
          userCardMode={userCardMode}
          setUserCardMode={setUserCardMode}
          editAccess={editAccess}
          setModalShow={setModalShow}
        />
      )
    }
    if (cardMode.open) {
      return (
        <MobileWrap>
          <OrganizationCard
            selectOrganization={selectOrganization}
            handleCloseUserCard={handleCloseUserCard}
            cardMode={cardMode}
            setCardMode={setCardMode}
            editAccess={editAccess}
            setModalShow={setModalShow}
          />
          {Object.keys(selectedOrganization).length > 0 && (
            <EntityList
              searchPlaceholder="СОТРУДНИКИ ОРГАНИЗАЦИИ"
              handleCloseCard={handleCloseUserCard}
              cardMode={userCardMode}
              setCardMode={setUserCardMode}
              entityItemTitle="fio"
              entityList={userList.filter(
                item =>
                  item.organization &&
                  item.organization.title === selectedOrganization.title,
              )}
              selectedEntity={selectedUser}
              selectEntity={selectUser}
              editAccess={editAccess}
              setModalShow={setModalShow}
            />
          )}
        </MobileWrap>
      )
    }
    return (
      <>
        <Wrapper>
          <div>
            <TitleWrapper>
              <Title>Сортировка:</Title>
            </TitleWrapper>
          </div>
          <Grid container>
            <Grid item>
              <RolesWrapper>
                {initialSortKeys.map(item => (
                  <Role
                    onClick={() =>
                      handleChangeSort({
                        sortKey: item.sortKey,
                        sortDirection:
                          sortDirectionShuffle[activeSort.sortDirection],
                      })
                    }
                    isActive={activeSort.sortKey === item.sortKey}
                    key={item.id}
                  >
                    <span>{item.name}</span>
                    <SortIcon
                      style={{
                        marginLeft: '5px',
                        transform: `${
                          activeSort.sortDirection === 'desc' &&
                          activeSort.sortKey === item.sortKey
                            ? 'rotate(0deg)'
                            : 'rotate(180deg)'
                        }`,
                      }}
                    />
                  </Role>
                ))}
              </RolesWrapper>
            </Grid>
          </Grid>
        </Wrapper>
        <EntityList
          searchPlaceholder="ОРГАНИЗАЦИИ"
          entityList={organizationsList}
          selectEntity={selectOrganization}
          selectedEntity={selectedOrganization}
          handleCloseCard={handleCloseCard}
          setCardMode={setCardMode}
          cardMode={cardMode}
          entityItemTitle="title"
          editAccess={editAccess}
          setModalShow={setModalShow}
        />
      </>
    )
  }

  const renderContent = () =>
    isMobile ? (
      <>
        {renderHeaderButton()}
        {renderAsMobile()}
      </>
    ) : (
      renderAsDesktop()
    )

  return modalViewShow ? (
    <AccessForbiddenPopup
      moduleName={'Организации'}
      setModalShow={setModalViewShow}
      canClose={false}
    />
  ) : (
    <Wrap>
      {modalShow && (
        <AccessForbiddenPopup
          moduleName={'Организации'}
          setModalShow={setModalShow}
        />
      )}
      {isLoading ? <StyledLoader /> : renderContent()}
    </Wrap>
  )
}

export default connect(
  R.applySpec({
    isLoading: getIsLoading,
    organizationsList: getOrganizationsList,
    selectedOrganization: getSelectedOrganization,
    selectedUser: getSelectedUser,
    userList: getAmoUsersList,
    accessList: getAccessListForUser,
    activeSort: getActiveSort,
  }),
  {
    getOrganizations: getOrganizationsRequest,
    getAllOrganizations: getAvaiableOrganisationsRequest,
    getActiveSortData: getActiveSortSuccess,
    getUsers: getAmoUsersRequest,
    getRoles: getAmoRolesRequest,
    selectOrganization,
    selectUser,
    selectRole,
  },
)(Organizations)
