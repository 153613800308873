import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { Bar } from 'react-chartjs-2'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { connect } from 'react-redux'
import { getOrganizationsRequest } from '../../../modules/organizations'
import { getAnalyticsGraphs, getGraphs } from '../../../modules/analytics'
import Header from './header'

import customTooltip from './customTooltip'
import * as R from 'ramda'

const GraphWrapper = styled.div`
  background: #FFFFFF;
  border-radius: 8px;
  width: 100%;
  min-width: 1100px;
  box-sizing: border-box;
  margin: 40px auto 0;
  padding: 27px 0 20px;

  &:last-child {
    margin-top: 20px;
    margin-bottom: 45px;
  }
`

const GraphContainer = styled.div`
  position: relative;
  padding: 20px 20px 20px 45px;
`

const YAxisLabel = styled.div`
  position: absolute;
  top: 45%;
  left: 20px;
  transform: translateX(-50%) rotate(-90deg);
  color: #928FA3;
  font-size: 12px;
  line-height: 16px;
`

const LegendsContainer = styled.div`
  margin-top: 25px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const LegendPoint = styled.div`
  width: 12px;
  height: 12px;
  background: #F0640C;
  border-radius: 10px;
  margin-right: 10px;
`

const LastYearLegendPoint = styled(LegendPoint)`
  background: #B9C2C8;
`

const YearToYearLegendPoint = styled(LegendPoint)`
  background: #F49355;
  border-radius: 0;
`

const LegendText = styled.div`
  color: #928FA3;
  font-size: 12px;
  line-height: 16px;
  margin-right: 30px;
`

const GraphHeader = styled.div`
  font-family: FuturaMediumC, sans-serif;
  font-size: 20px;
  line-height: 24px;
  color: #12161A;
  font-weight: normal;
  text-transform: uppercase;
  margin-left: 30px;
`

const legend = (
  <LegendsContainer>
    <LegendPoint />
    <LegendText>Этот год</LegendText>
    <LastYearLegendPoint />
    <LegendText>Прошедший год</LegendText>
    <YearToYearLegendPoint />
    <LegendText>Год к году</LegendText>
  </LegendsContainer>
)

const initGraphConfig = {
  labels: [],
  datasets: [
    {
      pointBackgroundColor: '#F0640C',
      type: 'line',
      label: 'Dataset 1',
      borderColor: '#F0640C',
      borderWidth: 2,
      fill: false,
      lineTension: 0,
      data: [],
      datalabels: {
        display: false,
      },
    },
    {
      yAxisID: 'line-axis',
      pointBackgroundColor: '#B9C2C8',
      type: 'line',
      label: 'Dataset 2',
      borderColor: '#B9C2C8',
      borderWidth: 2,
      fill: false,
      lineTension: 0,
      data: [],
      datalabels: {
        display: false,
      },
    },
    {
      yAxisID: 'bar-axis',
      type: 'bar',
      label: 'Dataset 3',
      backgroundColor: '#F7B388',
      data: [],
      borderColor: '#F7B388',
      borderWidth: 2,
      datalabels: {
        formatter: value => `${value}%`,
        display: true,
        anchor: 'end',
        align: 'end',
        offset: 7,
        font: {
          size: 12,
        },
      },
    },
  ],
}

const graphProps = {
  height: null,
  width: null,
  legend: { display: false },
  options: {
    aspectRatio: 3,
    tooltips: {
      mode: 'label',
      position: 'nearest',
      enabled: false,
      caretSize: 0,
      filter: (tooltipItem) => (tooltipItem.datasetIndex !== 2),
      custom: customTooltip,
    },
    scales: {
      xAxes: [{
        gridLines: {
          color: 'rgba(0,0,0,0)',
        },
        maxBarThickness: 50,
      }],
      yAxes: [{
        id: 'line-axis',
        ticks: {
          min: 0,
        },
        gridLines: {
          borderDash: [10, 4],
          color: '#CBCACD',
        },
      },
        {
          id: 'bar-axis',
          display: false,
          ticks: {
            min: 0,
          },
        }],
    },
  },
}


const AnalyticsGraph = ({ getOrganizations, getAnalyticsGraphs, graphs }) => {

  const [period, setPeriod] = useState({ startDate: '', endDate: '' })
  const [rangeType, setRangeType] = useState('month')
  const [totalPrice, count] = useMemo(() => {
    const totalPriceGraph = R.clone(initGraphConfig)
    const countGraph = R.clone(initGraphConfig)
    const data = graphs.data
    if (!data) return [totalPriceGraph, countGraph]

    const labels = Object.keys(data)
    totalPriceGraph.labels = labels
    countGraph.labels = labels

    //set 0 on all values
    totalPriceGraph.datasets = totalPriceGraph.datasets.map(i => {
      i.data = Array(labels.length).fill(0)
      return i
    })

    countGraph.datasets = countGraph.datasets.map(i => {
      i.data = Array(labels.length).fill(0)
      return i
    })

    const [currentTotalPrice, oldTotalPrice, changeTotalPrice] = totalPriceGraph.datasets
    const [currentCount, oldCount, changeCount] = countGraph.datasets

    const values = Object.values(data)
    values.forEach((val, index) => {
      if (R.is(Object, val)) {
        const { current, old, changes } = val
        if (current) {
          const { totalPrice, count } = current
          currentTotalPrice.data[index] = totalPrice || 0
          currentCount.data[index] = count || 0
        }
        if (old) {
          const { totalPrice, count } = old
          oldTotalPrice.data[index] = totalPrice || 0
          oldCount.data[index] = count || 0
        }

        if (changes) {
          const { totalPricePercent, countPercent } = changes
          changeTotalPrice.data[index] = totalPricePercent || 0
          changeCount.data[index] = countPercent || 0
        }
      }
    })


    return [totalPriceGraph, countGraph]
  }, [graphs])

  useEffect(() => {
    getOrganizations()
  }, [])
  const [selectedOrganization, setSelectedOrganization] = useState({ id: 0, title: 'Все организации' })

  useEffect(() => {
    const { startDate, endDate } = period
    getAnalyticsGraphs({ organization: selectedOrganization.id, startDate, endDate, type: rangeType })
  }, [period, selectedOrganization, rangeType])


  useEffect(() => () => document.getElementById('chartjs-tooltip')?.remove(), [])

  return (
    <>
      <Header
        period={period}
        setPeriod={setPeriod}
        selectedOrganization={selectedOrganization}
        setSelectedOrganization={setSelectedOrganization}
        rangeType={rangeType}
        setRangeType={setRangeType}
      />
      <GraphWrapper>
        <GraphHeader>
          Продажи
        </GraphHeader>
        <GraphContainer>
          <Bar data={totalPrice} {...graphProps} plugins={[ChartDataLabels]} />
          <YAxisLabel>тыс. ₽</YAxisLabel>
          {legend}
        </GraphContainer>
      </GraphWrapper>
      <GraphWrapper>
        <GraphHeader>Заказы</GraphHeader>
        <GraphContainer>
          <Bar data={count} {...graphProps} />
          <YAxisLabel>Количество </YAxisLabel>
          {legend}
        </GraphContainer>
      </GraphWrapper>
    </>
  )
}

export default connect(R.applySpec({
  graphs: getGraphs,
}), {
  getOrganizations: getOrganizationsRequest,
  getAnalyticsGraphs,
})(AnalyticsGraph)