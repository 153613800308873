import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  select: {
    width: ({ width }) => width || 180,
    marginTop: ({ marginTop }) => marginTop || 0,
    '& > .MuiSelect-select': {
      padding: '12px 28px 9px 15px',
    },
    fontSize: 14,
    fontFamily: 'FuturaBookC',
    '& > .MuiOutlinedInput-notchedOutline': {
      borderColor: '#F49355',
    },
    '&.Mui-disabled > .MuiOutlinedInput-notchedOutline': {
      borderColor: '#F49355',
    },
    '&:hover > .MuiOutlinedInput-notchedOutline': {
      borderColor: '#F49355',
    },
    '&.Mui-focused > .MuiOutlinedInput-notchedOutline': {
      borderColor: '#F49355',
      borderWidth: 1,
    },
    borderRadius: 8,
  },
  icon: {
    fill: '#F49355',
  },
  menu: {
    padding: '7px 0',
    boxShadow: '0px 6px 14px 0px rgba(2, 9, 75, 0.06)',
  },
  labelFormControl: {
    zIndex: 2,
  },
  labelShrink: {
    transform: ({ transformX }) =>
      `translate(10px, ${transformX || 15}px) scale(0.75)`,
    padding: '0 5px',
    background: '#fff',
  },
  labelRoot: {
    '&.Mui-focused': {
      color: 'rgba(0, 0, 0, 0.54)',
    },
  },
}))

export default useStyles
