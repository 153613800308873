import querystring from 'query-string'
import { handleStatuses } from '../../utils'
import { ENDPOINTS } from '../../API'

window.querystring = querystring

export const getAnalytics = ({ token, query, body, type }) =>
  fetch(
    ENDPOINTS.ANALYTICS(
      `${type}?${querystring.stringify(query, {
        arrayFormat: 'bracket',
      })}`,
    ),
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    },
  )
    .then(handleStatuses())
    .then(res => res.json())

export const getAnalyticsInfo = ({ token, query, body, type, id }) =>
  fetch(
    ENDPOINTS.ANALYTICS_INFO(
      type,
      `${id}?${querystring.stringify(query, {
        arrayFormat: 'bracket',
      })}`,
    ),
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    },
  )
    .then(handleStatuses())
    .then(res => res.json())

export const downloadAnalytics = ({ token, query, type, id }) =>
  fetch(
    ENDPOINTS.ANALYTICS_DOWNLOAD(
      type,
      id,
      `?${querystring.stringify(query, {
        arrayFormat: 'bracket',
      })}`,
    ),
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type':
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
    },
  )
    .then(response => {
      return response.blob()
    })
    .then(blob => {
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = 'file.xlsx'
      document.body.appendChild(a) // we need to append the element to the dom -> otherwise it will not work in firefox
      a.click()
      a.remove() //afterwards we remove the element again
    })
    .catch(ex => {
      throw ex
    })

export const getGraphs = ({ type, organization, startDate, endDate, token }) => {
  const query = { type, startDate, endDate, }
  if(organization > 0) {
    query.organization = organization
  }

  return fetch(ENDPOINTS.GET_ANALYTICS_GRAPHS(querystring.stringify(query)), {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    method: 'GET',
  })
    .then(handleStatuses())
    .then(res => res.json())
}
