import React from 'react'
import * as R from 'ramda'
import { connect } from 'react-redux'
import styled from 'styled-components'
import SearchSelect from '../orders/search-select'
import { Option } from '../orders/boards-select'

import NewInput from '../common/NewInput'
import {
  createTransportRequest,
  getTransportCategorySelect,
} from '../../modules/transport'
import DriversDropDown from './DriversDropDown'

const Container = styled.div`
  width: 300px;
  height: ${({ isActive }) => (isActive ? '400px' : '380px')};
  background: white;
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  left: 10%;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  top: -20%;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  box-shadow: 0px 6px 14px rgba(2, 9, 75, 0.06);
  border-radius: 8px;
  padding-left: 20px;
`

const Heading = styled.div`
  font-family: FuturaMediumC;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  opacity: 0.4;
  padding-top: 18px;
`

const inputProps = {
  style: {
    padding: '10px 15px !important',
    fontFamily: 'FuturaBookC',
    fontSize: '14px',
    color: '#000000',
    opacity: '0.8',
    maxWidth: '260px',
  },
}

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 12px;
`

const Button = styled.div`
  width: 95px;
  height: 36px;
  background: ${props =>
    props.add
      ? 'linear-gradient(92.32deg, #f49355 0%, #f0640c 100%)'
      : 'linear-gradient(0deg, #9BA1A6, #9BA1A6), linear-gradient(92.32deg, #F49355 0%, #F0640C 100%);'};
  border-radius: 8px;
  font-family: FuturaMediumC;
  font-style: normal;
  font-size: 17px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: white;
  margin-left: ${props => (props.add ? '0' : '8px')};
  cursor: pointer;
`

const Warning = styled.div`
  font-family: FuturaMediumC;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: red;
  opacity: 0.4;
  padding-top: 7px;
  display: ${({ isActive }) => (isActive ? 'flex' : 'none')};
`
const Margin = styled.div`
  margin: 10px 0 0 0;
`

const AddTransportModalDump = ({
  setIsOpenAddTransportModal,
  createTransport,
  transportCategoryList,
}) => {
  const [isCategoryOpen, setIsCategoryOpen] = React.useState(false)
  const [isDriverOpen, setIsDriverOpen] = React.useState(false)
  const [userId, setUserId] = React.useState(0)
  const [transportCategoryId, setTransportCategoryId] = React.useState(0)
  const [brandName, setBrandName] = React.useState('')
  const [carSize, setCarSize] = React.useState(0)
  const [carNumber, setCarNumber] = React.useState('')
  const [isButtonActive, setIsButtonActive] = React.useState(false)
  const [isShowWarning, setIsShowWarning] = React.useState(false)
  const [chosenCategory, setChosenCategory] = React.useState(
    'Выберите категорию',
  )

  React.useEffect(() => {
    if (isCategoryOpen) {
      setIsDriverOpen(false)
    }
    if (isDriverOpen) {
      setIsCategoryOpen(false)
    }
    if (isShowWarning) {
      setTimeout(() => {
        setIsShowWarning(false)
      }, 3000)
    }
    if (
      (brandName, carSize, carNumber) &&
      transportCategoryId !== 0 &&
      userId !== 0
    ) {
      setIsButtonActive(true)
    } else setIsButtonActive(false)
  }, [
    isDriverOpen,
    isCategoryOpen,
    userId,
    transportCategoryId,
    brandName,
    carSize,
    carNumber,
    isShowWarning,
  ])

  const handleCategoryChange = e => {
    setChosenCategory(e.target.value)
    setTransportCategoryId(e.target.value.id)
  }

  return (
    <Container isActive={isShowWarning}>
      <Heading>ДАННЫЕ НОВОГО ТС</Heading>
      <Warning isActive={isShowWarning}>Заполните все поля!</Warning>
      <NewInput
        name="brand"
        inputProps={inputProps}
        label="Марка"
        setBrandName={setBrandName}
      />
      <Margin>
        <SearchSelect
          value={chosenCategory}
          transformX={-5}
          label="Выберите категорию"
          width={260}
          onChange={handleCategoryChange}
        >
          {transportCategoryList.map((category, index) => (
            <Option key={index} value={category}>
              {category.title}
            </Option>
          ))}
        </SearchSelect>
      </Margin>
      <DriversDropDown
        isDriverOpen={isDriverOpen}
        setIsDriverOpen={setIsDriverOpen}
        setUserId={setUserId}
      />
      <NewInput
        name="capacity"
        inputProps={inputProps}
        label="Тоннаж"
        setCarSize={setCarSize}
      />
      <NewInput
        name="carNumber"
        inputProps={inputProps}
        label="ГосНомер"
        setCarNumber={setCarNumber}
      />
      <ButtonContainer>
        <Button
          add
          onClick={() => {
            isButtonActive && setIsOpenAddTransportModal(false)
            isButtonActive &&
              createTransport({
                transportCategoryId: transportCategoryId,
                userId: userId,
                carNumber: carNumber,
                capacity: carSize,
                brand: brandName,
              })
            !isButtonActive && setIsShowWarning(true)
          }}
        >
          Подтвердить
        </Button>
        <Button onClick={() => setIsOpenAddTransportModal(false)}>
          Отменить
        </Button>
      </ButtonContainer>
    </Container>
  )
}

const AddTransportModal = R.compose(
  connect(
    R.applySpec({
      transportCategoryList: getTransportCategorySelect,
    }),
    {
      createTransport: createTransportRequest,
    },
  ),
)(AddTransportModalDump)

export default AddTransportModal
