import React from 'react'
import * as R from 'ramda'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { deleteTransportRequest } from '../../modules/transport'

const Container = styled.div`
  width: 516px;
  height: 245px;
  background: white;
  position: absolute;
  z-index: 40;
  display: flex;
  flex-direction: column;
  align-items: center;
  left: 10%;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  top: -20%;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  box-shadow: 0px 6px 14px rgba(10, 21, 128, 0.08);
`

const Text = styled.div`
  margin-top: 69px;
  font-family: 'FuturaBookC', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  line-height: 30px;
  text-align: center;
  color: #3d4751;
`

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 50px;
`

const Button = styled.div`
  width: 95px;
  height: 36px;
  background: ${props =>
    props.add
      ? 'linear-gradient(92.32deg, #F49355 0%, #F0640C 100%)'
      : 'linear-gradient(0deg, #9BA1A6, #9BA1A6), linear-gradient(92.32deg, #F49355 0%, #F0640C 100%);'};
  border-radius: 8px;
  font-family: FuturaMediumC;
  font-style: normal;
  font-size: 17px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: white;
  margin-left: ${props => (props.add ? '0' : '8px')};
  cursor: pointer;
`

const DeleteTransportDump = ({
  deleteTransportRequest,
  setIsDeleteModalOpen,
  number,
  id,
  editAccess,
  setModalShow,
}) => {
  return (
    <Container>
      <Text>Удалить транспортное средство {number}?</Text>
      <ButtonContainer>
        <Button
          add
          onClick={() => {
            if (editAccess.length) {
              deleteTransportRequest(id)
              setIsDeleteModalOpen(false)
            } else {
              setModalShow(true)
            }
          }}
        >
          Да
        </Button>
        <Button onClick={() => setIsDeleteModalOpen(false)}>Отмена</Button>
      </ButtonContainer>
    </Container>
  )
}

const DeleteTransportModal = R.compose(
  connect(R.applySpec({}), { deleteTransportRequest }),
)(DeleteTransportDump)

export default DeleteTransportModal
