import React, { useEffect } from 'react'
import * as R from 'ramda'
import { connect } from 'react-redux'
import styled from 'styled-components'
import assets from '../../assets'
import { getAccessListForUser } from '../../modules/users'

const Container = styled.div`
  width: 137px;
  height: 36px;
  background: white;
  box-shadow: 0px 6px 14px rgba(2, 9, 75, 0.06);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
  cursor: pointer;
`

const Text = styled.div`
  font-family: 'FuturaBookC', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  text-align: right;
  letter-spacing: 0.02em;

  color: #000000;

  opacity: 0.4;
`
const PlusIcon = styled.img.attrs({ src: assets.img.plusIcon })``

const PlusContainer = styled.div`
  height: 17px;
  width: 17px;
  border-radius: 50%;
  border: 1px solid #f0640c;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  margin-bottom: 2px;
`

const AddTransport = ({
  setIsOpenAddTransportModal,
  setModalShow,
  editAccess
}) => {
  return (
    <Container
      onClick={() =>
        editAccess ? setIsOpenAddTransportModal(true) : setModalShow(true)
      }
    >
      <Text>Добавить ТС</Text>
      <PlusContainer>
        <PlusIcon />
      </PlusContainer>
    </Container>
  )
}


export default AddTransport
