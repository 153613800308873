import React, { useState } from 'react'
import styled from 'styled-components'
import { format } from 'date-fns'

import PeriodModal from './period-modal'

import { getUTCDate } from '../../../../screens/analytics'

import assets from '../../../../assets'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`

const AnalyticsPeriodFilterTitle = styled.div`
  display: flex;
  align-items: center;
  font-family: FuturaMediumC, sans-serif;
  font-size: 14px;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.4);
  margin-right: 20px;
`

const PeriodInputWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 36px;
  width: 220px;
  cursor: pointer;
  background: #ffffff;
  border: 1px solid #f49355;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 12px 15px 10px 15px;
`

const PeriodText = styled.div`
  font-family: FuturaBookC, sans-serif;
  font-size: 14px;
  line-height: 14px;
  display: flex;
  align-items: center;
  color: rgba(18, 22, 26, 0.9);
  margin-right: 5px;
`

const CalendarIcon = styled.img.attrs({ src: assets.img.calendar })`
  margin-left: auto;
  margin-bottom: 4px;
`

const AnalyticsPeriodFilter = ({ period, setPeriod }) => {
  const [open, setOpen] = useState(false)

  const handleModalClose = () => {
    setOpen(false)
  }

  return (
    <Wrapper>
      <PeriodModal
        open={open}
        handleClose={handleModalClose}
        initialValue={period}
        handleChange={setPeriod}
      />
      <AnalyticsPeriodFilterTitle>Период</AnalyticsPeriodFilterTitle>
      <PeriodInputWrapper onClick={() => setOpen(true)}>
        <PeriodText>
          {period.startDate &&
            format(getUTCDate(period.startDate), 'yyyy.MM.dd')}
        </PeriodText>
        {period.startDate && <PeriodText>—</PeriodText>}
        <PeriodText>
          {period.endDate && format(getUTCDate(period.endDate), 'yyyy.MM.dd')}
        </PeriodText>
        <CalendarIcon />
      </PeriodInputWrapper>
    </Wrapper>
  )
}

export default AnalyticsPeriodFilter
