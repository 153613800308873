import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import * as R from 'ramda'
import { useSubcategoryCreator } from '../../effects/categories'
import assets from '../../assets'

import {
  updateCategoryRequest,
  getSelectedCategory,
} from '../../modules/categories'

// fix reload

const Container = styled.div`
  position: absolute;
  width: 340px;
  height: 327px;
  background: white;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  top: 300px;
  z-index: 1001;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 6px 14px rgba(2, 9, 75, 0.06);
  border-radius: 8px;
`

const Heading = styled.div`
  font-family: FuturaMediumC;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 19px;
  text-align: center;
  color: linear-gradient(131.18deg, #3d4751 0%, #162836 108.75%);
  padding-top: 20px;
`

const InputBlock = styled.div`
  margin-top: 27px;
  width: 260px;
`

const Input = styled.input.attrs({ placeholder: 'Введите название' })`
  width: 100%;
  height: 36px;
  background: #ffffff;
  padding: 0 15px;
  border: 1px solid #f0640c;
  box-sizing: border-box;
  border-radius: 8px 8px 8px 8px;
  font-family: 'FuturaBookC', sans-serif;
  font-size: 17px;
  outline: none;
`

const InputQuanity = styled.input.attrs({
  placeholder: 'Введите минимальное кол-во',
})`
  width: 100%;
  height: 36px;
  background: #ffffff;
  padding: 0 15px;
  border: 1px solid #f0640c;
  box-sizing: border-box;
  border-radius: 8px 8px 8px 8px;
  font-family: 'FuturaBookC', sans-serif;
  font-size: 17px;
  outline: none;
`

const ButtonConfirm = styled.div`
  width: 260px;
  height: 36px;
  background: #f0640c;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'FuturaBookC', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 19px;
  color: white;
  margin-top: 35px;
  cursor: pointer;
`

const ButtonCancel = styled.div`
  width: 260px;
  height: 36px;
  background: #808080;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'FuturaBookC', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 19px;
  color: white;
  margin-top: 10px;
  cursor: pointer;
`
const DeleteContainer = styled.div`
  display: flex;
  align-items: center;
  padding-top: 18px;
  cursor: pointer;
`
const Icon = styled.img`
  margin: auto;
`

const DeleteText = styled.div`
  font-family: 'FuturaBookC', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: center;
  padding-left: 10px;
  color: #f55555;
`

const SubcategoryQuanityModalDump = ({
  selectedCategory,
  deleteId,
  setIsOpen,
  updateCategoryRequest,
  onAcceptDeleteClick,
  subcategory,
  editAccess,
  setModalShow,
}) => {
  const { onChangeInputText } = useSubcategoryCreator({})
  const [inputValue, setInputValue] = React.useState(subcategory.title)
  const [inputValueQuanity, setInputValueQuanity] = React.useState(
    Number(subcategory?.multiplicity) ? Number(subcategory?.multiplicity) : 1,
  )

  const changeTitle = (value, id) => {
    setInputValue(value.target.value)
    onChangeInputText(value)
  }

  const changeQuanity = (value, id) => {
    setInputValueQuanity(value.target.value)
  }

  const submitTitleChange = (inputValue, id, inputValueQuanity) => {
    updateCategoryRequest({
      title: inputValue,
      id: id,
      multiplicity: Number(inputValueQuanity),
      selectedCategory,
    })

    setIsOpen(false)
  }

  return (
    <>
      {selectedCategory === subcategory.id && deleteId !== subcategory.id && (
        <Container>
          <Heading>Редактирование подкатегории</Heading>
          <InputBlock>
            <Input
              value={inputValue}
              onChange={event => changeTitle(event, subcategory.id)}
            />
          </InputBlock>
          <InputBlock>
            <InputQuanity
              value={inputValueQuanity}
              onChange={event => changeQuanity(event, subcategory.id)}
            />
          </InputBlock>
          <ButtonConfirm
            onClick={() => {
              editAccess.length
                ? submitTitleChange(
                    inputValue,
                    subcategory.id,
                    inputValueQuanity,
                  )
                : setModalShow(true)
            }}
          >
            Сохранить
          </ButtonConfirm>
          <ButtonCancel onClick={() => setIsOpen(false)}>Отменить</ButtonCancel>
          <DeleteContainer
            onClick={() =>
              editAccess.length
                ? onAcceptDeleteClick(subcategory.id)
                : setModalShow(true)
            }
          >
            <Icon src={assets.img.basket} />
            <DeleteText>Удалить подкатегорию</DeleteText>
          </DeleteContainer>
        </Container>
      )}
    </>
  )
}

const SubcategoryQuanityModal = connect(
  R.applySpec({
    selectedCategory: getSelectedCategory,
  }),
  {
    updateCategoryRequest,
  },
)(SubcategoryQuanityModalDump)

export default SubcategoryQuanityModal
