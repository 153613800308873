import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { connect } from 'react-redux'
import * as R from 'ramda'
import * as _ from 'lodash'
import { Grid } from '@material-ui/core'

import useStyles from './styles'

import { EntityList, Loader } from '../../components/shared'
import { useMediaQuery } from '../../components/address/commons'
import { widthMobileScreen } from '../../components/address/utils'
import AccessForbiddenPopup from '../../components/common/AccessForbidden'
import assets from '../../assets'
import {
  getIsLoading,
 getStorageProductsList, getStorageProductsRequest,
  getStorageProvidersList,
  getStorageProvidersRequest,
  getStoragesList,
  getStoragesRequest,
} from '../../modules/storages'
import { useWebSockets } from '../../context'
import Search from '../../components/storages/Search'
import SearchSelectDropDown from '../../components/common/SearchSelectDropDown'
import TableBlock from '../../components/storages/TableBlock'
import Button from '@material-ui/core/Button'
import ModalPortal from '../../components/orders/mobile/ModalPortal'
import { StorageForm, ProviderForm, ProductForm } from '../../components/storages/storage-card'

const Wrap = styled.div`
  width: 100%;
  padding: 20px;
  background-color: #ecf1f4;
  box-sizing: border-box;
  @media screen and (min-width: 320px) and (max-width: 767px) {
    overflow: auto;
    height: 100%;
  }
`

const MobileWrap = styled.div`
  width: 100%;
  box-sizing: border-box;
  height: 100%;
`

const SortIcon = styled.img.attrs({ src: assets.img.sortIcon })``

const Wrapper = styled.div`
  margin-bottom: 30px;

  @media screen and (min-width: 320px) and (max-width: 767px) {
    margin-bottom: 15px;
  }
`

const Title = styled.div`
  font-family: FuturaMediumC;
  font-size: 24px;
  text-transform: uppercase;
  color: #000000;
  opacity: 1;
  margin-right: 20px;
`

const TitleWrapper = styled.div`
  display: flex;
  margin-bottom: 15px;
`

const StyledLoader = styled(Loader)`
  margin: auto;
  align-self: center;
`

const HeaderTitle = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  font: 500 20px/23px "FuturaMediumC", sans-serif;
  text-transform: uppercase;
  color: #283642;
  margin-bottom: 25px;
`

const ArrowLeft = styled.img.attrs({ src: assets.img.arrowLeft })`
  width: 20px;
  margin-right: 10px;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
`

const RowTable = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;

  &:last-child {
    width: 100%;
  }
`

const ProviderWrap = styled.div`
  margin-bottom: 20px;
`


const TableContainer = styled.div`
  margin-top: 22px;
  width: 100%;
`
const Storages = ({
                    isLoading,
                    storagesList = [],
                    getStorages,
                    getProviders,
                    getProducts,
                    providersList = [],
                    productsList = [],
                  }) => {
  const [modalShow, setModalShow] = useState(false)

  const [editAccess, setEditAccesses] = useState([])

  const [modalViewShow, setModalViewShow] = useState(false)

  const [modalItemOpen, setModalItemOpen] = useState(false)

  const { updatedData } = useWebSockets()

  const [selectedStorage, setSelectedStorage] = useState({})
  const [selectedProvider, setSelectedProvider] = useState({})

  const isMobile = useMediaQuery(`(max-width: ${widthMobileScreen - 1}px)`)

  const storageEvents = [
    'createStorage',
    'deleteStorage',
    'updateStorage',
  ]

  useEffect(() => {
    if (updatedData) {
      if (storageEvents.includes(updatedData.action)) {
        getStorages()
      }
    }
  }, [updatedData])

  useEffect(() => {
    getStorages()
  }, [])

  useEffect(() => {
    if (!_.isEmpty(selectedStorage)) {
      getProviders({ storageId: selectedStorage?.id })
    }
  }, [selectedStorage?.id])


  useEffect(() => {
    if (!_.isEmpty(selectedStorage)) {
      getProducts({ storageId: selectedStorage?.id, providerId: selectedProvider?.id })
    }
  }, [selectedStorage?.id, selectedProvider?.id])

  const [cardMode, setCardMode] = useState({
    open: false,
    mode: '',
  })

  const [providerCardMode, setProviderCardMode] = useState({
    open: false,
    mode: '',
  })

  const renderHeaderButton = () => (
    <HeaderTitle onClick={onClickBack}>
      {' '}
      {cardMode.open ? <ArrowLeft /> : null}Организации
    </HeaderTitle>
  )

  const onClickBack = () => {
    if (cardMode.open) {
      // selectOrganization({})
      return handleCloseCard()
    }
  }

  const handleCloseCard = () => {
    setCardMode({
      open: false,
      mode: '',
    })
  }

  const handleCloseProviderCard = () => {
    setProviderCardMode({
      open: false,
      mode: '',
    })
  }
  const classes = useStyles()

  const [isSelectCategoriesModalOpen, setIsSelectCategoriesModalOpen] = React.useState(
    false,
  )
  const [isSelectSemiCategoriesModalOpen, setIsSelectSemiCategoriesModalOpen] = React.useState(false)


  const [paginationQuery, setPaginationQuery] = useState({
    organization: '',
    product: '',
    driver: '',
    orderState: '',
    ttnStatus: '',
    manager: '',
    activeRoleNames: [],
  })
  const setActiveItemFromSelect = (name, item) => {
    setPaginationQuery({
      ...paginationQuery,
      [name]: item,
    })
  }

  const renderModal = () => {
    if (!modalItemOpen) return null
    const close = () => {
      setModalItemOpen(false)
    }
    return (
      <ModalPortal close={close}>
        <ProductForm visible={true} onClose={close} />
      </ModalPortal>
    )
  }

  const categories = []
  const semiCategories = []
  const [chosenId, setChosenId] = React.useState(0)

  const renderAsDesktop = () => (
    <>
      {renderModal()}
      <Wrapper>
        <TitleWrapper>
          <Title>Склады</Title>
        </TitleWrapper>
      </Wrapper>

      <Row>
        <Column container wrap='nowrap'>
          <Grid item>
            <EntityList
              searchPlaceholder='Поиск'
              entityList={storagesList}
              selectEntity={(item) => setSelectedStorage(item)}
              selectedEntity={selectedStorage}
              handleCloseCard={handleCloseCard}
              setCardMode={setCardMode}
              cardMode={cardMode}
              entityItemTitle='name'
              editAccess={editAccess}
              setModalShow={setModalShow}
              isEdit={true}
              addContent={
                <Grid item>
                  <StorageForm
                    data={selectedStorage}
                    visible={cardMode.open && cardMode.mode === 'EDIT'}
                    onClose={handleCloseCard}
                  />
                </Grid>
              }
            />
          </Grid>
        </Column>
        <Column>
          <Grid item>
            <StorageForm
              visible={cardMode.open && cardMode.mode === 'CREATE'}
              onClose={handleCloseCard}
            />
          </Grid>
        </Column>
        {!_.isEmpty(selectedStorage) && cardMode.mode === 'VIEW' &&
          <>
            <Column>
              <Title>Поставщики</Title>
              <ProviderWrap />
              <Grid item>
                <EntityList
                  searchPlaceholder='Поиск'
                  entityList={providersList}
                  selectEntity={(item) => setSelectedProvider(item)}
                  selectedEntity={selectedProvider}
                  handleCloseCard={handleCloseProviderCard}
                  setCardMode={setProviderCardMode}
                  cardMode={providerCardMode}
                  entityItemTitle='name'
                  editAccess={editAccess}
                  setModalShow={setModalShow}
                  isEdit={true}
                  addContent={
                    <Grid item>
                      <ProviderForm
                        data={selectedProvider}
                        visible={providerCardMode.open && providerCardMode.mode === 'EDIT'}
                        onClose={handleCloseProviderCard}
                      />
                    </Grid>
                  }
                />
              </Grid>

            </Column>
            <Column>
              <Grid item>
                <ProviderForm
                  data={selectedProvider}
                  visible={providerCardMode.open && providerCardMode.mode === 'CREATE'}
                  onClose={handleCloseProviderCard}
                />
              </Grid>
            </Column>
          </>
        }
        {!_.isEmpty(selectedStorage) && !_.isEmpty(selectedProvider) &&
          <Column>
            <RowTable>
              <Button
                onClick={() => setModalItemOpen(true)}
                classes={{
                  root: classes.addProductButton,
                }}
              >
                Добавить товар
              </Button>
              <Search />
              <Grid item>
                <SearchSelectDropDown
                  items={categories}
                  setActiveItem={setActiveItemFromSelect}
                  isSelectModalOpen={isSelectCategoriesModalOpen}
                  setIsSelectModalOpen={setIsSelectCategoriesModalOpen}
                  defaultValue={'Все категории'}
                  visibleObjectName={'fio'}
                  hideObjectName={'id'}
                  name={'manager'}
                  defaultStyles={{ marginTop: '0px' }}
                  styles={{ minWidth: '200px' }}
                />
              </Grid>
              <Grid item>
                <SearchSelectDropDown
                  items={semiCategories}
                  setActiveItem={setActiveItemFromSelect}
                  isSelectModalOpen={isSelectSemiCategoriesModalOpen}
                  setIsSelectModalOpen={setIsSelectSemiCategoriesModalOpen}
                  defaultValue={'Все подкатегории'}
                  visibleObjectName={'title'}
                  hideObjectName={'id'}
                  name={'organization'}
                  defaultStyles={{ marginTop: '0px' }}
                  styles={{ minWidth: '200px' }}
                />
              </Grid>
            </RowTable>
            <Row>
              <TableContainer>
                <TableBlock
                  data={productsList}
                  chosenId={chosenId}
                  setModalItemOpen={setModalItemOpen}
                />
              </TableContainer>
            </Row>
          </Column>
        }
      </Row>
      <div id='modal-portal' />
    </>
  )

  const renderAsMobile = () => (
    <div>sad</div>
  )
  const renderContent = () =>
    isMobile ? (
      <>
        {/*{renderHeaderButton()}*/}
        {/*{renderAsMobile()}*/}
        {renderAsDesktop()}
      </>
    ) : (
      renderAsDesktop()
    )

  return modalViewShow ? (
    <AccessForbiddenPopup
      moduleName={'Склады'}
      setModalShow={setModalViewShow}
      canClose={false}
    />
  ) : (
    <Wrap>
      {modalShow && (
        <AccessForbiddenPopup
          moduleName={'Склады'}
          setModalShow={setModalShow}
        />
      )}
      {isLoading ? <StyledLoader /> : renderContent()}
    </Wrap>
  )
}

export default connect(
  R.applySpec({
    isLoading: getIsLoading,
    storagesList: getStoragesList,
    providersList: getStorageProvidersList,
    productsList: getStorageProductsList,
  }),
  {
    getStorages: getStoragesRequest,
    getProviders: getStorageProvidersRequest,
    getProducts: getStorageProductsRequest,
  },
)(Storages)