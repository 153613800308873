import React from 'react'
import { FormControl, Select, InputLabel } from '@material-ui/core'

import useStyles from './styles'

const SearchSelect = ({
  input,
  label,
  meta,
  children,
  name,
  search,
  setSearchValue,
  searchPlacholder,
  searchValue,
  ...custom
}) => {
  const classes = useStyles(custom)
  return (
    <FormControl error={meta?.touched && meta?.error}>
      <InputLabel
        classes={{
          root: classes.labelRoot,
          formControl: classes.labelFormControl,
          shrink: classes.labelShrink,
        }}
        shrink
      >
        {label}
      </InputLabel>
      <Select
        variant="outlined"
        className={classes.select}
        autoWidth
        {...input}
        {...custom}
        inputProps={{
          name,
          classes: {
            icon: classes.icon,
          },
        }}
        placeholder={label}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
          classes: {
            paper: classes.menu,
          },
        }}
      >
        {children}
      </Select>
    </FormControl>
  )
}

export default SearchSelect
