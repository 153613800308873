import * as R from 'ramda'

const getAnalytics = R.prop('analytics')

export const getAnalyticsById = R.pipe(getAnalytics, R.prop('analyticsById'))

export const getTotalAnalytics = R.pipe(getAnalytics, R.prop('totalAnalytics'))

export const getAnalyticsDownload = R.pipe(
  getAnalytics,
  R.prop('analyticsDownload'),
)

export const getIsLoading = R.pipe(getAnalytics, R.prop('isLoading'))

export const getAnalyticsInfo = R.pipe(getAnalytics, R.prop('analyticsInfo'))

export const getAnalyticsAllIds = R.pipe(
  getAnalytics,
  R.prop('analyticsAllIds'),
)

export const getGraphs = R.pipe(
  getAnalytics,
  R.prop('graphs'),
)
export const getAnalyticsList = R.converge(
  (byId, allIds) => R.map(x => byId[x])(allIds),
  [getAnalyticsById, getAnalyticsAllIds],
)
