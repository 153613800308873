import React from 'react'
import * as R from 'ramda'
import { connect } from 'react-redux'
import styled from 'styled-components'
import assets from '../../assets'
import { transportSearchRequest } from '../../modules/transport'
const Container = styled.div`
  display: flex;
  margin-right: 5px;
`

const Input = styled.input`
  width: ${props =>
    props.width >= 768 && props.width <= 1023
      ? '274px'
      : props.width <= 767
      ? '215px'
      : '204px'};

  height: 36px;
  background: white;
  font-family: 'FuturaBookC', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #b9c2c8;
  padding-left: 11px;
  display: flex;
  align-items: center;
  outline: none;
  border: none;
  border-radius: 8px 0px 0px 8px;
`

const SearchButton = styled.div`
  width: 36px;
  height: 38px;
  background: linear-gradient(92.15deg, #f6a26d -38.69%, #f87c2e 107.08%);
  border-radius: 0px 8px 8px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

const SerchIcon = styled.img.attrs({ src: assets.img.searchIcon })``

const SearchDumb = ({ transportSearchRequest, width }) => {
  const [inputValue, setInputValue] = React.useState('')

  React.useEffect(() => {
    transportSearchRequest(inputValue)
  }, [inputValue, transportSearchRequest])

  return (
    <Container>
      <Input
        width={width}
        placeholder="Поиск"
        value={inputValue}
        onChange={event => setInputValue(event.target.value)}
      />
      <SearchButton>
        <SerchIcon onClick={() => transportSearchRequest(inputValue)} />
      </SearchButton>
    </Container>
  )
}

const Search = connect(R.applySpec({}), { transportSearchRequest })(SearchDumb)

export default Search
