import React from 'react'
import styled from 'styled-components'
import moment from 'moment'


const getDotBackground = (order) => {
  const today = moment()

  const orderDate = moment(JSON.parse(order.date).date)

  const daysDiffFromOrderDate = Math.ceil(
    moment.duration(orderDate.diff(today)).asDays(),
  )

  const moreOneDay = daysDiffFromOrderDate > 1
  const oneDay = daysDiffFromOrderDate === -0
  const overdue = daysDiffFromOrderDate < 0

  if (moreOneDay) {
    return '#0BD781'
  } else if (oneDay) {
    return '#FAB400'
  } else if (overdue) {
    return '#F55555'
  }
}

const Dot = styled.div`
  background: ${({ order }) => getDotBackground(order)};
  border-radius: 50%;
  width: 5px;
  height: 5px;
`

export default Dot