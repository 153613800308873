import React from 'react'
import Button from '@material-ui/core/Button'

import addIcon from '../../../assets/img/add.svg'
import useStyles from './styles'

const AddBoard = ({ onClick }) => {
  const classes = useStyles()

  return (
    <Button
      onClick={onClick}
      classes={{
        root: classes.addButton,
      }}
      endIcon={<img src={addIcon} alt="add" />}
    >
      Новая доска
    </Button>
  )
}

export default AddBoard
