import React from 'react'
import * as R from 'ramda'
import { connect } from 'react-redux'
import styled from 'styled-components'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { makeStyles } from '@material-ui/core/styles'
import assets from 'assets'
import { toLower } from 'lodash'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
    background: 'transparent',
  },
  table: {
    borderSpacing: '2px',
    borderCollapse: 'separate',
  },

  leftCell: {
    borderRadius: '8px 0px 0px 8px',
  },
  rightCell: {
    borderRadius: '0px 8px 8px 0px',
    padding: '0',
  },
  headerCell: {
    background: '#fff',
    fontFamily: 'FuturaMediumC',
    fontSize: '14px',
    lineHeight: '14px',
    textTransform: 'uppercase',
    color: 'rgba(0,0,0,0.4)',
    textAlign: 'center',
    maxWidth: '194px',
  },
  regularCell: {
    background: '#fff',
    fontFamily: 'FuturaBookC',
    fontSize: '14px',
    color: 'rgba(0,0,0,0.8)',
    textAlign: 'center',
    padding: '14px 10px',
  },
}))

const SortingArrow = styled.img.attrs({ src: assets.img.sortingArrow })`
  width: 11px;
  height: 6.3px;
  margin-left: 6px;
  cursor: pointer;
  transform: rotate(${({ sortingDirection }) => (sortingDirection === 'asc' ? 180 : 0)}deg);
`

const DeleteColorIcon = styled.img.attrs({ src: assets.img.deleteColorIcon })``

const DeleteIcon = styled.img.attrs({ src: assets.img.deleteIcon })`
  cursor: pointer;
`

const StatusContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const StatusText = styled.div`
  font-family: 'FuturaBookC', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: ${props => (props.green ? '#0bd781' : props.red ? 'red' : '#B9C2C8')};
  padding-left: 5px;
`

const Circle = styled.div`
  height: 7px;
  width: 7px;
  border-radius: 50%;
  background: ${props =>
          props.green ? '#0bd781' : props.red ? 'red' : '#B9C2C8'};
  margin-bottom: 2px;
`

const TableBlockDump = ({
                          data,
                          sortItemRequest,
                          setModalItemOpen
                        }) => {
  const classes = useStyles()
  const [sortingDirection, setSortingDirection] = React.useState('asc')

  const sortOrders = React.useCallback(() => {
    if (sortingDirection === 'desc') {
      setSortingDirection('asc')
      sortItemRequest(sortingDirection)
    } else {
      setSortingDirection('desc')
      sortItemRequest(sortingDirection)
    }
  }, [sortItemRequest, sortingDirection])

  return (
    <Table className={classes.table}>
      <TableHead>
        <TableRow>
          <TableCell className={`${classes.headerCell} ${classes.leftCell} `}>
            Категория
          </TableCell>
          <TableCell className={`${classes.headerCell} `}>
            Подкатегория
          </TableCell>
          <TableCell className={`${classes.headerCell} `}>
            Товар
            <SortingArrow
              sortingDirection={sortingDirection}
              onClick={sortOrders}
            />
          </TableCell>
          <TableCell className={`${classes.headerCell} `}>
            Цена доставки
          </TableCell>
          <TableCell className={`${classes.headerCell} ${classes.rightCell} `}>
            Статус
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((item, index) => (
          <TableRow key={index}
          onClick={() => setModalItemOpen(true)}
          >
            <TableCell
              className={`${classes.leftCell} ${classes.regularCell}`}
              component='th'
              scope='row'
            >
              <span>{item.category}</span>
            </TableCell>
            <TableCell
              className={`${classes.regularCell}`}
            >
              {item.semiCategory}
            </TableCell>
            <TableCell
              className={`${classes.regularCell}`}
            >
              {item.item}
            </TableCell>
            <TableCell
              className={`${classes.regularCell}`}
            >
              {item.deliveryCoast} ₽/м <small>3</small>
            </TableCell>
            <TableCell
              className={`${classes.regularCell} ${classes.rightCell}`}
            >
              {toLower(item.status) === 'в наличии' ? (
                <StatusContainer>
                  <StatusText green>В наличии</StatusText>
                </StatusContainer>
              ) : (
                <StatusContainer>
                  <StatusText red>Отсутствует</StatusText>
                </StatusContainer>
              )}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

const TableBlock = connect(R.applySpec())(TableBlockDump)

export default TableBlock
