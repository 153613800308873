import React from 'react'
import * as R from 'ramda'
import styled from 'styled-components'
import moment from 'moment'
import { createScheduleRequest } from '../../modules/schedule'

import { connect } from 'react-redux'

const Container = styled.div`
  display: -webkit-flex;
  justify-content: center;
`

const ShownText = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  text-align: center;
  letter-spacing: -0.01em;

  color: #35414c;

  opacity: 0.6;
`

const DropDownTest = styled.div`
  flex-direction: column;
  padding-top: 9px;
  position: absolute;
  width: 150px;
  height: 150px;
  background: black;
  display: ${({ isActive }) => (isActive ? 'flex' : 'none')}};
  z-index: 100000;
`

export const TileContent = ({
  date,
  schedule,
  createScheduleRequest,
  editAccess,
  setModalShow,
}) => {
  //const [isOpen, setIsOpen] = React.useState(false)
  const [selectedTime, setSelectedTime] = React.useState('до 18:00')

  React.useEffect(() => {
    const tileDate = moment(date).format('MM/DD/YYYY')
    schedule.forEach(element => {
      const scheduleDate = moment(element.date).format('MM/DD/YYYY')
      if (tileDate === scheduleDate) {
        setSelectedTime(element.time)
      }
    })
  }, [date, schedule])

  const onChangeTime = time => {
    if (!editAccess.length) return setModalShow(true)
    createScheduleRequest({
      schedules: [
        {
          date: moment(date).add(3, 'h'),
          time,
        },
      ],
    })
  }

  const getDropdown = () => {
    const dates = [
      { text: 'До 11:00', value: 'до 11:00' },
      { text: 'До 14:00', value: 'до 14:00' },
      { text: 'До 18:00', value: 'до 18:00' },
      { text: 'Выходной', value: 'выходной' },
    ]
    return (
      <div className="tile__dropdown">
        {dates
          .filter(item => (item.value === selectedTime ? false : true))
          .map(item => (
            <div
              onClick={() => onChangeTime(item.value)}
              className="tile__line"
            >
              {item.text}
            </div>
          ))}
      </div>
    )
  }

  return (
    <Container>
      <ShownText>{selectedTime}</ShownText>
      {getDropdown()}
    </Container>
  )
}

export default TileContent
