import React from 'react'
import * as R from 'ramda'
import { connect } from 'react-redux'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { Loader } from '../components'
import {
  PromotionsBlock,
  PromotionsEditorBlock,
  PromotionProductBlock,
} from '../components/promotions'
import { useAction } from '../effects'
import {
  getPromotionsRequest,
  getIsLoadingPromotions,
} from '../modules/promotions'

import { getAccessListForUser } from '../modules/users'

import AccessForbiddenPopup from '../components/common/AccessForbidden'
import { useWebSockets } from '../context'
const StyledLoader = styled(Loader)`
  margin: auto;
  align-self: center;
`

const Wrap = styled.div`
  background: #ecf1f4;
  display: flex;
  flex: 1;
  overflow-y: scroll;
  padding: 0 10px;
`

const Promotions = ({ accessList }) => {
  const isLoading = useSelector(getIsLoadingPromotions)
  const getPromotions = useAction(getPromotionsRequest)
  const [modalShow, setModalShow] = React.useState(false)

  const [editAccess, setEditAccesses] = React.useState([])

  const [viewAccess, setViewAccess] = React.useState([])

  const [modalViewShow, setModalViewShow] = React.useState(false)

  React.useEffect(() => {
    if (!accessList.length) return
    let view = accessList.filter(
      access => access.module === 'promotions' && access.accessType === 'view',
    )
    let edit = accessList.filter(
      access => access.module === 'promotions' && access.accessType === 'edit',
    )
    setEditAccesses(edit)
    setViewAccess(view)
    setModalViewShow(!view.length)
  }, [accessList, accessList.length])


  const { updatedData } = useWebSockets()

  const promotionEvents = [
    'updatePromotion',
    'createPromotion',
    'deletePromotion',
  ]

  React.useEffect(() => {
    if (updatedData) {
      if (promotionEvents.includes(updatedData.action)) {
        getPromotions()
      }
    }
  }, [updatedData])

  React.useEffect(() => {
    getPromotions()
  }, [getPromotions])

  return modalViewShow ? (
    <AccessForbiddenPopup
      moduleName={'Акции'}
      setModalShow={setModalViewShow}
      canClose={false}
    />
  ) : (
    <Wrap>
      {modalShow && (
        <AccessForbiddenPopup
          moduleName={'Акции'}
          setModalShow={setModalShow}
        />
      )}
      {isLoading ? (
        <StyledLoader />
      ) : (
        <>
          <PromotionsBlock
            editAccess={editAccess}
            setModalShow={setModalShow}
          />
          <PromotionsEditorBlock
            editAccess={editAccess}
            setModalShow={setModalShow}
          />
          <PromotionProductBlock
            editAccess={editAccess}
            setModalShow={setModalShow}
          />
        </>
      )}
    </Wrap>
  )
}

export default connect(
  R.applySpec({
    accessList: getAccessListForUser,
  }),
)(Promotions)
