import styled from 'styled-components'
import React from 'react'


const Wrapper = styled.div` 
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0,0,0,.6);
  z-index: 99999;
`

const Container = styled.div`
  width: 516px;
  height: 245px;
  background: #ffffff;
  box-shadow: 0 6px 14px rgba(2, 9, 75, 0.06);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 20px 10px;
`

const TextContainer = styled.div`
  height: 160px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-family: 'FuturaBookC', sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0
`

const ButtonContainer = styled.div`
  display: flex;
  background: #fff;
  padding: 20px 0;
  border-radius: 8px;
  width: 100%;
  justify-content: center;
`

const CloseButton = styled.button`
  height: 36px;
  border: none;
  background: linear-gradient(135deg, #f49355 0%, #f0640c 100%);
  box-shadow: 0 4px 10px rgba(247, 137, 68, 0.55);
  cursor: pointer;
  outline: none;
  border-radius: 3px;
  color: white;
  width: 95px;
`


export const NoDataForDownloadModal = ({isOpen,setIsOpen}) => {
  if(!isOpen) {
    return null
  }
  return  (
    <Wrapper>
    <Container id='tttt'>
      <TextContainer>Нет данных для выгрузки</TextContainer>
      <ButtonContainer>
        <CloseButton onClick={()=>setIsOpen(false)}>
          Закрыть
        </CloseButton>
      </ButtonContainer>
    </Container>
    </Wrapper>
  )
}


