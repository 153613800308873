import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  select: {
    '& > .MuiSelect-select': {
      padding: '6px 28px 7px 0',
    },
    fontSize: 20,
    fontFamily: 'FuturaMediumC',
    '&:before': {
      borderBottom: 'none',
    },
    '&:hover:not(.Mui-disabled):before': {
      borderBottom: 'none',
    },
    '&:after': {
      borderBottom: 'none',
    },
  },
  icon: {
    fill: '#F49355',
  },
  menu: {
    left: '320px !important',
    padding: '7px 0',
    boxShadow: '0px 6px 14px 0px rgba(2, 9, 75, 0.06)',
  },
}))

export default useStyles
