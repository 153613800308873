import { call, all, put, takeLatest, select } from 'redux-saga/effects'
import {
  getStoragesSuccess,
  getStoragesRequest,
  getStorageProvidersRequest,
  getStorageProvidersSuccess,
  deleteStorageRequest,
  updateStorageRequest,
  createStorageRequest,
  getStorageProductsRequest,
  setError,
  getStorageProductsSuccess,
  createStorageProductRequest,
  updateStorageProductRequest,
  deleteStorageProductRequest,
  createStorageProviderRequest,
  updateStorageProviderRequest,
  deleteStorageProviderRequest,
} from './duck'
import * as StoragesManager from './StoragesManager'
import { getToken } from '../auth'

function* getStoragesSaga({ payload, search }) {
  try {
    const accessToken = yield select(getToken)
    const sortKey = payload?.sortKey ? payload.sortKey : 'title'
    const sortDirection = payload?.sortDirection ? payload.sortDirection : 'asc'
    const storages = yield call(StoragesManager.getStorages, {
      accessToken,
      search: {
        search: search !== '' ? search : '',
        sortKey,
        sortDirection,
      },
    })
    // yield put(getActiveSortSuccess({ sortKey, sortDirection }))
    yield put(getStoragesSuccess(storages))
  } catch (ex) {
    yield put(setError(ex.localeMessage ?? ex.message))
  }
}

function* removeStorageSaga({ payload: { storageId } }) {
  try {
    const accessToken = yield select(getToken)
    yield call(
      StoragesManager.deleteStorage,
      {
        accessToken,
        id: storageId
      },
    )
    yield put(getStoragesRequest())
  } catch (ex) {
    yield put(setError(ex.localeMessage ?? ex.message))
  }
}

function* updateStorageSaga(
  {
    payload: {
      id,
      address,
      isActive,
      name,
      phone,
      price,
    },
  }) {
  try {
    const accessToken = yield select(getToken)
    const phoneWithCode = '+7' + phone.replace(/[^+\d]/g, '')
    const body = {
      id,
      address,
      isActive,
      name,
      phone: phoneWithCode,
      price,
    }

    yield call(
      StoragesManager.updateStorage,
      {
        id,
        ...body,
        accessToken,
      },
    )

    yield put(getStoragesRequest())
  } catch (ex) {
    yield put(setError(ex.localeMessage ?? ex.message))
  }
}

function* createStorageSaga(
  {
    payload: {
      address,
      isActive,
      name,
      phone,
      price,
    },
  }) {
  const phoneWithCode = '+7' + phone.replace(/[^+\d]/g, '')
  try {
    const accessToken = yield select(getToken)
    const body = {
      address,
      isActive,
      name,
      phone: phoneWithCode,
      price,
    }

    yield call(StoragesManager.createStorage, {
      ...body,
      accessToken,
    })

    // yield put(createStorageSuccess({ name }))
    yield put(getStoragesRequest())
  } catch (ex) {
    yield put(setError(ex.localeMessage ?? ex.message))
  }
}

function* getStorageProvidersSaga({ payload: { storageId, search } }) {
  try {
    const accessToken = yield select(getToken)

    const providers = yield call(StoragesManager.getStorageProviders, {
      accessToken,
      storageId,
      search,
    })
    yield put(getStorageProvidersSuccess(providers))
  } catch (ex) {
    yield put(setError(ex.localeMessage ?? ex.message))
  }
}

function* createStorageProviderSaga(
  {
    payload,
  }) {
  // const phoneWithCode = '+7' + phone.replace(/[^+\d]/g, '')
  try {
    const accessToken = yield select(getToken)

    yield call(StoragesManager.createStorageProvider, {
      ...payload,
      accessToken,
    })

    yield put(getStorageProvidersRequest())
  } catch (ex) {
    yield put(setError(ex.localeMessage ?? ex.message))
  }
}

function* updateStorageProviderSaga(
  {
    payload,
  }) {
  // const phoneWithCode = '+7' + phone.replace(/[^+\d]/g, '')
  try {
    const accessToken = yield select(getToken)

    yield call(StoragesManager.updateStorageProvider, {
      ...payload,
      accessToken,
    })

    yield put(getStorageProvidersRequest())
  } catch (ex) {
    yield put(setError(ex.localeMessage ?? ex.message))
  }
}

function* removeStorageProviderSaga(
  {
    payload,
  }) {
  try {
    const accessToken = yield select(getToken)

    yield call(StoragesManager.deleteStorageProvider, {
      ...payload,
      accessToken,
    })

    yield put(getStorageProvidersRequest())
  } catch (ex) {
    yield put(setError(ex.localeMessage ?? ex.message))
  }
}

function* getStorageProductsSaga({ payload: { storageId, search } }) {
  try {
    const accessToken = yield select(getToken)

    const providers = yield call(StoragesManager.getStorageProducts, {
      accessToken,
      storageId,
      search: {},
    })
    yield put(getStorageProductsSuccess(providers))
  } catch (ex) {
    yield put(setError(ex.localeMessage))
  }
}

function* createStorageProductSaga(
  {
    payload,
  }) {
  try {
    const accessToken = yield select(getToken)

    yield call(StoragesManager.createStorageProduct, {
      ...payload,
      accessToken,
    })

    yield put(getStorageProductsRequest())
  } catch (ex) {
    yield put(setError(ex.localeMessage ?? ex.message))
  }
}

function* updateStorageProductSaga(
  {
    payload,
  }) {
  try {
    const accessToken = yield select(getToken)

    yield call(StoragesManager.updateStorageProduct, {
      ...payload,
      accessToken,
    })

    yield put(getStorageProductsRequest())
  } catch (ex) {
    yield put(setError(ex.localeMessage ?? ex.message))
  }
}

function* removeStorageProductSaga(
  {
    payload,
  }) {
  try {
    const accessToken = yield select(getToken)

    yield call(StoragesManager.deleteStorageProduct, {
      ...payload,
      accessToken,
    })

    yield put(getStorageProductsRequest())
  } catch (ex) {
    yield put(setError(ex.localeMessage ?? ex.message))
  }
}


function* storagesSaga() {
  yield all([
    takeLatest(getStoragesRequest, getStoragesSaga,),
    takeLatest(getStorageProvidersRequest, getStorageProvidersSaga),
    takeLatest(getStorageProductsRequest, getStorageProductsSaga),
    takeLatest(createStorageRequest, createStorageSaga),
    takeLatest(updateStorageRequest, updateStorageSaga),
    takeLatest(deleteStorageRequest, removeStorageSaga),

    takeLatest(createStorageProviderRequest, createStorageProviderSaga),
    takeLatest(updateStorageProviderRequest, updateStorageProviderSaga),
    takeLatest(deleteStorageProviderRequest, removeStorageProviderSaga),

    takeLatest(createStorageProductRequest, createStorageProductSaga),
    takeLatest(updateStorageProductRequest, updateStorageProductSaga),
    takeLatest(deleteStorageProductRequest, removeStorageProductSaga),
  ])
}

export default storagesSaga