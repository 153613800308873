import React from 'react'
import { TextField, Button, Modal } from '@material-ui/core'
import SearchSelect from '../search-select'
import { useStyles } from './styles'
import { Option } from '../boards-select'
import styled from 'styled-components'
const getModalStyle = () => {
  const top = 50
  const left = 50

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}
const Margin = styled.div`
  margin: 20px 0 0 0;
`
const AddValueModal = ({
  open,
  handleClose,
  value,
  setValue,
  handleSubmit,
  title,
  roles,
}) => {
  const classes = useStyles()
  const [modalStyle] = React.useState(getModalStyle)
  return (
    <Modal open={open} onClose={handleClose}>
      <div style={modalStyle} className={classes.paper}>
        <div className={classes.title}>{title}</div>
        <TextField
          classes={{
            root: classes.input,
          }}
          variant="outlined"
          value={value.columnName}
          onChange={e => setValue({ ...value, columnName: e.target.value })}
        />
        <Margin>
          <SearchSelect
            value={value.roleId}
            transformX={-5}
            label="Выберите роль"
            width={260}
            onChange={e => setValue({ ...value, roleId: e.target.value })}
          >
            <Option key={-1} value={''}>
              Все
            </Option>
            {roles.map((role, index) => (
              <Option key={index} value={role.id}>
                {role.name}
              </Option>
            ))}
          </SearchSelect>
        </Margin>
        <div className={classes.buttonWrapper}>
          <Button
            onClick={handleSubmit}
            classes={{
              root: classes.addButton,
            }}
          >
            Создать
          </Button>
          <Button
            onClick={handleClose}
            classes={{
              root: classes.cancelButton,
            }}
          >
            Отмена
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default AddValueModal
