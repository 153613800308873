import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import DocumentsViewModal from '../documents-view-modal'
import Document from './document'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const DocumentItemView = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 36px;
  background: #ffffff;
  box-shadow: 0 6px 10px rgba(2, 9, 75, 0.06);
  border-radius: 8px;
  padding: 0 15px;
  margin-bottom: 15px;
  cursor: pointer;
`
const DocumentItemTitle = styled.div`
  font-family: FuturaBookC, sans-serif;
  font-size: 14px;
  display: flex;
  align-items: center;
  color: rgba(18, 22, 26, 0.9);
`
const DocumentItemCount = styled.div`
  font-family: FuturaBookC, sans-serif;
  font-size: 14px;
  display: flex;
  align-items: center;
  color: rgba(18, 22, 26, 0.4);
`


const groupBy = (xs, key) =>
  xs.reduce((rv, x) => {
    ;(rv[x[key]] = rv[x[key]] || []).push(x)
    return rv
  }, {})

const Documents = ({
  documents,
  setOrganizationCreateData,
  setOrganizationEditData,
  organizationCreateData,
  organizationEditData,
  isEditMode,
  isCreatingMode,
  isAddingDocument,
  setIsAddingDocument,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedDocuments, setSelectedDocuments] = useState([])

  useEffect(() => {
    setIsAddingDocument(false)
    setSelectedDocuments([])
  }, [isEditMode, isCreatingMode])

  const groupedDocuments =
    documents.length > 0 ? groupBy(documents, 'title') : {}

  const handleSelectDocuments = item => {
    setSelectedDocuments(groupedDocuments[item])
    setIsModalOpen(true)
  }

  const handleModalClose = () => {
    setIsModalOpen(false)
    setSelectedDocuments([])
  }

  const handleAddDocuments = files => {
    if (isCreatingMode) {
      if (
        files[0] &&
        organizationCreateData.documents.find(item => item.id === files[0].id)
      ) {
        const newDocs = organizationCreateData.documents.filter(
          item => item.id !== files[0].id,
        )
        setOrganizationCreateData({
          ...organizationCreateData,
          documents: [...newDocs, ...files],
        })
      } else {
        setOrganizationCreateData({
          ...organizationCreateData,
          documents: [...organizationCreateData.documents, ...files],
        })
      }
    } else {
      if (
        files[0] &&
        organizationEditData.documents.find(item => item.id === files[0].id)
      ) {
        const newDocs = organizationEditData.documents.filter(
          item => item.id !== files[0].id,
        )
        setOrganizationEditData({
          ...organizationEditData,
          documents: [...newDocs, ...files],
        })
      } else {
        setOrganizationEditData({
          ...organizationEditData,
          documents: [...organizationEditData.documents, ...files],
        })
      }
    }
    setIsAddingDocument(false)
  }

  const handleDeleteDocuments = fileId => {
    if (isCreatingMode) {
      if (
        fileId &&
        organizationCreateData.documents.find(item => item.id === fileId)
      ) {
        const newDocs = organizationCreateData.documents.filter(
          item => item.id !== fileId,
        )
        setOrganizationCreateData({
          ...organizationCreateData,
          documents: [...newDocs],
        })
      }
    } else {
      if (
        fileId &&
        organizationEditData.documents.find(item => item.id === fileId)
      ) {
        const newDocs = organizationEditData.documents.filter(
          item => item.id !== fileId,
        )
        setOrganizationEditData({
          ...organizationEditData,
          documents: [...newDocs],
        })
      }
    }
  }

  const viewItems =
    documents.length > 0 &&
    Object.keys(groupedDocuments).map(item => (
      <DocumentItemView onClick={() => handleSelectDocuments(item)} key={item}>
        <DocumentItemTitle>{item}</DocumentItemTitle>
        <DocumentItemCount>
          {groupedDocuments[item].length === 1
            ? ''
            : groupedDocuments[item].length}
        </DocumentItemCount>
      </DocumentItemView>
    ))

  const items =
    documents.length > 0 &&
    documents.map(item => (
      <Document
        key={item.id}
        isEditMode={isEditMode}
        isCreatingMode={isCreatingMode}
        document={item}
        handleAddDocuments={handleAddDocuments}
        handleDeleteDocuments={handleDeleteDocuments}
      />
    ))


  return (
    <Wrapper>
      {selectedDocuments.length > 0 && (
        <DocumentsViewModal
          open={isModalOpen}
          handleModalClose={handleModalClose}
          documents={selectedDocuments}
        />
      )}
      {!isEditMode && !isCreatingMode ? (
        viewItems
      ) : (
        <>
          {items}
          <Document
            isEditMode={isEditMode}
            isCreatingMode={isCreatingMode}
            isAdd
            isAddingDocument={isAddingDocument}
            setIsAddingDocument={setIsAddingDocument}
            handleAddDocuments={handleAddDocuments}
            handleDeleteDocuments={handleDeleteDocuments}
          />
        </>
      )}
    </Wrapper>
  )
}

export default Documents
