import * as R from 'ramda'
import { createAction, handleActions, handleAction } from 'redux-actions'
import { combineReducers } from 'redux'
import { MODULES } from '../../constants'

export const createAdminUserRequest = createAction(
  `${MODULES.ADMINUSERS}/CREATE_ADMINUSER_REQUEST`,
)

export const createAdminUserSuccess = createAction(
  `${MODULES.ADMINUSERS}/CREATE_ADMINUSER_SUCCESS`,
)

export const createAdminUserFailure = createAction(
  `${MODULES.ADMINUSERS}/CREATE_ADMINUSER_FAILURE`,
)

export const getAdminUsersRequest = createAction(
  `${MODULES.ADMINUSERS}/GET_ADMINUSERS_REQUEST`,
)

export const getAdminUsersSuccess = createAction(
  `${MODULES.ADMINUSERS}/GET_ADMINUSERS_SUCCESS`,
)

export const getAdminUsersFailure = createAction(
  `${MODULES.ADMINUSERS}/GET_ADMINUSERS_FAILURE`,
)

export const getOrganizationUsersRequest = createAction(
  `${MODULES.ADMINUSERS}/GET_ORGUSERS_REQUEST`,
)

export const getOrganizationUsersSuccess = createAction(
  `${MODULES.ADMINUSERS}/GET_ORGUSERS_SUCCESS`,
)

export const getOrganizationUsersFailure = createAction(
  `${MODULES.ADMINUSERS}/GET_ORGUSERS_FAILURE`,
)

export const updateAdminUserRequest = createAction(
  `${MODULES.ADMINUSERS}/UPDATE_ADMINUSER_REQUEST`,
)

export const updateAdminUserSuccess = createAction(
  `${MODULES.ADMINUSERS}/UPDATE_ADMINUSER_SUCCESS`,
)

export const updateAdminUserFailure = createAction(
  `${MODULES.ADMINUSERS}/UPDATE_ADMINUSER_FAILURE`,
)

export const selectAdminUser = createAction(
  `${MODULES.ADMINUSERS}/SELECT_ADMINUSER`,
)

export const startCreatingAdminUser = createAction(
  `${MODULES.ADMINUSERS}/START_CREATING_ADMINUSER`,
)

export const sendCredsSuccess = createAction(
  `${MODULES.ADMINUSERS}/SEND_CREDS_SUCCESS`,
)

export const sendCredsFailure = createAction(
  `${MODULES.ADMINUSERS}/SEND_CREDS_FAILURE`,
)

export const deleteAdminUserSuccess = createAction(
  `${MODULES.ADMINUSERS}/DELETE_ADMINUSER_SUCCESS`,
)

export const deleteAdminUserRequest = createAction(
  `${MODULES.ADMINUSERS}/DELETE_ADMINUSER_REQUEST`,
)

export const deleteAdminUserFailure = createAction(
  `${MODULES.ADMINUSERS}/DELETE_ADMINUSER_SUCCESS`,
)

const byId = handleActions(
  {
    [getAdminUsersSuccess]: (state, { payload }) => ({
      ...state,
      ...R.indexBy(R.prop('id'))(payload),
    }),
    [deleteAdminUserSuccess]: (state, { payload: id }) => R.dissoc(id, state),
  },
  {},
)

const allIds = handleActions(
  {
    [getAdminUsersSuccess]: (state, { payload }) =>
      R.uniq([...state, ...R.map(R.prop('id'))(payload)]),
    [deleteAdminUserSuccess]: (state, { payload: id }) =>
      state.filter(x => x !== id),
  },
  [],
)

const getOrgUsers = handleActions(
  {
    [getOrganizationUsersSuccess]: (_, { payload }) => payload[0].users,
  },
  [],
)

const isCreatingAdminUser = handleActions(
  {
    [startCreatingAdminUser]: R.T,
    [createAdminUserSuccess]: R.F,
    [selectAdminUser]: R.F,
  },
  null,
)

const isPostingAdminUser = handleActions(
  {
    [createAdminUserRequest]: R.T,
    [createAdminUserSuccess]: R.F,
    [createAdminUserFailure]: R.F,
  },
  null,
)

const adminUserError = handleActions(
  {
    [createAdminUserFailure]: R.T,
    [createAdminUserSuccess]: R.F,
    [createAdminUserRequest]: R.F,
  },
  false,
)

const selectedAdminUser = handleActions(
  {
    [selectAdminUser]: (_, { payload }) => payload,
    [startCreatingAdminUser]: R.always(null),
    [updateAdminUserSuccess]: R.always(null),
  },
  null,
)

const isLoading = handleActions(
  {
    [getAdminUsersRequest]: R.T,
    [getAdminUsersSuccess]: R.F,
    [getAdminUsersFailure]: R.F,
  },
  false,
)

const adminUsersReducer = combineReducers({
  getOrgUsers,
  selectedAdminUser,
  isLoading,
  isCreatingAdminUser,
  isPostingAdminUser,
  byId,
  allIds,
  adminUserError,
})

export default adminUsersReducer
