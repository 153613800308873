import React from 'react'
import styled from 'styled-components'

import { searchWhite } from '../../../../assets/img'

const Wrapper = styled.div`
  height: 36px;
  border-radius: 8px;
  width: 100%;
  display: flex;
  margin-bottom: 30px;
`

const Input = styled.input.attrs({ type: 'text' })`
  height: 36px;
  border: none;
  outline: none;
  border-radius: 8px 0 0 8px;
  padding: 10px;
  box-sizing: border-box;
  width: 100%;
`

const SearchIconContainer = styled.div`
  background: linear-gradient(92.15deg, #F6A26D -38.69%, #F87C2E 107.08%);
  border-radius: 0 8px 8px 0;
  height: 36px;
  width: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const SearchIcon = styled.img.attrs({ src: searchWhite })``

const SearchBar = ({ value, onChange }) => (
  <Wrapper>
    <Input value={value} onChange={e => onChange(e.target.value)} placeholder='Поиск' />
    <SearchIconContainer>
      <SearchIcon />
    </SearchIconContainer>
  </Wrapper>
)

export default SearchBar