import React from 'react'
import { connect } from 'react-redux'
import {
  Container,
  CategoryType,
  CategoryTitle,
} from './category-element-components'
import * as R from 'ramda'

import SubcategoryCreator from './SubcategoryCreator'
import SubcategoryElement from './SubcategoryElement'
import SubcategoryQuanityModal from '../common/SubcategoryQuanityModal'

import { useCategoryElement } from '../../effects/categories'
import {
  createCategoryRequest,
  updateCategoryRequest,
  getCategoriesList,
} from '../../modules/categories'

const CategoryElementDump = ({
  id,
  title,
  onClick,
  subcategories,
  selectedCategory,
  deleteSubcategoryRequest,
  createCategoryRequest,
  updateCategoryRequest,
  isEditable,
  editAccess,
  setModalShow,
}) => {
  const {
    deleteId,
    editId,
    setEditId,
    setDelete,
    onCancelDeleteClick,
    globalSelected,
    onAcceptDeleteClick,
    onSubcategoryContainerClick,
    stopEdit,
  } = useCategoryElement({
    selectedCategory,
    subcategories,
    id,
    deleteSubcategoryRequest: deleteSubcategoryRequest,
    onBlockClick: onClick,
    updateCategoryRequest: updateCategoryRequest,
  })
  const [isOpen, setIsOpen] = React.useState(false)

  return (
    <>
      <>
        <Container
          selected={selectedCategory === id}
          onClick={() => onClick(id)}
        >
          <CategoryType selected={selectedCategory === id}>
            {id === 1 ? 'Основная категория' : `Категория ${id}`}
          </CategoryType>
          <CategoryTitle selected={selectedCategory === id}>
            {title}
          </CategoryTitle>
        </Container>
        {globalSelected && (
          <>
            {isEditable && (
              <SubcategoryCreator
                parentId={id}
                onClick={onCancelDeleteClick}
                action={createCategoryRequest}
                editId={editId}
                stopEdit={stopEdit}
                editAccess={editAccess}
                setModalShow={setModalShow}
              />
            )}
            {subcategories.map(subcategory => (
              <React.Fragment key={subcategory.id}>
                {isOpen && (
                  <SubcategoryQuanityModal
                    onEditButtonClick={id => setEditId(id)}
                    selectedCategory={selectedCategory}
                    deleteId={deleteId}
                    stopEdit={stopEdit}
                    setEditId={setEditId}
                    setIsOpen={setIsOpen}
                    subcategory={subcategory}
                    onAcceptDeleteClick={onAcceptDeleteClick}
                    editAccess={editAccess}
                    setModalShow={setModalShow}
                  />
                )}
                <SubcategoryElement
                  key={subcategory.id}
                  deleteId={deleteId}
                  parentId={id}
                  editId={editId}
                  selectedCategory={selectedCategory}
                  onEditButtonClick={id => {
                    // setEditId(id)
                    setIsOpen(true)
                  }}
                  onDeleteButtonClick={setDelete}
                  onCancelDeleteClick={onCancelDeleteClick}
                  onAcceptDeleteClick={onAcceptDeleteClick}
                  onSubcategoryContainerClick={onSubcategoryContainerClick}
                  updateCategoryRequest={updateCategoryRequest}
                  stopEdit={stopEdit}
                  {...subcategory}
                  editAccess={editAccess}
                  setModalShow={setModalShow}
                />
              </React.Fragment>
            ))}
          </>
        )}
      </>
    </>
  )
}

const CategoryElement = connect(
  R.applySpec({
    categories: getCategoriesList,
  }),
  {
    createCategoryRequest,
    updateCategoryRequest,
  },
)(CategoryElementDump)

export default CategoryElement
