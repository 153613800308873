import React, { useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import assets from '../../assets'

const Container = styled.div`
  display: flex;
  align-items: ${({ isActive }) => (isActive ? 'normal' : 'center')};
  justify-content: space-between;
  height: 36px;
  position: relative;
  background-color: white;
  border-radius: 6px;
  color: #35414c;
  font-size: 14px;
  line-height: 19px;
  border: 1px solid #f49355;
  border-bottom-left-radius: ${({ isActive }) => (isActive ? '0' : '6px')};
  border-bottom-right-radius: ${({ isActive }) => (isActive ? '0' : '6px')};
  cursor: pointer;
`

const DropDownContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: ${({ isActive }) => (isActive ? 'absolute' : 'relative')};
  height: ${({ isActive }) => (isActive ? 'auto' : '39px')};
  max-height: ${({ isActive }) => (isActive ? '200px' : '39px')};
  background-color: white;
  width: 100%;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  padding-bottom: 10px;
  top: 100%;
  left: -1px;
  z-index: 30;
  border: 1px solid #f49355;
  overflow: scroll;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    display: none;
  }
`

const DropDownStart = styled.div`
  font-family: 'FuturaBookC', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 15px;
  display: flex;
  align-items: center;
  color: rgba(18, 22, 26, 0.9);
  padding-left: 15px;
`

const DropDownDefault = styled.div`
  font-family: 'FuturaBookC', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 15px;
  display: flex;
  align-items: center;
  padding: 11px 11px 9px 11px;
  margin-top: 4px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${({ disabled }) => (disabled ? 'rgba(0, 0, 0, 0.38)' : '')};
`

const DropDownItem = styled.div`
  padding-top: 15px;
  padding-left: 15px;
  z-index: 1;
  font-family: 'FuturaBookC', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 15px;
  color: rgba(18, 22, 26, 0.9);
`

const ShrinkLabel = styled.div`
  position: absolute;
  top: -7px;
  left: 9px;
  padding: 0 4px;
  background: #ffffff;
  font-family: FuturaBookC, sans-serif;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.4);
`

const DropDownItemText = styled.div`
  z-index: 1;
  font-family: 'FuturaBookC', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 15px;
  color: rgba(18, 22, 26, 0.9);
`

const Arrow = styled.img.attrs({ src: assets.img.selectArrow })`
  transform: ${({ isActive }) => (isActive ? 'rotate(180deg)' : '')};
  margin-right: 16px;
  width: 10px;
`
const SearchInput = styled.input`
  padding: 3px;
`

const SearchSelectDropDown = ({
                                disabled,
                                items,
                                setActiveItem,
                                isSelectModalOpen,
                                setIsSelectModalOpen,
                                defaultValue,
                                visibleObjectName,
                                hideObjectName,
                                name,
                                shrinkLabel,
                                shrinkStyles,
                                styles,
                                defaultStyles,
                                notSorted,
                                withoutSearch,
                              }) => {
  const [chosenItem, setChosenItem] = useState('')
  const [searchValue, setSearchValue] = useState('')
  const [searchActive, setSearchActive] = useState(false)
  const ref = useRef(null)
  const handleClickOutside = event => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsSelectModalOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  })

  useEffect(() => {
    if (defaultValue) {
      const currentItem = items.find(
        item => item[hideObjectName] === defaultValue,
      )
      if (currentItem) {
        setChosenItem(currentItem[visibleObjectName] || defaultValue)
      } else {
        setChosenItem(defaultValue)
      }
    } else {
      setChosenItem('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue])

  const itemsToShow = useMemo(() => {
    return items.filter(
      (item) =>
        item[visibleObjectName]
          ?.toLowerCase()
          ?.indexOf(searchValue.toLowerCase()) !== -1,
    )
  }, [items, searchValue])

  return (
    <Container
      onClick={() => {
        !disabled && !searchActive && setIsSelectModalOpen(!isSelectModalOpen)
      }
      }
      isActive={isSelectModalOpen}
      style={{ ...styles }}
      ref={ref}
    >
      {shrinkLabel && (
        <ShrinkLabel style={{ ...shrinkStyles }}>{shrinkLabel}</ShrinkLabel>
      )}
      {isSelectModalOpen ? (
        <DropDownStart>
          {chosenItem}
          <DropDownContainer isActive={isSelectModalOpen}>
            {!withoutSearch && (
              <DropDownItem>
                <SearchInput
                  type='text'
                  placeholder={'Поиск...'}
                  onFocus={() => setSearchActive(true)}
                  onBlur={() => setSearchActive(false)}
                  onKeyDown={e => {
                    if (e.key === 'Enter') e.preventDefault()
                  }}
                  onKeyUp={e => {
                    setSearchValue(e.target.value)
                  }}
                />
              </DropDownItem>
            )}
            {defaultValue && (
              <DropDownItem key={-1}>
                <DropDownItemText
                  onClick={() => {
                    setChosenItem(defaultValue)
                    setActiveItem(name, '')
                    setSearchValue('')
                  }}
                >
                  {defaultValue}
                </DropDownItemText>
              </DropDownItem>
            )}
            {itemsToShow.map(
              (item, index) =>
                (
                  <DropDownItem key={index}>
                    <DropDownItemText
                      onClick={() => {
                        setChosenItem(item[visibleObjectName])
                        setActiveItem(name, item[hideObjectName])
                        setSearchValue('')
                      }}
                    >
                      {item[visibleObjectName]}
                    </DropDownItemText>
                  </DropDownItem>
                ),
            )}
          </DropDownContainer>
        </DropDownStart>
      ) : (
        <DropDownDefault disabled={disabled} style={{ ...defaultStyles }}>
          {chosenItem}
        </DropDownDefault>
      )}
      <Arrow isActive={isSelectModalOpen} />
    </Container>
  )
}

export default SearchSelectDropDown
