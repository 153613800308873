import React, { useCallback } from 'react'
import styled from 'styled-components'

import { withFullHeight } from './utils'

const Container = withFullHeight()(styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  position: fixed;
  left: 0;
  top: 0;
  overflow: hidden;
  z-index: 10000;
  @media screen and (min-width: 320px) and (max-width: 767px) {
    overflow-y: scroll;
  }
`)

const ServiceScrollBody = {
  count: 0,
  openModal() {
    this.count++
    document.body.style.overflow = 'hidden'
  },
  closeModal() {
    if (!--this.count) {
      document.body.style.overflow = ''
    }
  },
}

export const Modal = ({
  visible,
  children,
  onRequestClose: onRequestCloseBase = () => {},
  ...props
}) => {
  const refMouseDownElement = React.useRef(null)

  const onRequestClose = React.useRef(onRequestCloseBase)
  onRequestClose.current = onRequestCloseBase

  React.useEffect(() => {
    if (visible) {
      ServiceScrollBody.openModal()
      return () => ServiceScrollBody.closeModal()
    }
  }, [visible])

  React.useEffect(() => {
    const handlerKeyDown = event => {
      if (event.key === 'Escape') {
        onRequestClose.current(false)
      }
    }
    document.addEventListener('keyup', handlerKeyDown)
    return () => document.removeEventListener('keyup', handlerKeyDown)
  }, [])

  return visible ? (
    // @ts-ignore
    <Container
      // @ts-ignore
      onMouseUp={event => {
        if (
          event.target === event.currentTarget &&
          event.target === refMouseDownElement.current
        ) {
          onRequestClose.current(false)
        }
        refMouseDownElement.current = null
      }}
      // @ts-ignore
      onMouseDown={event => {
        refMouseDownElement.current = event.target
      }}
      // @ts-ignore
      onClick={event => event.stopPropagation()}
      {...props}
    >
      {children}
    </Container>
  ) : null
}

export const ModalDark = styled(Modal)`
  background-color: rgba(53, 65, 76, 0.6);
`

export const ButtonBase = styled.button.attrs(({ type = 'button' }) => ({
  type,
}))`
  padding: 0;
  background: initial;
  border: initial;
  outline: none !important;
  cursor: pointer;
`

export const Button = styled(ButtonBase)`
  padding: 8px 16px;

  font-family: 'FuturaBookC', sans-serif;
  font-size: 15px;
  color: #ffffff;

  display: inline-flex;
  justify-content: center;
  align-items: center;

  &:hover {
    box-shadow: 0 4px 10px rgba(247, 137, 68, 0.55);
  }
  &:disabled {
    background-color: #ff9f63;
    cursor: initial;
    box-shadow: initial;
  }
  transition: 225ms;

  background-color: #f0640c;
  border-radius: 6px;
  border: 1px solid transparent;
  ${({ isError }) => (isError ? 'border-color: #F06060;' : '')}
`

export const ButtonWhite = styled(Button)`
  background: #ffffff;
  color: #f0640c;
  box-shadow: 0 2px 5px rgba(2, 9, 75, 0.15);
  &:disabled {
    background-color: white;
    cursor: initial;
    box-shadow: initial;
  }
`

const Wrapper = styled.div`
  width: 100%;
  position: relative;
`

const Scroll = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  ::-webkit-scrollbar {
    display: none;
  }
`

const Children = styled.div`
  white-space: normal;
  display: flex;
  flex-direction: column;
  margin-left: 30px;
  overflow: hidden;
  overflow-y: auto;
  @media screen and (min-width: 320px) and (max-width: 767px) {
    margin-left: 15px;
  }
`

export const HorizontalScrollableRadioGroup = ({
  name = '',
  value,
  items,
  onChange,
  children,
  className,
}) => {
  const handleChange = useCallback(value => () => onChange(value), [onChange])

  const generateId = useCallback(value => `${name}-${value}`, [name])

  return (
    <Wrapper className={className}>
      <Scroll>
        {items.map(item => (
          <Children key={item.value}>
            {children(
              generateId(item.value),
              item.label || item.value,
              item.value,
              item.value === value,
              handleChange(item.value),
            )}
          </Children>
        ))}
      </Scroll>
    </Wrapper>
  )
}

const InputTextStyle = styled.input`
  padding: 9px 14px;
  &::placeholder {
    color: #000000;
    opacity: 0.3;
  }
  &:focus {
    border-color: #f49355;
  }
  transition: 225ms;

  outline: none;
  font-family: 'FuturaBookC', sans-serif;
  font-size: 12px;
  line-height: 16px;
  color: #8b949b;

  border: 1px solid transparent;
  background: #ecf1f4;
  border-radius: 6px;
  ${({ isError }) => (isError ? 'border-color: #F55555;' : '')};
`

export const InputText = React.forwardRef(({ onChange, ...props }, ref) => (
  <InputTextStyle
    type="text"
    onChange={event => onChange(event.target.value)}
    // @ts-ignore
    ref={ref}
    {...props}
  />
))

export const InputTextArea = React.forwardRef((props, ref) => {
  // @ts-ignore
  return <InputText as="textarea" ref={ref} {...props} />
})

export const useMediaQuery = query => {
  const [state, setState] = React.useState(
    () => window.matchMedia(query).matches,
  )
  React.useEffect(() => {
    const media = window.matchMedia(query)
    const handler = event => setState(event.matches)

    media.addListener(handler)
    return () => media.removeListener(handler)
  }, [query])

  return state
}
