import { call, all, put, takeLatest, select, delay } from 'redux-saga/effects'
import {
  getProductsRequest,
  getProductsSuccess,
  getProductsFailure,
  deleteProductRequest,
  deleteProductSuccess,
  deleteProductFailure,
  clearProducts,
  updateProduct,
  createProductRequest,
  createProductFailure,
  createProductSuccess,
  updateProductSuccess,
  disabledProductRequest,
  disabledProductSuccess,
  disabledProductFailure,
  getProductList,
  getProductsByCityRequest,
  getProductsByCitySuccess,
  getProductsByCityFailure,
  uploadProductsRequest,
  uploadProductsFailure,
  uploadProductsSuccess,
} from './duck'
import {
  getCategoriesList,
  setSelectedCategory,
  resetSelectedCategory,
  getSelectedCategory,
  getSelectedCategoryEntity,
  CategoriesManager,
  updateCategories,
} from '../categories'
import { setTaskModalVisible } from '../ui'
import * as ProductsManager from './ProductsManager'
import { getToken } from '../auth'
import { getSelectedProductInfo } from './selectors'
import { createBody, destructById } from '../../utils/functions'

const getCategoriesForLoadingProducts = (categories, categoryForLoading) => {
  const allCategoriesById = destructById(categories)

  const selectedCategory = allCategoriesById[categoryForLoading]

  if (!selectedCategory) {
    return []
  }

  return selectedCategory.subcategories && selectedCategory.subcategories.length
    ? selectedCategory.subcategories.map(x => x.id)
    : [selectedCategory.id]
}

function* selectCategorySaga({payload}) {
  if(payload) {
    yield put(getProductsRequest({ discountType: 'simple' }))
  } else {
    yield put(getProductsSuccess([]))
  }
}

function* getProductsSaga({
  payload: { searchLine, getAll, discountType, withoutPromotion },
}) {
  try {
    if (searchLine === '' && !getAll) {
      yield put(clearProducts())
      return
    }

    const token = yield select(getToken)
    const [products] = yield all([
      call(ProductsManager.getProducts, {filter:{}, token}),
      delay(500),
    ])
    yield put(getProductList(products))
    const categories = yield select(getCategoriesList)
    const selectedCategory = yield select(getSelectedCategory)
    const categoriesToShow = getCategoriesForLoadingProducts(
      categories,
      selectedCategory,
    )
    const filter = {
      categories: searchLine ? undefined : categoriesToShow,
      search: searchLine || undefined,
      discountType,
      withoutPromotion,
      filterDisabled: '0',
    }
    const [loadedProducts] = yield all([
      call(ProductsManager.getProducts, {token, filter} ),
      delay(500),
    ])
    yield put(getProductsSuccess(loadedProducts))
  } catch (ex) {
    yield put(getProductsFailure(ex.localeMessage))
  }
}

function* deleteProductSaga({ payload: id }) {
  try {
    const accessToken = yield select(getToken)

    yield call(ProductsManager.deleteProduct, {
      token: accessToken,
      id,
    })
    yield put(deleteProductSuccess(id))
    const categories = yield call(CategoriesManager.getCategories, {token: accessToken})
    yield put(updateCategories(categories))
  } catch (ex) {
    //
    yield put(deleteProductFailure())
  }
}

function* updateProductSaga({ payload }) {
  yield put(setTaskModalVisible(true))

  try {
    const accessToken = yield select(getToken)
    const body = createBody(payload)

    const newProduct = yield call(ProductsManager.updateProduct, {
      body,
      accessToken,
    })

    const selectedCategory = yield select(getSelectedCategoryEntity)
    const isMainCategory =
      selectedCategory && selectedCategory.subcategories !== undefined
    const selectedProduct = yield select(getSelectedProductInfo)

    if (!isMainCategory && selectedProduct.categoryId !== payload.categoryId) {
      const categories = yield call(CategoriesManager.getCategories, {token: accessToken})
      yield put(updateCategories(categories))
      yield put(resetSelectedCategory())
      yield put(clearProducts())
    }

    yield put(updateProductSuccess(newProduct))
  } catch (ex) {
    //
  }

  yield put(setTaskModalVisible(false))
}

function* createProductSaga({ payload }) {
  yield put(setTaskModalVisible(true))

  try {
    const accessToken = yield select(getToken)
    const body = yield call(createBody, payload)

    const { product: newProduct } = yield call(ProductsManager.createProduct, {
      body,
      accessToken,
    })

    const categories = yield call(CategoriesManager.getCategories, {token:accessToken})
    yield put(updateCategories(categories))

    const selectedCategoryId = yield select(getSelectedCategory)
    const selectedCategory = yield select(getSelectedCategoryEntity)

    if (
      selectedCategoryId === newProduct.categoryId ||
      (selectedCategory && selectedCategory.subcategories)
    ) {
      yield put(createProductSuccess(newProduct))
    } else {
      yield put(createProductSuccess())
    }
  } catch (ex) {
    yield put(createProductFailure(ex))
  }

  yield put(setTaskModalVisible(false))
}

function* disableProductSaga({ payload: id }) {
  try {
    const accessToken = yield select(getToken)

    yield call(ProductsManager.disableProduct, {
      token: accessToken,
      id,
    })
    yield put(disabledProductSuccess(id))
  } catch (ex) {
    //
    yield put(disabledProductFailure(id))
  }
}

function* getProductsByCitySaga({ payload }) {
  try {
    const byCity = yield call(ProductsManager.getProductsByCity, payload || {})
    yield put(getProductsByCitySuccess(byCity))
  } catch (ex) {
    yield put(getProductsByCityFailure(ex))
  }
}

function* uploadProductsSaga({ payload }) {
  const token = yield select(getToken)

  try {
    yield call(ProductsManager.uploadProducts, { token, file: payload })
    yield put(uploadProductsSuccess())
    yield put(getProductsByCityRequest())
  } catch (ex) {
    yield put(uploadProductsFailure({ message: ex.localeMessage, uri: ex.reason }))
  }
}

function* productsSaga() {
  yield all([
    takeLatest(getProductsByCityRequest, getProductsByCitySaga),
    takeLatest(setSelectedCategory, selectCategorySaga),
    takeLatest(getProductsRequest, getProductsSaga),
    takeLatest(deleteProductRequest, deleteProductSaga),
    takeLatest(updateProduct, updateProductSaga),
    takeLatest(createProductRequest, createProductSaga),
    takeLatest(disabledProductRequest, disableProductSaga),
    takeLatest(uploadProductsRequest, uploadProductsSaga),
  ])
}

export default productsSaga
