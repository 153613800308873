import { handleStatuses } from '../../utils'
import { ENDPOINTS } from '../../API'

export const getUsers = ({ filters, token }) =>
  fetch(ENDPOINTS.USERS(filters), {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then(handleStatuses())
    .then(res => res.json())
    .catch(ex => {
      throw ex
    })

    export const getMe = ({ token }) =>
    fetch(ENDPOINTS.ME(), {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(handleStatuses())
      .then(res => res.json())
      .catch(ex => {
        throw ex
      })

export const getAccessList = ({ token }) =>
  fetch(ENDPOINTS.ACCESS_LIST(), {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then(handleStatuses())
    .then(res => res.json())
    .catch(ex => {
      throw ex
    })

export const getAccessListForUser = ({ token }) =>
  fetch(ENDPOINTS.ACCESS_LIST_FOR_USER(), {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then(handleStatuses())
    .then(res => res.json())
    .catch(ex => {
      throw ex
    })

export const getRole = ({ roleId, token }) =>
  fetch(ENDPOINTS.GET_ROLE(roleId), {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then(handleStatuses())
    .then(res => res.json())
    .catch(ex => {
      throw ex
    })

export const deleteRole = ({ roleId, token }) =>
  fetch(ENDPOINTS.GET_ROLE(roleId), {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
    .then(handleStatuses())
    .then(res => res.json())
    .catch(ex => {
      throw ex
    })

export const updateRole = ({ body, roleId, token }) =>
  fetch(ENDPOINTS.GET_ROLE(roleId), {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ ...body }),
  })
    .then(handleStatuses())
    .then(res => res.json())
    .catch(ex => {
      throw ex
    })

export const createRole = ({ body, token }) =>
  fetch(ENDPOINTS.GET_ROLES(), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ ...body }),
  })
    .then(handleStatuses())
    .then(res => res.json())
    .catch(ex => {
      throw ex
    })

export const setAccess = ({ body, token }) =>
  fetch(ENDPOINTS.ACCESS(), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ ...body }),
  })
    .then(handleStatuses())
    .then(res => res.json())
    .catch(ex => {
      throw ex
    })

export const deleteAccess = ({ body, token }) =>
  fetch(ENDPOINTS.ACCESS(), {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ ...body }),
  })
    .then(handleStatuses())
    .then(res => res.json())
    .catch(ex => {
      throw ex
    })

export const getAmoUsers = ({ filters, token }) =>
  fetch(ENDPOINTS.GET_USERS(filters), {
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      Authorization: `Bearer ${token}`,
    },
  })
    .then(handleStatuses())
    .then(res => res.json())
    .catch(ex => {
      throw ex
    })

export const getAmoRoles = ({ token }) =>
  fetch(ENDPOINTS.GET_ROLES(), {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then(handleStatuses())
    .then(res => res.json())
    .catch(ex => {
      throw ex
    })

export const getAmoOrganisations = ({ token }) =>
  fetch(ENDPOINTS.GET_ALL_ORGANISATIONS(), {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then(handleStatuses())
    .then(res => res.json())
    .catch(ex => {
      throw ex
    })

export const setUserStatus = ({ userId, status, token }) =>
  fetch(ENDPOINTS.SET_USER_STATUS(userId, status, token), {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then(handleStatuses().then(res => res.json()))
    .catch(ex => {
      throw ex
    })

export const setUser = ({ body, token }) =>
  fetch(ENDPOINTS.SET_USER(), {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  })
    .then(handleStatuses().then(res => res.json()))
    .catch(ex => {
      throw ex
    })

export const updateUser = ({ body, accessToken }) =>
  fetch(ENDPOINTS.UPDATE_USER(), {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(body),
  }).then(handleStatuses())

export const updateUserRoles = ({ body, accessToken }) =>
  fetch(ENDPOINTS.UPDATE_ROLES(), {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(body),
  }).then(handleStatuses())

export const createUser = ({ body, accessToken }) =>
  fetch(ENDPOINTS.CREATE_USER(), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(body),
  }).then(handleStatuses())

export const deleteUser = ({ body, accessToken }) =>
  fetch(ENDPOINTS.DELETE_USER(body.id), {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    // body: JSON.stringify(body),
  }).then(handleStatuses())
