import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Fade from '@material-ui/core/Fade'
import { Checkbox, FormControlLabel } from '@material-ui/core'
import * as R from 'ramda'
import clsx from 'clsx'

import useStyles from './styles'

const Wrapper = styled.div`
  min-width: 200px;
  position: absolute;
  padding: 20px;
  background: #ffffff;
  box-shadow: 0 6px 14px rgba(2, 9, 75, 0.24);
  border-radius: 8px;
  z-index: 200;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  top: 35px;
`

const Label = styled.div`
  font-family: 'FuturaBookC', sans-serif;
  font-size: 14px;
  display: flex;
  align-items: center;
  color: #000000;
  opacity: 0.6;
  text-transform: none;
`

const ListStatusFilter = ({ open, setStatusId, statusId, statuses }) => {
  const classes = useStyles()
  const [statusFilter, setStatusFilter] = useState([])
  useEffect(() => {
    const statusArr = JSON.parse(localStorage.getItem('STATUSES')) || {}
    if (!statusArr || (!R.equals(statuses, statusArr) && !statusId)) {
      localStorage.setItem('STATUSES', JSON.stringify(statuses))
    }
  }, [open])
  useEffect(() => {
    const statusArr = JSON.parse(localStorage.getItem('STATUSES')) || {}
    const filterArr = Object.keys(statusArr).map(item => {
      return {
        id: item,
        title: statusArr[item].title,
        checked: statusId === item,
      }
    })
    setStatusFilter(filterArr)
  }, [statusId, open])

  return (
    <Fade in={open}>
      <Wrapper>
        {statusFilter.map(item => (
          <FormControlLabel
            control={
              <Checkbox
                checkedIcon={
                  <span className={clsx(classes.icon, classes.checkedIcon)} />
                }
                icon={<span className={classes.icon} />}
                className={classes.root}
                checked={item.checked}
                onChange={() =>
                  setStatusId(item.id === statusId ? '' : item.id)
                }
                name={item.id}
              />
            }
            label={<Label>{item.title}</Label>}
          />
        ))}
      </Wrapper>
    </Fade>
  )
}

export default ListStatusFilter
