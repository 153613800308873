import React, { useState } from 'react'
import styled from 'styled-components'

import { triangle } from '../../../../assets/img'

import ModalPortal from '../ModalPortal'

const Wrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 25px;
`


const SelectedBoardContainer = styled.div`
  font-family: FuturaMediumC;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: #283642;
`

const Triangle = styled.img.attrs({ src: triangle })`
  transform: rotate(90deg);
  margin-left: 10px;
`

const ListItem = styled.div`
  margin-bottom: 10px;
  font-family: 'FuturaBookC', sans-serif;
  font-size: 18px;
  line-height: 30px;
  color: rgba(18, 22, 26, 0.7);
`


const BoardSelect = ({ boards, selectedBoard, setSelectedBoard }) => {

  const [modalOpen, setIsOpen] = useState(false)

  const renderModal = () => {
    if (!modalOpen) return null
    const close = () => setIsOpen(false)

    const onClick = (board) => {
      setSelectedBoard(board)
      close()
    }

    return (
      <ModalPortal close={close} header='Доска'>
        {boards.map(el => <ListItem onClick={() => onClick(el)} key={el.id}>{el.title}</ListItem>)}
      </ModalPortal>
    )
  }

  return (
    <Wrap>
      <SelectedBoardContainer onClick={() => setIsOpen(true)}>
        {selectedBoard.title}
        <Triangle />
      </SelectedBoardContainer>
      {renderModal()}
    </Wrap>)
}

export default BoardSelect