import styled, { css } from 'styled-components'
import { Button, TextInput } from '../components'
import React, { useEffect } from 'react'
import * as R from 'ramda'
import { Field, reduxForm } from 'redux-form'
import { FORMS } from '../constants'
import { connect } from 'react-redux'
import { getResetPasswordErrors } from '../modules/redux-form'
import { sendResetLogin, clearIsResetEmailSent, getIsResetEmailSent } from '../modules/auth'
import { withRediretToMain } from '../utils'
import { Link } from 'react-router-dom'

const Container = styled.div`
  display: flex;
  flex: 1;
  background: #ecf1f4;
  font-family: 'FuturaBookC', sans-serif;
`

const FormTitle = styled.div`
  font-size: 25px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.01em;
  color: linear-gradient(170.86deg, #3d4751 0%, #162836 108.75%);
  margin-bottom: 10px;
  ${({ isEmailSent }) =>
          isEmailSent &&
          css`
            line-height: 30px;
            margin-bottom: 60px;
          `}
`

const FormSubtitle = styled.div`

  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  text-align: center;
  color: rgba(18, 22, 26, 0.4);
`

const FormContainer = styled.div`
  width: 360px;
  height: 340px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  background: #ffffff;
  box-shadow: 0 6px 14px rgba(2, 9, 75, 0.06);
  border-radius: 8px;
  padding: 44px 39px 0 38px;
  ${({ isEmailSent }) =>
          isEmailSent &&
          css`
            width: 560px;
            height: 240px;
          `}
`


const FieldContainer = styled.div`
  width: 100%;
`

const LoginTextInput = styled(TextInput)`
  width: 100%;
  margin-top: 15px;
  margin-bottom: 5px;
  display: flex;
  justify-content: center;

  &:last-child {
    margin-bottom: 60px;
  }
`

const LoginButton = styled(Button)`
  width: 100%;
`

const ErrorText = styled.div`
  text-align: center;
  color: #f55555;
  margin-bottom: 36px;
`

const StyledLink = styled(Link)`
  text-decoration: none;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 19px;
  margin-top: 20px;
  color: rgba(18, 22, 26, 0.4);
`

const LinkButton = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #F0640C;
  border-radius: 8px;
  width: 165px;
  height: 46px;
  font-size: 17px;
  text-decoration: none;
  color: white;
`

const inputLabelProps = {
  style: {
    color: '#000000',
    opacity: 0.4,
    fontFamily: 'FuturaBookC',
    fontSize: 17,
  },
}

const ResetPasswordDump = ({
                             sendResetLogin,
                             handleSubmit,
                             submitting,
                             errors,
                             isEmailSent,
                             clearIsResetEmailSent,
                           }) => {
  useEffect(() => clearIsResetEmailSent, [])

  const renderContent = () => {
    if (isEmailSent) return (
      <Container>
        <FormContainer isEmailSent={isEmailSent}>
          <FormTitle isEmailSent={isEmailSent}>
            Новый пароль был выслан вам на электоронную почту
          </FormTitle>
          <LinkButton to='/login'>
            Закрыть
          </LinkButton>
        </FormContainer>
      </Container>
    )
    return (
      <Container>
        <FormContainer isEmailSent={isEmailSent}>
          <FormTitle>Восстановление пароля</FormTitle>
          <FormSubtitle>Введите ваш логин</FormSubtitle>
          <FieldContainer>
            <Field
              name='login'
              component={LoginTextInput}
              inputLabelProps={inputLabelProps}
              label='Логин'
            />
            {errors.description && <ErrorText>{errors.description}</ErrorText>}
          </FieldContainer>
          <LoginButton
            title='Готово'
            onClick={handleSubmit(sendResetLogin)}
            isLoading={submitting}
          />
          <StyledLink to='/login'>Войти</StyledLink>
        </FormContainer>
      </Container>
    )
  }

  return renderContent()
}

const ResetPassword = R.compose(
  reduxForm({
    form: FORMS.RESET_PASSWORD,
    initialValues: {
      login: '',
    },
  }),
  connect(R.applySpec({ errors: getResetPasswordErrors, isEmailSent: getIsResetEmailSent }), {
    sendResetLogin,
    clearIsResetEmailSent,
  }),
  withRediretToMain(),
)(ResetPasswordDump)

export default ResetPassword