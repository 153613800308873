import querystring from 'query-string'
import * as R from 'ramda'
import { ENDPOINTS } from '../../API'
import { handleStatuses } from '../../utils'

window.querystring = querystring

const productMapper = product => ({
  ...product,
  price: Number(product.price),
  discount: Math.round(Number(product.discount)),
  minCount: Number(product.minCount),
  multiplicity: Number(product.multiplicity),
})

export const getProducts = ({ filter, token }) => {
  return (
    fetch(
      ENDPOINTS.PRODUCTS(
        querystring.stringify(filter, { arrayFormat: 'bracket' })),
      {
        method:'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then(handleStatuses())
      .then(res => res.json())
      .then(R.map(productMapper))
  )
}


export const deleteProduct = ({ id, token }) =>
  fetch(ENDPOINTS.PRODUCT(id), {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }).then(handleStatuses())

export const updateProduct = ({ body, accessToken }) =>
  fetch(ENDPOINTS.PRODUCTS(), {
    method: 'PUT',
    headers: {
      //'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${accessToken}`,
    },

    body,
  })
    .then(handleStatuses())
    .then(res => res.json())
    .then(productMapper)

export const createProduct = ({ body, accessToken }) =>
  fetch(ENDPOINTS.PRODUCTS_CREATE(), {
    method: 'POST',
    headers: {
      // 'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${accessToken}`,
    },
    body,
  })
    .then(handleStatuses())
    .then(res => res.json())
    .then(R.evolve({ product: productMapper }))

export const disableProduct = ({ id, token }) =>
  fetch(ENDPOINTS.TOGGLE_DISABLED_PRODUCT(id), {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }).then(handleStatuses())

export const getProductById = ({ id }) =>
  fetch(ENDPOINTS.PRODUCT(id))
    .then(handleStatuses())
    .then(res => res.json())

export const getProductsByCity = (query = {}) => {
  return fetch(ENDPOINTS.PRODUCTS_BY_CITY(querystring.stringify(query)))
    .then(handleStatuses())
    .then(res => res.json())
}

export const uploadProducts = ({ token, file }) => {
  const formData = new FormData()
  formData.append('file', file, file.name)
  return fetch(ENDPOINTS.UPLOAD_PRODUCTS_PRICE(), {
    method: 'POST',
    headers: {
      // 'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
    body: formData,
  })
    .then(handleStatuses())
    .then(res => res.json())
}

export const downloadExampleProducts = () => fetch(
  ENDPOINTS.PRODUCTS_EXAMPLE_DOWNLOAD(),
)
  .then(handleStatuses())
  .then(res => res.blob())
  .then(blob => {
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = 'Шаблон цен.xlsx'
    document.body.appendChild(a)
    a.click()
    a.remove()
  })
  .catch(ex => {
    throw ex
  })
