import React, { useCallback, useRef, useState } from 'react'
import styled from 'styled-components'
import { Field, reduxForm } from 'redux-form'
import { connect } from 'react-redux'

import assets from '../../assets'
import { setUserRequest } from '../../modules/users'
import TextInput from '../common/TextInput'
import * as R from 'ramda'
import { PercentNumberInput } from './PercentNumberInput'

const PREPAYMENT_OPTIONS = [
  { title: '0% (Нет)', value: 0 },
  { title: '10%', value: 10 },
  { title: '20%', value: 20 },
  { title: '30%', value: 30 },
  { title: '40%', value: 40 },
  { title: '50%', value: 50 },
]

const inputStyle = {
  padding: '10px 15px !important',
  fontFamily: 'FuturaBookC',
  fontSize: '14px',
  color: '#000000',
  opacity: '0.8',
}

const Container = styled.form`
  position: relative;
`

const required = value => (value == null ? 'Required' : undefined);

const SubcategoryButton = styled.button`
  transform: rotateZ(${({active}) => active ? '90' : '0'}deg);
  cursor: pointer;
  width: 6px;
  height: 8px;
  border: none;
  outline: none;
  background: transparent url(${assets.img.triangle}) 0px 0px no-repeat;
  position: absolute;
  right: 15px;
  top: 14px;
  padding: 0;
`

const ListBox = styled.div`
  width: 100%;
  z-index: 1;
  position: absolute;
  background-color: white;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  padding-top: 8px; 
  visibility: ${({ visible }) => visible ? 'visible' : 'hidden'};
`

const ListItem = styled.div`
  color: black;
  cursor: pointer;
  font-family: 'FuturaBookC', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 15px;
  padding: 5px 15px;
  text-align: left;
  :hover {
    color: #F0640C;
  };
`

const PrepaymentSelectDump = ({ handleSubmit, setUserPrepayment }) => {
  const [showModal, setShowModal] = useState(false)
  const formRef = useRef(null);
  const handleFocus = useCallback(() => {
    setShowModal(true)
  }, [setShowModal])

  const handleBlur = useCallback(() => {
    setTimeout(() => {
      setShowModal(false);
      if (formRef.current) {
        formRef.current.dispatchEvent(new Event("submit"));
      }
    }, 150)
  }, [setShowModal])

  const handleListItemClick = useCallback((value, onChange) => () => onChange(value), []);

  return (
    <Container ref={formRef} onSubmit={handleSubmit(setUserPrepayment)}>
      <SubcategoryButton active={showModal}/>
      <Field
        name="prePayment"
        inputProps={{
          onFocus: handleFocus,
          onBlur: handleBlur,
          inputComponent: PercentNumberInput,
          autoComplete: 'off',
          style: inputStyle,
        }}
        component={TextInput}
        validate={required}
        margin={'none'}
      />
      <Field
        name="prePayment"
        component={({ input: { onChange } }) => (
          <ListBox visible={showModal}>
            {PREPAYMENT_OPTIONS.map(({ title, value }) => (
              <ListItem key={value} onClick={handleListItemClick(value, onChange)}>{title}</ListItem>
            ))}
          </ListBox>
        )}
        validate={required}
      />
    </Container>
  )
}

const PrepaymentSelect = R.compose(
  connect(
    null,
    { setUserPrepayment: setUserRequest },
  ),
  reduxForm({
    enableReinitialize: true,
  }),
)(PrepaymentSelectDump)

export default PrepaymentSelect
