import * as R from 'ramda'
import { ENDPOINTS } from '../../API'
import { handleStatuses } from '../../utils'

export const getSchedule = ({ token }) =>
  fetch(ENDPOINTS.SCHEDULE(), {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
    .then(handleStatuses())
    .then(res => res.json())

export const createSchedule = ({ body, token }) =>
  fetch(ENDPOINTS.SCHEDULE(), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body:JSON.stringify(body)
  })
    .then(handleStatuses())
    .then(res => res.json())
