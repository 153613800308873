import React, { useState } from 'react'
import styled from 'styled-components'
import assets from '../../assets'

const CustomSelectContainer = styled.div`
  position: relative;
  padding: 12px 28px 9px 15px;
  height: 38px;
  box-sizing: border-box;
  width: 100%;
  cursor: pointer;
  background: #ffffff;
  border: 1px solid #f49355;
  border-radius: 8px;
  font-family: FuturaBookC, sans-serif;
  font-size: 14px;
  line-height: 14px;
  border-bottom: ${({ isOpen }) => isOpen ? 'none' : ' 1px solid #f49355'};
`

const Text = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

const Triangle = styled.img.attrs({ src: assets.img.triangle })`
  position: absolute;
  top: 14px;
  right: 16px;
  width: 7px;
  transform: rotate(${({ isOpen }) => isOpen ? -90 : 90}deg);
  margin-left: 10px;
  user-select: none;
`

const ChildrenWrapper = styled.div`
  position: absolute;
  z-index: 10;
  top: 30px;
  left: -1px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #f49355;
  border-top: none;
`

const CustomSelect = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <CustomSelectContainer onClick={() => setIsOpen(!isOpen)} isOpen={isOpen}>
      <Text>{title}</Text>
      <Triangle isOpen={isOpen} />
      {isOpen && <ChildrenWrapper>{children}</ChildrenWrapper>}
    </CustomSelectContainer>

  )
}

export default CustomSelect