import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import Filter from './Filter'
import Board from './Board'
import * as R from 'ramda'
import { connect } from 'react-redux'
import { createBoardRequest, getBoardsList, getBoardsRequest } from '../../../modules/boards'
import { getOrdersList, getOrdersRequest } from '../../../modules/orders'
import { getOrganizationList } from '../../../modules/organizations'
import { getProductsList } from '../../../modules/products'
import { getFreeTransport } from '../../../modules/transport'
import PlusButton from './PlusButton'
import { getMeRequest, getMeSelector } from '../../../modules/users'

const Wrapper = styled.div`
  width: 100%;
`

const Mobile = ({
                  boards,
                  organizations,
                  products,
                  getOrders,
                  freeTransport,
                  orders,
                  createBoard,
                  getBoards,
                  getCurrentUser,
                  currentUser,
                }) => {
  const [selectedBoard, setSelectedBoard] = useState(null)
  const [search, setSearch] = useState('')
  const [organization, setOrganization] = useState({
    title: 'Все организации',
    id: 0,
  })
  const [product, setProduct] = useState({
    title: 'Все продукты',
    id: 0,
  })
  const [transportCategory, setTransportCategory] = useState({
    title: 'Свободных машин',
    id: 0,
  })

  const [activeRoleNames, setActiveRoleNames] = useState([])

  const [isAdmin, setIsAdmin] = React.useState(false)

  const query = useMemo(() => ({
    search,
    organization: organization.id || '',
    product: product.id || '',
    transportCategory: transportCategory.id || '',
    activeRoleNames: activeRoleNames,
  }), [search, organization, product, transportCategory, activeRoleNames])

  const organizationList = useMemo(() => ([
    {
      title: 'Все организации',
      id: 0,
    },
    ...organizations,
  ]), [organizations])

  const productsList = useMemo(() => ([
    {
      title: 'Все продукты',
      id: 0,
    },
    ...products,
  ]), [products])

  const handleGetOrders = useCallback(() => {
    getOrders({
      boardId: selectedBoard.id,
      query,
    })
  }, [selectedBoard, query])

  useEffect(() => {
    if (!selectedBoard && boards.length) {
      setSelectedBoard(boards[0])
    }
  }, [boards])

  useEffect(() => {
    if (selectedBoard !== null) {
      handleGetOrders()
    }
  }, [selectedBoard, query])

  useEffect(()=>{
    getCurrentUser()
  },[])

  useEffect(() => {
      let isAdmin = currentUser?.roles?.filter(role => {
        return role.name === 'Администратор'
      }) || []

      if (isAdmin.length) {
        setActiveRoleNames(['Администратор'])
        setIsAdmin(true)
      }
    },[currentUser]
  )

  return (
    <Wrapper>
      <Filter
        boards={boards}
        freeTransport={freeTransport}
        orders={orders}
        search={search}
        setSearch={setSearch}
        selectedBoard={selectedBoard}
        setSelectedBoard={setSelectedBoard}
        organizationList={organizationList}
        organization={organization}
        setOrganization={setOrganization}
        productsList={productsList}
        product={product}
        setProduct={setProduct}
        transportCategory={transportCategory}
        setTransportCategory={setTransportCategory}
        setActiveRoleNames={setActiveRoleNames}
        activeRoleNames={activeRoleNames}
        isAdmin={isAdmin}
      />
      <Board handleGetOrders={handleGetOrders} />
      <PlusButton handleGetOrders={handleGetOrders} organizations={organizations} createBoard={createBoard}
                  getBoards={getBoards} />
      <div id='modal-portal' />
    </Wrapper>
  )
}

export default R.compose(connect(R.applySpec({
    boards: getBoardsList,
    orders: getOrdersList,
    organizations: getOrganizationList,
    products: getProductsList,
    freeTransport: getFreeTransport,
    currentUser: getMeSelector,
  }),
  {
    getOrders: getOrdersRequest,
    getBoards: getBoardsRequest,
    createBoard: createBoardRequest,
    getCurrentUser: getMeRequest,
  }))(Mobile)
