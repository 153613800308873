import React from 'react'
import styled from 'styled-components'

import OrdersCount from './orders-count'
import OrdersPagination from './orders-pagination'

const Wrapper = styled.div`
  position: relative;
  top: 85%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const OrdersPaginationFooter = ({
  countOnPage,
  setCountOnPage,
  page,
  setPage,
  query,
  period,
}) => {
  return (
    <Wrapper>
      <OrdersCount countOnPage={countOnPage} setCountOnPage={setCountOnPage} />
      <OrdersPagination
        countOnPage={countOnPage}
        page={page}
        setPage={setPage}
        query={query}
        period={period}
      />
    </Wrapper>
  )
}

export default OrdersPaginationFooter
