import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { Grid } from '@material-ui/core'
import * as R from 'ramda'

import { RoleCard } from '../../components/roles'
import { EntityList, Loader } from '../../components/shared'
import { getAccessListForUser } from '../../modules/users'

import AccessForbiddenPopup from '../../components/common/AccessForbidden'
import {
  getAccessListRequest,
  getAmoRoles,
  getAmoRolesRequest,
  getAvaiableOrganisationsRequest,
  getIsLoading,
  getSelectedRole,
  selectRole,
} from '../../modules/users'
import { useWebSockets } from '../../context'
const Wrap = styled.div`
  width: 100%;
  padding: 20px;
  background-color: #ecf1f4;
`

const StyledLoader = styled(Loader)`
  margin: auto;
  align-self: center;
`

const Roles = ({
  isLoading,
  getRoles,
  getAccessList,
  rolesList,
  selectRole,
  selectedRole,
  accessList,
}) => {
  const [modalShow, setModalShow] = React.useState(false)

  const [editAccess, setEditAccesses] = React.useState([])

  const [modalViewShow, setModalViewShow] = React.useState(false)

  React.useEffect(() => {
    if (!accessList.length) return
    let view = accessList.filter(
      access => access.module === 'roles' && access.accessType === 'view',
    )
    let edit = accessList.filter(
      access => access.module === 'roles' && access.accessType === 'edit',
    )
    setEditAccesses(edit)
    setModalViewShow(!view.length)
  }, [accessList, accessList.length])

  const [roleCardMode, setRoleCardMode] = useState({
    open: false,
    mode: '',
  })

  const { updatedData } = useWebSockets()

  const rolesEvents = ['addAccess', 'deleteAccess']

  React.useEffect(() => {
    if (updatedData) {
      if (rolesEvents.includes(updatedData.action)) {
        getRoles()
        getAccessList()
      }
    }
  }, [updatedData])

  useEffect(() => {
    getRoles()
    getAccessList()
  }, [])

  const handleCloseRoleCard = () => {
    setRoleCardMode({
      open: false,
      mode: '',
    })
  }

  return modalViewShow ? (
    <AccessForbiddenPopup
      moduleName={'Роли'}
      setModalShow={setModalViewShow}
      canClose={false}
    />
  ) : (
    <Wrap>
      {modalShow && (
        <AccessForbiddenPopup
          moduleName={'Роли'}
          setModalShow={setModalShow}
        />
      )}
      {isLoading ? (
        <StyledLoader />
      ) : (
        <>
          <Grid container>
            <Grid item>
              <EntityList
                handleCloseCard={handleCloseRoleCard}
                cardMode={roleCardMode}
                setCardMode={setRoleCardMode}
                selectedEntity={selectedRole}
                selectEntity={selectRole}
                entityList={rolesList}
                entityItemTitle="name"
                editAccess={editAccess}
                setModalShow={setModalShow}
              />
            </Grid>
            {roleCardMode.open && (
              <Grid item>
                <RoleCard
                  roleCardMode={roleCardMode}
                  setRoleCardMode={setRoleCardMode}
                  editAccess={editAccess}
                  setModalShow={setModalShow}
                />
              </Grid>
            )}
          </Grid>
        </>
      )}
    </Wrap>
  )
}

export default connect(
  R.applySpec({
    isLoading: getIsLoading,
    rolesList: getAmoRoles,
    selectedRole: getSelectedRole,
    accessList: getAccessListForUser,
  }),
  {
    getRoles: getAmoRolesRequest,
    getAccessList: getAccessListRequest,
    getOrganizations: getAvaiableOrganisationsRequest,
    selectRole,
  },
)(Roles)
