import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import * as R from 'ramda'
import { Grid } from '@material-ui/core'

import { Roles, UserCard } from '../../components/users'
import { EntityList, Loader } from '../../components/shared'

import {
  getAmoRolesRequest,
  getAmoUsersList,
  getAmoUsersRequest,
  getAvaiableOrganisationsRequest,
  getIsLoading,
  getSelectedUser,
  selectRole,
  selectUser,
  getAccessListForUser,
  getAccessListRequest,
} from '../../modules/users'
import { useWebSockets } from '../../context'
import AccessForbiddenPopup from '../../components/common/AccessForbidden'
import { useMediaQuery } from '../../components/address/commons'
import { widthMobileScreen } from '../../components/address/utils'
import assets from '../../assets'

const Wrap = styled.div`
  width: 100%;
  padding: 20px;
  background-color: #ecf1f4;
  box-sizing: border-box;
`

const ModalContainer = styled.div``

const StyledLoader = styled(Loader)`
  margin: auto;
  align-self: center;
`

const MobileWrapper = styled.div`
  overflow: auto;
`

const UserCardWrapper = styled.div`
  margin-bottom: 30px;
  overflow: auto;
`

const HeaderTitle = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  font-family: FuturaMediumC;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  text-transform: uppercase;
  color: #283642;
  margin-bottom: 25px;
`

const ArrowLeft = styled.img.attrs({ src: assets.img.arrowLeft })`
  width: 20px;
  margin-right: 10px;
`

const Users = ({
  isLoading,
  selectRole,
  getRoles,
  getOrganizations,
  userList,
  selectUser,
  selectedUser,
  getUsers,
  accessList,
  getAccessList,
}) => {
  const [modalShow, setModalShow] = React.useState(false)
  const [editAccess, setEditAccesses] = React.useState([])
  const [modalViewShow, setModalViewShow] = React.useState(false)

  const userSocketEvents = ['newUser', 'deleteUser', 'updateUser']

  const { updatedData } = useWebSockets()

  React.useEffect(() => {
    if (updatedData) {
      if (userSocketEvents.includes(updatedData.action)) {
        getUsers()
      }
    }
  }, [updatedData])

  React.useEffect(() => {
    if (accessList.length > 0) {
      let view = accessList.filter(
        access => access.module === 'users' && access.accessType === 'view',
      )
      let edit = accessList.filter(
        access => access.module === 'users' && access.accessType === 'edit',
      )
      setEditAccesses(edit)
      setModalViewShow(!view.length)
    } else {
    }
  }, [accessList])

  const [userCardMode, setUserCardMode] = useState({
    open: false,
    mode: '',
  })

  useEffect(() => {
    selectRole('Все')
    getRoles()
    getOrganizations()
    getUsers()
    getAccessList()
  }, [])

  const handleCloseUserCard = () => {
    setUserCardMode({
      open: false,
      mode: '',
    })
  }

  const isMobile = useMediaQuery(`(max-width: ${widthMobileScreen - 1}px)`)

  const onClickBack = () => {
    if (userCardMode.open) {
      selectUser({})
      return handleCloseUserCard()
    }
  }

  const renderHeaderButton = () => (
    <HeaderTitle onClick={onClickBack}>
      {userCardMode.open ? <ArrowLeft /> : null}Пользователи
    </HeaderTitle>
  )

  const renderContent = () => {
    if (isMobile)
      return (
        <MobileWrapper>
          {renderHeaderButton()}
          <Roles handleCloseUserCard={handleCloseUserCard} />
          {userCardMode.open ? (
            <UserCardWrapper>
              <UserCard
                userCardMode={userCardMode}
                setUserCardMode={setUserCardMode}
                editAccess={editAccess}
                setModalShow={setModalShow}
              />
            </UserCardWrapper>
          ) : (
            <EntityList
              handleCloseCard={handleCloseUserCard}
              cardMode={userCardMode}
              setCardMode={setUserCardMode}
              entityItemTitle="fio"
              entityList={userList}
              selectedEntity={selectedUser}
              selectEntity={selectUser}
              editAccess={editAccess}
              setModalShow={setModalShow}
            />
          )}
        </MobileWrapper>
      )

    return (
      <>
        <Roles handleCloseUserCard={handleCloseUserCard} />
        <Grid container>
          <Grid item>
            <EntityList
              handleCloseCard={handleCloseUserCard}
              cardMode={userCardMode}
              setCardMode={setUserCardMode}
              entityItemTitle="fio"
              entityList={userList}
              selectedEntity={selectedUser}
              selectEntity={selectUser}
              editAccess={editAccess}
              setModalShow={setModalShow}
            />
          </Grid>
          {userCardMode.open && (
            <Grid item>
              <UserCard
                userCardMode={userCardMode}
                setUserCardMode={setUserCardMode}
                editAccess={editAccess}
                setModalShow={setModalShow}
              />
            </Grid>
          )}
        </Grid>
      </>
    )
  }

  return modalViewShow ? (
    <AccessForbiddenPopup
      moduleName={'Пользователи'}
      setModalShow={setModalViewShow}
      canClose={false}
    />
  ) : (
    <Wrap>
      {modalShow && (
        <AccessForbiddenPopup
          moduleName={'Пользователи'}
          setModalShow={setModalShow}
        />
      )}
      {isLoading ? <StyledLoader /> : renderContent()}
      <ModalContainer id="modal-portal" />
    </Wrap>
  )
}

export default connect(
  R.applySpec({
    isLoading: getIsLoading,
    userList: getAmoUsersList,
    selectedUser: getSelectedUser,
    accessList: getAccessListForUser,
  }),
  {
    getRoles: getAmoRolesRequest,
    getOrganizations: getAvaiableOrganisationsRequest,
    getUsers: getAmoUsersRequest,
    getAccessList: getAccessListRequest,
    selectRole,
    selectUser,
  },
)(Users)
