import React from 'react'
import styled from 'styled-components'
const SidebarItemWrapper = styled.div(props => ({
  display: 'flex',
  alignItems: 'center',
  width: props.isMobile? "100%": 317,
  height: 58,
  background: props.isSelected
    ? 'linear-gradient(135deg, #F49355 0%, #F0640C 100%)'
    : '#fff',
  boxShadow: '0px 6px 14px rgba(2, 9, 75, 0.06)',
  marginBottom: 2,
  cursor: 'pointer',
}))

const SidebarItemImageWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 58,
  height: 58,
})

const SidebarItemImage = styled.img({})
const SidebarItemTitle = styled.div(props => ({
  fontFamily: 'FuturaMediumC',
  fontSize: 19,
  opacity: props.isSelected ? 1 : 0.5,
  color: props.isSelected ? '#fff' : '#000',
}))

const SidebarItem = ({ onClick, icon, activeIcon, isSelected, title, isMobile }) => {
  return (
    <SidebarItemWrapper isSelected={isSelected} onClick={onClick} isMobile={isMobile}>
      <SidebarItemImageWrapper>
        <SidebarItemImage src={isSelected ? activeIcon : icon} alt="menu" />
      </SidebarItemImageWrapper>
      <SidebarItemTitle isSelected={isSelected}>{title}</SidebarItemTitle>
    </SidebarItemWrapper>
  )
}

export default SidebarItem
