import React from 'react'
import styled from 'styled-components'
import { BrowserRouter as Router } from 'react-router-dom'
import { Provider } from 'react-redux'
import { Root as RootNavigator } from './navigators'
import configureStore from './configureStore'
import { createReatomStore } from './createReatomStore'
import { context } from '@reatom/react'
const Root = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
`

const store = createReatomStore({})

const App = () => (
  <Root>
    <context.Provider value={store}>
      <Provider store={configureStore}>
        <>
          <Router>
            <RootNavigator />
          </Router>
        </>
      </Provider>
    </context.Provider>
  </Root>
)

export default App
