import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import * as R from 'ramda'
import assets from '../../../assets'
import { getAnalyticsDownload } from '../../../modules/analytics'

const Wrapper = styled.a`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 106px;
  height: 36px;
  background: linear-gradient(0deg, #ffffff, #ffffff),
    linear-gradient(92.15deg, #f6a26d -38.69%, #f87c2e 107.08%);
  box-shadow: 0 6px 14px rgba(2, 9, 75, 0.06);
  border-radius: 8px;
  padding: 9px 13px;
  box-sizing: border-box;
  font-family: FuturaBookC, sans-serif;
  font-size: 16px;
  letter-spacing: 0.02em;
  color: rgba(0, 0, 0, 0.4);
  text-decoration: none;
  cursor: pointer;
`

const DownloadIcon = styled.img.attrs({ src: assets.img.download })`
  margin-left: 10px;
`

const AnalyticsDownload = ({ downloadOnClick }) => {
  return (
    <Wrapper onClick={downloadOnClick}>
      Скачать <DownloadIcon />
    </Wrapper>
  )
}

export default connect(
  R.applySpec({
    downloadUri: getAnalyticsDownload,
  }),
)(AnalyticsDownload)
