import React, { useState } from 'react'

export default ({ multiple, accept }) => {
  const [files, setFiles] = useState([])

  const removeElement = input => {
    input.parentNode.removeChild(input)
  }

  const onChange = async e => {
    const newFiles = e.target.files
    if (!newFiles?.length) return

    const promises = [].map.call(
      newFiles,
      file =>
        new Promise(resolve => {
          const fileReader = new FileReader()
          fileReader.readAsDataURL(file)
          fileReader.onload = () => {
            resolve([file, fileReader.result])
          }
        }),
    )

    const result = await Promise.all(promises)
    setFiles([
      ...files,
      {
        file: result[0][0],
        result: result[0][1],
      },
    ])
  }

  const onCancel = () => {
    if (!files?.length) return

    setFiles([])
  }

  const onFileDelete = file => {
    if (!file) return
    const newFiles = files.filter(item => item.result !== file.result)
    setFiles([...newFiles])
  }

  const onSelect = e => {
    const input = document.createElement('input')

    if (!!multiple) input.multiple = multiple
    if (!!accept) input.accept = accept

    input.type = 'file'
    input.style.display = 'none'
    input.onchange = onChange

    document.body.appendChild(input)
    input.click()

    e.currentTarget.onblur = () => removeElement(input)
  }

  return [onSelect, onCancel, files, onFileDelete]
}
