import * as R from 'ramda'

const getUi = R.prop('ui')

export const getTaskModalVisible = R.pipe(
  getUi,
  R.prop('taskModalVisible'),
)

export const getUserDeletionModalVisible = R.pipe(
  getUi,
  R.prop('userDeletionModalVisible'),
)

export const getOrganisationDeletionModalVisible = R.pipe(
  getUi,
  R.prop('organisationDeletionModalVisible'),
)

export const getOrganisationDocumentsModalVisible = R.pipe(
  getUi,
  R.prop('organisationDocumentsModalVisible'),
)
