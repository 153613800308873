import React, { useState } from 'react'
import { v4 as uuid } from 'uuid';

import styled from 'styled-components'
import assets from '../../../assets'

const TableContainer = styled.div`
  margin-top: 20px;
  width: 100%;
`

const TableRow = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 4px;
  max-width: 1600px;
`

const TableCell = styled.div`
  flex: 1.5;
  box-sizing: border-box;
  background: #FFFFFF;
  font-family: FuturaBookC sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  margin-right: 4px;
  padding: 16px 16px 14px 19px;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  min-width: 218px;

  &:first-child {
    flex: 2;
    min-width: 280px;
  }

  &:last-child {
    flex: 1;
    min-width: 121px;
    text-align: center;

  }
`

const HeaderTableCell = styled(TableCell)`
  color: #999999;
  text-transform: uppercase;
  text-align: center;
  font-family: FuturaMediumC sans-serif;

  &:first-child {
    cursor: pointer;
  }
`

const Triangle = styled.img.attrs({ src: assets.img.triangle })`
  width: 8px;
  transform: rotate(${({ asc }) => asc ? -90 : 90}deg);
  margin-left: 10px;
  user-select: none;
`

const Table = ({ productsByCity }) => {


  const [asc, setAsc] = useState(true)

  const toggleAsc = () => setAsc(!asc)

  const renderTableHeader = () => (
    <TableRow>
      <HeaderTableCell onClick={toggleAsc}>Товар <Triangle asc={asc} /></HeaderTableCell>
      <HeaderTableCell>Город</HeaderTableCell>
      <HeaderTableCell>Цена за ед.</HeaderTableCell>
    </TableRow>
  )

  const renderTableBody = () => productsByCity
    .sort((a, b) => asc ? a.title.localeCompare(b.title) : b.title.localeCompare(a.title))
    .map(({ city, price, title }) =>
      <TableRow key={uuid()}>
        <TableCell>{title}</TableCell>
        <TableCell>{city}</TableCell>
        <TableCell>{price} ₽</TableCell>
      </TableRow>,
    )

  return (
    <TableContainer>
      {renderTableHeader()}
      {renderTableBody()}
    </TableContainer>
  )
}

export default Table