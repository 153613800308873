import React from 'react'
import { TextField } from '@material-ui/core'

import searchIcon from '../../../assets/img/search-white.svg'
import useStyles from './styles'

const SearchInput = ({
  label,
  input,
  meta: { touched, invalid, error },
  ...custom
}) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <TextField
        label={label}
        error={touched && invalid}
        helperText={touched && error}
        {...input}
        {...custom}
        placeholder="Поиск"
        classes={{
          root: classes.input,
        }}
        variant="outlined"
      />
      <div className={classes.iconWrapper}>
        <img src={searchIcon} alt="search" />
      </div>
    </div>
  )
}

export default SearchInput
