import React from 'react'
import * as R from 'ramda'
import { connect } from 'react-redux'
import styled from 'styled-components'
import assets from '../../assets'
import {
  getTransportCategorySelect,
  selectCategoryRequest,
} from '../../modules/transport'
const Container = styled.div`
  display: flex;
  align-items: ${({ isActive }) => (isActive ? 'normal' : 'center')};
  justify-content: space-between;
  min-height: 35px;
  width: 260px;
  position: relative;
  background-color: white;
  margin-top: 15px;
  border-radius: 6px;
  color: #35414c;
  font-size: 14px;
  line-height: 19px;
  border: 1px solid #f49355;
  border-bottom-left-radius: ${({ isActive }) => (isActive ? '0' : '6px')};
  border-bottom-right-radius: ${({ isActive }) => (isActive ? '0' : '6px')};
  cursor: pointer;
`

const DropDownContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: ${({ isActive }) => (isActive ? 'absolute' : 'relative')};
  height: ${({ isActive }) => (isActive ? 'auto' : '39px')};
  max-height: ${({ isActive }) => (isActive ? '200px' : '39px')};
  background-color: white;
  width: 100%;
  min-width: 260px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  padding-bottom: 10px;
  top: 100%;
  left: -1px;
  z-index: 30;
  border: 1px solid #f49355;
  overflow: scroll;
  overflow-x: hidden;
  ::-webkit-scrollbar {
    display: none;
  }
`

const DropDownStart = styled.div`
  font-family: 'FuturaBookC', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 15px;
  display: flex;
  align-items: center;
  color: rgba(18, 22, 26, 0.9);
  padding-left: 15px;
`

const DropDownDefault = styled.div`
  font-family: 'FuturaBookC', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 15px;
  display: flex;
  align-items: center;
  color: rgba(18, 22, 26, 0.9);
  opacity: 0.8;
  padding-left: 15px;
`

const DropDownItem = styled.div`
  padding-top: 15px;
  padding-left: 15px;
  z-index: 1;
  font-family: 'FuturaBookC', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 15px;
  color: rgba(18, 22, 26, 0.9);
`

const DropDownItemText = styled.div`
  z-index: 1;
  font-family: 'FuturaBookC', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 15px;
  color: rgba(18, 22, 26, 0.9);
`

const Arrow = styled.img.attrs({ src: assets.img.selectArrow })`
  transform: ${({ isActive }) => (isActive ? 'rotate(180deg)' : '')};
  margin-right: 16px;
  width: 10px;
`

const MobileCategoriesDropDownDump = ({
  transportCategoryList,
  setIsMobileCategoriesDropDownOpen,
  isMobileCategoriesDropDownOpen,
  selectCategoryRequest,
}) => {
  const [chosenCategory, setChosenCategory] = React.useState(
    'Выберите категорию',
  )

  const [chosenCategoryId, setChosenCategoryId] = React.useState('')
  React.useEffect(() => {
    selectCategoryRequest(chosenCategoryId)
  }, [chosenCategoryId, selectCategoryRequest])

  console.log(transportCategoryList)

  return (
    <Container
      onClick={() =>
        setIsMobileCategoriesDropDownOpen(!isMobileCategoriesDropDownOpen)
      }
      isActive={isMobileCategoriesDropDownOpen}
    >
      {isMobileCategoriesDropDownOpen ? (
        <DropDownStart>
          {chosenCategory}
          <DropDownContainer isActive={isMobileCategoriesDropDownOpen}>
            {chosenCategory !== 'Выберите категорию' && (
              <DropDownItem>
                <DropDownItemText
                  onClick={() => {
                    setChosenCategory('Выберите категорию')
                    setChosenCategoryId('')
                  }}
                >
                  Все категории
                </DropDownItemText>
              </DropDownItem>
            )}

            {transportCategoryList.map((category, index) => (
              <DropDownItem key={index}>
                <DropDownItemText
                  onClick={() => {
                    setChosenCategory(category.title)
                    setChosenCategoryId(category.id)
                  }}
                >
                  {category.title}
                </DropDownItemText>
              </DropDownItem>
            ))}
          </DropDownContainer>
        </DropDownStart>
      ) : (
        <DropDownDefault>{chosenCategory}</DropDownDefault>
      )}
      <Arrow isActive={isMobileCategoriesDropDownOpen} />
    </Container>
  )
}

const MobileCategoriesDropDown = connect(
  R.applySpec({ transportCategoryList: getTransportCategorySelect }),
  {
    selectCategoryRequest,
  },
)(MobileCategoriesDropDownDump)

export default MobileCategoriesDropDown
