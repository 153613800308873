import React, { useMemo } from 'react'
import * as R from 'ramda'
import { connect } from 'react-redux'
import { getBoardsList } from '../../../modules/boards'
import { getOrdersList } from '../../../modules/orders'
import { getOrganizationList } from '../../../modules/organizations'
import { getProductsList } from '../../../modules/products'
import { getFreeTransport } from '../../../modules/transport'
import styled from 'styled-components'
import Column from './board/Column'

const Wrapper = styled.div`
  display: flex;
  overflow: auto;
  min-width: 0;
  width: 100%;
`

const Board = ({ columns, handleGetOrders }) => {
  const columnsMeta = useMemo(
    () => columns.map(({ title, id }) => ({ title, id })),
    [columns],
  )
  return (
    <Wrapper>
      {columns.map(col => (
        <Column
          column={col}
          key={col.id}
          columnsMeta={columnsMeta}
          handleGetOrders={handleGetOrders}
        />
      ))}
    </Wrapper>
  )
}

export default R.compose(
  connect(
    R.applySpec({
      boards: getBoardsList,
      columns: getOrdersList,
      organizations: getOrganizationList,
      products: getProductsList,
      freeTransport: getFreeTransport,
    }),
  ),
)(Board)
