import React from 'react'
import { StyledTableCell, StyledTableRow } from '../styled-table'
import Dot from '../dot'
import ListStatus from '../list-status'
import Documents from '../../../documents/documentsList'
import { connect } from 'react-redux'
import * as R from 'ramda'
import { getTransportList } from '../../../../modules/transport'
import { getIsOrganization } from '../../../../modules/orders'
import styled from 'styled-components'
import assets from '../../../../assets'
import RealizeDot from '../dot/realizeDot'
const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`
const OrderNumberContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  vertical-align: center;
`

const TrashIcon = styled.img.attrs({ src: assets.img.trashIcon })``
const TTNExistActive = styled.img.attrs({ src: assets.img.activeTtnExist })``
const TTNExist = styled.img.attrs({ src: assets.img.ttnExist })``

const ListTableBody = ({
  drivers,
  orders,
  sort,
  openEditOrderModal,
  setModalShow,
  isCompleted,
  editAccess,
  statuses,
  setIsDeleting,
  setDeletingOrder,
  setTTNExistOrder,
  setIsTTNExistModalOpen,
  isOrganization,
}) => {
  const normalizeOrders = () =>
    orders
      .map(item => {
        const transport = drivers.find(d => d.transportId === item.transportId)
        if (transport) {
          item.driverName = transport.fio
          item.carNumber = transport.carNumber
        }
        return item
      })
      .sort(function(a, b) {
        if (sort) {
          if (a.product > b.product) {
            return 1
          }
          if (a.product < b.product) {
            return -1
          }
        } else {
          return b.id - a.id
        }
        return 0
      })

  const calcPrice = item => {
    if (item.volume)
      return Math.ceil(
        (Number(item.price) / Number(item.count)) * Number(item.volume),
      )
    return item.price
  }

  const renderTTN = item => {
    if (!item.ttn) return 'Нет'

    const documents = [
      {
        id: 1,
        title: 'ТТН',
        uri: item.ttn,
      },
    ]

    return <Documents documents={documents} />
  }

  const renderTableBody = () =>
    normalizeOrders().map(item => (
      <StyledTableRow key={item.id}>
        <StyledTableCell
          clickable
          onClick={() => openEditOrderModal(item)}
          isFirst
        >
          {isCompleted ? (
            <OrderNumberContainer>
              {<RealizeDot order={item} />}
              <span>{item.orderNumber}</span>
            </OrderNumberContainer>
          ) : (
            <OrderNumberContainer>
              {isCompleted || item.transportId ? null : <Dot order={item} />}
              <span>{item.orderNumber}</span>
            </OrderNumberContainer>
          )}
        </StyledTableCell>
        <StyledTableCell clickable onClick={() => openEditOrderModal(item)}>
          {item.organization}
        </StyledTableCell>
        <StyledTableCell clickable onClick={() => openEditOrderModal(item)}>
          {calcPrice(item)} ₽
        </StyledTableCell>
        <StyledTableCell clickable onClick={() => openEditOrderModal(item)}>
          {item.product}
        </StyledTableCell>
        {isCompleted && (
          <>
            <StyledTableCell>{item.volume || '-'}</StyledTableCell>
            <StyledTableCell>
              {item.driverName || ''}
              <br />
              {item.carNumber || '-'}
            </StyledTableCell>
          </>
        )}
        {!isCompleted && (
          <StyledTableCell>
            <ListStatus
              currentOrderId={item.id}
              value={
                isOrganization
                  ? item.organizationLeadStatusId
                  : item.leadStatusId
              }
              statuses={statuses}
              editAccess={editAccess}
              setModalShow={setModalShow}
            />
          </StyledTableCell>
        )}
        <StyledTableCell>{renderTTN(item)}</StyledTableCell>
        <StyledTableCell clickable onClick={() => openEditOrderModal(item)}>
          {JSON.parse(item.address).address}
        </StyledTableCell>
        <StyledTableCell clickable onClick={() => openEditOrderModal(item)}>
          {item.user.fio}
        </StyledTableCell>
        <StyledTableCell clickable onClick={() => openEditOrderModal(item)}>
          {item.user.phone}
        </StyledTableCell>
        {isCompleted && (
          <StyledTableCell>
            <IconWrapper
              onClick={() => {
                setTTNExistOrder(item)
                setIsTTNExistModalOpen(true)
              }}
            >
              {item.ttnIsExist ? <TTNExistActive /> : <TTNExist />}
            </IconWrapper>
          </StyledTableCell>
        )}
        <StyledTableCell isLast>
          <IconWrapper
            onClick={() => {
              setDeletingOrder(item)
              setIsDeleting(true)
            }}
          >
            <TrashIcon />
          </IconWrapper>
        </StyledTableCell>
      </StyledTableRow>
    ))

  return <>{renderTableBody()}</>
}

export default connect(
  R.applySpec({
    drivers: getTransportList,
    isOrganization: getIsOrganization,
  }),
)(ListTableBody)
