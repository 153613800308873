import { call, all, put, takeLatest, select, delay } from 'redux-saga/effects'
import {
  createScheduleRequest,
  getScheduleRequest,
  getScheduleFailure,
  getScheduleSuccess,
} from './duck'
import * as ScheduleManager from './ScheduleManager'
import { getToken } from '../auth/selectors'

const getSchedule = function*() {
  try {
    const token = yield select(getToken)
    const schedule = yield call(ScheduleManager.getSchedule, token)
    yield put(getScheduleSuccess(schedule))
  } catch (err) {
    console.log('err', err)
    yield put(getScheduleFailure())
  }
}

const createSchedule = function*({ payload }) {
  try {
    const token = yield select(getToken)
    yield call(ScheduleManager.createSchedule, {
      body: payload,
      token,
    })
    const schedule = yield call(ScheduleManager.getSchedule, token)
    yield put(getScheduleSuccess(schedule))
  } catch (err) {
    console.log('err', err)
    yield put(getScheduleFailure())
  }
}

function* scheduleSaga() {
  yield all([
    takeLatest(getScheduleRequest, getSchedule),
    takeLatest(createScheduleRequest, createSchedule),
  ])
}

export default scheduleSaga
