import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(() => ({
  root: {
    boxSizing: 'border-box',
    width: '95%',
    marginBottom: 20,
  },
  error: {
    fontFamily: 'FuturaBookC',
    fontSize: 16,
    color: 'red',
    marginTop: 10,
  },
  cancelButton: {
    marginTop: 18,
    width: 95,
    height: 36,
    background:
      'linear-gradient(0deg, #9BA1A6, #9BA1A6), linear-gradient(92.32deg, #F49355 0%, #F0640C 100%)',
    borderRadius: 8,
    boxShadow: '0px 6px 14px 0px rgba(2, 9, 75, 0.06)',
    padding: '9px 11px',
    fontFamily: 'FuturaBookC',
    fontSize: 16,
    color: '#ffffff',
    textTransform: 'initial',
    lineHeight: 'initial',
  },
  addButton: {
    marginTop: 18,
    marginRight: 8,
    width: 95,
    height: 36,
    boxShadow: '0px 6px 14px 0px rgba(2, 9, 75, 0.06)',
    padding: '9px 11px',
    fontFamily: 'FuturaBookC',
    fontSize: 16,
    color: '#ffffff',
    textTransform: 'initial',
    lineHeight: 'initial',
    borderRadius: 8,
    background: 'linear-gradient(92.15deg, #F6A26D -38.69%, #F87C2E 107.08%)',
  },
  buttonWrapper: {
    display: 'flex',
  },
  input: {
    width: '95%',
    marginBottom: 20,
    '& > .MuiOutlinedInput-root': {
      borderRadius: '8px',
    },
    '& > .MuiOutlinedInput-root > input': {
      fontFamily: 'FuturaBookC',
      fontSize: 14,
      padding: '11px 11px 9px 11px',
    },
    '& > .MuiOutlinedInput-root > .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #F49355',
    },
  },
  newBorderInput: {
    width: 285,
    marginBottom: 20,
    '& > .MuiOutlinedInput-root': {
      borderRadius: '8px',
    },
    '& > .MuiOutlinedInput-root > input': {
      fontFamily: 'FuturaBookC',
      fontSize: 14,
      padding: '11px 11px 9px 11px',
    },
    '& > .MuiOutlinedInput-root > .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #F49355',
    },
  },
  select: {
    width: ({ width }) => width || '100%',
    marginTop: ({ marginTop }) => marginTop || 0,
    '& > .MuiSelect-select': {
      padding: '12px 28px 9px 15px',
    },
    fontSize: 14,
    fontFamily: 'FuturaBookC',
    '& > .MuiOutlinedInput-notchedOutline': {
      borderColor: '#F49355',
    },
    '&.Mui-disabled > .MuiOutlinedInput-notchedOutline': {
      borderColor: '#F49355',
    },
    '&:hover > .MuiOutlinedInput-notchedOutline': {
      borderColor: '#F49355',
    },
    '&.Mui-focused > .MuiOutlinedInput-notchedOutline': {
      borderColor: '#F49355',
      borderWidth: 1,
    },
    borderRadius: 8,
  },
  icon: {
    fill: '#F49355',
  },
  menu: {
    padding: '7px 0',
    boxShadow: '0px 6px 14px 0px rgba(2, 9, 75, 0.06)',
  },
  labelFormControl: {
    zIndex: 2,
  },
  labelShrink: {
    transform: ({ transformX }) =>
      `translate(10px, ${transformX || 15}px) scale(0.75)`,
    padding: '0 5px',
    background: '#fff',
  },
  labelRoot: {
    '&.Mui-focused': {
      color: 'rgba(0, 0, 0, 0.54)',
    },
  },
}))

export default useStyles
