import React from 'react'
import * as R from 'ramda'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { Loader, Tabs } from '../components'
import { CategoriesAndProducts } from '../components/categories'
import { ProductRatings, ProductsByCity } from '../components/products'
import {
  getCategoriesRequest,
  getIsLoading,
  resetSelectedCategory,
} from '../modules/categories'
import { getAccessListForUser } from '../modules/users'
import { useWebSockets } from '../context'
import AccessForbiddenPopup from '../components/common/AccessForbidden'
const Wrap = styled.div`
  background: #ecf1f4;
  display: flex;
  flex: 1;
  overflow-y: scroll;
  padding: 20px 20px 0;
`

const TabsWrapper = styled.div`
  width: 100%;
`

const StyledLoader = styled(Loader)`
  margin: auto;
  align-self: center;
`

const CategoriesDump = ({
  getCategories,
  isLoading,
  resetSelectedCategory,
  accessList,
}) => {
  const [modalShow, setModalShow] = React.useState(false)

  const [editAccess, setEditAccesses] = React.useState([])

  const [viewAccess, setViewAccess] = React.useState([])

  const [modalViewShow, setModalViewShow] = React.useState(false)

  const clickInTable = () => {
    if (!editAccess.length) setModalShow(true)
  }

  React.useEffect(() => {
    if (!accessList.length) return
    let view = accessList.filter(
      access => access.module === 'categories' && access.accessType === 'view',
    )
    let edit = accessList.filter(
      access => access.module === 'categories' && access.accessType === 'edit',
    )
    setEditAccesses(edit)
    setViewAccess(view)
    setModalViewShow(!view.length)
  }, [accessList, accessList.length])

  const { updatedData } = useWebSockets()

  const categoryEvents = [
    'createSubcategory',
    'deleteSubcategory',
    'updateSubcategory',
  ]

  React.useEffect(() => {
    if (updatedData) {
      if (categoryEvents.includes(updatedData.action)) {
        getCategories()
      }
    }
  }, [updatedData])

  React.useEffect(() => {
    getCategories()

    return () => {
      resetSelectedCategory()
    }
  }, [getCategories, resetSelectedCategory])

  const tabsNames = ['Категории и товары', 'Рейтинг товаров', 'Цены по городам']
  const tabsContent = [
    <CategoriesAndProducts
      editAccess={editAccess}
      setModalShow={setModalShow}
    />,
    <ProductRatings editAccess={editAccess} setModalShow={setModalShow} />,
    <ProductsByCity editAccess={editAccess} setModalShow={setModalShow} />,
  ]

  return modalViewShow ? (
    <AccessForbiddenPopup
      moduleName={'Категории'}
      setModalShow={setModalViewShow}
      canClose={false}
    />
  ) : (
    <Wrap>
      {modalShow && (
        <AccessForbiddenPopup
          moduleName={'Категории'}
          setModalShow={setModalShow}
        />
      )}
      {isLoading ? (
        <StyledLoader />
      ) : (
        <TabsWrapper>
          <Tabs tabsNames={tabsNames} tabsContent={tabsContent} />
        </TabsWrapper>
      )}
    </Wrap>
  )
}

const Categories = connect(
  R.applySpec({
    isLoading: getIsLoading,
    accessList: getAccessListForUser,
  }),
  { getCategories: getCategoriesRequest, resetSelectedCategory },
)(CategoriesDump)

export default Categories
