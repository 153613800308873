import React from 'react'
import * as R from 'ramda'
import { connect } from 'react-redux'
import styled from 'styled-components'
import assets from '../../assets'
import { getAmoUsersList, selectRole } from '../../modules/users'

const Container = styled.div`
  display: flex;
  align-items: ${({ isActive }) => (isActive ? 'normal' : 'center')};
  justify-content: space-between;
  height: 35px;
  max-width: 260px;
  position: relative;
  background-color: white;
  margin-top: 15px;
  border-radius: 6px;
  color: #35414c;
  font-size: 14px;
  line-height: 19px;
  border: 1px solid #f49355;
  border-bottom-left-radius: ${({ isActive }) => (isActive ? '0' : '6px')};
  border-bottom-right-radius: ${({ isActive }) => (isActive ? '0' : '6px')};
  cursor: pointer;
`

const DropDownContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: ${({ isActive }) => (isActive ? 'absolute' : 'relative')};
  height: ${({ isActive }) => (isActive ? 'auto' : '39px')};
  max-height: ${({ isActive }) => (isActive ? '200px' : '39px')};
  background-color: white;
  width: 100%;
  min-width: 260px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  padding-bottom: 10px;
  top: 100%;
  left: -1px;
  z-index: 30;
  border: 1px solid #f49355;
  overflow: scroll;
  overflow-x: hidden;
  ::-webkit-scrollbar {
    display: none;
  }
`

const DropDownStart = styled.div`
  font-family: 'FuturaBookC', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 15px;
  display: flex;
  align-items: center;
  color: rgba(18, 22, 26, 0.9);
  padding-left: 15px;
`

const DropDownDefault = styled.div`
  font-family: 'FuturaBookC', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 15px;
  display: flex;
  align-items: center;
  color: rgba(18, 22, 26, 0.9);
  opacity: 0.8;
  padding-left: 15px;
`

const DropDownItem = styled.div`
  padding-top: 15px;
  padding-left: 15px;
  z-index: 1;
  font-family: 'FuturaBookC', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 15px;
  color: rgba(18, 22, 26, 0.9);
`

const DropDownItemText = styled.div`
  z-index: 1;
  font-family: 'FuturaBookC', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 15px;
  color: rgba(18, 22, 26, 0.9);
`

const Arrow = styled.img.attrs({ src: assets.img.selectArrow })`
  transform: ${({ isActive }) => (isActive ? 'rotate(180deg)' : '')};
  margin-right: 16px;
  width: 10px;
`
const SearchInput = styled.input`
  padding: 3px;
`

const DriversDropDownDump = ({
  driversList,
  isDriverOpen,
  setIsDriverOpen,
  setUserId,
  selectRole,
}) => {
  const [chosenDriver, setChosenDriver] = React.useState('Выберите водителя')
  const [searchValue, setSearchValue] = React.useState('')
  const [searchActive, setSearchActive] = React.useState(false)
  React.useEffect(() => {
    selectRole('Водитель')
  }, [selectRole])

  return (
    <Container
      onClick={() => !searchActive && setIsDriverOpen(!isDriverOpen)}
      isActive={isDriverOpen}
    >
      {isDriverOpen ? (
        <DropDownStart>
          {chosenDriver}
          <DropDownContainer isActive={isDriverOpen}>
            <DropDownItem>
              <SearchInput
                type="text"
                placeholder="Поиск по водителям..."
                onFocus={() => setSearchActive(true)}
                onBlur={() => setSearchActive(false)}
                onKeyUp={e => setSearchValue(e.target.value)}
              ></SearchInput>
            </DropDownItem>
            {driversList.sort().map(
              (driver, index) =>
                driver.fio.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1 && (
                  <DropDownItem key={index}>
                    <DropDownItemText
                      onClick={() => {
                        setChosenDriver(driver.fio)
                        setUserId(driver.id)
                      }}
                    >
                      {driver.fio}
                    </DropDownItemText>
                  </DropDownItem>
                ),
            )}
          </DropDownContainer>
        </DropDownStart>
      ) : (
        <DropDownDefault>{chosenDriver}</DropDownDefault>
      )}
      <Arrow isActive={isDriverOpen} />
    </Container>
  )
}

const DriversDropDown = connect(R.applySpec({ driversList: getAmoUsersList }), {
  selectRole,
})(DriversDropDownDump)

export default DriversDropDown
