import React from 'react'
import styled from 'styled-components'
import { Draggable } from 'react-beautiful-dnd'
import { Button } from '@material-ui/core'
import { connect } from 'react-redux'
import * as R from 'ramda'
import QuoteList from '../quote-list'

import { grid, borderRadius } from '../constants'
import { deleteColumnRequest } from '../../../../modules/boards'
import { getTransport } from '../../../../modules/transport'
import { deleteButtonIcon } from '../../../../assets/img'
import { useStyles } from './styles'

const Container = styled.div`
  margin: ${grid}px;
  display: flex;
  flex-direction: column;
`

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-top-left-radius: ${borderRadius}px;
  border-top-right-radius: ${borderRadius}px;
`

const TitleWrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Title = styled.span`
  font-family: FuturaDemiC;
  font-size: 11px;
  color: rgba(18, 22, 26, 0.9);
  text-transform: uppercase;
  margin-bottom: 2px;
`

const Description = styled.span`
  font-family: 'FuturaBookC', sans-serif;
  font-size: 12px;
  color: rgba(18, 22, 26, 0.7);
  margin-bottom: 10px;
`

const Divider = styled.div`
  background: #ffffff;
  height: 2px;
  width: 100%;
  margin-bottom: 10px;
`

const Column = ({
  title,
  totalPrice,
  count,
  quotes,
  index,
  isScrollable,
  isCombineEnabled,
  useClone,
  columnObj,
  deleteColumn,
  formValues,
  getOrders,
  setModalState,
  setModalShow,
  editAccess,
  transports,
}) => {
  quotes.forEach(quote => {
    for (const transport of transports) {
      if (quote.transportId === transport.transportId) {
        quote.driverName = transport.fio;
        quote.carNumber = transport.carNumber;
        break;
      }
    }
  })
  const classes = useStyles()
  const deleteHandle = () => {
    deleteColumn({ statusId: columnObj?.id })
    const { board, organization, product, driver, search } = formValues
    setTimeout(() => {
      getOrders({
        boardId: board,
        query: {
          organization: organization === '0' ? '' : organization,
          product: product === '0' ? '' : product,
          driver: driver === '0' ? '' : driver,
          search,
        },
      })
    }, 500)
  }
  return (
    <Draggable draggableId={title} index={index}>
      {(provided, snapshot) => (
        <Container ref={provided.innerRef} {...provided.draggableProps}>
          <Header
            isDragging={snapshot.isDragging}
            {...provided.dragHandleProps}
          >
            <TitleWrapper>
              <Title>{title}</Title>
              <Description>
                {count} сделки: {totalPrice} ₽
              </Description>
              {!columnObj?.isDefault && (
                <Button
                  onClick={() =>
                    editAccess ? deleteHandle() : setModalShow(true)
                  }
                  classes={{
                    root: classes.deleteButton,
                  }}
                >
                  <img src={deleteButtonIcon} alt="" />
                </Button>
              )}
            </TitleWrapper>
            <Divider />
          </Header>
          <QuoteList
            setModalState={setModalState}
            listId={title}
            listType="QUOTE"
            quotes={quotes}
            internalScroll={isScrollable}
            isCombineEnabled={Boolean(isCombineEnabled)}
            useClone={Boolean(useClone)}
            editAccess={editAccess}
            setModalShow={setModalShow}
          />
        </Container>
      )}
    </Draggable>
  )
}

export default connect(
  R.applySpec({
    transports: getTransport,
  }),
  {
    deleteColumn: deleteColumnRequest,
  },
)(Column)
