import querystring from 'query-string'

const API_KEY = 'AIzaSyB2A4NUCeiipumsnmItXZojnH0vYFapA8U'

const URL = 'https://maps.googleapis.com/maps/api/geocode/json'

export const fetchLocationByCoords = ({ lat, lng }, options) =>
  fetch(
    `${URL}?${querystring.stringify(
      {
        latlng: `${lat}, ${lng}`,
        key: API_KEY,
        //kind: 'house',
      },
      { arrayFormat: 'comma' },
    )}`,
    options,
  )
    .then(res => res.json())
    .then(({ results: GeoObjectCollection }) => {
      return GeoObjectCollection.map(GeoObject => ({
        address: GeoObject['formatted_address'],
      }))
    })

export const fetchSuggestions = ({ city = 'Краснодар', part = '' }, options) =>
  fetch(
    `/api/v1/suggestions?${querystring.stringify({
      part: `${city} ${part}`,
    })}`,
    options,
  )
    .then(res => res.json())
    .then(items =>
      // @ts-ignore
      items.map(({ pos, title: { text: address } }) => ({
        address,
        lng: Number(pos.split(',')[0]),
        lat: Number(pos.split(',')[1]),
      })),
    )

export const fetchCurrentLocation = () =>
  new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      position =>
        resolve({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        }),
      reject,
    )
  })
