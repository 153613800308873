import { call, all, put, takeLatest, select } from 'redux-saga/effects'
import {
  getTransportRequest,
  getTransportSuccess,
  getTransportFailure,
  getTransportCategoryRequest,
  getTransportCategorySuccess,
  getTransportCategoryFailure,
  createTransportRequest,
  createTransportSuccess,
  createTransportFailure,
  createCategoryRequest,
  createCategorySuccess,
  createCategoryFailure,
  deleteTransportRequest,
  deleteTransportSuccess,
  deleteTransportFailure,
  editTransportRequest,
  editTransportSuccess,
  editTransportFailure,
  deleteCategoryRequest,
  deleteCategorySuccess,
  deleteCategoryFailure,
  editCategoryRequest,
  editCategorySuccess,
  editCategoryFailure,
  getTransportStatusesRequest,
  getTransportStatusesSuccess,
  getTransportStatusesFailure,
} from './duck'
import * as TransportManager from './TransportManager'
import {
  getSearchedTransport,
  getSelectedCategory,
  getSelectedStatus,
  getSortTransport,
} from './selectors'
import { getToken } from '../auth'

const getTransportSaga = function*() {
  try {
    const accessToken = yield select(getToken)
    const selectedCategory = yield select(getSelectedCategory)
    const searchedTransport = yield select(getSearchedTransport)
    const selectedStatus = yield select(getSelectedStatus)
    const selectedSort = yield select(getSortTransport)
    const sort = 'ordersCount'
    const transport = yield call(TransportManager.getTransport, {
      accessToken,
      filters: {
        transportCategory: selectedCategory !== 'Все' ? selectedCategory : '',
        search: searchedTransport !== '' ? searchedTransport : '',
        status: selectedStatus !== '' ? selectedStatus : '',
        sortField: sort,
        sortType: selectedSort,
      },
    })

    yield put(getTransportSuccess(transport))
  } catch (ex) {
    console.log(ex)
    yield put(getTransportFailure(ex.localeMessage))
  }
}

const getTransportCategorySaga = function*() {
  try {
    const accessToken = yield select(getToken)
    const transportCategory = yield call(
      TransportManager.getTransportCategory,
      {
        accessToken,
      },
    )
    yield put(getTransportCategorySuccess(transportCategory))
  } catch (ex) {
    console.log(ex)
    yield put(getTransportCategoryFailure(ex.localeMessage))
  }
}

const getTransportStatusesSaga = function*() {
  try {
    const accessToken = yield select(getToken)
    const transportStatuses = yield call(
      TransportManager.getTransportStatuses,
      { accessToken },
    )
    yield put(getTransportStatusesSuccess(transportStatuses))
  } catch (ex) {
    yield put(getTransportStatusesFailure(ex))
  }
}

const createTransportCategorySaga = function*({ payload: title }) {
  try {
    const accessToken = yield select(getToken)
    const body = { title }
    const newCategory = yield call(TransportManager.createTransportCategory, {
      body,
      accessToken,
    })
    yield put(createCategorySuccess(newCategory))
  } catch (ex) {
    console.log(ex)
    yield put(createCategoryFailure(ex))
  }
}

const createTransportSaga = function*({
  payload: { userId, carNumber, transportCategoryId, capacity, brand },
}) {
  try {
    const accessToken = yield select(getToken)
    const body = { userId, carNumber, transportCategoryId, capacity, brand }
    const newTransport = yield call(TransportManager.createTransport, {
      body,
      accessToken,
    })
    yield put(createTransportSuccess(newTransport))
    yield put(getTransportRequest())
  } catch (ex) {
    console.log(ex)

    yield put(createTransportFailure(ex))
  }
}

const deleteTransportSaga = function*({ payload: id }) {
  try {
    const accessToken = yield select(getToken)
    yield call(TransportManager.deleteTransport, {
      id,
      accessToken,
    })
    yield put(deleteTransportSuccess(id))
  } catch (ex) {
    console.log(ex)
    yield put(deleteTransportFailure())
  }
}

const deleteCategorySaga = function*({ payload: id }) {
  try {
    const accessToken = yield select(getToken)
    yield call(TransportManager.deleteCategory, {
      id,
      accessToken,
    })
    yield put(deleteCategorySuccess(id))
  } catch (ex) {
    console.log(ex)
    yield put(deleteCategoryFailure())
  }
}

const editCategorySaga = function*({ payload: { id, title } }) {
  try {
    const accessToken = yield select(getToken)
    const body = { id, title }
    yield call(TransportManager.editCategory, {
      accessToken,
      body,
    })
    yield put(editCategorySuccess())
  } catch (ex) {
    yield put(editCategoryFailure(ex))
  }
}

const editTransportSaga = function*({
  payload: {
    id,
    userId,
    capacity,
    brand,
    transportCategoryId,
    status,
    carNumber,
  },
}) {
  try {
    const accessToken = yield select(getToken)
    const body = {
      id,
      userId,
      capacity,
      brand,
      transportCategoryId,
      status,
      carNumber,
    }
    yield call(TransportManager.editTransport, {
      body,
      accessToken,
    })
    yield put(editTransportSuccess())
  } catch (ex) {
    console.log(ex)
    yield put(editTransportFailure(ex))
  }
}

function* transportSaga() {
  yield all([
    takeLatest(getTransportStatusesRequest, getTransportStatusesSaga),
    takeLatest(editCategoryRequest, editCategorySaga),
    takeLatest(editTransportRequest, editTransportSaga),
    takeLatest(deleteTransportRequest, deleteTransportSaga),
    takeLatest(createCategoryRequest, createTransportCategorySaga),
    takeLatest(getTransportRequest, getTransportSaga),
    takeLatest(getTransportCategoryRequest, getTransportCategorySaga),
    takeLatest(createTransportRequest, createTransportSaga),
    takeLatest(deleteCategoryRequest, deleteCategorySaga),
  ])
}

export default transportSaga
