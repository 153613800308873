import { all, call } from 'redux-saga/effects'
import { categoriesSaga } from './categories'
import { authSaga } from './auth'
import { productsSaga } from './products'
import { promotionsSaga } from './promotions'
import { usersSaga } from './users'
import { driversSaga } from './drivers'
import { scheduleSaga } from './schedule'
import { organizationsSaga } from './organizations'
import { adminUsersSaga } from './admin-users'
import { boardsSaga } from './boards'
import { ordersSaga } from './orders'
import { transportSaga } from './transport'
import { analyticsSaga } from './analytics'
import { storageSaga } from './storage'
import { storagesSaga } from './storages'

function* rootSaga() {
  yield all([
    call(storageSaga),
    call(authSaga),
    call(transportSaga),
    call(productsSaga),
    call(promotionsSaga),
    call(usersSaga),
    call(categoriesSaga),
    call(driversSaga),
    call(scheduleSaga),
    call(organizationsSaga),
    //call(adminUsersSaga),
    call(adminUsersSaga),
    call(boardsSaga),
    call(ordersSaga),
    call(analyticsSaga),
    call(storagesSaga),
  ])
}

export default rootSaga
