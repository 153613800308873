import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  loadButton: {
    boxShadow: '0px 6px 14px 0px rgba(2, 9, 75, 0.06)',
    padding: '9px 11px',
    fontFamily: 'FuturaBookC',
    fontSize: 16,
    color: '#ffffff',
    textTransform: 'initial',
    lineHeight: 'initial',
    borderRadius: 8,
    background: 'linear-gradient(92.15deg, #F6A26D -38.69%, #F87C2E 107.08%)',
    marginLeft : 10
  },
  closeModalButton : {
    boxShadow: '0px 6px 14px 0px rgba(2, 9, 75, 0.06)',
    padding: '9px 18px',
    color: '#ffffff',
    textTransform: 'initial',
    lineHeight: 'initial',
    borderRadius: 8,
    background: 'linear-gradient(92.15deg, #F6A26D -38.69%, #F87C2E 107.08%)',
    fontFamily: 'FuturaBookC',
    fontSize: 17,
    fontWeight: 'bold'
  },
  searchInput: {
    height: 37,
    width: '100%',
    '& > .MuiOutlinedInput-root': {
      borderRadius: '8px 0px 0px 8px',
    },
    '& > .MuiOutlinedInput-root > input': {
      fontFamily: 'FuturaBookC',
      fontSize: 14,
      padding: '11px 0 9px 11px',
    },
    '& > .MuiOutlinedInput-root > .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #F49355',
    },
  },
}))

export default useStyles