import { ENDPOINTS } from '../../API'
import { handleStatuses } from '../../utils'

export const getStorage = ({ token }) =>
  fetch(ENDPOINTS.STORAGES(), {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
    .then(handleStatuses())
    .then(res => res.json())
