import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { registerLocale } from 'react-datepicker'
import ru from 'date-fns/locale/ru'

import './index.css'

registerLocale('ru', ru)

ReactDOM.render(<App />, document.getElementById('root'))

serviceWorker.unregister()
