import React, { useState } from 'react'
import styled from 'styled-components'

const TabsContainer = styled.div`
  width: 100%;
  min-width: 700px;
  display: flex;
`

const TabContainer = styled.div`
  padding: 10px 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 24px;
  text-transform: uppercase;
  font-family: FuturaMediumC sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #7F8992;
  cursor: pointer;
`

const ActiveTabContainer = styled(TabContainer)`
  border-radius: 8px 8px 0 0;
  background-color: #fff;
  color: #F49355;
`

const Tabs = ({ tabsNames, tabsContent }) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0)
  if (!tabsNames) return null

  const renderTabs = () => (
    <TabsContainer>
      {tabsNames.map((tabName, index) => {
        if (index === activeTabIndex) return (
          <ActiveTabContainer key={index}>{tabName}</ActiveTabContainer>
        )
        return (
          <TabContainer key={index} onClick={() => setActiveTabIndex(index)}>{tabName}</TabContainer>
        )
      })}
    </TabsContainer>
  )

  const renderContent = ()=> tabsContent[activeTabIndex] || null

  return (
    <>
      {renderTabs()}
      {renderContent()}
    </>
  )

}

export default Tabs