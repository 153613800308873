import React, { useMemo, useState } from 'react'
import styled from 'styled-components'

import { triangle } from '../../../../assets/img'

import ModalPortal from '../ModalPortal'
import * as R from 'ramda'

const Wrapper = styled.div`
  height: 36px;
  border-radius: 8px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  background-color: #ffffff;
  padding: 10px 10px 10px 0;
  box-sizing: border-box;
`

const ListItem = styled.div`
  margin-bottom: 10px;
  font-family: 'FuturaBookC', sans-serif;
  font-size: 18px;
  line-height: 30px;
  color: rgba(18, 22, 26, 0.7);
`

const Triangle = styled.img.attrs({ src: triangle })`
  transform: rotate(90deg);
  margin-left: 10px;
`

const SelectedProductContainer = styled.div`
  font-family: 'FuturaBookC', sans-serif;
  font-size: 14px;
  line-height: 15px;
  color: rgba(18, 22, 26, 0.9);
`

const FreeTransportCountContainer = styled.div`
  background: #F49355;
  border-radius: 8px 0 0 8px;
  width: 36px;
  height: 36px;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: FuturaDemiC;
  font-weight: 600;
  font-size: 16px;
  line-height: 15px;
  color: #FFFFFF;
`

const Container = styled.div`
  display: flex;
  align-items: center;
`

const TransportCategorySelect = ({ transports, transportCategory, setTransportCategory }) => {
  const [modalOpen, setIsOpen] = useState(false)

  const getFreeTransportOptions = R.pipe(
    () => transports,
    R.groupBy(transport => transport.title),
    Object.entries,
    R.map(([title, value]) => ({
      title,
      count: value.length,
      id: R.head(value).transportCategoryId,
    })),
  )

  const getFreeTransportCount = useMemo(() => {
    if (transportCategory.id === 0) return transports.length
    return R.find(R.propEq('id', transportCategory.id), getFreeTransportOptions()).count
  }, [transportCategory, transports])

  const renderModal = () => {
    if (!modalOpen) return null
    const close = () => {
      setIsOpen(false)
    }

    const onClick = (category) => {
      setTransportCategory(category)
      close()
    }

    return (
      <ModalPortal close={close} header='Организации'>
        {getFreeTransportOptions().map(el => <ListItem onClick={() => onClick(el)} key={el.id}>{el.title}</ListItem>)}
      </ModalPortal>
    )
  }

  return (
    <>
      <Wrapper onClick={() => setIsOpen(true)}>
        <Container>
          <FreeTransportCountContainer>
            {getFreeTransportCount}
          </FreeTransportCountContainer>
          <SelectedProductContainer>
            {transportCategory.title}
          </SelectedProductContainer>
        </Container>
        <Triangle />
      </Wrapper>
      {renderModal()}
    </>
  )
}

export default TransportCategorySelect