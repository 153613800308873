import React from 'react'
import styled from 'styled-components'
import * as R from 'ramda'
import { connect } from 'react-redux'
import {
  getSelectedUser,
  createUserRequest,
  deleteUserRequest,
  getUsersRequest,
} from '../../modules/users'
import {
  getUserDeletionModalVisible,
  setUserDeletionModalVisible,
} from '../../modules/ui'

const Container = styled.div`
  width: 516px;
  height: 245px;
  background: #ffffff;
  box-shadow: 0px 6px 14px rgba(2, 9, 75, 0.06);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 20px 10px;
`
const TextContainer = styled.div`
  height: 160px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-family: 'FuturaBookC', sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
`

const ButtonsContainer = styled.div`
  display: flex;
  background: #fff;
  padding: 20px 0px;
  border-radius: 8px;
  width: 100%;
  justify-content: center;
`

const ConfirmButton = styled.button`
  height: 36px;
  border: none;
  background: linear-gradient(135deg, #f49355 0%, #f0640c 100%);
  box-shadow: 0px 4px 10px rgba(247, 137, 68, 0.55);
  cursor: pointer;
  outline: none;
  border-radius: 3px;
  color: white;
  margin-right: 15px;
  width: 95px;
`

const CancelButton = styled(ConfirmButton)`
  background: linear-gradient(135deg, #b6b6b6 0%, #636363 100%);
`

const DeletionPopupDump = ({ cancel, selectedUser, deleteUser }) => {
  const delete_user = () => {
    deleteUser({ userId: selectedUser.id })
    cancel()
  }

  return (
    <Container>
      <TextContainer>Удалить пользователя {selectedUser?.fio} ?</TextContainer>
      <ButtonsContainer>
        <ConfirmButton onClick={delete_user}>Да</ConfirmButton>
        <CancelButton onClick={cancel}>Отмена</CancelButton>
      </ButtonsContainer>
    </Container>
  )
}

const UserDeletionPopup = R.compose(
  connect(
    R.applySpec({
      selectedUser: getSelectedUser,
    }),
    {
      createUser: createUserRequest,
      cancel: setUserDeletionModalVisible,
      deleteUser: deleteUserRequest,
      getUsers: getUsersRequest,
    },
  ),
)(DeletionPopupDump)

export default UserDeletionPopup
