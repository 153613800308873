import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import DocumentsViewModal from './documentModal'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const DocumentItemView = styled.div`
  display: flex;
  justify-content: center
  align-items: center;
  height: 36px;
  background: #ffffff;
  box-shadow: 0 6px 10px rgba(2, 9, 75, 0.06);
  border-radius: 8px;
  padding: 0 15px;
  cursor: pointer;
`

const DocumentItemViewOrange = styled.div`
  padding: 9px 11px;
  font-family: FuturaBookC, sans-serif;
  font-size: 16px;
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 20px;
  background: linear-gradient(92.15deg, #f6a26d -38.69%, #f87c2e 107.08%);
  box-shadow: 0 6px 10px rgba(2, 9, 75, 0.06);
  border-radius: 8px;
  cursor: pointer;
`

const DocumentItemTitleOrange = styled.div`
  font-family: FuturaBookC, sans-serif;
  font-size: 16px;
  display: flex;
  align-items: center;
  color: white;
`

const DocumentItemTitle = styled.div`
  font-family: FuturaBookC, sans-serif;
  font-size: 14px;
  display: flex;
  align-items: center;
  color: rgba(18, 22, 26, 0.9);
`
const DocumentItemCount = styled.div`
  font-family: FuturaBookC, sans-serif;
  font-size: 14px;
  display: flex;
  align-items: center;
  color: rgba(18, 22, 26, 0.4);
`

const groupBy = (xs, key) =>
  xs.reduce((rv, x) => {
    ;(rv[x[key]] = rv[x[key]] || []).push(x)
    return rv
  }, {})

const Documents = ({ documents, isView }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedDocuments, setSelectedDocuments] = useState([])

  useEffect(() => {
    setSelectedDocuments([])
  }, [])

  const groupedDocuments =
    documents.length > 0 ? groupBy(documents, 'title') : {}

  const handleSelectDocuments = item => {
    setSelectedDocuments(groupedDocuments[item])
    setIsModalOpen(true)
  }

  const handleModalClose = () => {
    setIsModalOpen(false)
    setSelectedDocuments([])
  }

  const viewItems =
    documents.length > 0 &&
    Object.keys(groupedDocuments).map(item =>
      isView ? (
        <DocumentItemViewOrange
          onClick={() => handleSelectDocuments(item)}
          key={item}
        >
          <DocumentItemTitleOrange>{item}</DocumentItemTitleOrange>
          <DocumentItemCount>
            {groupedDocuments[item].length === 1
              ? ''
              : groupedDocuments[item].length}
          </DocumentItemCount>
        </DocumentItemViewOrange>
      ) : (
        <DocumentItemView
          onClick={() => handleSelectDocuments(item)}
          key={item}
        >
          <DocumentItemTitle>{item}</DocumentItemTitle>
          <DocumentItemCount>
            {groupedDocuments[item].length === 1
              ? ''
              : groupedDocuments[item].length}
          </DocumentItemCount>
        </DocumentItemView>
      ),
    )

  return (
    <Wrapper>
      {selectedDocuments.length > 0 && (
        <DocumentsViewModal
          open={isModalOpen}
          handleModalClose={handleModalClose}
          documents={selectedDocuments}
        />
      )}
      {viewItems}
    </Wrapper>
  )
}

export default Documents
