import React, { useEffect } from 'react'
import styled, { css } from 'styled-components'
import { connect } from 'react-redux'
import assets from '../../../../assets'
import * as R from 'ramda'
import {
  getTotalOrdersRequest,
  getTotalOrders,
} from '../../../../modules/orders'
import moment from 'moment'
const Wrapper = styled.div`
  display: flex;
  align-items: center;
`
const FirstPaginatorArrowsLeft = styled.img.attrs({
  src: assets.img.paginatorArrows,
})`
  cursor: pointer;
`
const LastPaginatorArrowsRight = styled.img.attrs({
  src: assets.img.paginatorArrows,
})`
  cursor: pointer;
  transform: rotate(180deg);
`
const PrevPaginatorArrowsLeft = styled.img.attrs({
  src: assets.img.arrow,
})`
  cursor: pointer;
  width: 14px;
  height: 14px;
  transform: rotate(90deg);
  margin-right: 10px;
`
const NextPaginatorArrowsRight = styled.img.attrs({
  src: assets.img.arrow,
})`
  cursor: pointer;
  width: 14px;
  height: 14px;
  transform: rotate(270deg);
  margin-left: 10px;
`

const Count = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 38px;
  height: 38px;
  font-family: FuturaBookC, sans-serif;
  font-size: 16px;
  ${({ isActive }) =>
    isActive
      ? css`
          background: #ffffff;
          box-shadow: 0 6px 14px rgba(10, 21, 128, 0.1);
          color: #f0640c;
        `
      : css`
          color: #495560;
        `}
  border-radius: 6px;
`
const periodToArray = period => {
  return Object.values(
    period || {
      endDate: '',
      startDate: '',
      periodType: 'all',
    },
  ).map(p => {
    if (p && !['day', 'week', 'all'].includes(p)) return moment(p).format()
    return p
  })
}

const OrdersPagination = ({
  total,
  getTotal,
  page,
  setPage,
  countOnPage,
  query,
  period,
}) => {
  const totalPages = Math.ceil(total / countOnPage) || 1
  useEffect(() => {
    if (page * countOnPage > total) {
      const curPage = Math.ceil(total / countOnPage)
      setPage(curPage)
    } else {
      setPage(1)
    }
  }, [total, countOnPage])
  useEffect(() => {
    getTotal({
      query: { ...query, isRealize: true, period: periodToArray(period) },
    })
  }, [query, period])
  const minimumArrowSize = totalPages > 3 ? 3 : totalPages
  let paginationBar = [...Array(minimumArrowSize)].map((n, i) =>
    totalPages - page < minimumArrowSize ? totalPages - i : page + i,
  )
  const isReverse =
    totalPages - page < minimumArrowSize
      ? paginationBar.reverse()
      : paginationBar

  const setPageStatus = page => {
    setPage(page)
  }

  return (
    <Wrapper>
      <FirstPaginatorArrowsLeft
        onClick={() => {
          if (page !== 1) {
            setPageStatus(1)
          }
        }}
      />
      <PrevPaginatorArrowsLeft
        onClick={() => {
          if (page !== 1) {
            setPageStatus(page - 1)
          }
        }}
      />
      {isReverse.map(item => (
        <Count
          key={item}
          onClick={() => setPageStatus(item)}
          isActive={item === page}
        >
          {item}
        </Count>
      ))}
      <NextPaginatorArrowsRight
        onClick={() => {
          if (page !== totalPages) {
            setPageStatus(page + 1)
          }
        }}
      />
      <LastPaginatorArrowsRight
        onClick={() => {
          if (page !== totalPages) {
            setPageStatus(totalPages)
          }
        }}
      />
    </Wrapper>
  )
}
export default connect(
  R.applySpec({
    total: getTotalOrders,
  }),
  {
    getTotal: getTotalOrdersRequest,
  },
)(OrdersPagination)
