import React, { useState, useMemo } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import * as R from 'ramda'
import { addDays, format } from 'date-fns'
import {
  Organizations,
  Drivers,
  AnalyticsFooter,
  AnalyticsInfoModal,
  AnalyticsGraphs,
} from '../../components/analytics'
import { Tabs } from '../../components'
import { getAccessListForUser } from '../../modules/users'

import AccessForbiddenPopup from '../../components/common/AccessForbidden'
import {
  getAnalyticsInfo,
  getAnalyticsInfoRequest,
  getAnalyticsRequest,
  getDownloadAnalyticsRequest,
  getIsLoading,
} from '../../modules/analytics'

const Wrapper = styled.div`
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
`

const TabContentSeparator = styled.div`
  width: 100%;
  height: 6px;
  margin-bottom: 20px;
  background: #ffffff;
`

export const getUTCDate = (dateString = Date.now()) => {
  const date = new Date(dateString)

  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours() + 3,
    date.getUTCMinutes(),
    date.getUTCSeconds(),
  )
}

const Analytics = ({
  getAnalytics,
  getAnalyticsInfo,
  analyticsInfo,
  getDownloadUri,
  accessList,
}) => {
  const [page, setPage] = useState(1)
  const [countOnPage, setCountOnPage] = useState(5)
  const [period, setPeriod] = useState({ startDate: '', endDate: '' })
  const [infoPage, setInfoPage] = useState(1)
  const [infoTotal, setInfoTotal] = useState(0)
  const [infoCountOnPage, setInfoCountOnPage] = useState(5)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [searchedDriver, setSearchedDriver] = useState('')
  const [searchedOrg, setSearchedOrg] = useState('')
  const [modalType, setModalType] = useState('organization')
  const [modalViewShow, setModalViewShow] = React.useState(false)

  React.useEffect(() => {
    if (!accessList.length) return
    let view = accessList.filter(
      access => access.module === 'analytics' && access.accessType === 'view',
    )
    setModalViewShow(!view.length)
  }, [accessList, accessList.length])
  const downloadOnClick = type => {
    const query = {
      page: page,
      countOnPage: countOnPage,
      startDate: period.startDate
        ? format(new Date(period.startDate), 'yyyy-MM-dd')
        : '',
      endDate: period.endDate
        ? format(new Date(period.endDate), 'yyyy-MM-dd')
        : '',
    }

    const infoQuery = {
      page: infoPage,
      countOnPage: infoCountOnPage,
      startDate: '',
      endDate: '',
    }

    let options = {
      type,
    }

    if (analyticsInfo.id && isModalOpen) {
      options.id = analyticsInfo.id
      options.query = infoQuery
    } else {
      options.query = query
    }

    getDownloadUri(options)
  }

  const requestData = useMemo(
    () => ({
      query: {
        page: page,
        countOnPage: countOnPage,
        driver: searchedDriver,
        organization: searchedOrg,
      },
      body: {
        startDate: period.startDate,
        endDate: period.endDate,
      },
    }),
    [page, countOnPage, period, searchedDriver, searchedOrg],
  )

  const handleGetAnalyticsInfo = (id, type) => {
    const query = {
      page: infoPage,
      countOnPage: infoCountOnPage,
      driver: searchedDriver,
      organization: searchedOrg,
    }
    const body = {
      startDate: period.startDate ? addDays(period.startDate, 1) : '',
      endDate: period.endDate ? addDays(period.endDate, 1) : '',
    }
    getAnalyticsInfo({ query, body, type, id }) //тут
    setModalType(type)
    setIsModalOpen(true)
  }

  const organizations = (
    <>
      <TabContentSeparator />
      <Organizations
        getAnalytics={getAnalytics}
        requestData={requestData}
        setInfoTotal={setInfoTotal}
        handleGetAnalyticsInfo={handleGetAnalyticsInfo}
        downloadOnClick={downloadOnClick}
        period={period}
        setPeriod={setPeriod}
        setSearchedOrg={setSearchedOrg}
        searchedOrg={searchedOrg}
      />
      <AnalyticsFooter
        isMain
        page={page}
        setPage={setPage}
        countOnPage={countOnPage}
        setCountOnPage={setCountOnPage}
      />
    </>
  )

  const drivers = (
    <>
      <TabContentSeparator />
      <Drivers
        getAnalytics={getAnalytics}
        requestData={requestData}
        setInfoTotal={setInfoTotal}
        handleGetAnalyticsInfo={handleGetAnalyticsInfo}
        downloadOnClick={downloadOnClick}
        period={period}
        setPeriod={setPeriod}
        setSearchedDriver={setSearchedDriver}
        searchedDriver={searchedDriver}
      />
      <AnalyticsFooter
        isMain
        page={page}
        setPage={setPage}
        countOnPage={countOnPage}
        setCountOnPage={setCountOnPage}
      />
    </>
  )

  const Graphs = (
    <>
      <TabContentSeparator />
      <AnalyticsGraphs />
    </>
  )

  const tabsNames = ['организации', 'водители', 'графики']
  const tabsContent = [organizations, drivers, Graphs]

  return modalViewShow ? (
    <AccessForbiddenPopup
      moduleName={'Аналитика'}
      setModalShow={setModalViewShow}
      canClose={false}
    />
  ) : (
    <Wrapper>
      <AnalyticsInfoModal
        open={isModalOpen}
        handleClose={() => setIsModalOpen(false)}
        period={period}
        setPeriod={setPeriod}
        page={infoPage}
        setPage={setInfoPage}
        countOnPage={infoCountOnPage}
        setCountOnPage={setInfoCountOnPage}
        type={modalType}
        handleGetAnalyticsInfo={handleGetAnalyticsInfo}
        infoTotal={infoTotal}
      />
      <Tabs tabsNames={tabsNames} tabsContent={tabsContent} />
    </Wrapper>
  )
}

export default connect(
  R.applySpec({
    isLoading: getIsLoading,
    analyticsInfo: getAnalyticsInfo,
    accessList: getAccessListForUser,
  }),
  {
    getAnalytics: getAnalyticsRequest,
    getAnalyticsInfo: getAnalyticsInfoRequest,
    getDownloadUri: getDownloadAnalyticsRequest,
  },
)(Analytics)
