import React, { useState } from 'react'
import styled from 'styled-components'
import CardModal from '../CardModal'
import ModalPortal from '../../ModalPortal'

const OpenButton = styled.div`
  padding-top: 10px;
  border-top: 1px solid #ECF1F4;
  width: 100%;
  display: flex;
  justify-content: center;
  font-family: 'FuturaBookC', sans-serif;
  font-size: 18px;
  line-height: 30px;
  color: #F0640C;
`

const OpenModalButton = ({ order, handleGetOrders }) => {
  const [modalOpen, setIsOpen] = useState(false)

  const renderModal = () => {
    if (!modalOpen) return null
    const close = () => {
      setIsOpen(false)
    }

    return (
      <ModalPortal close={close}>
        <CardModal order={order} close={close} handleGetOrders={handleGetOrders} />
      </ModalPortal>
    )

  }

  return (
    <>
      <OpenButton onClick={() => setIsOpen(true)}>Открыть</OpenButton>
      {renderModal()}
    </>
  )
}

export default OpenModalButton
