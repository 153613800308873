import React from 'react'
import * as R from 'ramda'
import { connect } from 'react-redux'
import styled from 'styled-components'
import AddTransport from '../components/transport/AddTransportContainer'
import CategoryContainer from '../components/transport/CategoryContainer'
import AddTransportModal from '../components/transport/AddTransportModal'
import CategoryModal from '../components/transport/CategoryModal'
import ActiveBlock from '../components/transport/ActiveBlock'
import CategoriesBlock from '../components/transport/CategoriesBlock'
import TableBlock from '../components/transport/TableBlock'
import Search from '../components/transport/Search'
import DeleteTransportModal from '../components/transport/DeleteTransportModal'
import MobileTransport from '../components/transport/MobileTransport'
import MobileCategoriesDropDown from '../components/transport/MobileCategoriesDropDown'
import { menuHorizontal } from '../assets/img/index'
import { useWebSockets } from '../context'
import assets from '../assets'
import {
  getTransportRequest,
  getTransportCategoryRequest,
  deleteTransportRequest,
  getSelectedCategory,
  getSearchedTransport,
  getTransportStatusesRequest,
  getStatusesTransport,
  getSelectedStatus,
  getSortTransport,
} from '../modules/transport'
import { getAccessListForUser, getAmoUsersRequest } from '../modules/users'
import AccessForbiddenPopup from '../components/common/AccessForbidden'

const Wrap = styled.div`
  background: #ecf1f4;
  display: flex;
  flex: 1;
  overflow-y: scroll;
  padding: 0 10px;
  flex-direction: column;
`

const TransportHeading = styled.div`
  font-family: FuturaMediumC;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  text-transform: uppercase;
  color: #283642;
`

const ActiveContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 13px;
  justify-content: space-between;
`

const TypeContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 25px;
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    justify-content: flex-start;
  }
`

const PlusIcon = styled.img.attrs({ src: assets.img.plusIcon })`
  position: relative;
  width: 15px;
  height: 15px;
`

const PlusContainer = styled.div`
  position: absolute;
  width: 48px;
  height: 48px;
  right: 10px;
  bottom: 10px;
  background: #ffffff;
  box-shadow: 0px 6px 14px rgba(2, 9, 75, 0.12);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center
`

const FlexRow = styled.div`
  display: flex;
`

const TableContainer = styled.div`
  margin-top: 22px;
`

const MenuIcon = styled.img.attrs({ src: menuHorizontal })`
  width: 25px;
  transform: ${props => (props.isMenuOpen ? 'rotate(90deg)' : '0')};
  margin-right: 8px;
`

const SubHeading = styled.div`
  font-family: FuturaMediumC;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;

  color: #000000;

  opacity: 0.4;
`

const TransportDump = ({
  getTransportRequest,
  getTransportCategoryRequest,
  getAmoUsersRequest,
  selectedCategory,
  searchedTransport,
  getTransportStatusesRequest,
  statuses,
  selectedStatus,
  selectedSort,
  accessList,
}) => {
  const [modalShow, setModalShow] = React.useState(false)

  const [editAccess, setEditAccesses] = React.useState([])

  const [viewAccess, setViewAccess] = React.useState([])

  const [modalViewShow, setModalViewShow] = React.useState(false)

  const { updatedData } = useWebSockets()

  const transportEvents = ['newTransport', 'deleteTransport', 'updateTransport']

  const transportCategoryEvents = [
    'updateTransportCategory',
    'deleteTransportCategory',
    'newTransportCategory',
  ]

  React.useEffect(() => {
    if (updatedData) {
      if (transportEvents.includes(updatedData.action)) {
        getTransportRequest()
      }
      if (transportCategoryEvents.includes(updatedData.action)) {
        getTransportCategoryRequest()
      }
    }
  }, [updatedData])

  React.useEffect(() => {
    if (!accessList.length) return
    let view = accessList.filter(
      access => access.module === 'transports' && access.accessType === 'view',
    )
    let edit = accessList.filter(
      access => access.module === 'transports' && access.accessType === 'edit',
    )
    setEditAccesses(edit)
    setViewAccess(view)
    setModalViewShow(!view.length)
  }, [accessList, accessList.length])

  const [activeBlock, setActiveBlock] = React.useState(1)
  const [typeBlock, setTypeBlock] = React.useState(0)
  const [isOpenAddTransportModal, setIsOpenAddTransportModal] = React.useState(
    false,
  )
  const [isOpenAddCategoryModal, setIsOpenAddCategoryModal] = React.useState(
    false,
  )
  const [isCreatingNewCategory, setIsCreatingNewCategory] = React.useState(
    false,
  )
  const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false)
  const [isEditModalOpen, setIsEditModalOpen] = React.useState(false)
  const [numberForDelete, setNumberForDelete] = React.useState('')
  const [id, setId] = React.useState('')
  const [update, setUpdate] = React.useState(false)
  const [width, setWidth] = React.useState(window.innerWidth)
  const [isMenuOpen, setIsMenuOpen] = React.useState(false)
  const [chosenId, setChosenId] = React.useState(0)
  const [editNumber, setEditNumber] = React.useState('')
  const [editCapacity, setEditCapacity] = React.useState('')
  const [editBrand, setEditBrand] = React.useState('')
  const [categoryId, setCategoryId] = React.useState(0)
  const [status, setStatus] = React.useState('')
  const [chosenDriver, setChosenDriver] = React.useState(0)
  const [
    isMobileCategoriesDropDownOpen,
    setIsMobileCategoriesDropDownOpen,
  ] = React.useState(false)
  React.useEffect(() => {
    window.addEventListener('resize', () => setWidth(window.innerWidth))
  }, [])

  const openPopUp = React.useCallback(
    ({ id, number, capacity, brand, categoryId, status, driver }) => {
      setIsEditModalOpen(true)
      setChosenId(id)
      setEditNumber(number)
      setEditCapacity(capacity)
      setEditBrand(brand)
      setCategoryId(categoryId)
      setStatus(status)
      setChosenDriver(driver)
    },
    [setIsEditModalOpen],
  )

  React.useEffect(() => {
    getTransportRequest()
    getTransportCategoryRequest()
    getTransportStatusesRequest()
  }, [
    getTransportRequest,
    getTransportCategoryRequest,
    getAmoUsersRequest,
    isOpenAddCategoryModal,
    isOpenAddTransportModal,
    isDeleteModalOpen,
    isCreatingNewCategory,
    isEditModalOpen,
    update,
    selectedCategory,
    selectedStatus,
    selectedSort,
    searchedTransport,
    getTransportStatusesRequest,
    accessList,
  ])

  return modalViewShow ? (
    <AccessForbiddenPopup
  moduleName={'Транспорт'}
  setModalShow={setModalViewShow}
  canClose={false}
  />
  ) : (
    <Wrap>
      <>
        {modalShow && (
          <AccessForbiddenPopup
  moduleName={'Транспорт'}
  setModalShow={setModalShow}
  />
        )}

        {isOpenAddTransportModal && (
          <AddTransportModal
            setIsOpenAddTransportModal={setIsOpenAddTransportModal}
          />
        )}
        {isDeleteModalOpen && (
          <DeleteTransportModal
            setIsDeleteModalOpen={setIsDeleteModalOpen}
            number={numberForDelete}
            id={id}
            editAccess={editAccess}
            setModalShow={setModalShow}
          />
        )}
        {isOpenAddCategoryModal && (
          <CategoryModal
            setIsOpenAddCategoryModal={setIsOpenAddCategoryModal}
            isCreatingNewCategory={isCreatingNewCategory}
            setIsCreatingNewCategory={setIsCreatingNewCategory}
            setUpdate={setUpdate}
            update={update}
          />
        )}
        {width >= 768 && (
          <ActiveContainer>
            <FlexRow>
              <ActiveBlock
                setActiveBlock={setActiveBlock}
                activeBlock={activeBlock}
                statuses={statuses}
                width={width}
              />
            </FlexRow>
            <FlexRow>
              {width >= 1440 && (
                <CategoryContainer
                  setIsOpenAddCategoryModal={setIsOpenAddCategoryModal}
                  editAccess={editAccess}
                  setModalShow={setModalShow}
                  width={width}
                />
              )}
              {width >= 1024 && (
                <AddTransport
                  editAccess={editAccess}
                  setModalShow={setModalShow}
                  isOpenAddTransportModal={isOpenAddTransportModal}
                  setIsOpenAddTransportModal={setIsOpenAddTransportModal}
                />
              )}
            </FlexRow>
          </ActiveContainer>
        )}
        {width < 767 && (
          <FlexRow
            style={{
              marginTop: 15,
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <TransportHeading>ТРАНСПОРТ</TransportHeading>
            <MenuIcon
              alt="menu"
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              isMenuOpen={isMenuOpen}
            />
          </FlexRow>
        )}
        {width >= 768 && (
          <TypeContainer>
            <CategoriesBlock
              setTypeBlock={setTypeBlock}
              typeBlock={typeBlock}
              width={width}
            />
            <div style={{ display: 'flex' }}>
              {width < 1440 && (
                <CategoryContainer
                  setIsOpenAddCategoryModal={setIsOpenAddCategoryModal}
                  editAccess={editAccess}
                  setModalShow={setModalShow}
                  width={width}
                />
              )}
              {width >= 1024 && <Search width={width} />}
            </div>
          </TypeContainer>
        )}

        <FlexRow
          style={{
            marginTop: 10,
            marginLeft: 5,
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          {width < 1024 && width >= 768 && <Search width={width} />}
          {width < 1024 && width >= 768 && (
            <AddTransport
              editAccess={editAccess}
              setModalShow={setModalShow}
              isOpenAddTransportModal={isOpenAddTransportModal}
              setIsOpenAddTransportModal={setIsOpenAddTransportModal}
            />
          )}
        </FlexRow>
        {width >= 768 && (
          <TableContainer>
            <TableBlock
              setNumberForDelete={setNumberForDelete}
              setIsDeleteModalOpen={setIsDeleteModalOpen}
              setId={setId}
              setIsEditModalOpen={setIsEditModalOpen}
              isEditModalOpen={isEditModalOpen}
              editAccess={editAccess}
              setModalShow={setModalShow}
              chosenId={chosenId}
              setChosenId={setChosenId}
              editNumber={editNumber}
              setEditNumber={setEditNumber}
              editCapacity={editCapacity}
              setEditCapacity={setEditCapacity}
              editBrand={editBrand}
              setEditBrand={setEditBrand}
              categoryId={categoryId}
              setCategoryId={setCategoryId}
              status={status}
              setStatus={setStatus}
              chosenDriver={chosenDriver}
              setChosenDriver={setChosenDriver}
              openPopUp={openPopUp}
              width={width}
            />
          </TableContainer>
        )}
        {width < 768 && isMenuOpen && (
          <>
            <FlexRow style={{ justifyContent: 'center' }}>
              <Search width={width} />
            </FlexRow>

            <FlexRow
              style={{
                marginTop: 20,
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <SubHeading>Категории ТС</SubHeading>
              <SubHeading style={{ color: '#F0640C', fontWeight: 'bold' }}     onClick={() =>
                editAccess ? setIsOpenAddCategoryModal(true) : setModalShow(true)
              }>
                Изменить
              </SubHeading>
            </FlexRow>
            <FlexRow style={{ justifyContent: 'center' }}>
              <MobileCategoriesDropDown
                isMobileCategoriesDropDownOpen={isMobileCategoriesDropDownOpen}
                setIsMobileCategoriesDropDownOpen={
                  setIsMobileCategoriesDropDownOpen
                }
              />
            </FlexRow>
            <FlexRow style={{ justifyContent: 'center' }}>
              <SubHeading style={{ marginTop: 20 }}>Активность</SubHeading>
            </FlexRow>
            <FlexRow style={{ justifyContent: 'center' }}>
              <ActiveBlock
                setActiveBlock={setActiveBlock}
                activeBlock={activeBlock}
                statuses={statuses}
                width={width}
              />
            </FlexRow>
          </>
        )}

        {width < 768 && (
          <MobileTransport
            isMobileCategoriesDropDownOpen={isMobileCategoriesDropDownOpen}
            setIsMobileCategoriesDropDownOpen={
              setIsMobileCategoriesDropDownOpen
            }
            editAccess={editAccess}
            setIsEditModalOpen={setIsEditModalOpen}
            isEditModalOpen={isEditModalOpen}
            setModalShow={setModalShow}
            chosenId={chosenId}
            setChosenId={setChosenId}
            editNumber={editNumber}
            setEditNumber={setEditNumber}
            editCapacity={editCapacity}
            setEditCapacity={setEditCapacity}
            editBrand={editBrand}
            setEditBrand={setEditBrand}
            categoryId={categoryId}
            setCategoryId={setCategoryId}
            status={status}
            setStatus={setStatus}
            chosenDriver={chosenDriver}
            setChosenDriver={setChosenDriver}
            openPopUp={openPopUp}
            width={width}
          />
        )}

        {width < 768 && (
          <PlusContainer onClick={() => setIsOpenAddTransportModal(true)}>
            <PlusIcon />
          </PlusContainer>
        )}
      </>
    </Wrap>
  )
}

const Transport = connect(
  R.applySpec({
    selectedCategory: getSelectedCategory,
    searchedTransport: getSearchedTransport,
    statuses: getStatusesTransport,
    selectedStatus: getSelectedStatus,
    selectedSort: getSortTransport,
    accessList: getAccessListForUser,
  }),
  {
    getTransportRequest,
    getTransportCategoryRequest,
    deleteTransportRequest,
    getAmoUsersRequest,
    getTransportStatusesRequest,
  },
)(TransportDump)

export default Transport
