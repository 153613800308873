import styled from 'styled-components'

const getDotBackground = order => {
  const { orderState } = order

  if (orderState === 'realize') {
    return '#0BD781'
  } else if (orderState === 'notRealize') {
    return '#FAB400'
  } else if (orderState === 'deleted') {
    return '#F55555'
  }
}

const RealizeDot = styled.div`
  background: ${({ order }) => getDotBackground(order)};
  border-radius: 50%;
  width: 5px;
  height: 5px;
`

export default RealizeDot
