import React from 'react'
import { FormControl, Select } from '@material-ui/core'

import useStyles from './styles'

const SearchSelectInfo = ({
  input,
  label,
  meta: { touched, error },
  children,
  name,
  count,
  ...custom
}) => {
  const classes = useStyles()

  return (
    <FormControl className={classes.wrapper} error={touched && error}>
      <div className={classes.iconWrapper}>
        <span>{count}</span>
      </div>
      <Select
        variant="outlined"
        className={classes.select}
        autoWidth
        {...input}
        {...custom}
        inputProps={{
          name,
          classes: {
            icon: classes.icon,
          },
        }}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
          classes: {
            paper: classes.menu,
          },
        }}
      >
        {children}
      </Select>
    </FormControl>
  )
}

export default SearchSelectInfo
