import React from 'react'
import styled from 'styled-components'

import AnalyticsCount from './analytics-count'
import AnalyticsPagination from './analytics-pagination'

const Wrapper = styled.div`
  margin-top: 30px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const AnalyticsFooter = ({
  countOnPage,
  setCountOnPage,
  page,
  setPage,
  infoTotal,
  isMain,
}) => {
  return (
    <Wrapper>
      <AnalyticsCount
        countOnPage={countOnPage}
        setCountOnPage={setCountOnPage}
      />
      <AnalyticsPagination
        isMain={isMain}
        infoTotal={infoTotal}
        countOnPage={countOnPage}
        page={page}
        setPage={setPage}
      />
    </Wrapper>
  )
}

export default AnalyticsFooter
