import React, { useCallback } from 'react'
import styled from 'styled-components'
import { useAtom } from '@reatom/react'
import { Modal } from '@material-ui/core'

import {
  ButtonBase,
  ButtonWhite,
  HorizontalScrollableRadioGroup,
} from './commons'
import { Map as MapBase } from './map'
import { Suggestions as SuggestionsBase } from './suggestions'
import { ArrowLong, Direction } from '../../assets/img/arrows'

import { useOnClickOutside, btTablet, ltTablet } from './utils'
import { favoriteAddresses as favoriteAddressesAtom } from '../../reatom/basket'

import { location, search, closeMenu } from '../../assets/img'

const MainWrapper = styled.div`
  width: 100%;
  @media screen and (min-width: 320px) and (max-width: 767px) {
    width: 95%;
  }
`

const Button = styled(ButtonBase)`
  width: 100%;
  min-height: 38px;
  padding: 11px 11px 9px;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    box-shadow: 0 4px 10px rgba(247, 137, 68, 0.55);
  }
  &:disabled {
    cursor: initial;
    box-shadow: initial;
  }
  transition: 225ms;

  border-radius: 6px;
  border: 1px solid #F49355;
`

const IconLocation = styled.img`
  height: 17px;
`

const Container = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  outline: 0;
  width: 100%;
  height: 67%;
  ${ltTablet} {
    height: 100%;
  }
  margin-top: auto;
`

const Wrapper = styled.div`
  height: calc(100% - 40px);
  padding: 0 40px;

  ${ltTablet} {
    height: 100%;
  }

  display: flex;
  ${ltTablet} {
    position: relative;
    flex-direction: column-reverse;
  }
`

const Map = styled(MapBase)`
  ${btTablet} {
    flex: 2;
  }
  ${ltTablet} {
    flex: 1;
  }
`

const Suggestions = styled(SuggestionsBase)`
  ${btTablet} {
    flex: 1;
  }
`

const CloseText = styled.div`
  margin: 10px 0;
  ${ltTablet} {
    display: none;
  }

  cursor: pointer;
  font-family: 'FuturaBookC', sans-serif;
  text-align: center;
  font-size: 15px;
  color: #ffffff;
`

const CloseButton = styled(ButtonWhite)`
  width: 38px;
  height: 38px;
  padding: 0;

  position: absolute;
  left: 15px;
  top: 30px;

  display: inline-flex;
  ${btTablet} {
    display: none;
  }

  background: #ffffff;
  box-shadow: 0 2px 5px rgba(10, 21, 128, 0.1);
  border-radius: 6px;
`

const Arrow = styled(ArrowLong)`
  height: 12px;
  margin: auto;

  stroke: #35414c;
`

const RadioGroup = styled(HorizontalScrollableRadioGroup)`
  margin-top: auto;
  overflow-y: scroll;
  overflow-x: hidden;
  ::-webkit-scrollbar {
    display: none;
  }
  @media screen and (min-width: 320px) and (max-width: 767px) {
    max-height: 300px;
  }
`

const TmpValueAddress = styled.div`
  font-style: normal;
  font-family: 'FuturaBookC', sans-serif;
  font-size: 16px;
  color: rgba(18, 22, 26, 0.4);
`

const DestinationButton = styled(ButtonBase)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 10px;
  background-color: white;
  color: #495560;
  margin-right: 5px;
  padding: 10px 15px;
  border-radius: 6px;
  width: 400px;
  border: ${({ selected }) => (selected ? '1px solid ' : 'null')};
  height: ${({ selected }) => (selected ? '66px' : '46px')};
  ${({ selected }) => (selected ? 'border-color: #FF9F63;' : '')}
  box-shadow: 0px 2px 5px rgba(10, 21, 128, 0.1);
  @media screen and (min-width: 320px) and (max-width: 767px) {
    width: 270px;
  }
`

const DestinationButtonText = styled.div`
  font-family: 'FuturaBookC', sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  color: #35414c;
  max-width: 360px;
  display: flex;
  align-items: flex-start;
`

const ChosenButton = styled.div`
  display: ${({ selected }) => (selected ? 'block' : 'none')};
  margin-top: auto;
  color: #f0640c;
`

const WhereToDeliverModal = styled.div`
  outline: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-left: auto;
  margin-right: auto;
  bottom: 200px;
  z-index: 1000;
  width: 460px;
  max-height: 530px;
  min-height: 530px;
  border-radius: 10px;
  background: #ffffff;

  @media screen and (min-width: 320px) and (max-width: 767px) {
    width: 300px;
    top: 50%;
    max-height: 500px;
    min-height: 500px;
  }
`

const Heading = styled.div`
  font-family: FuturaBookC, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 130%;
  padding-left: 30px;
  padding-top: 24px;
  color: #35414c;

  @media screen and (min-width: 320px) and (max-width: 767px) {
    font-size: 18px;
    padding-left: 15px;
  }
`
const Input = styled.input`
  width: 400px;
  height: 42px;
  margin-top: 26px;
  padding-left: 30px;
  margin-left: 30px;
  padding-right: 0;
  border: none;
  background-image: url(${search});
  background-repeat: no-repeat;
  background-position: left 10px center;
  background-size: 15px 15px;
  background-color: rgba(236, 241, 244, 0.7);
  outline: none;
  border-radius: 6px;
  font-family: FuturaBookC, sans-serif;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 19px;
  color: #8b949b;
  :focus {
    border: 1.5px solid rgba(240, 100, 12, 0.7);
  }

  @media screen and (min-width: 320px) and (max-width: 767px) {
    width: 270px;
    margin-left: 15px;
  }
`

const ShowOnMap = styled.div`
  display: flex;
  align-items: center;
  padding-left: 48px;
  margin-top: 12px;
  font-family: FuturaBookC, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  color: #f0640c;
  cursor: pointer;
  @media screen and (min-width: 320px) and (max-width: 767px) {
    padding-left: 30px;
  }
`

const ShowOnMapText = styled.div`
  padding-left: 12px;
`

const LastAddresses = styled.div`
  padding-left: 30px;
  padding-top: 20px;
  font-family: FuturaBookC, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 130%;
  color: #8b949b;
  @media screen and (min-width: 320px) and (max-width: 767px) {
    padding-left: 15px;
  }
`

const CloseModal = styled.div`
  font-family: FuturaBookC, sans-serif;
  font-style: normal;
  font-weight: normal;
  cursor: pointer;
  font-size: 15px;
  line-height: 20px;
  color: #ffffff;
  position: absolute;
  z-index: 1000;
  left: 190px;
  bottom: -30px;
  @media screen and (min-width: 320px) and (max-width: 767px) {
    left: 120px;
  }
`

const MobileClose = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Close = styled.img.attrs({ src: closeMenu })`
  margin-top: 21px;
  margin-right: 21px;
  @media screen and (min-width: 768px) {
    display: none;
  }
`

export const Address = ({
  className,
  input: { onChange, value, onBlur },
  meta: { error, touched },
  disabled,
}) => {
  const [inputValue, setInputValue] = React.useState('')
  const [isShowAddress, setIsShowAddress] = React.useState(false)
  const [isShowSuggestions, setIsShowSuggestions] = React.useState(false)
  const [tmpValue, setTmpValue] = React.useState(value)
  const [isOpen, setIsOpen] = React.useState(false)
  const favoriteAddresses = useAtom(favoriteAddressesAtom).slice(0, 5)
  React.useEffect(() => {
    setTmpValue(value)
  }, [value])

  React.useEffect(
    () => () => {
      if (isShowAddress === true) {
        onBlur()
      }
    },
    [isShowAddress, onBlur],
  )
  const handleRadioChange = useCallback(
    value => {
      onChange(
        favoriteAddresses.filter(address => address.address === value)[0],
      )
    },
    [favoriteAddresses, onChange],
  )
  // @ts-ignore
  const inputSearch = event => {
    setInputValue(event.target.value)
  }

  React.useEffect(() => {
    if (inputValue !== '') {
      setIsShowSuggestions(true)
    } else {
      setIsShowSuggestions(false)
    }
    if (isOpen === false) {
      setInputValue('')
    }
  }, [inputValue, isOpen])

  const root = useOnClickOutside(() => setIsOpen(false))

  return (
    <MainWrapper onClick={root}>
      <Button
        disabled={disabled}
        onClick={() => setIsOpen(true)}
        className={className}
        isError={error && touched}
      >
        <TmpValueAddress isActive={tmpValue?.address !== undefined}>
          {tmpValue.address ? tmpValue.address : 'Адрес доставки'}
        </TmpValueAddress>
        <IconLocation src={location} alt="location" />
      </Button>
      {isOpen && (
        <Modal open={isOpen} onClose={setIsOpen}>
          <WhereToDeliverModal>
            <MobileClose>
              <Heading>Куда доставить заказ?</Heading>

              <Close onClick={() => setIsOpen(false)} />
            </MobileClose>
            <Input
              placeholder="Введите адрес"
              value={inputValue}
              onChange={event => inputSearch(event)}
            />
            <ShowOnMap
              onClick={() => {
                setInputValue('')
                setIsShowAddress(true)
              }}
            >
              <IconLocation src={location} alt="location" />
              <ShowOnMapText>Указать на карте</ShowOnMapText>
            </ShowOnMap>
            {!isShowSuggestions && (
              <div>
                <LastAddresses>Последние адреса доставки</LastAddresses>
                <RadioGroup
                  value={value.address}
                  onChange={handleRadioChange}
                  items={favoriteAddresses.map(({ address }) => ({
                    value: address,
                  }))}
                >
                  {(id, label, value, selected, onChange) => (
                    <DestinationButton
                      id={id}
                      selected={selected}
                      onClick={() => {
                        setIsOpen(false)
                        onChange()
                      }}
                    >
                      <DestinationButtonText>{label}</DestinationButtonText>
                      <ChosenButton selected={selected}>Выбран</ChosenButton>
                    </DestinationButton>
                  )}
                </RadioGroup>
              </div>
            )}
            {isShowSuggestions && (
              <Suggestions
                value={tmpValue}
                onSubmit={address => {
                  onChange(address)
                }}
                onChange={setTmpValue}
                isShowSuggestions={isShowSuggestions}
                inputValue={inputValue}
                setIsOpen={setIsOpen}
                setIsShowAddress={setIsShowAddress}
                setInputValue={setInputValue}
              />
            )}

            <CloseModal onClick={() => setIsOpen(false)}>Закрыть</CloseModal>
          </WhereToDeliverModal>
        </Modal>
      )}
      <Modal open={isShowAddress} onClose={setIsShowAddress}>
        <Container>
          <CloseText onClick={() => setIsShowAddress(false)}>Закрыть</CloseText>
          <Wrapper>
            <Suggestions
              value={tmpValue}
              onSubmit={address => {
                onChange(address)
              }}
              onChange={setTmpValue}
              isShowSuggestions={isShowSuggestions}
              inputValue={inputValue}
              setIsOpen={setIsOpen}
              setIsShowAddress={setIsShowAddress}
              setInputValue={setInputValue}
            />
            <Map value={tmpValue} onChange={setTmpValue} />
            <CloseButton onClick={() => setIsShowAddress(false)}>
              <Arrow direct={Direction.Left} />
            </CloseButton>
          </Wrapper>
        </Container>
      </Modal>
    </MainWrapper>
  )
}
