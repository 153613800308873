import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { makeStyles } from '@material-ui/core/styles'
import { Table, TableBody, TableContainer, TableHead } from '@material-ui/core'
import { connect } from 'react-redux'
import * as R from 'ramda'
import { format } from 'date-fns'

import { StyledTableCell } from './table-cell'
import { StyledTableRow } from './table-row'

import { getAnalyticsInfo } from '../../../../modules/analytics'

import assets from '../../../../assets'

const Container = styled.div`
  width: 1020px;
  display: flex;
  margin: 15px 0;

  max-height: 600px;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
`

const SortWrapper = styled.div`
  position: absolute;
  right: 12px;
  top: 11px;
  cursor: pointer;
  ${({ isActive }) =>
    isActive &&
    css`
      top: 13px;
      transform: rotate(180deg);
    `}
`

const StatusWrapper = styled.div`
  display: flex;
  align-items: center;
`

const SortIcon = styled.img.attrs({ src: assets.img.sortIcon })``
const Open = styled.img.attrs({ src: assets.img.openIcon })`
  margin-right: 5px;
  margin-bottom: 2px;
`
const Closed = styled.img.attrs({ src: assets.img.closedIcon })`
  margin-right: 5px;
  margin-bottom: 2px;
`

const useStyles = makeStyles({
  table: {
    borderCollapse: 'separate',
    borderSpacing: '4px',
  },
})

const DriversInfo = ({ organizationAnalyticsInfo }) => {
  const classes = useStyles()
  const [sort, setSort] = useState(false)

  return (
    <TableContainer component={Container}>
      <Table className={classes.table}>
        <TableHead>
          <StyledTableRow>
            <StyledTableCell isFirst>Номер заявки</StyledTableCell>
            <StyledTableCell>Дата создания</StyledTableCell>
            <StyledTableCell minWidth={120}>
              Товар
              <SortWrapper isActive={sort} onClick={() => setSort(!sort)}>
                <SortIcon />
              </SortWrapper>
            </StyledTableCell>
            <StyledTableCell>Кол-во</StyledTableCell>
            <StyledTableCell>ед. изм.</StyledTableCell>
            <StyledTableCell>Цена за единицу</StyledTableCell>
            <StyledTableCell>Документы</StyledTableCell>
            <StyledTableCell>Статус ТТН по товару заявки</StyledTableCell>
            <StyledTableCell>Статус заявки</StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {organizationAnalyticsInfo &&
            organizationAnalyticsInfo.orders &&
            organizationAnalyticsInfo.orders
              .sort((a, b) => {
                if (sort) {
                  if (a.productName < b.productName) {
                    return -1
                  }
                  if (a.productName > b.productName) {
                    return 1
                  }
                  return 0
                } else {
                  if (a.productName > b.productName) {
                    return -1
                  }
                  if (a.productName < b.productName) {
                    return 1
                  }
                  return 0
                }
              })
              .map(item => (
                <StyledTableRow key={item.id}>
                  <StyledTableCell isFirst>{item.id}</StyledTableCell>
                  <StyledTableCell>
                    {format(new Date(item.createdAt), 'dd.MM.yyyy')}
                  </StyledTableCell>
                  <StyledTableCell>{item.productName}</StyledTableCell>
                  <StyledTableCell>{item.count}</StyledTableCell>
                  <StyledTableCell>{item.measurement}</StyledTableCell>
                  <StyledTableCell>
                    {Math.ceil(Number(item.pricePerOne))} ₽
                  </StyledTableCell>
                  <StyledTableCell>
                    {organizationAnalyticsInfo.documents &&
                    organizationAnalyticsInfo.documents.length > 0
                      ? 'Есть'
                      : 'Нет'}
                  </StyledTableCell>
                  <StyledTableCell>
                    {item.ttn ? (
                      <div
                        style={{
                          color: 'rgb(11,215,129)',
                        }}
                      >
                        Есть
                      </div>
                    ) : (
                      'Нет'
                    )}
                  </StyledTableCell>
                  <StyledTableCell>
                    {item.status === 'Открытая' ? (
                      <StatusWrapper>
                        <Open /> {item.status}
                      </StatusWrapper>
                    ) : (
                      <StatusWrapper>
                        <Closed /> {item.status}
                      </StatusWrapper>
                    )}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default connect(
  R.applySpec({
    organizationAnalyticsInfo: getAnalyticsInfo,
  }),
)(DriversInfo)
