const customTooltip = function(tooltipModel) {
  // Tooltip Element
  let tooltipEl = document.getElementById('chartjs-tooltip')


  const chartID = this._chartInstance.id
  // Create element on first render
  if (!tooltipEl) {
    tooltipEl = document.createElement('div')
    tooltipEl.id = 'chartjs-tooltip'
    tooltipEl.innerHTML = '<div id="tooltipRoot" style="padding-top: 10px;padding-left: 20px"></div>'
    document.body.appendChild(tooltipEl)
  }

  // Hide if no tooltip
  if (tooltipModel.opacity === 0) {
    tooltipEl.style.opacity = 0
    return
  }

  // Set caret Position
  tooltipEl.classList.remove('above', 'below', 'no-transform')
  if (tooltipModel.yAlign) {
    tooltipEl.classList.add(tooltipModel.yAlign)
  } else {
    tooltipEl.classList.add('no-transform')
  }

  // Set Text
  if (tooltipModel.body) {
    let titleLines = tooltipModel.title || []
    const bodyLines = tooltipModel.dataPoints.map((item, index) =>
      `<div style='width: 100%; margin-bottom: 13px; display: flex; align-items: center' >
          <div style='width: 14px;height: 14px;background:${index ? '#B9C2C8' : '#F0640C'}; border-radius: 50%'></div>
          <div style='margin-left: 8px'> ${item.value} ${chartID ? '' : '₽'}</div>
       </div>`,
    )
      .join(' ')


    titleLines = titleLines.map(title => `<div>${title}</div>`).join(' ')

    const innerHtml = `
    <div style='text-transform: capitalize; margin-bottom: 10px'>${titleLines}</div>
    <div style='width: 100%'>${bodyLines}</div>
    `

    let tableRoot = tooltipEl.querySelector('#tooltipRoot')
    tableRoot.innerHTML = innerHtml
  }

  // `this` will be the overall tooltip
  let position = this._chart.canvas.getBoundingClientRect()

  const calculateXPosition = () => {
    if(position.width > tooltipModel.caretX + 200){
      return `${position.left + window.pageXOffset + tooltipModel.caretX}px`
    }
    return `${position.left + window.pageXOffset + tooltipModel.caretX - 160}px`

  }

  // Display, position, and set styles for font
  tooltipEl.style.opacity = '1'
  tooltipEl.style.position = 'absolute'
  tooltipEl.style.left = calculateXPosition()
  tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px'
  tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily
  tooltipEl.style.fontSize = '16px'
  tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle
  tooltipEl.style.pointerEvents = 'none'
  tooltipEl.style.background = '#6A6A6A'
  tooltipEl.style.boxShadow = '0px 4px 15px rgba(0, 0, 0, 0.25);'
  tooltipEl.style.borderRadius = '5px'
  tooltipEl.style.color = '#FFFFFF'
  tooltipEl.style.width = '160px'
  tooltipEl.style.height = '100px'

}

export default customTooltip